import { fetchMeasureDataRegist } from "api/measure";
import {
  measureModalConfirmState,
  measureModalResultCompleteState,
  measureRecordingInfoState,
} from "atoms/measure.state";
import MeasureChartRechart from "components/measure/components/measure.chart.rechart";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  MeasureChartWrapper,
  RecordingInfoWrap,
  SimpleMeasureRecordingWrap,
} from "style/measure";
import {
  formatDateTimeAddSec,
  formatDateTimeAddSecUDTVer,
  getKSTTime,
  getKSTTimeForLogs,
  writeWavHeader,
} from "utils/util";
import MeasureRecordingHeader from "./measure.recording.header";
import { calculateElapsedTime } from "utils/util"; // ✅ `util.ts`에서 가져오기
import { LegendIcon } from "cssReset/icon";
import MeasureLegend from "components/measure/components/measure.legend";
import ModalCenterLayout from "components/modal/modal.center.layout";
import ModalMeasureResultConfirm from "./modal/modal.measure.reult.confirm";
import { closeModal } from "utils/modal.util";
import ModalMeasureResultComplete from "./modal/modal.measure.result.complete";

const SimpleMeasureRecording = () => {
  const navigate = useNavigate();
  const [dB, setDb] = useState(0);
  const [isRecording, setIsRecording] = useState(false);
  const [logs, setLogs] = useState<{ time: string; value: number }[]>([]);
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null);
  const measureRecordingInfo = useRecoilValue(measureRecordingInfoState);

  // 상태값 추가
  const audioContextRef = useRef<AudioContext | null>(null);
  const analyserRef = useRef<AnalyserNode | null>(null);
  const audioChunksRef = useRef<Float32Array[]>([]);
  const scriptProcessorRef = useRef<ScriptProcessorNode | null>(null);
  const mediaStreamRef = useRef<MediaStream | null>(null);
  const [legendState, setLegendState] = useState(false);

  // ✅ 녹음 시작/종료 시간 저장
  const [startDt, setStartDt] = useState<string | null>(null);
  const [endDt, setEndDt] = useState<string | null>(null);
  const [currentTime, setCurrentTime] = useState(
    formatDateTimeAddSec(String(new Date()))
  ); // 현재 시간
  const [elapsedTime, setElapsedTime] = useState("00:00:00"); // 경과 시간
  console.log("currentTime: ", currentTime);
  console.log("startDt: ", startDt);
  console.log("for", formatDateTimeAddSec(String(startDt)));

  // ✅ 2시간 자동 종료 타이머
  const stopTimerRef = useRef<NodeJS.Timeout | null>(null);

  // 기존 상태값
  const dataArrayRef = useRef<Uint8Array | null>(null);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  // 녹음을 종료하고 WAV 파일을 생성하는 동안 로딩 UI 표시
  const [isUploading, setIsUploading] = useState(false); // ✅ 로딩 상태 추가

  // modal
  const [measureModal, setMeasureModal] = useRecoilState(
    measureModalConfirmState
  );
  const [measureResultModal, setMeasureResultModal] = useRecoilState(
    measureModalResultCompleteState
  );

  useEffect(() => {
    if (!measureRecordingInfo.location) {
      navigate("/measure/simple/step");
    }
  }, [measureRecordingInfo]);

  useEffect(() => {
    return () => {
      // ✅ 중복 호출 방지: `isRecording`이 `true`일 때만 stopRecording 실행
      if (isRecording) {
        console.log("🚫 useEffect에서 `stopRecording()` 호출 제거");
      }
    };
  }, []);

  useEffect(() => {
    // 현재 시간 업데이트 (1초마다 실행)
    const currentTimeInterval = setInterval(() => {
      setCurrentTime(formatDateTimeAddSec(String(new Date())));
    }, 1000);

    return () => clearInterval(currentTimeInterval);
  }, []);

  useEffect(() => {
    const elapsedTimeInterval = calculateElapsedTime(
      startDt,
      isRecording,
      setElapsedTime
    );

    return () => {
      if (elapsedTimeInterval) {
        clearInterval(elapsedTimeInterval);
      }
    };
  }, [startDt, isRecording]); // ✅ `isRecording` 추가하여 상태 변화 감지

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });

      audioContextRef.current = new (window.AudioContext ||
        (window as any).webkitAudioContext)();
      const audioContext = audioContextRef.current;
      mediaStreamRef.current = stream;

      const source = audioContext.createMediaStreamSource(stream);
      analyserRef.current = audioContext.createAnalyser();
      scriptProcessorRef.current = audioContext.createScriptProcessor(
        4096,
        1,
        1
      );

      source.connect(analyserRef.current);
      analyserRef.current.connect(scriptProcessorRef.current);
      scriptProcessorRef.current.connect(audioContext.destination);

      audioChunksRef.current = []; // 🔥 녹음 시작 시 배열 초기화

      scriptProcessorRef.current.onaudioprocess = (event) => {
        const inputBuffer = event.inputBuffer.getChannelData(0);
        audioChunksRef.current.push(new Float32Array(inputBuffer)); // 🔥 Float32Array 저장 (오류 없음)
      };

      setIsRecording(true);
      setStartDt(getKSTTime()); // ✅ 녹음 시작 시간 저장
      startMeasuring();

      // ✅ 2시간 후 자동 정지 타이머 설정
      stopTimerRef.current = setTimeout(() => {
        console.log("⏳ 2시간이 지나 녹음을 자동으로 종료합니다.");
        stopRecording();
      }, 2 * 60 * 60 * 1000); // 2시간 (120분 * 60초 * 1000ms)
    } catch (error) {
      console.error("마이크 접근 실패: ", error);
    }
  };

  const stopRecording = async () => {
    console.log("🛑 stopRecording() 호출됨");

    if (!isRecording) {
      console.log("🚫 이미 중지된 상태, 실행 안 함");
      return;
    }

    setIsRecording(false);
    setEndDt(getKSTTime()); // ✅ 녹음 종료 시간 저장

    // ✅ 자동 정지 타이머 해제
    if (stopTimerRef.current) {
      clearTimeout(stopTimerRef.current);
      stopTimerRef.current = null;
    }

    if (scriptProcessorRef.current) {
      scriptProcessorRef.current.disconnect();
      scriptProcessorRef.current = null;
    }
    if (analyserRef.current) {
      analyserRef.current.disconnect();
      analyserRef.current = null;
    }
    if (audioContextRef.current) {
      audioContextRef.current.close();
      audioContextRef.current = null;
    }
    if (mediaStreamRef.current) {
      mediaStreamRef.current.getTracks().forEach((track) => track.stop());
      mediaStreamRef.current = null;
    }

    console.log("🟡 녹음된 오디오 데이터 개수:", audioChunksRef.current.length);

    // ✅ measureRecordingInfo.recordingOption이 "all"일 때만 WAV 생성
    if (
      measureRecordingInfo.recordingOption === "all" &&
      audioChunksRef.current.length > 0
    ) {
      const wavBlob = createWavBlob(audioChunksRef.current);
      console.log("🟢 WAV 파일 생성 완료:", wavBlob);
      setAudioBlob(wavBlob); // ✅ WAV 설정 후 `useEffect()`에서 업로드 실행
    } else {
      console.log("🚫 녹음 파일 생성 생략 (recordingOption이 'all'이 아님)");
      setAudioBlob(null); // 녹음 파일이 없도록 설정
    }

    if (!isUploading) {
      console.log("📤 업로드 시작: setIsUploading(true)");
      setIsUploading(true); // ✅ 업로드 시작
    } else {
      console.log("⚠️ 이미 업로드 중이므로 setIsUploading(true) 호출 안 함");
    }
  };

  const startMeasuring = () => {
    if (intervalRef.current) return;

    if (analyserRef.current) {
      dataArrayRef.current = new Uint8Array(
        analyserRef.current.frequencyBinCount
      );
    } else {
      console.error("❌ `analyserRef.current`가 없습니다.");
      return;
    }

    intervalRef.current = setInterval(() => {
      if (analyserRef.current && dataArrayRef.current) {
        analyserRef.current.getByteTimeDomainData(dataArrayRef.current);

        const rms = Math.sqrt(
          dataArrayRef.current.reduce(
            (sum, value) => sum + (value - 128) ** 2,
            0
          ) / dataArrayRef.current.length
        );

        let db = 20 * Math.log10(rms / 128);

        // 🚀 **Null 방지: `-Infinity` 또는 `NaN` 값이 들어오는 경우 기본값(40)으로 설정**
        if (!isFinite(db)) {
          db = -100; // 기본값 설정
        }

        // 🚀 null 방지: NaN 또는 Infinity일 경우 40~43 사이 랜덤 값 적용
        // if (isNaN(db) || db === -Infinity) {
        //   db = -60 + Math.random() * 3; // 40~43 사이의 랜덤 값
        // }

        const roundedDb = parseFloat(db.toFixed(2));
        const adjustedDb = roundedDb + 100; // 0~100 범위로 변환

        setDb(adjustedDb);

        // 시간 +9 후 입력
        const timestamp = getKSTTimeForLogs();

        setLogs((prevLogs) => [
          ...prevLogs,
          { time: timestamp, value: adjustedDb },
        ]);
      }
    }, 1000);
  };

  const createWavBlob = (audioData: Float32Array[]): Blob => {
    const sampleRate = 44100;
    const numChannels = 1;
    const bitsPerSample = 16;

    // 전체 데이터 크기 계산
    const bufferLength = audioData.length * audioData[0].length * 2;
    const wavBuffer = new ArrayBuffer(44 + bufferLength);
    const view = new DataView(wavBuffer);

    // WAV 헤더 추가
    writeWavHeader(view, sampleRate, numChannels, bitsPerSample, bufferLength);

    let offset = 44;
    for (const buffer of audioData) {
      for (let i = 0; i < buffer.length; i++) {
        const sample = Math.max(-1, Math.min(1, buffer[i])); // 클리핑 방지
        view.setInt16(offset, sample * 32767, true); // 16비트 PCM 변환
        offset += 2;
      }
    }

    return new Blob([wavBuffer], { type: "audio/wav" });
  };

  const recordStopClicked = async () => {
    console.log("🎤 녹음 중단 버튼 클릭됨! recordStopClicked() 실행");

    if (!isRecording) {
      console.log("🚫 이미 중지된 상태에서 중지 버튼 클릭됨, 실행 안 함");
      return;
    }

    stopRecording(); // ✅ 녹음 중단 (WAV 생성 후 `audioBlob` 설정)
  };

  useEffect(() => {
    if (isUploading) {
      console.log("🟢 업로드 시작... isUploading:", isUploading);

      if (measureRecordingInfo.recordingOption !== "all" || audioBlob) {
        console.log("📤 업로드 실행: uploadRecording() 호출");

        uploadRecording().then(() => {
          console.log("✅ 업로드 완료 후 isUploading을 false로 변경");
          setIsUploading(false); // ✅ 업로드 후 중복 실행 방지
        });
      } else {
        console.log("⏳ audioBlob을 기다리는 중...");
      }
    }
  }, [isUploading, audioBlob]);

  const uploadRecording = async () => {
    try {
      // ✅ 측정 데이터 생성
      const timestamp = formatDateTimeAddSec(String(new Date())); // 년도-월-일 시:분:초 포맷
      const formData = new FormData();

      formData.append(
        "data",
        JSON.stringify({
          result: JSON.stringify(logs),
          location: "서울",
          startDt, // ✅ 녹음 시작 시간 전송
          endDt, // ✅ 녹음 종료 시간 전송
        })
      );

      // ✅ measureRecordingInfo.recordingOption이 "all"일 때만 파일 추가
      if (measureRecordingInfo.recordingOption === "all" && audioBlob) {
        const fileName = `simple-measure_${timestamp}.wav`;
        formData.append("files", audioBlob, fileName);
        console.log("📤 WAV 파일 포함하여 업로드");
      } else {
        console.log("🚫 녹음 파일 제외하고 업로드");
      }

      const response = await fetchMeasureDataRegist(formData);
      console.log("✅ 측정 데이터 업로드 완료!", response);

      if (response.result) {
        //localStorage.setItem("logsData", JSON.stringify(logs)); // 로그인 하지 않는 유저일때만 로컬에 데이터 저장

        setLogs([]);
        setDb(0);
        setAudioBlob(null);
        closeModal(setMeasureModal);
        setMeasureResultModal({ isOpen: true, isVisible: true });
      }
    } catch (error) {
      console.error("❌ 업로드 중 오류 발생:", error);
    } finally {
      setIsUploading(false); // ✅ 업로드 완료 후 로딩 상태 해제
    }
  };

  const modalClosedClicked = () => {
    closeModal(setMeasureModal);
  };
  const modalOpenedClicked = () => {
    setMeasureModal({ isOpen: true, isVisible: true });
  };

  const modalClosedClickedResult = () => {
    closeModal(setMeasureResultModal);
  };

  return (
    <SimpleMeasureRecordingWrap>
      <MeasureRecordingHeader
        isRecording={isRecording}
        onStart={startRecording}
        onStop={modalOpenedClicked}
      />
      <RecordingInfoWrap>
        <div className="item">
          <div className="title">현재시간</div>
          <div className="value">{currentTime}</div>
        </div>
        <div className="item double">
          <div>
            <div className="title">측정시간</div>
            <div className="value">
              {startDt ? formatDateTimeAddSecUDTVer(startDt) : "-"}
            </div>
          </div>
          <div>
            <div className="title">경과시간</div>
            <div className="value">{elapsedTime}</div>
          </div>
        </div>
        <div className="item">
          <div className="title">측정장소</div>
          <div className="value">{measureRecordingInfo.location}</div>
        </div>
      </RecordingInfoWrap>
      <MeasureChartWrapper>
        <div className="title">
          <span>시간대별 소음도</span>
          <div className="legend-tooltip">
            <div
              className="icon-box"
              onClick={() => {
                setLegendState((prev) => !prev);
              }}
            >
              <LegendIcon />
            </div>
            {legendState && <MeasureLegend />}
          </div>
        </div>
        <MeasureChartRechart data={logs} isRecording={isRecording} />
      </MeasureChartWrapper>

      {isRecording && <div>로딩 중입니다.</div>}

      {measureModal && (
        <ModalCenterLayout
          closedHandler={modalClosedClicked}
          isVisible={measureModal.isVisible}
        >
          <ModalMeasureResultConfirm
            closedHandler={modalClosedClicked}
            measureStop={recordStopClicked}
          />
        </ModalCenterLayout>
      )}

      {measureResultModal && (
        <ModalCenterLayout
          closedHandler={modalClosedClickedResult}
          isVisible={measureResultModal.isVisible}
        >
          <ModalMeasureResultComplete endDt={endDt ?? ""} />
        </ModalCenterLayout>
      )}
    </SimpleMeasureRecordingWrap>
  );
};

export default SimpleMeasureRecording;
