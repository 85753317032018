import { loginHandler } from "api/login";
import { adminLoginModalState } from "atoms/admin/modal/modal.state";
import ModalCenterLayout from "components/modal/modal.center.layout";
import { PasswordInput } from "components/pieces/input";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useRecoilState } from "recoil";
import { EmailInputWrap, InputBox, LoginWrap } from "style/admin/login";
import ModalLogin from "./modal/modal.login";
import { useNavigate } from "react-router-dom";
import { Slide, ToastContainer, toast } from "react-toastify";
import { logoutToastStatus } from "atoms/auth.state";

const AdminLogin = () => {
  const { register, handleSubmit, watch } = useForm();
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const navigate = useNavigate();

  const [modalContent, setModalContents] = useState<{
    title: string | null;
    contents: string | null;
  }>({
    title: null,
    contents: null,
  });

  const adminIdValue = watch("adminId");
  const passwordValue = watch("password");

  const [loginModal, setLoginModal] = useRecoilState(adminLoginModalState);
  const [logoutState, setLogoutState] = useRecoilState(logoutToastStatus);

  // 로컬 스토리지를 확인하여 로그인 상태 판단
  useEffect(() => {
    const authState = localStorage.getItem("authState");
    const accessToken = localStorage.getItem("accessToken");

    if (authState && accessToken) {
      const { isAuthenticated } = JSON.parse(authState);

      if (isAuthenticated) {
        navigate("/admin/inquiry"); // 로그인 상태라면 /home으로 리다이렉트
        return;
      }
    }

    // authState 또는 accessToken이 없을 경우 /으로 리다이렉트
    navigate("/admin");
  }, [navigate]);

  useEffect(() => {
    setIsSubmitDisabled(
      !(adminIdValue?.length >= 1 && passwordValue?.length >= 1)
    );
  }, [adminIdValue, passwordValue]);

  const onSubmit = async (data: any) => {
    localStorage.clear();

    try {
      const response = await loginHandler({
        userId: data.adminId,
        password: data.password,
      });

      const { accessToken, refreshToken } = response.result;

      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("refreshToken", refreshToken);

      // 로컬 스토리지에 사용자 유형 저장
      localStorage.setItem(
        "authState",
        JSON.stringify({ isAuthenticated: true })
      );

      navigate("/admin/inquiry");
    } catch (error) {
      console.error(error);
      setModalContents({
        title: "알림",
        contents: "아이디와 비밀번호를 확인해주세요.",
      });
      loginModalOpened();
    }
  };

  useEffect(() => {
    if (logoutState) {
      loadToast();
      setLogoutState(false);
    }
  }, [logoutState]);

  // Enter 키 감지
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSubmit(onSubmit);
    }
  };

  // confirm delete modal
  const loginModalClosed = () => {
    setLoginModal({ isOpen: false, isVisible: false });
  };
  const loginModalOpened = () => {
    setLoginModal({ isOpen: true, isVisible: true });
  };

  const loadToast = () => {
    toast(`로그아웃 되었습니다.`, {
      position: "bottom-center",
      style: {
        backgroundColor: "rgba(0,0,0,0.83)",
        color: "#fff",
        fontSize: "1.2rem",
        borderRadius: "1rem",
        fontWeight: "500",
        letterSpacing: "-0.06rem",
        justifyContent: "center",
        padding: "0.8rem",
        minHeight: "auto",
        boxShadow: "1px 1px 4px 0 #0000004D",
      },
      transition: Slide,
    });
  };

  return (
    <LoginWrap>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="login-box">
          <div className="logo">
            <h1>LOGO</h1>
          </div>

          <EmailInputWrap>
            <InputBox>
              <input
                type="text"
                placeholder="아이디"
                {...register("adminId")}
              />
              <span></span>
            </InputBox>
            <PasswordInput
              name="password"
              placeholder="비밀번호"
              register={register}
            />
          </EmailInputWrap>
          <EmailInputWrap>
            <input
              type="submit"
              value="로그인"
              disabled={isSubmitDisabled}
              onKeyDown={handleKeyDown} // Enter 키 감지
              style={{
                backgroundColor: isSubmitDisabled ? "#cecece" : "#225298",
              }}
            />
          </EmailInputWrap>
        </div>
      </form>

      {/* 삭제 완료 후 모달 */}
      {loginModal.isOpen && (
        <ModalCenterLayout
          closedHandler={loginModalClosed}
          isVisible={loginModal.isVisible}
        >
          <ModalLogin
            closedHandler={loginModalClosed}
            title={modalContent.title ?? ""}
            contents={modalContent.contents ?? ""}
          />
        </ModalCenterLayout>
      )}

      <ToastContainer
        hideProgressBar
        closeButton={false}
        autoClose={1000}
        style={{
          padding: "1.6rem 3.2rem 4.7rem",
          borderRadius: "1rem",
        }}
      />
    </LoginWrap>
  );
};

export default AdminLogin;
