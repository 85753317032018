import { kakaoCodeHandler } from "api/login";
import { loginModalState } from "atoms/modal.state";
import ModalCenterLayout from "components/modal/modal.center.layout";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { closeModal } from "utils/modal.util";
import LoginAlertModal from "view/auth/modal/login.alert.modal";

const RedirectPage = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [loginModal, setLoginModal] = useRecoilState(loginModalState);
  const [modalTxt, setModalTxt] = useState<string | null>(null);

  const [searchParams] = useSearchParams();
  const code = searchParams.get("code") || "";
  const navigate = useNavigate();

  useEffect(() => {
    const kakaoLogin = async () => {
      if (isFetching) return; // 중복 호출 방지
      setIsFetching(true);

      let fcmToken: string | undefined;
      let isAndroid: boolean = false;

      // ✅ iOS WebView인 경우에만 토큰 요청
      if (window.webkit?.messageHandlers?.getFcmToken) {
        try {
          const tokenPromise = new Promise<string>((resolve) => {
            const handleToken = (event: any) => {
              if (event.detail?.token) {
                console.log("✅ 받은 FCM 토큰:", event.detail.token);
                window.removeEventListener("fcm-token", handleToken);
                resolve(event.detail.token);
              }
            };
            window.addEventListener("fcm-token", handleToken);
          });

          // ✅ iOS에게 토큰 요청
          window.webkit.messageHandlers.getFcmToken.postMessage("request");
          fcmToken = await tokenPromise;
        } catch (error) {
          console.warn("🔥 FCM 토큰 요청 중 오류 발생:", error);
        }
      } else if (window.ReactNativeWebView) {
        // ✅ Android 처리
        try {
          const tokenPromise = new Promise<string>((resolve) => {
            const handleToken = (event: any) => {
              if (event.detail?.token) {
                console.log("✅ [Android] 받은 FCM 토큰:", event.detail.token);
                window.removeEventListener("fcm-token", handleToken);
                resolve(event.detail.token);
              }
            };
            window.addEventListener("fcm-token", handleToken);
          });

          // 👉 안드로이드에게 토큰 요청
          window.ReactNativeWebView.postMessage("fcm_token_request");

          isAndroid = true;
          fcmToken = await tokenPromise;
        } catch (err) {
          console.warn("🔥 [Android] 토큰 요청 실패:", err);
        }
      }

      try {
        const androidState = isAndroid ? "Y" : "N";
        const response = await kakaoCodeHandler(code, fcmToken, androidState);

        const { accessToken, refreshToken } = response.result;

        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("refreshToken", refreshToken);

        // 로컬 스토리지에 사용자 유형 저장
        localStorage.setItem(
          "authState",
          JSON.stringify({ isAuthenticated: true })
        );

        navigate("/home");
      } catch (error: any) {
        if (error.response?.status === 409) {
          console.warn("⚠️ 이미 존재하는 사용자입니다.");
          const serverMgs = error.response.data?.message;

          if (serverMgs === "is not kakao user.") {
            console.warn("⚠️ 이미 가입된 이메일 주소입니다.");
            setModalTxt("⚠️ 이미 가입된 이메일 주소입니다.");
            setLoginModal({ isOpen: true, isVisible: true });
          } else if (serverMgs === "already deleted user.") {
            console.warn("⚠️ 탈퇴한 회원입니다.");
            setModalTxt("⚠️ 탈퇴한 회원입니다.");
            setLoginModal({ isOpen: true, isVisible: true });
          } else {
            console.warn("⚠️ 기타 409 충돌:", error.response.data?.message);
          }
        } else {
          console.error(
            "카카오 로그인 에러:",
            error.response?.data || error.message
          );
          navigate("/");
        }
      } finally {
        setIsFetching(false); // 요청 완료 후 상태 초기화
      }
    };

    if (code) {
      kakaoLogin();
    } else {
      console.error("카카오 로그인 코드가 없습니다.");
      // 필요 시 오류 페이지로 리다이렉트
    }
  }, [code]);

  return (
    <LoadingWrap>
      loading... {/* 후기 댓글 삭제 팝업 */}
      {loginModal.isOpen && (
        <ModalCenterLayout
          closedHandler={() => {
            closeModal(setLoginModal);
          }}
          isVisible={loginModal.isVisible}
        >
          <LoginAlertModal txt={modalTxt} />
        </ModalCenterLayout>
      )}
    </LoadingWrap>
  );
};

export default RedirectPage;

const LoadingWrap = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
