import { BoxInner, TextBox } from "style/modal";

interface ModalProps {
  closedHandler: () => void;
  deleteAccountHandler: () => void;
}

const ConfirmDeleteModal = ({
  closedHandler,
  deleteAccountHandler,
}: ModalProps) => {
  return (
    <BoxInner>
      <TextBox>
        <p>회원 탈퇴 최종 확인</p>
        <span>
          {`회원 탈퇴 전 모든 사항을 꼼꼼히 \n확인하셔서 불이익이 발생하지 \n않도록 유의해 주세요.`}
        </span>
      </TextBox>
      <div className="column-btn-box">
        <button className="continue" onClick={closedHandler}>
          돌아가기
        </button>
        <button className="del" onClick={deleteAccountHandler}>
          탈퇴하기
        </button>
      </div>
    </BoxInner>
  );
};

export default ConfirmDeleteModal;
