import { Controller, useForm } from "react-hook-form";
import { BoxInner, TextBox, TextLengthBox } from "style/admin/layout";
import TextareaAutosize from "react-textarea-autosize";
import { fetchUpdateReviewCommentHide } from "api/admin/review";

interface ModalProps {
  closedHandler: () => void;
  onClick?: () => void;
  reviewCommentId: number;
  isHideState: "Y" | "N";
}

const modalTxt = `모든 고객이 해당 글을 볼 수 없게 되며, 
글을 작성한 고객에게는 글 숨김처리 사실 및 사유가 푸시 알림으로 발송됩니다.

글 숨김 사유를 입력 후, [글 숨기기] 버튼을 눌러주세요.`;

const HideConfirmModal = ({
  closedHandler,
  reviewCommentId,
  isHideState,
}: ModalProps) => {
  const { control, watch, setError, setValue, clearErrors, handleSubmit } =
    useForm();

  const contentsValue = watch("contents") || "";

  const onSubmit = async (data: any) => {
    const hideState = isHideState === "Y" ? "N" : "Y";

    try {
      const response = await fetchUpdateReviewCommentHide(
        reviewCommentId,
        hideState,
        data.contents
      );
      if (response.result) closedHandler();
    } catch (error) {
      console.error(error);
    }
  };

  // 내용 길이 검증 (1000자 이상 입력 불가)
  const handleContentsChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const inputValue = e.target.value;

    if (inputValue.length > 50) {
      setError("contents", {
        type: "maxLength",
        message: "문의 내용은 최대 1,000자까지 입력 가능합니다.",
      });

      // 🔥 입력값을 1000자까지만 유지하도록 강제 설정
      setValue("contents", inputValue.slice(0, 50));
    } else {
      clearErrors("contents"); // 🔥 에러 초기화
      setValue("contents", inputValue);
    }
  };

  return (
    <BoxInner>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextBox>
          <p>글을 숨김처리 하시겠습니까?</p>
          <span>{modalTxt}</span>
          <div>
            <div className="textarea-wrapper">
              <Controller
                name="contents"
                control={control}
                rules={{
                  required: "문의 내용을 입력해주세요.",
                  maxLength: {
                    value: 1000,
                    message: "문의 내용은 최대 1,000자까지 입력 가능합니다.",
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextareaAutosize
                    {...field}
                    minRows={3}
                    maxRows={5}
                    onChange={(e) => {
                      field.onChange(e); // react-hook-form의 값 업데이트
                      handleContentsChange(e); // 입력 제한 적용
                    }}
                  />
                )}
              />
            </div>
            <TextLengthBox>
              <span>{`${contentsValue.length} / 50`}</span>
            </TextLengthBox>
          </div>
        </TextBox>
        <div className="btn-box">
          <button className="continue" onClick={closedHandler} type="button">
            돌아가기
          </button>
          <span></span>
          <button
            className="del"
            disabled={Number(contentsValue.length) === 0}
            type="submit"
          >
            글 숨기기
          </button>
        </div>
      </form>
    </BoxInner>
  );
};

export default HideConfirmModal;
