import { GoogleOAuthProvider } from "@react-oauth/google"; // GoogleOAuthProvider 임포트
import GoogleSocialLogin from "./google.login";

const GoogleloginProviderCustom = () => {
  const REST_API_KEY = process.env.REACT_APP_GOOGLE_CLIENT_KEY_ID || "";

  if (!REST_API_KEY) {
    console.error("Google Client Key ID is missing.");
    return <div>Error: Google Client Key ID is not provided.</div>;
  }

  return (
    // GoogleOAuthProvider로 GoogleOAuth 컴포넌트를 감쌈
    <GoogleOAuthProvider clientId={REST_API_KEY}>
      <GoogleSocialLogin />
    </GoogleOAuthProvider>
  );
};

export default GoogleloginProviderCustom;
