import { BoxInner, TextBox } from "style/modal";

interface Props {
  closedHandler: () => void;
  measureStop: () => void;
}

const ModalMeasureResultConfirm = ({ closedHandler, measureStop }: Props) => {
  return (
    <BoxInner>
      <TextBox>
        <p>측정을 종료하시겠습니까?</p>
        <span>
          측정 시간이 5분 미만일 경우
          <br /> ’1분 등가소음도‘ 및 ‘5분 등가소음도'
          <br /> 값이 결과에 표시되지 않습니다.
        </span>
      </TextBox>
      <div className="btn-box">
        <button onClick={closedHandler}>계속 측정</button>
        <span></span>
        <button className="del" onClick={measureStop}>
          측정 종료
        </button>
      </div>
    </BoxInner>
  );
};

export default ModalMeasureResultConfirm;
