import { ReviewCommentDto } from "interface/admin/interface.review";

export const repleCount = (data: any) => {
  // 🔥 부모 댓글 중 useYn === "Y"인 데이터만 필터링
  // 부모 댓글 중 삭제된 댓글은 화면에는 보이지만 카운트 안함.
  const filteredData = data.filter((item: any) => item.useYn === "Y");

  if (filteredData.length > 0) {
    // 🔥 부모 댓글 개수
    const repleCount = filteredData.length;

    // 🔥 `children` 배열에서도 useYn === "Y"인 것들만 필터링하여 개수 추출
    const repleRepleCount = data.map(
      (item: any) =>
        item.children?.filter((child: any) => child.useYn === "Y").length || 0
    );

    // 🔥 배열의 모든 값을 합산
    const totalRepleCount = repleRepleCount.reduce(
      (acc: any, curr: any) => acc + curr,
      0
    );

    return totalRepleCount + repleCount; // 🔥 부모 + 자식 합산 댓글 개수 반환
  }

  return 0; // 🔥 `useYn === "Y"`인 데이터가 없으면 0 반환
};

export const countTotalComments = (comments: ReviewCommentDto[]): number => {
  return comments.reduce((count, comment) => {
    const childCount = comment.children
      ? countTotalComments(comment.children)
      : 0;
    return count + 1 + childCount;
  }, 0);
};
