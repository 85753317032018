import { AnimatePresence } from "framer-motion";
import { NoiseTooltipWrap } from "style/measure";

const MeasureNoiseTooltip = () => {
  return (
    <AnimatePresence mode="wait">
      <NoiseTooltipWrap>
        <ul>
          <li>
            <span>배경소음도</span> : 대상소음이 없을 때 측정한 소음도 <br />
            (본 앱에서는 측정된 ‘5분 등가소음도’ 중 최소값으로 정의)
          </li>
          <li>
            <span>최고소음도</span> : 측정된 소음도 중 최고값 3개 표시
          </li>
          <li>
            <span>등가소음도</span> : 1분, 5분 동안 측정된 소음도의 평균값
          </li>
          <li>* 각 항목별 최고값 1개 표시 (단, 최고소음도는 3개)</li>
          <li>
            * 표시값은 ‘측정소음도’ 이므로 활용에 제한이 있을 수 있음
            <br />
            (‘대상소음도’ 산정을 위해서는 배경소음 보정 필요)
          </li>
          <li>* 주간 : 06시~22시, 야간 : 22시~익일 06시</li>
        </ul>
      </NoiseTooltipWrap>
    </AnimatePresence>
  );
};

export default MeasureNoiseTooltip;
