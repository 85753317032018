import styled from "styled-components";

export const TableContainer = styled.div`
  height: 100%;
`;

export const TableTop = styled.div`
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 2.5rem;
  .table-count {
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 2rem;
    letter-spacing: -0.06rem;
  }
`;

export const TableContents = styled.div`
  height: calc(100% - 6rem);
  max-height: 66.2rem;
  overflow: hidden scroll;
  table {
    width: 100%;
    &.no-data-table {
      height: 100%;
      min-height: 60rem;
    }
    thead {
      position: sticky;
      top: 0;
      tr {
        background-color: #225298;
        th {
          padding: 1.5rem;
          color: white;
          font-weight: 700;
          font-size: 1.6rem;
          line-height: 2rem;
          letter-spacing: -0.06rem;
          &:not(:first-child) {
            border-left: 1px solid white;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          padding: 1.5rem;
          font-size: 1.4rem;
          font-weight: 400;
          line-height: 2rem;
          text-align: center;
          vertical-align: middle;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          &.pending-answer {
            padding: 1.2rem 1.4rem;
          }
          &.title-cell {
            text-align: left;
            padding: 1rem 2rem;
            font-size: 1.6rem;
            line-height: 2.4rem;
            text-decoration: underline;
            max-width: 31rem;
            .title-box {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              cursor: pointer;
            }
          }
          &.notice-title-cell {
            padding: 1.3rem 2rem;
            font-size: 1.6rem;
            line-height: 2.4rem;
            max-width: 31rem;
            text-align: left;
          }
          &.profile-cell {
            padding: 1.3rem;
            max-width: 16rem;
            &.user-table {
              padding: 1.3rem 2rem;
              .profile {
                justify-content: flex-start;
                span {
                  max-width: 11.6rem;
                }
              }
            }
            .withdraw {
              font-size: 1.6rem;
              line-height: 2.4rem;
              font-weight: 700;
              display: flex;
              gap: 0.4rem;
              span {
                display: flex;
                width: 2rem;
                height: 2rem;
                background-color: #a1a1a1;
                border-radius: 50%;
              }
            }
          }
          .profile {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 0.4rem;
            .img-box {
              width: 2rem;
              height: 2rem;
              overflow: hidden;
              img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                object-fit: cover;
              }
            }
            span {
              font-size: 1.6rem;
              font-weight: 700;
              line-height: 2.4rem;
              max-width: 11rem;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          &.hide-state {
            padding: 0.7rem 0.8rem;
            .hide-item {
              width: 8rem;
              padding: 0.8rem;
              font-size: 1.6rem;
              font-weight: 700;
              letter-spacing: -0.06rem;
              line-height: 2rem;
              color: white;
              border-radius: 100px;
              margin: 0 auto;
              &.bluebox {
                background-color: #225298;
              }
              &.redbox {
                background-color: #ed2115;
              }
            }
          }
          &.user-state {
            padding: 0.7rem 0.8rem;
            .item {
              width: 8rem;
              padding: 0.7rem 0.8rem;
              font-size: 1.6rem;
              font-weight: 700;
              letter-spacing: -0.06rem;
              line-height: 2rem;
              border-radius: 100px;
              margin: 0 auto;
              background-color: white;
              &.bluebox {
                border: 1px solid #225298;
                color: #225298;
              }
              &.redbox {
                border: 1px solid #ed2115;
                color: #ed2115;
              }
              &.graybox {
                border: 1px solid #575757;
                color: #575757;
              }
              &.withdraw {
                border: 1px solid #a1a1a1;
                color: #a1a1a1;
              }
            }
          }

          .report-count {
            text-decoration: underline;
            .report-txt {
              color: #ed2115;
              font-weight: 500;
            }
            &.report-txt {
              color: #ed2115;
              font-weight: 500;
            }
          }
          .report-total-count {
            &.report-txt {
              color: #ed2115;
              font-weight: 500;
            }
          }
        }
        border-bottom: 1px solid #cecece;
        &.no-data {
          border-bottom: 0;
          height: calc(100% - 5rem);
          .no-data-td {
            font-size: 1.8rem;
            line-height: 2.4rem;
            letter-spacing: -0.06rem;
            font-weight: 700;
          }
        }
      }
    }
  }
  &.another-table-padding {
    min-height: 78.2rem;
    tbody {
      tr {
        td {
          padding: 1rem 2rem;
          &.banner-cell {
            height: 17.2rem;
            .img-box {
              width: 100%;
              height: 15.2rem;
              display: flex;
              align-items: center;
              justify-content: center;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
          }
          .order-controls {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            button {
              border: none;
              outline: none;
              background-color: transparent;
              display: flex;
              padding: 0;
            }
          }
          .action-buttons {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            button {
              border: 1px solid #cecece;
              border-radius: 0.5rem;
              box-shadow: 1px 1px 4px 0 #00000040;
              background-color: white;
              padding: 0.2rem 0.8rem;
              font-size: 1.4rem;
              font-weight: 500;
              line-height: 2rem;
              letter-spacing: -0.06rem;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
`;

export const Pendding = styled.span`
  display: inline-flex;
  border-radius: 0.2rem;
  background-color: #ff7a00;
  padding: 0.4rem;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1.8rem;
  letter-spacing: -0.06rem;
`;

export const CompleteTxt = styled.span`
  display: inline-flex;
  border-radius: 0.2rem;
  background-color: #a1a1a1;
  padding: 0.4rem;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1.8rem;
  letter-spacing: -0.06rem;
`;

export const TableNavigation = styled.div`
  display: flex;
  gap: 2.5rem;
  button {
    cursor: pointer;
    border: none;
    outline: none;
    background-color: transparent;
    padding: 0;
    display: flex;
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &:disabled {
      cursor: default;
    }
  }
`;
