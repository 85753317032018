import { fetchAlramAllDelete } from "api/alram";
import { BoxInner, TextBox } from "style/modal";

interface ModalProps {
  closedHandler: () => void;
  triggerUpdate: () => void;
}

const ModalAllRemove = ({ closedHandler, triggerUpdate }: ModalProps) => {
  const allRemoveHandler = async () => {
    try {
      const response = await fetchAlramAllDelete();
      if (response.result) {
        triggerUpdate();
        closedHandler();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <BoxInner>
      <TextBox>
        <p>알림을 모두 삭제하시겠습니까?</p>
      </TextBox>
      <div className="btn-box">
        <button className="continue" onClick={closedHandler}>
          취소
        </button>
        <span></span>
        <button className="del" onClick={allRemoveHandler}>
          삭제
        </button>
      </div>
    </BoxInner>
  );
};

export default ModalAllRemove;
