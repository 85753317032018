import { NoiseData } from "interface/interface.measure";
import { InstantaneousNoiseLevelWrap } from "style/measure";
import { classifyAndCountExceedingValues } from "utils/measure.util";

interface DataProps {
  resultData: NoiseData[];
}

const MeasureRecordResultTotalCount = ({ resultData }: DataProps) => {
  const { dayExceedCount, nightExceedCount } =
    classifyAndCountExceedingValues(resultData);

  return (
    <InstantaneousNoiseLevelWrap>
      <div className="title">기준치 초과 소음 발생 횟수 (순간소음도)</div>
      <div className="wrapper">
        <div className="item">
          <div className="title">주간(06~22시) 57dB(A) 이상</div>
          <div className="value">
            <span>{dayExceedCount}</span> 회
          </div>
        </div>
        <div className="item">
          <div className="title">야간(06~22시) 52dB(A) 이상</div>
          <div className="value">
            <span>{nightExceedCount}</span> 회
          </div>
        </div>
        <div className="info">* 발생 간격이 1초 이내인 경우 1회로 간주함</div>
      </div>
    </InstantaneousNoiseLevelWrap>
  );
};

export default MeasureRecordResultTotalCount;
