import axios from "axios";

const baseURL = process.env.REACT_APP_REST_API_URL;

// 로컬 스토리지 키 정의
const LOCAL_STORAGE_KEY_ACCESS_TOKEN = "accessToken";
const LOCAL_STORAGE_KEY_REFRESH_TOKEN = "refreshToken";
const LOCAL_STORAGE_KEY_EXPIRE_TIME = "expire";
// 만료 시간을 2일로 설정

// 기본 Axios 인스턴스 설정
const axiosInstance = axios.create({
  baseURL: baseURL,
});

// 인증이 필요한 요청을 위한 Axios 인스턴스 설정
export const axiosPrivate = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

// 요청 인터셉터 설정
axiosInstance.interceptors.request.use(
  async (config) => {
    const url = config.url;
    // console.log(isAuthRequest(url));
    // JWT 인증이 필요한 요청일 경우 헤더에 토큰 추가
    if (isAuthRequest(url)) {
      // 로컬 스토리지에서 JWT를 가져옴
      let jwt = localStorage.getItem(LOCAL_STORAGE_KEY_ACCESS_TOKEN);

      if (jwt) {
        //await refreshAccessToken();
        jwt = localStorage.getItem(LOCAL_STORAGE_KEY_ACCESS_TOKEN);

        config.headers["Authorization"] = `Bearer ${jwt}`;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

let isRefreshing = false; // 토큰 갱신 중인지 확인하는 플래그
let failedQueue: Array<() => void> = []; // 갱신 실패한 요청들을 큐에 저장

const retryRequest = (errorConfig: any) => {
  // 재시도할 요청을 큐에 추가
  return new Promise((resolve) => {
    failedQueue.push(() => {
      resolve(axiosInstance(errorConfig));
    });
  });
};

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },

  async (error) => {
    if (error.response) {
      const status = error.response.status;
      const message = error.response.data?.message; // response 안의 메시지를 추출

      if (status === 401) {
        // `message`가 없다면 액세스 토큰 만료로 처리
        if (!message) {
          console.error(
            "액세스 토큰이 만료되었습니다. 리프레시 토큰을 시도합니다."
          );
          console.log("message:", message);
          return handleTokenRefresh(error); // 액세스 토큰 만료 처리
        }

        // `message`가 "not matched refresh token"인 경우 리프레시 토큰 오류로 처리
        if (message === "not matched refresh token") {
          console.error("리프레시 토큰이 맞지 않습니다.");
          localStorage.clear();
          window.location.replace("/"); // 로그인 페이지로 리다이렉트
          return Promise.reject(error); // 더 이상 진행하지 않음
        }

        if (message === "invalid refresh token") {
          console.error("리프레시 토큰이 맞지 않습니다.");
          localStorage.clear();
          window.location.replace("/"); // 로그인 페이지로 리다이렉트
          return Promise.reject(error); // 더 이상 진행하지 않음
        }

        // 리프레시 토큰을 사용하여 새로운 액세스 토큰을 발급받기
        return handleTokenRefresh(error);
      }

      // 403 에러 처리: 권한 부족
      if (status === 403) {
        localStorage.removeItem(LOCAL_STORAGE_KEY_ACCESS_TOKEN);
        localStorage.removeItem(LOCAL_STORAGE_KEY_REFRESH_TOKEN);
        localStorage.removeItem(LOCAL_STORAGE_KEY_EXPIRE_TIME);
        localStorage.removeItem("userId");
        localStorage.removeItem("authState");
        window.location.replace("/");
      }

      // 409 에러 처리: 데이터 충돌
      if (status === 409) {
        return Promise.reject(error);
      }
    }

    // `error.response`가 없는 경우
    if (!error.response) {
      console.error("No response received:", error);
      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);

// 액세스 토큰 갱신을 처리하는 함수
const handleTokenRefresh = async (error: any) => {
  const accessToken = localStorage.getItem(LOCAL_STORAGE_KEY_ACCESS_TOKEN);
  const refreshToken = localStorage.getItem(LOCAL_STORAGE_KEY_REFRESH_TOKEN);
  console.log("refreshToken:", refreshToken);

  // ✅ "admin"이 포함된 경우에는 에러만 반환 (페이지 이동 X)
  if (!accessToken || !refreshToken) {
    if (window.location.pathname.includes("admin")) {
      window.location.replace("/admin");
      return Promise.reject(error); // ❌ 페이지 이동 없이 에러만 반환
    }

    localStorage.clear();
    window.location.replace("/"); // 일반 유저는 로그인 페이지로 이동
    return Promise.reject(error);
  }

  if (!isRefreshing) {
    isRefreshing = true;
    try {
      // 리프레시 토큰을 사용하여 새로운 액세스 토큰을 발급받기
      const response = await axiosInstance.post(
        "/reissue",
        {
          accessToken: accessToken,
          refreshToken: refreshToken,
        },
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
          },
        }
      );

      console.log(response);

      const { accessToken: newAccessToken, refreshToken: newRefreshToken } =
        response.data.result;

      // 새로운 액세스 토큰과 리프레시 토큰을 로컬 스토리지에 저장
      localStorage.setItem(LOCAL_STORAGE_KEY_ACCESS_TOKEN, newAccessToken);
      localStorage.setItem(LOCAL_STORAGE_KEY_REFRESH_TOKEN, newRefreshToken);

      // 요청을 재시도할 때 새로운 액세스 토큰을 헤더에 설정
      axiosInstance.defaults.headers[
        "Authorization"
      ] = `Bearer ${newAccessToken}`;

      // 실패한 요청들을 재시도
      failedQueue.forEach((callback) => callback());
      failedQueue = []; // 큐 초기화
      return axiosInstance(error.config); // 원래 실패했던 요청 재시도
    } catch (refreshError: any) {
      console.error(
        "리프레시 토큰 갱신 실패:",
        refreshError.response?.data || refreshError
      );

      const errorMessage =
        refreshError.response?.data?.message || "Unknown error occurred";
      console.error("Error Message: ", errorMessage);

      // ✅ "admin"이 포함된 경우에는 에러만 반환 (페이지 이동 X)
      if (window.location.pathname.includes("admin")) {
        return Promise.reject(refreshError);
      }

      localStorage.clear();
      window.location.replace("/"); // 일반 유저는 로그인 페이지로 이동
      return Promise.reject(refreshError);
    } finally {
      isRefreshing = false;
    }
  }

  // 토큰 갱신 중이면, 실패한 요청을 큐에 저장하고 재시도
  return retryRequest(error.config);
};

/**
 * @brief 인증이 필요한 요청인지 확인
 * @param url 요청 URL
 * @return 인증이 필요한 요청인지 여부 boolean 값
 */
function isAuthRequest(url?: string): boolean {
  if (!url) return false;
  // console.log(/\/popup/.test("/popup"), "이게 왜 False 임?");
  return (
    /\/auth/.test(url) ||
    /\/is-exists/.test(url) ||
    /\/popup/.test(url) ||
    /\/review/.test(url) ||
    /\/signup/.test(url) ||
    /\/user\/password/.test(url) ||
    /\/oauth2\/kakao/.test(url) ||
    /\/file/.test(url) ||
    /\/block/.test(url) ||
    /\/report/.test(url) ||
    /\/announcement/.test(url) ||
    /\/user/.test(url) ||
    /\/notification/.test(url) ||
    /\/home/.test(url) ||
    /\/inquiry/.test(url) ||
    /\/contents/.test(url) ||
    /\/api\/admin/.test(url) ||
    /\/measure-result/.test(url) ||
    /\/test-result/.test(url) ||
    /\/apple/.test(url)
  ); // 경로 추가
}

// 리프레시 액세스 토큰 로직 추가
async function refreshAccessToken() {
  try {
    const accessToken = localStorage.getItem(LOCAL_STORAGE_KEY_ACCESS_TOKEN);
    const refreshToken = localStorage.getItem(LOCAL_STORAGE_KEY_REFRESH_TOKEN);

    if (!accessToken || !refreshToken) {
      throw new Error("액세스 토큰 또는 리프레시 토큰이 없습니다.");
    }

    // POST 요청으로 Access Token과 Refresh Token을 서버로 전송
    const response = await axiosInstance.post("/reissue", {
      accessToken: accessToken,
      refreshToken: refreshToken,
    });

    console.log("response :", response);

    const { accessToken: newAccessToken, refreshToken: newRefreshToken } =
      response.data.result;

    // 새로운 액세스 토큰, 리프레시 토큰 및 만료 시간 저장
    localStorage.setItem(LOCAL_STORAGE_KEY_ACCESS_TOKEN, newAccessToken);
    localStorage.setItem(LOCAL_STORAGE_KEY_REFRESH_TOKEN, newRefreshToken);
  } catch (error) {
    console.error("토큰 갱신 실패:", error);
    // 토큰 갱신 실패 시 로그인 페이지로 리다이렉트
    window.location.replace("/");
  }
}

export default axiosInstance;
