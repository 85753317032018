import { atom } from "recoil";

interface SnsModalStateProps {
  isOpen: boolean; // 모달 열림 여부
  isVisible: boolean; // 모달 표시 여부
}

interface ReviewEditModalStateProps {
  isOpen: boolean; // 모달 열림 여부
  isVisible: boolean; // 모달 표시 여부
}

export const loginModalState = atom<{
  isOpen: boolean;
  isVisible: boolean;
}>({
  key: "loginModalState",
  default: {
    isOpen: false,
    isVisible: false,
  },
});

export const snsModalState = atom<SnsModalStateProps>({
  key: "snsState",
  default: {
    isOpen: false, // 모달 열림 여부
    isVisible: false, // 모달 표시 여부
  },
});

export const reviewEditModalState = atom<ReviewEditModalStateProps>({
  key: "reviewEditState",
  default: {
    isOpen: false,
    isVisible: false,
  },
});

export const reviewDeleteConfirmModalState = atom({
  key: "reviewDeleteConfirmModalState",
  default: {
    isOpen: false,
    isVisible: false,
  },
});

export const reviewComentDeleteConfirmModalState = atom({
  key: "reviewComentDeleteConfirmModalState",
  default: {
    isOpen: false,
    isVisible: false,
  },
});

export const reviewCommentUpdateState = atom({
  key: "reviewCommentUpdateState",
  default: false,
});

export const reviewCommentBeforeUpdateValue = atom<{
  commentId: number | null; // 🔹 undefined 대신 null을 사용
  commentContnets: string | null;
}>({
  key: "reviewCommentBeforeUpdateValue",
  default: {
    commentId: null, // 🔹 초기값을 null로 설정
    commentContnets: null,
  },
});

export const reviewBlockModalState = atom<{
  isOpen: boolean;
  isVisible: boolean;
}>({
  key: "reviewBlockModalState",
  default: {
    isOpen: false,
    isVisible: false,
  },
});

export const confirmBlockModalState = atom<{
  isOpen: boolean;
  isVisible: boolean;
}>({
  key: "confirmBlockModalState",
  default: {
    isOpen: false,
    isVisible: false,
  },
});

export const confirmBlockAfterModalState = atom<{
  isOpen: boolean;
  isVisible: boolean;
}>({
  key: "confirmBlockAfterModalState",
  default: {
    isOpen: false,
    isVisible: false,
  },
});

export const reviewInformationState = atom<{
  reviewRegUserId: string | null;
  blockUserName: string | null;
  reviewId?: number | null;
  commentId?: number | null;
}>({
  key: "reviewInformation",
  default: {
    reviewRegUserId: null,
    blockUserName: null,
  },
});

export const blockReviewModalState = atom<{
  isOpen: boolean;
  isVisible: boolean;
}>({
  key: "blockReviewModalState",
  default: {
    isOpen: false,
    isVisible: false,
  },
});

export const blockUserState = atom<{
  blockUserId: string | null;
  blockUserName: string | null;
  targetId?: number | null;
  type?: string | null;
  reviewId?: number | null;
} | null>({
  key: "blockUserState",
  default: {
    blockUserId: null,
    blockUserName: null,
  },
});

export const declarationProps = atom<{
  reviewType: string | null;
  declarationTargetId: number | null;
  reviewId?: number | null;
}>({
  key: "declarationProps",
  default: {
    reviewType: null,
    declarationTargetId: null,
  },
});

export const orderByStateModal = atom<{
  isOpen: boolean;
  isVisible: boolean;
}>({
  key: "orderByStateModal",
  default: {
    isOpen: false,
    isVisible: false,
  },
});

export const duplicationModalState = atom<{
  isOpen: boolean;
  isVisible: boolean;
}>({
  key: "duplicationModalState",
  default: {
    isOpen: false,
    isVisible: false,
  },
});

export const deleteConfirmModalState = atom<{
  isOpen: boolean;
  isVisible: boolean;
}>({
  key: "deleteConfirmModalState",
  default: {
    isOpen: false,
    isVisible: false,
  },
});

export const deleteAccountConfirmModalState = atom<{
  isOpen: boolean;
  isVisible: boolean;
}>({
  key: "deleteAccountConfirmModalState",
  default: {
    isOpen: false,
    isVisible: false,
  },
});
