import {
  RepleIconX14,
  RightArrowIconX16,
  WhiteLineHeartIconX14,
} from "cssReset/icon";
import { ContentDTO } from "interface/interface.main.home";
import { useNavigate } from "react-router-dom";
import {
  BestReviewContentsBox,
  BestReviewListBox,
  TopBox,
} from "style/main.home";

interface ColumnDTOProps {
  listData: ContentDTO[];
}

const Rs101Column = ({ listData }: ColumnDTOProps) => {
  const navigate = useNavigate();

  const handleTotalViewClicked = () => {
    navigate("/column");
  };

  const handleReviewItemClicked = (contentsId: number) => {
    navigate(`/column/detail/${contentsId}`);
  };
  return (
    <BestReviewListBox>
      <TopBox>
        <p>RS01 칼럼</p>
        <span className="total-review-btn" onClick={handleTotalViewClicked}>
          전체보기{" "}
          <span className="icon-box">
            <RightArrowIconX16 />{" "}
          </span>
        </span>
      </TopBox>
      <BestReviewContentsBox>
        <div className="inner">
          {listData &&
            listData.slice(0, 5).map((list) => (
              <div
                className="list-item"
                key={list.contentsId}
                onClick={() => {
                  handleReviewItemClicked(list.contentsId);
                }}
              >
                <div className="img-box">
                  {list.files.length > 0 ? (
                    <img src={list.files[0].accessUrl} alt="" />
                  ) : (
                    ""
                  )}
                </div>
                <div className="sub-box">
                  <p className="review-title">{list.title}</p>
                  <div className="info-box">
                    <div className="profile-img-box">
                      <img src="/assets/icon/admin-profile.png" alt="" />
                    </div>
                    <span>관리자</span>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </BestReviewContentsBox>
    </BestReviewListBox>
  );
};

export default Rs101Column;
