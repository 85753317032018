import { declarationProps } from "atoms/modal.state";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import styled from "styled-components";

interface BlockModalProps {
  handleModalClose: () => void;
  blockUser: {
    blockUserId: string | null;
    blockUserName: string | null;
    targetId?: number | null;
    type?: string | null;
    reviewId?: number | null;
  } | null;
  confirmModalOpen: () => void;
}

const ModalReviewBlockCommentBox = ({
  confirmModalOpen,
  blockUser,
}: BlockModalProps) => {
  const navigate = useNavigate();
  const setReviewDeclarationData = useSetRecoilState(declarationProps);
  const declarationHandler = () => {
    setReviewDeclarationData({
      reviewType: blockUser?.type === "comment" ? "REVIEW_COMMENT" : "REVIEW",
      declarationTargetId: blockUser?.targetId ?? null,
      reviewId: blockUser?.reviewId,
    });
    navigate("/declaration");
  };
  return (
    <BoxInner>
      <ModalItemBox>
        <li className="del" onClick={declarationHandler}>
          신고하기
        </li>
        <li onClick={confirmModalOpen}>차단하기</li>
      </ModalItemBox>
    </BoxInner>
  );
};

export default ModalReviewBlockCommentBox;

const BoxInner = styled.div``;

const ModalItemBox = styled.ul`
  li {
    display: flex;
    padding: 1.6rem 1rem;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: -0.06rem;
    border-bottom: 1px solid #ececec;
    &.del {
      color: #ed2115;
    }
  }
`;
