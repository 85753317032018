import { GoogleOAuthProvider } from "@react-oauth/google"; // GoogleOAuthProvider 임포트
import GoogleRedirectPage from "./google.redirect.page";
// 삭제 예정
const GoogleRedirectPageTest = () => {
  const REST_API_KEY = process.env.REACT_APP_GOOGLE_CLIENT_KEY_ID || "";
  console.log("khi");
  if (!REST_API_KEY) {
    console.error("Google Client Key ID is missing.");
    return <div>Error: Google Client Key ID is not provided.</div>;
  }
  return (
    <GoogleOAuthProvider clientId={REST_API_KEY}>
      <GoogleRedirectPage />
    </GoogleOAuthProvider>
  );
};

export default GoogleRedirectPageTest;
