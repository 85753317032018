import { measureUnauthenticatedRecordingInfoState } from "atoms/measure.state";
import { useForm, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { FtBtnBox, StepFormWrap } from "style/measure";

const UnauthenticatedMeasureStepTwo = () => {
  const navigate = useNavigate();
  const { register, handleSubmit, control, setValue } = useForm({
    defaultValues: {
      location: "",
    },
  });
  const setMeasureRecordingInfo = useSetRecoilState(
    measureUnauthenticatedRecordingInfoState
  );

  const locationName = useWatch({ control, name: "location" }) || ""; // 🚀 기본값 설정

  const onSubmit = (data: any) => {
    console.log(data);
    setMeasureRecordingInfo({
      location: data.location,
    });
    navigate("/measure/unauthenticated/simple/recording");
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <StepFormWrap>
        <div className="input-box">
          <div className="title">측정장소 명칭을 입력해주세요.</div>
          <div className="sub">
            최대 20자까지 입력할 수 있습니다. <br />
            추후 기록을 확인하기 쉽도록 명칭을 입력해주세요.
          </div>
          <div className="input-form">
            <div style={{ position: "relative" }}>
              <input
                type="text"
                placeholder="예: 거실 중앙"
                {...register("location", { maxLength: 20 })} // ✅ maxLength 적용
                onInput={(e) => {
                  const target = e.target as HTMLInputElement;
                  if (target.value.length > 20) {
                    setValue("location", target.value.slice(0, 20)); // 🚀 20자 초과 입력 차단
                  }
                }}
              />
              <span></span>
            </div>
            <div className="count">{locationName.length}/20</div>
          </div>
        </div>
      </StepFormWrap>
      <FtBtnBox>
        <button disabled={!locationName}>측정 시작하기</button>
      </FtBtnBox>
    </form>
  );
};

export default UnauthenticatedMeasureStepTwo;
