import { TableContainer, TableContents, TableTop } from "style/admin/table";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { formatDateTime } from "utils/util";
import { UserProfile } from "interface/admin/interface.user";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import PaginationControls from "../table.components/pagination";

interface DataProps {
  data: UserProfile[];
}

const AdminUserTable = ({ data }: DataProps) => {
  const navigate = useNavigate();
  // ✅ 최신 등록일(`createdAt`)을 기준으로 내림차순 정렬
  const sortedData = useMemo(() => {
    return [...data].sort(
      (a, b) => new Date(b.regDt).getTime() - new Date(a.regDt).getTime()
    );
  }, [data]);

  const columns = useMemo<ColumnDef<UserProfile>[]>(
    () => [
      {
        header: "Index",
        id: "number", // 넘버링을 위한 id
        cell: ({ row }) => sortedData.length - row.index, // 각 행의 인덱스에 1을 더해 넘버링
        size: 128,
      },
      {
        header: "작성자",
        accessorKey: "userName",
        cell: (info) => {
          const row = info.row.original; // ✅ 현재 행(row)의 원본 데이터 가져오기
          const userName = row.userName;
          const useYn = row.useYn;
          const userProfileUrl =
            row.userProfileUrl || "/assets/icon/userprofile.png"; // 기본 이미지 설정

          if (useYn !== "Y")
            return (
              <div className="withdraw">
                <span></span>탈퇴한 사용자
              </div>
            );

          return userName ? (
            <div className="profile">
              <div className="img-box">
                <img
                  src={
                    userProfileUrl
                      ? userProfileUrl
                      : "/assets/icon/userprofile.png"
                  }
                  alt={`${userName} 프로필`}
                />
              </div>
              <span>{userName}</span> {/* ✅ 텍스트 올바르게 출력 */}
            </div>
          ) : (
            "-"
          );
        },
        size: 180,
      },
      {
        header: "가입일시",
        accessorKey: "regDt",
        cell: (info) => {
          const value = info.getValue();
          return value ? formatDateTime(String(value)) : "-";
        },
        size: 156,
      },
      {
        header: "검사",
        cell: (info) => {
          const row = info.row.original;
          const simpleTestCount = row.simpleTestResultCount;
          const deepTestCount = row.deepTestResultCount;
          return simpleTestCount + deepTestCount;
        },
        size: 112,
      },
      {
        header: "측정",
        accessorKey: "simpleMeasureResultCount",
        cell: (info) => info.getValue(),
        size: 112,
      },
      {
        header: "글/댓글",
        cell: (info) => {
          const row = info.row.original;
          const reviewCount = row.reviewCount;
          const commentCount = row.reviewCommentCount;
          return reviewCount + commentCount;
        },
        size: 112,
      },
      {
        header: "총 신고",
        accessorKey: "reportCount",
        cell: (info) => {
          const value = info.getValue();
          return value ? (
            <span
              className={`report-total-count ${
                Number(value) >= 10 ? "report-txt" : ""
              }`}
            >{`${value}`}</span>
          ) : (
            "-"
          ); // ✅ 값이 없으면 "-" 반환
        },
        size: 112,
      },
      {
        header: "제한해제 후 신고",
        accessorKey: "reportCountAfterUnblock",
        cell: (info) => {
          const value = info.getValue();
          return value ? (
            <span
              className={`report-total-count ${
                Number(value) >= 10 ? "report-txt" : ""
              }`}
            >{`${value}`}</span>
          ) : (
            "-"
          ); // ✅ 값이 없으면 "-" 반환
        },
        size: 140,
      },
      {
        header: "계정상태",
        accessorKey: "blockExpDt",
        cell: (info) => {
          const value = info.getValue(); // ✅ 값 가져오기
          const row = info.row.original;
          const useYn = row.useYn;
          const isPermanentBlock =
            typeof value === "string" && value.startsWith("9999-12-31"); // ✅ "9999-12-31" 포함 여부 확인

          // ✅ useYn이 "Y"가 아니면 빈 문자열 반환
          if (useYn !== "Y") return <div className="withdraw item">탈퇴</div>;

          return value === null ? (
            <div className="bluebox item">일반회원</div>
          ) : isPermanentBlock ? (
            <div className="graybox item">영구 제한</div>
          ) : (
            <div className="redbox item">일시 제한</div>
          );
        },
        size: 120,
      },
    ],
    [sortedData]
  );

  const table = useReactTable({
    data: sortedData,
    columns,
    enableRowSelection: true,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: { pagination: { pageSize: 50 } }, // ✅ 초기 페이지 크기 설정
  });

  const paginationState = table.getState().pagination;
  const startRow = paginationState.pageIndex * paginationState.pageSize + 1;
  const endRow = startRow + table.getRowModel().rows.length - 1;
  const totalRows = sortedData.length;

  const clickedRowHandler = (id: string) => {
    navigate(`/admin/user/detail/${id}`);
  };

  return (
    <TableContainer>
      <TableTop>
        <span className="table-count">
          {totalRows} 중 {startRow}-{endRow}
        </span>
        <PaginationControls table={table} />
      </TableTop>
      <TableContents>
        <table
          className={
            table.getRowModel().rows.length === 0 ? "no-data-table" : ""
          }
        >
          <thead>
            {table.getHeaderGroups().map((item) => (
              <tr key={item.id}>
                {item.headers.map((header) => (
                  <th
                    key={header.id}
                    style={{ minWidth: `${header.getSize()}px` }}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.length > 0 ? (
              table.getRowModel().rows.map((row) => (
                <tr
                  key={row.id}
                  onClick={() => clickedRowHandler(row.original.userId)} // ✅ 더블 클릭 시 상세 페이지 이동
                  style={{ cursor: "pointer" }} // ✅ 마우스 오버 시 클릭 가능하도록 스타일 추가
                >
                  {row.getVisibleCells().map((cell) => {
                    const isUserState = cell.column.id === "blockExpDt";
                    const isProfile = cell.column.id === "userName";

                    return (
                      <td
                        key={cell.id}
                        className={`${isUserState ? "user-state" : ""} ${
                          isProfile ? "profile-cell user-table" : ""
                        }`.trim()} // ✅ 여러 개의 클래스 추가
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                </tr>
              ))
            ) : (
              // ✅ 데이터가 없을 경우 "검색 결과가 없습니다" 표시
              <tr className="no-data">
                <td colSpan={columns.length} className="no-data-td">
                  검색 결과가 없습니다.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </TableContents>
    </TableContainer>
  );
};

export default AdminUserTable;
