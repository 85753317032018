import { fetchAlramReadHandler } from "api/alram";
import { alramClickedState } from "atoms/alram.state";
import { AlertCircleIcon, HeartIcon, PinIcon, RepleIcon } from "cssReset/icon";
import { NotificationResult } from "interface/interface.alram";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { AlramListItem } from "style/alram";
import { formatDateTimeToToday } from "utils/util";

interface NotificationResultProps {
  alramDt: NotificationResult;
}

const AlramList = ({ alramDt }: NotificationResultProps) => {
  const navigate = useNavigate();
  const setAlramLinkState = useSetRecoilState(alramClickedState);

  console.log(alramDt);

  const getGreetingType = (type: string) => {
    let typeMessage: string;

    switch (type) {
      case "COMMENT":
        typeMessage = "댓글 알림";
        break;
      case "LIKES":
        typeMessage = "좋아요 알림";
        break;
      case "NOTICE":
        typeMessage = "공지사항 알림";
        break;
      case "ANSWER":
        typeMessage = "1:1 문의 사항 답변 알림";
        break;
      default:
        typeMessage = "알림";
    }

    return typeMessage;
  };

  const getGreetingTypeIcon = (type: string) => {
    let typeIcon: JSX.Element;

    switch (type) {
      case "COMMENT":
        typeIcon = <RepleIcon />;
        break;
      case "LIKES":
        typeIcon = <HeartIcon />;
        break;
      case "NOTICE":
        typeIcon = <PinIcon />;
        break;
      default:
        typeIcon = <AlertCircleIcon />;
    }

    return typeIcon;
  };

  const handleItemClicked = async (notificationId: number) => {
    const type = alramDt.type;

    try {
      const response = await fetchAlramReadHandler(notificationId);

      if (response.result) {
        if (type === "COMMENT") {
          setAlramLinkState({
            linkYn: true,
            commentId: alramDt.moveToTargetId,
            targetType: alramDt.targetType,
          });
          navigate(`/review/detail/${alramDt.targetId}`);

          // target type이 review_comment이면 함수 추가해야함.
        } else if (type === "LIKE") {
          navigate(`/review/detail/${alramDt.targetId}`);
        } else if (type === "ANNOUNCEMENT") {
          setAlramLinkState({
            linkYn: true,
            commentId: alramDt.targetId,
            targetType: alramDt.targetType,
          });
          navigate(`/setting/notice`);
        } else if (type === "ANSWER") {
          setAlramLinkState({
            linkYn: true,
            commentId: alramDt.targetId,
            targetType: alramDt.targetType,
          });
          navigate(`/setting/inquiry`);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <AlramListItem
      onClick={() => {
        handleItemClicked(alramDt.notificationId);
      }}
    >
      <div className="t-alram-info">
        <div className="l">
          <span className="icon-box">{getGreetingTypeIcon(alramDt.type)}</span>
          <span className="type-box">{getGreetingType(alramDt.type)}</span>
        </div>
        <div className="r">{formatDateTimeToToday(alramDt.regDt)}</div>
      </div>
      <div className="contents-box">
        <p>{alramDt.message}</p>
      </div>
    </AlramListItem>
  );
};

export default AlramList;
