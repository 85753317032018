import { fetchLoadReportList } from "api/admin/review";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import AdminReportListTable from "./admin.report.list.table";
import { Wrapper } from "style/admin/window.layout";

const AdminReportList = () => {
  const { id } = useParams();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const type = params.get("type");
  const [data, setData] = useState([]);

  console.log(data);

  useEffect(() => {
    const loadReportList = async () => {
      try {
        const params = {
          targetId: Number(id),
          type: type || "REVIEW",
          orderBy: "REGDT",
          orderByDir: "DESC",
        };
        const response = await fetchLoadReportList(params);

        setData(response.result);
      } catch (error) {
        console.error(error);
      }
    };

    loadReportList();
  }, []);

  return <Wrapper>{data && <AdminReportListTable data={data} />}</Wrapper>;
};

export default AdminReportList;
