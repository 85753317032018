import { FtBtnBox, StepWrap } from "style/measure";

interface StepProps {
  handleNext: () => void;
}

const MeasureStepOne = ({ handleNext }: StepProps) => {
  return (
    <>
      <StepWrap>
        <div className="box">
          <div className="box-item-1">
            스마트폰의 마이크를 이용해 <br /> 최대 2시간(120분) 동안 소음을
            측정합니다.
          </div>
        </div>
        <div className="box">
          <div className="box-item-1">
            소음을 측정하고자 하는 공간으로 이동하여, <br />
            성인 머리 높이 정도에 스마트폰을 위치시켜 주세요.
          </div>
          <div className="box-item-2">
            <span>
              정확한 측정을 위해 삼각대 등으로 스마트폰을 고정해 두는 것이
              좋습니다. <br />
              손으로 들고 측정하실 경우, 최대한 측정 위치가 변하지 않도록
              유지해주세요.
            </span>
            <div className="img-box">
              <img src="/assets/icon/simple_test_step.png" alt="" />
            </div>
          </div>
        </div>
        <div className="box">
          <div className="box-item-1">
            측정하는 동안, 우리 집 내부에서 <br />
            소음이 발생하지 않도록 주의해주세요.
          </div>
          <div className="box-item-2">
            <span>
              측정 공간 내에서 발생하는 소음으로 인해 정확한 측정이 어려워질 수
              있습니다. 측정하는 동안 다른 공간에 계시는 것을 추천드립니다.{" "}
              <br />
              스마트폰을 거치한 후, ‘측정 시작하기’ 버튼을 눌러주세요. 1분 후
              자동으로 측정 및 녹음이 시작됩니다. 사능하면 측정공간 및 집안
              방문은 모두 열어두시고, 창문과 대문은 닫혀있도록 해주세요. 최대한
              정확하게 층간소음을 측정할 수 있습니다.
            </span>
          </div>
        </div>
        <div className="box">
          <div className="box-item-1">기타 안내 사항</div>
          <div className="box-item-2">
            <span>
              텍스트 추후 추가안내문구 텍스트 추후 추가안내문구 텍스트 추후
              추가안내문구 텍스트 추후 추가안내문구 텍스트 추후 추가안내문구
              텍스트 추후 추가안내문구 텍스트 추후 추가안내문구 텍스트 추후
              추가안내문구 텍스트 추후 추가안내문구 텍스트 추후 추가안내문구
              텍스트 추후 추가안내문구 텍스트 추후 추가
            </span>
          </div>
        </div>
        <div className="box"></div>
      </StepWrap>
      <FtBtnBox>
        <button onClick={handleNext}>다음</button>
      </FtBtnBox>
    </>
  );
};

export default MeasureStepOne;
