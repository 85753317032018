import { reveiewDeleteHandler } from "api/review";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

interface ReviewDeleteConfirmModalProps {
  reviewId: string | undefined;
  closedHandler: () => void;
}

const ModalReviewDeleteConfirmBox = ({
  reviewId,
  closedHandler,
}: ReviewDeleteConfirmModalProps) => {
  const navigate = useNavigate();

  const handleReviewDelete = async () => {
    try {
      const response = await reveiewDeleteHandler(Number(reviewId));
      if (response.result) {
        navigate(`/review`);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <BoxInner>
      <TextBox>
        <p>글을 삭제하시겠습니까?</p>
        <span>
          작성하신 글과 관련된 모든 데이터가 삭제되며, 되돌릴 수 없습니다.
        </span>
      </TextBox>
      <div className="btn-box">
        <button onClick={closedHandler}>취소</button>
        <span></span>
        <button className="del" onClick={handleReviewDelete}>
          삭제
        </button>
      </div>
    </BoxInner>
  );
};

export default ModalReviewDeleteConfirmBox;

const BoxInner = styled.div`
  display: flex;
  gap: 2.4rem;
  flex-direction: column;
  .btn-box {
    display: flex;
    position: relative;
    button {
      border: 0;
      outline: 0;
      flex: 1;
      background-color: transparent;
      padding: 1.8rem 0;
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 2rem;
      letter-spacing: -0.06rem;
      color: #575757;
      &.del {
        color: #ed2115;
      }
    }
    span {
      position: absolute;
      display: block;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 1px;
      height: 2.4rem;
      background-color: #a1a1a1;
    }
  }
`;

const TextBox = styled.div`
  display: flex;
  gap: 2.4rem;
  flex-direction: column;
  padding: 5.2rem 3rem 0;
  p {
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 2rem;
    letter-spacing: -0.06rem;
    text-align: center;
  }
  span {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 2rem;
    text-align: center;
    display: block;
    color: #575757;
  }
`;
