import { NotAnswerInquiryDto } from "interface/admin/interface.inquiry";
import { InquiryDetailLeftBox, SliderWrapper } from "style/admin/inquiry";
import { formatDateTime } from "utils/util";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useState } from "react";
import { CustomNextArrow, CustomPrevArrow } from "../slick.arrow/slick.arrow";

interface DetailDataProps {
  data: NotAnswerInquiryDto;
}

const InquiryDetailLeft = ({ data }: DetailDataProps) => {
  const [currentSlide, setCurrentSlide] = useState(1); // 현재 슬라이드 번호

  const sliderSetting = {
    infinite: data?.files && data.files.length > 1, // data와 files가 존재할 경우에만 확인
    speed: 500,
    slidesToShow: 1, // 3개 이하일 경우 슬라이드를 하나만 보여줌
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />, // ✅ 커스텀 컴포넌트 사용
    nextArrow: <CustomNextArrow />, // ✅ 커스텀 컴포넌트 사용
    afterChange: (current: number) => setCurrentSlide(current + 1), // 현재 슬라이드 번호 업데이트
  };
  return (
    <InquiryDetailLeftBox>
      <div className="top-box">
        <div className="question-box">
          <div className="lbox">
            <span
              className={`state ${
                data.answerYn === "N" ? "pending" : "complete"
              }`}
            >
              {data.answerYn === "N" ? "답변대기" : "딥뱐완료"}
            </span>
            <p>{data.title}</p>
          </div>
          <div className="rbox">{formatDateTime(data.regDt)}</div>
        </div>
        <div className="profile">
          <div className="img-box">
            <img
              src={
                data.userProfileUrl
                  ? data.userProfileUrl
                  : "/assets/icon/userprofile.png"
              }
              alt={`${data.userName} 프로필`}
            />
          </div>
          <span>{data.userName}</span>
        </div>
      </div>

      <div className="contents-box">
        <p>{data.contents}</p>
        {data?.files && data.files.length > 0 ? (
          <SliderWrapper>
            <div className="inner">
              <Slider {...sliderSetting}>
                {data.files.map((img, index) => (
                  <div className="img-box" key={img.fileId}>
                    {/* src url 배포시 수정 */}
                    <img src={img.accessUrl} alt={img.realFileName} />
                  </div>
                ))}
              </Slider>
            </div>
            {/* 현재 슬라이드 위치 표시 */}
            <div className="slide-counter">
              {currentSlide} / {data.files.length}
            </div>
          </SliderWrapper>
        ) : null}
      </div>
    </InquiryDetailLeftBox>
  );
};

export default InquiryDetailLeft;
