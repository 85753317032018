import { atom } from "recoil";

export const isHomePathState = atom<string>({
  key: "isHomePathState",
  default: "home", // 기본값: 변경 없음
});

export const mainBannerModalState = atom<{
  isOpen: boolean;
  isVisible: boolean;
}>({
  key: "mainBannerModalState",
  default: {
    isOpen: true, // 모달 열림 여부
    isVisible: true, // 모달 표시 여부
  },
});
