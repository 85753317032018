import { useNavigate } from "react-router-dom";
import styled from "styled-components";

interface ModalReviewEditProps {
  reviewId: string | undefined;
  handleReviewDeleteConfirmModalOpen: () => void;
}

const ModalReviewEditBox = ({
  reviewId,
  handleReviewDeleteConfirmModalOpen,
}: ModalReviewEditProps) => {
  const navigate = useNavigate();

  const modifyMove = () => {
    navigate(`/review/modify/${reviewId}`);
  };
  return (
    <BoxInner>
      <ModalItemBox>
        <li onClick={modifyMove}>수정하기</li>
        <li className="del" onClick={handleReviewDeleteConfirmModalOpen}>
          삭제하기
        </li>
      </ModalItemBox>
    </BoxInner>
  );
};

export default ModalReviewEditBox;

const BoxInner = styled.div``;

const ModalItemBox = styled.ul`
  li {
    display: flex;
    padding: 1.6rem 1rem;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: -0.06rem;
    border-bottom: 1px solid #ececec;
    &.del {
      color: #ed2115;
    }
  }
`;
