import { NoiseData } from "interface/interface.measure";
import { TopThreeValueWrapper } from "style/measure";

interface NoiseTopThreeListProps {
  title: string;
  data: NoiseData[];
}

const MeasureRecordResultTopThreeValue = ({
  title,
  data,
}: NoiseTopThreeListProps) => {
  // 주간/야간 기준 판별하여 "기준초과" 여부 확인
  const isExceed = data.some((item) => {
    const hour = new Date(item.time).getHours();
    const threshold = hour >= 6 && hour < 22 ? 57 : 52; // 주간 57dB, 야간 52dB
    return item.value >= threshold;
  });

  return (
    <TopThreeValueWrapper>
      <h3>
        {title}{" "}
        <span className={isExceed ? "red" : "blue"}>
          {isExceed ? "기준초과" : "기준이하"}
        </span>
      </h3>
      <ul>
        {data.map((item, index) => (
          <li key={index}>
            <span>{item.value}</span>
            <span>({item.time})</span>
          </li>
        ))}
      </ul>
    </TopThreeValueWrapper>
  );
};

export default MeasureRecordResultTopThreeValue;
