import ReviewRegistHeader from "components/layout/review.regist.header";
import { useForm, Controller, useWatch } from "react-hook-form";
import { Container, ReviewRegistContainer } from "style/review";
import TextareaAutosize from "react-textarea-autosize";
import ReviewFileAttachment from "components/review/review.file.attachment";
import { useEffect, useState } from "react";
import { useVirtualKeyboard } from "utils/custom.hook";
import { reviewRegistHandler } from "api/review";
import { useNavigate } from "react-router-dom";
import { isReviewModifyChangedState } from "atoms/review.state";
import { useSetRecoilState } from "recoil";

interface ReviewFormData {
  reviewTitle: string; // 제목
  reviewContents: string; // 내용
  reviewFile: File[]; // 첨부된 파일 리스트
}

const ReviewRegist = () => {
  const { register, handleSubmit, control, setValue } =
    useForm<ReviewFormData>();
  const [maxRows, setMaxRows] = useState(10); // 초기 maxRows 값
  const isKeyboardVisible = useVirtualKeyboard(); // 가상 키보드 상태 확인
  const navigate = useNavigate();
  const setIsReviewModifyChanged = useSetRecoilState(
    isReviewModifyChangedState
  );

  // 각 줄의 최대 글자 수와 최대 줄 수
  const MAX_ROWS = 2;
  const MAX_CHARS_PER_ROW = 24; // 한 줄에 들어갈 최대 글자 수 (폰트, 너비에 따라 다를 수 있음)
  const MAX_TOTAL_CHARS = MAX_ROWS * MAX_CHARS_PER_ROW;

  const watchedTitle = useWatch({ control, name: "reviewTitle" });
  const watchedContent = useWatch({ control, name: "reviewContents" });

  useEffect(() => {
    if (watchedTitle?.trim() && watchedContent?.trim()) {
      setIsReviewModifyChanged(true);
    } else {
      setIsReviewModifyChanged(false);
    }
  }, [watchedTitle, watchedContent, setIsReviewModifyChanged]);

  // maxRows 업데이트
  useEffect(() => {
    if (isKeyboardVisible) {
      setMaxRows(5); // 키보드가 열리면 maxRows를 5로 줄임
    } else {
      // 키보드가 닫히면 화면 크기에 맞게 다시 계산
      const totalHeight = window.innerHeight;
      const headerHeight = 56;
      const footerHeight = 124;
      const titleHeight = 60;
      const titleMargin = 24 * 2;
      const availableHeight =
        totalHeight - headerHeight - footerHeight - titleHeight - titleMargin;

      const lineHeight = 16; // 한 줄 높이
      const calculatedRows = Math.floor(availableHeight / lineHeight);
      setMaxRows(calculatedRows);
    }
  }, [isKeyboardVisible]);

  const onSubmit = async (data: ReviewFormData) => {
    try {
      const formData = new FormData();

      formData.append(
        "data",
        JSON.stringify({
          type: "review",
          title: data.reviewTitle,
          contents: data.reviewContents,
        })
      );

      // 파일 데이터 추가 (여러 파일 처리)
      if (data.reviewFile && data.reviewFile.length > 0) {
        data.reviewFile.forEach((file) => {
          formData.append("files", file);
        });
      }

      const response = await reviewRegistHandler(formData);

      if (response.result) {
        navigate("/review");
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ReviewRegistHeader reviewTitle="후기 작성하기" />
        <ReviewRegistContainer>
          <div className="title-box">
            <Controller
              name="reviewTitle"
              control={control}
              rules={{ required: "제목은 필수입니다." }}
              render={({ field, fieldState }) => (
                <>
                  <TextareaAutosize
                    {...field}
                    minRows={1}
                    maxRows={2}
                    placeholder="제목을 입력해주세요."
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault(); // 엔터 키 입력 방지
                      }
                    }}
                    onChange={(e) => {
                      const sanitizedValue = e.target.value.replace(/\n/g, ""); // 줄바꿈 제거
                      if (sanitizedValue.length <= MAX_TOTAL_CHARS) {
                        field.onChange(sanitizedValue); // 제한된 길이 내에서 업데이트
                      }
                    }}
                  />
                  {fieldState.error && (
                    <span style={{ color: "red" }}>
                      {fieldState.error.message}
                    </span>
                  )}
                </>
              )}
            />
          </div>
          <div className="contents-box">
            <Controller
              name="reviewContents"
              control={control}
              rules={{ required: "내용은 필수입니다." }}
              render={({ field, fieldState }) => (
                <>
                  <TextareaAutosize
                    {...field}
                    minRows={5}
                    maxRows={maxRows}
                    placeholder={`RS101과 함께한 층간소음 극복 후기를 나눠보세요. \n\n첨부한 이미지는 글 하단에 표시됩니다. \n(최대 10개, 개당 최대 10MB)`}
                  />
                  {fieldState.error && (
                    <span style={{ color: "red" }}>
                      {fieldState.error.message}
                    </span>
                  )}
                </>
              )}
            />
          </div>
        </ReviewRegistContainer>
        <ReviewFileAttachment
          name="reviewFile"
          register={register}
          setValue={setValue}
        />
      </form>
    </Container>
  );
};

export default ReviewRegist;
