import { InvisibleIcon, VisibleIcon } from "cssReset/icon";
import { InputHTMLAttributes, useState } from "react";
import styled from "styled-components";

interface JoinInputProps {
  name: string;
  errors?: string[];
  register?: any;
  validation?: object;
}

export const PasswordInput = ({
  name,
  register,
  validation = {},
  ...rest
}: JoinInputProps & InputHTMLAttributes<HTMLInputElement>) => {
  const [inputType, setInputType] = useState("password"); // 초기값을 type으로 설정

  const handleToggleType = () => {
    setInputType((prevType) => (prevType === "password" ? "text" : "password"));
  };
  return (
    <InputBox>
      <input
        name={name}
        type={inputType}
        {...rest}
        {...register(name, validation)}
      />
      <span></span>
      <button className="eye-btn" type="button" onClick={handleToggleType}>
        {inputType === "password" ? <VisibleIcon /> : <InvisibleIcon />}
      </button>
    </InputBox>
  );
};

const InputBox = styled.div`
  position: relative;
  margin-bottom: 2rem;
  span {
    display: flex;
    position: absolute;
    bottom: 0;
    left: 50%;
    background-color: #1f75fe;
    width: 0;
    height: 2px;
    border-radius: 2px;
    transform: translateX(-50%);
    transition: all 0.5s;
  }
  input:focus ~ span {
    width: 100%;
  }

  input[type="text"],
  input[type="password"] {
    width: 100%;
    border: 0;
    border-bottom: 1px solid #cecece;
    padding: 0.8rem 0.8rem 0.7rem;
    line-height: 2.4rem;
    font-size: 1.2rem;
    border-radius: 0;
    &:focus {
      outline: none;
    }
  }
  .eye-btn {
    position: absolute;
    right: 0.8rem;
    top: 0.8rem;
    display: flex;
    padding: 0;
    outline: none;
    border: none;
    background-color: transparent;
    z-index: 1;
  }
`;
