import styled from "styled-components";
import { AnimatePresence, motion } from "framer-motion";
import { ReactNode, useEffect } from "react";
import { AdminModalClosedIcon } from "cssReset/icon";

interface ModalProps {
  onClick?: () => void;
  closedHandler: () => void;
  children?: ReactNode; // children prop 추가
  isVisible?: boolean; // 모달 표시 여부
}

const AdminCommonModalLayout = (props: ModalProps) => {
  // 모달 표시 상태에 따라 스크롤 제한
  useEffect(() => {
    if (props.isVisible) {
      document.body.style.overflow = "hidden"; // 스크롤 비활성화
    } else {
      document.body.style.overflow = ""; // 스크롤 복원
    }

    // 컴포넌트 언마운트 시 overflow 복원
    return () => {
      document.body.style.overflow = "";
    };
  }, [props.isVisible]);
  return (
    <AnimatePresence initial={false}>
      {props.isVisible ? ( // 조건부 렌더링 추가
        <ModalContainer
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            duration: 0.3,
            type: "fade",
          }}
        >
          <div className="overlay"></div>
          <ModalInner
            initial={{ y: "100%" }}
            animate={{ y: "0%" }}
            exit={{ y: "100%" }}
            transition={{
              type: "spring",
              stiffness: 400,
              damping: 40,
            }}
          >
            <div className="modal-closed-header" onClick={props.closedHandler}>
              <AdminModalClosedIcon />
            </div>
            {props.children}
          </ModalInner>
        </ModalContainer>
      ) : null}
      {props.isVisible ? null : null} {/* 🚀 `undefined` 방지용 코드 */}
    </AnimatePresence>
  );
};

export default AdminCommonModalLayout;

const ModalContainer = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  padding: 3rem;
  justify-content: center;
  align-items: center;
  cursor: "pointer";
  width: 100%;
  height: 100%;
  .overlay {
    display: flex;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
`;

const ModalInner = styled(motion.div)`
  background-color: white;
  width: 100%;
  border-radius: 1rem;
  overflow: hidden;
  max-width: 44rem;
  .modal-closed-header {
    padding: 1.6rem;
  }
`;
