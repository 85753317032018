import { DownArrowIconX24, LeftArrowIcon, RightArrowIcon } from "cssReset/icon";
import { NoticeDto } from "interface/interface.notice";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { formatDateTime } from "utils/util";
import { motion } from "framer-motion";
import { SliderWrapper } from "style/review";
import Slider from "react-slick";
import { useRecoilValue } from "recoil";
import { alramClickedState } from "atoms/alram.state";

interface NoticeListProps {
  noticeDt: NoticeDto;
}

const NoticeList = ({ noticeDt }: NoticeListProps) => {
  const [slideState, setSlideState] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(1); // 현재 슬라이드 번호
  const [isHidden, setIsHidden] = useState(true);
  const [contentHeight, setContentHeight] = useState(0);
  const [clicked, setClicked] = useState(false); // 클릭 여부 추적 상태 추가

  const alramLinkState = useRecoilValue(alramClickedState);

  const contentRef = useRef<HTMLDivElement>(null); // ✅ ContentsBox 참조
  const titleRef = useRef<HTMLDivElement>(null); // 추가된 ref

  const slideHandler = () => {
    setSlideState((prev) => !prev);
  };

  const sliderSetting = {
    infinite: noticeDt?.files && noticeDt.files.length > 1, // data와 files가 존재할 경우에만 확인
    speed: 500,
    slidesToShow: 1, // 3개 이하일 경우 슬라이드를 하나만 보여줌
    rows: 1, // 4개 이상일 경우에만 3개 행을 보여줌
    slidesToScroll: 1,
    prevArrow: <LeftArrowIcon color="#000" />, // 이전 버튼에 아이콘 추가
    nextArrow: <RightArrowIcon color="#000" />, // 다음 버튼에 아이콘 추가
    afterChange: (current: number) => setCurrentSlide(current + 1), // 현재 슬라이드 번호 업데이트
  };

  // ✅ `ContentsBox`의 실제 높이를 계산하여 상태에 저장
  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight); // ✅ 실제 높이 저장
    }
  }, [noticeDt.contents, noticeDt.files]); // ✅ 내용이 변경될 때마다 높이 업데이트

  // ✅ `slideState === true`가 된 후 약간의 지연을 주고 높이를 가져옴
  useEffect(() => {
    if (slideState) {
      setIsHidden(false); // ✅ display: block 설정

      setTimeout(() => {
        if (contentRef.current) {
          setContentHeight(contentRef.current.scrollHeight); // ✅ 실제 높이 저장
        }
      }, 10); // ✅ 10ms 딜레이 후 실행 (motion.div가 렌더링될 시간을 확보)
    } else {
      setTimeout(() => {
        setIsHidden(true); // ✅ 애니메이션이 끝난 후 display: none 적용
      }, 300);
    }
  }, [slideState]);

  useEffect(() => {
    if (alramLinkState.linkYn && titleRef.current && !clicked) {
      // 이미 클릭되지 않았고, 알림에 해당하는 아이템일 경우만 클릭을 트리거
      setTimeout(() => {
        if (
          noticeDt.announcementId === alramLinkState.commentId &&
          !slideState &&
          titleRef.current // titleRef.current가 null이 아닌 경우에만 클릭
        ) {
          titleRef.current.click(); // 해당 요소 클릭
          setClicked(true); // 클릭이 한번 발생했으므로 상태 업데이트
        }
      }, 350); // 100ms의 지연 후 클릭
    }
  }, [alramLinkState, noticeDt.announcementId, slideState, clicked]); // clicked 상태도 의존성 배열에 추가

  return (
    <NoticeListItemBox>
      <TitleBox
        onClick={slideHandler}
        className={slideState ? "isActive" : ""}
        ref={titleRef}
      >
        <div className="l-box">
          <p className="title">{noticeDt.title}</p>
          <span className="date">{formatDateTime(noticeDt.regDt)}</span>
        </div>
        <div className="r-box">
          <span className="icon-box">
            <DownArrowIconX24 />
          </span>
        </div>
      </TitleBox>
      <motion.div
        initial={{ maxHeight: 0, opacity: 0 }}
        animate={{
          maxHeight: slideState ? contentHeight + 2 : 0, // ✅ 동적으로 높이 적용 +1은 border-bottom 크기
          opacity: slideState ? 1 : 0,
        }}
        transition={{ duration: 0.3, ease: "easeInOut" }}
        style={{
          overflow: "hidden",
          display: isHidden ? "none" : "block",
        }}
      >
        <ContentsBox ref={contentRef}>
          {" "}
          {/* ✅ 실제 높이 측정을 위한 ref 적용 */}
          <p className="content-txt-box">{noticeDt.contents}</p>
          {noticeDt.files && noticeDt.files.length > 0 && (
            <SliderWrapper style={{ marginTop: "0.4rem" }}>
              <div className="inner">
                <Slider {...sliderSetting}>
                  {noticeDt.files.map((img, index) => (
                    <div className="img-box" key={img.fileId}>
                      {/* src url 배포시 수정 */}
                      <img src={img.accessUrl} alt={img.realFileName} />
                    </div>
                  ))}
                </Slider>
              </div>
              {/* 현재 슬라이드 위치 표시 */}
              <div className="slide-counter">
                {currentSlide} / {noticeDt.files.length}
              </div>
            </SliderWrapper>
          )}
        </ContentsBox>
      </motion.div>
    </NoticeListItemBox>
  );
};

export default NoticeList;

const NoticeListItemBox = styled.div``;

const TitleBox = styled.div`
  display: flex;
  align-items: center;
  padding: 1.6rem 0.8rem 1.5rem;
  border-bottom: 1px solid #cecece;
  .l-box {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    .title {
      font-size: 1.2rem;
      font-weight: 600;
      line-height: 1.6rem;
      letter-spacing: -0.06rem;
    }
    .date {
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.2rem;
      color: #575757;
    }
  }
  .r-box {
    flex: 0;
    .icon-box {
      display: flex;
      width: 2.4rem;
      height: 2.4rem;
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
  &.isActive {
    background-color: #ececec;
    .r-box {
      .icon-box {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }
`;

const ContentsBox = styled.div`
  padding: 0.8rem;
  border-bottom: 1px solid #a1a1a1;
  .content-txt-box {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.6rem;
  }
`;
