import { PopupData } from "interface/interface.main.home";
import { BoxInner } from "style/modal";
import Slider from "react-slick";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

interface PopupProps {
  data: PopupData[];
  hideForOneDay: () => void;
  closedHandler?: () => void;
}

const PopupBanner = ({ data, hideForOneDay, closedHandler }: PopupProps) => {
  const navigate = useNavigate();

  const sortedData = [...data].sort((a, b) => a.order - b.order);

  const sliderSetting = {
    infinite: sortedData.some((item) => item.files.length > 1),
    speed: 500,
    slidesToShow: 1, // 3개 이하일 경우 슬라이드를 하나만 보여줌
    rows: 1, // 4개 이상일 경우에만 3개 행을 보여줌
    slidesToScroll: 1,
    arrows: false,
    dots: true,
  };

  const clickHandler = (path: string) => {
    navigate(`/${path}`);
  };

  return (
    <BoxInner>
      <PopupWrap>
        <Slider {...sliderSetting}>
          {sortedData.map((popup) => (
            <div
              key={popup.popupId}
              className="img-box"
              onClick={() => {
                popup.contents && clickHandler(popup.contents);
              }}
            >
              {popup.files.map((file) => (
                <img
                  key={file.fileId}
                  src={file.accessUrl}
                  alt={file.realFileName}
                />
              ))}
            </div>
          ))}
        </Slider>
        <div className="btn">
          <button className="day-btn" onClick={hideForOneDay}>
            하루 동안 보지 않기
          </button>
          <button className="closed-btn" onClick={closedHandler}>
            닫기
          </button>
        </div>
      </PopupWrap>
    </BoxInner>
  );
};

export default PopupBanner;

const PopupWrap = styled.div`
  padding-bottom: 1rem;
  .img-box {
    display: flex;
    img {
      max-width: 100%;
      width: 37.5rem;
      height: 100%;
      overflow: hidden;
      object-fit: contain;
      aspect-ratio: 375 / 176;
    }
  }
  .slick-dots {
    bottom: -2.6rem;
    li {
      margin: 0;
      width: 1.5rem;
      height: 1.5rem;
      button {
        width: 1.5rem;
        height: 1.5rem;
        padding: 0.5rem;
        &::before {
          width: 1.5rem;
          height: 1.5rem;
          line-height: 1.5rem;
        }
      }
    }
  }
  .btn {
    padding: 0.8rem 1.6rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 100;
    button {
      border: none;
      outline: none;
      background-color: transparent;
      font-size: 1.2rem;
      font-weight: 700;
      letter-spacing: -0.06rem;
      line-height: 1.8rem;
      &.day-btn {
        color: #a1a1a1;
      }
      &.closed-btn {
        color: black;
      }
    }
  }
`;
