import {
  reviewCommentHideConfirmModalState,
  reviewCommentShowConfirmModalState,
  reviewHideConfirmModalState,
} from "atoms/admin/modal/modal.state";
import { RepleIcon } from "cssReset/icon";
import { ReviewCommentDto } from "interface/admin/interface.review";
import { useSetRecoilState } from "recoil";
import { CommentBox, CommentContainer } from "style/admin/review";
import { repleCount } from "utils/comment.function";
import { formatDateTime, formatNumber } from "utils/util";

interface CommentProps {
  commentData: ReviewCommentDto[];
  regUserId: string | undefined;
  commentIdPropsHandler: (id: number, hideState: "Y" | "N") => void;
  triggerUpdate: () => void;
}

const AdminReviewDetailComment = ({
  commentData,
  regUserId,
  commentIdPropsHandler,
  triggerUpdate,
}: CommentProps) => {
  const setHideConfirmModal = useSetRecoilState(
    reviewCommentHideConfirmModalState
  );
  const setShowConfirmModal = useSetRecoilState(
    reviewCommentShowConfirmModalState
  );

  const modalOpenHandler = (id: number, hideState: "Y" | "N") => {
    setHideConfirmModal({ isOpen: true, isVisible: true });
    commentIdPropsHandler(id, hideState);
  };

  const showModalOpenhandler = (id: number, hideState: "Y" | "N") => {
    commentIdPropsHandler(id, hideState);
    setShowConfirmModal({ isOpen: true, isVisible: true });
  };

  const hostURL = process.env.REACT_APP_DEV_URL;

  const handleClickedReportlist = (id: string, type: string) => {
    window.open(
      `${hostURL}/admin/report/list/${id}?type=${type}`,
      "_blank",
      "width=1240,height=800,scrollbars=no,resizable=no,toolbar=no,menubar=no,location=no,status=no"
    );
  };

  return (
    <CommentContainer>
      <div className="count-box">
        <span>댓글</span>
        <span className="count">{formatNumber(repleCount(commentData))}</span>
      </div>
      {commentData && commentData.length > 0 ? (
        <CommentBox>
          {commentData
            .slice()
            .reverse()
            .map((parent) => {
              // 🔹 부모 댓글이 삭제된 경우 (useYn !== "Y")
              const isDeleted = parent.useYn !== "Y";
              // 자식이 없을때
              const hasChildren = parent.children && parent.children.length > 0;
              const isReported = parent.hideYn === "Y"; // 관리자가 댓글 숨김 처리

              // 🔹 삭제된 댓글이지만 대댓글이 있는 경우 댓글 박스 유지
              if (isDeleted && !hasChildren) return null;

              // 🔹 댓글 내용을 렌더링하는 함수
              const renderCommentContent = () => {
                switch (true) {
                  case isDeleted:
                    return (
                      <div className="comment-text-box">
                        <p className="delete-comment-txt">삭제된 댓글입니다.</p>
                      </div>
                    );
                  case isReported:
                    return (
                      <div className="comment-text-box blocked">
                        <span>운영 정책 위반</span>으로 숨겨진 댓글입니다.
                      </div>
                    );
                  default:
                    return (
                      <div className="comment-text-box">{parent.contents}</div>
                    );
                }
              };
              return (
                <div
                  className="inner"
                  key={parent.reviewCommentId}
                  // 알림 클릭해서 위치로 이동하면 어디 댓글인지 참조
                >
                  {/* 최상위 댓글 */}
                  <div className="parent-box">
                    <div className="profile-box">
                      {parent.userProfileUrl ? (
                        <div>
                          <img src={parent.userProfileUrl} alt="" />
                        </div>
                      ) : (
                        <div>
                          <img src="/assets/icon/userprofile.png" alt="" />
                        </div>
                      )}
                    </div>
                    <div className="comment-wrap">
                      <div className="user-info-box">
                        <div className="lbox">
                          {/* 차단 당한 사람의 profile */}
                          <div className="user-name">
                            {parent.userName}{" "}
                            {parent.regUserId === regUserId && (
                              <span className="reg-user">작성자</span>
                            )}{" "}
                          </div>
                          <span className="date">
                            {formatDateTime(parent.regDt)}
                          </span>
                        </div>
                        <div className="rbox">
                          <div className="report">
                            <span
                              className={`${
                                Number(parent.reviewCommentReportCount) >= 10
                                  ? "report-txt"
                                  : ""
                              } ${
                                Number(parent.reviewCommentReportCount ?? 0) > 0
                                  ? "cursor"
                                  : ""
                              }`}
                              onClick={() =>
                                handleClickedReportlist(
                                  String(parent.reviewCommentId),
                                  "REVIEW_COMMENT"
                                )
                              }
                            >{`신고(${parent.reviewCommentReportCount})`}</span>
                          </div>
                          <button
                            disabled={
                              parent.hideYn === "Y" || parent.useYn === "N"
                            }
                            onClick={() => {
                              modalOpenHandler(
                                parent.reviewCommentId,
                                parent.hideYn
                              );
                            }}
                          >
                            숨기기
                          </button>
                          <button
                            disabled={
                              parent.hideYn === "N" || parent.useYn === "N"
                            }
                            // 노출하기 버튼 처리 시 팝업을 띄우면 안됨.
                            onClick={() => {
                              showModalOpenhandler(
                                parent.reviewCommentId,
                                parent.hideYn
                              );
                            }}
                          >
                            노출하기
                          </button>
                        </div>
                      </div>
                      {/* 차단 당한 사람의 글 */}
                      {renderCommentContent()}
                      <div className="reple-btn-box">
                        <button onClick={() => {}}>
                          <span className="icon-box">
                            <RepleIcon />
                          </span>
                          답글{" "}
                          <span>
                            {parent.children?.filter(
                              (child) => child.useYn === "Y"
                            ).length || ""}
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>

                  {/* 댓글에 댓글 */}
                  {parent.children && parent.children.length > 0 && (
                    <div className="children-wrap">
                      {parent.children
                        .slice()
                        .reverse()
                        .map((child) => {
                          const isChildDeleted = child.useYn !== "Y";
                          const isReported = child.hideYn === "Y"; // 관리자가 댓글 숨김 처리

                          // 자식을 삭제하면 화면에 표시하지 않음.
                          if (isChildDeleted) return null;

                          // 🔹 댓글 내용을 렌더링하는 함수
                          const renderCommentContent = () => {
                            switch (true) {
                              case isReported:
                                return (
                                  <div className="comment-text-box blocked">
                                    <span>운영 정책 위반</span>으로 숨겨진
                                    댓글입니다.
                                  </div>
                                );
                              default:
                                return (
                                  <div className="comment-text-box">
                                    {child.contents}
                                  </div>
                                );
                            }
                          };
                          return (
                            <div
                              className="child-box"
                              key={child.reviewCommentId}
                              // 알림 클릭해서 위치로 이동하면 어디 댓글인지 참조
                            >
                              <div className="profile-box">
                                {child.userProfileUrl ? (
                                  <div>
                                    <img
                                      src={child.userProfileUrl || undefined}
                                      alt=""
                                    />
                                  </div>
                                ) : (
                                  <div>
                                    <img
                                      src="/assets/icon/userprofile.png"
                                      alt=""
                                    />
                                  </div>
                                )}
                              </div>
                              <div className="comment-wrap">
                                <div className="user-info-box">
                                  <div className="lbox">
                                    <div className="user-name">
                                      {child.userName}{" "}
                                      {child.regUserId === regUserId && (
                                        <span className="reg-user">작성자</span>
                                      )}
                                    </div>
                                    <span className="date">
                                      {formatDateTime(child.regDt)}
                                    </span>
                                  </div>
                                  <div className="rbox">
                                    <div className="report">
                                      <span
                                        className={`${
                                          Number(
                                            child.reviewCommentReportCount
                                          ) >= 10
                                            ? "report-txt"
                                            : ""
                                        } ${
                                          Number(
                                            child.reviewCommentReportCount ?? 0
                                          ) > 0
                                            ? "cursor"
                                            : ""
                                        }`}
                                        onClick={() =>
                                          handleClickedReportlist(
                                            String(child.reviewCommentId),
                                            "REVIEW_COMMENT"
                                          )
                                        }
                                      >{`신고(${child.reviewCommentReportCount})`}</span>
                                    </div>
                                    <button
                                      disabled={
                                        child.hideYn === "Y" ||
                                        child.useYn === "N"
                                      }
                                      onClick={() => {
                                        modalOpenHandler(
                                          child.reviewCommentId,
                                          child.hideYn
                                        );
                                      }}
                                    >
                                      숨기기
                                    </button>
                                    <button
                                      disabled={
                                        child.hideYn === "N" ||
                                        child.useYn === "N"
                                      }
                                      type="button"
                                      onClick={() => {
                                        showModalOpenhandler(
                                          child.reviewCommentId,
                                          child.hideYn
                                        );
                                      }}
                                    >
                                      노출하기
                                    </button>
                                  </div>
                                </div>
                                {renderCommentContent()}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  )}
                </div>
              );
            })}
        </CommentBox>
      ) : (
        <div>
          <span>댓글을 남겨보세요.</span>
        </div>
      )}
    </CommentContainer>
  );
};

export default AdminReviewDetailComment;
