import { fetchLoadAdminFooterPopupList } from "api/admin/contents.footer.popup";
import { bannerDeleteModalState } from "atoms/admin/contents/banner.state";
import AdminFooterPopupTable from "components/admin/contents/footer.popup/admin.footer.popup.table";
import {
  AdminFooterPopupDto,
  FooterPopupParams,
} from "interface/admin/interface.contents";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { Container } from "style/admin/contents";

const AdminFooterPopupHome = () => {
  const [data, setData] = useState<AdminFooterPopupDto[]>([]);
  const bannerDeleteModal = useRecoilValue(bannerDeleteModalState);

  console.log(data);

  useEffect(() => {
    const loadBannerList = async () => {
      try {
        const params: FooterPopupParams = {
          orderBy: "ORDER",
          orderByDir: "ASC",
        };
        const response = await fetchLoadAdminFooterPopupList(params);
        if (response.result) {
          // ✅ useYn이 "Y"인 항목만 필터링하여 저장
          const filteredData = response.result.filter(
            (item: AdminFooterPopupDto) => item.useYn === "Y"
          );
          setData(filteredData);
        }
      } catch (error) {
        console.error(error);
      }
    };

    loadBannerList();
  }, [bannerDeleteModal]);

  return (
    <Container>
      <AdminFooterPopupTable data={data} />
    </Container>
  );
};

export default AdminFooterPopupHome;
