import styled from "styled-components";
import { motion } from "framer-motion";

export const Container = styled.div``;

export const Step = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.6rem;
`;

export const JoinDescription = styled.div`
  padding: 2.4rem 0;
  margin-bottom: 2.4rem;
  p {
    font-size: 2rem;
    line-height: 2.4rem;
    font-weight: 700;
    letter-spacing: -0.06rem;
    margin-bottom: 1.2rem;
  }
  span {
    font-size: 1.2rem;
    line-height: 1.6rem;
    font-weight: 400;
    white-space: pre-wrap;
  }
`;

export const InputWarpper = styled.div`
  .input-box-custom {
    display: grid;
    grid-template-columns: 6rem auto;
    gap: 1.2rem;
    position: relative;
    > label {
      display: flex;
      align-items: center;
      font-size: 1.4rem;
      font-weight: 700;
      letter-spacing: -0.06rem;
    }
    input[type="text"] {
      padding: 0.9rem 0.8rem 0.7rem;
      outline: none;
      border: 1px solid #cecece;
      border-radius: 0.5rem;
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 2.2rem;
    }
  }
`;

export const RadioBox = styled.div`
  display: flex;
  align-items: center;
  gap: 1.2rem;
  .radio-box {
    flex: 1 0 30%;
    display: flex;
    align-items: center;
    input[type="radio"] {
      display: none;
    }
    label {
      flex: 1 1 auto;
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 1.6rem;
      display: flex;
      justify-content: center;
      align-content: center;
      background-color: white;
      color: #242629;
      border: 1px solid #242629;
      padding: 0.7rem 0;
      border-radius: 10rem;
    }
    input[type="radio"]:checked + label {
      background-color: #242629;
      color: white;
      border: #242629;
    }
  }
`;

export const NextBtn = styled.button`
  width: 100%;
  outline: none;
  border: none;
  padding: 1rem;
  color: white;
  background-color: #225298;
  border-radius: 0.5rem;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2rem;
  margin-top: 2.4rem;

  &:disabled {
    background-color: #cecece;
  }
`;

export const ErrorMessage = styled.div`
  color: #ed2115;
  font-size: 1rem;
  margin-top: 0.4rem;
  position: absolute;
  left: 0.4rem;
  top: 4rem;
`;

export const ProfileWrap = styled.div`
  .input-box {
    position: relative;
    margin-bottom: 2rem;
    span {
      display: flex;
      position: absolute;
      bottom: 0;
      left: 50%;
      background-color: #1f75fe;
      width: 0;
      height: 2px;
      border-radius: 2px;
      transform: translateX(-50%);
      transition: all 0.5s;
    }
    input:focus ~ span {
      width: 100%;
    }

    input[type="text"] {
      width: 100%;
      border: 0;
      border-bottom: 1px solid #cecece;
      padding: 0.8rem 0.8rem 0.7rem;
      line-height: 2.4rem;
      font-size: 1.2rem;
      border-radius: 0;
      &:focus {
        outline: none;
      }
    }
  }
  .submit-btn {
    margin-top: 4.4rem;
    input[type="submit"] {
      width: 100%;
      outline: none;
      border: none;
      padding: 1rem;
      color: white;

      border-radius: 0.5rem;
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 2rem;
      transition: background-color 0.3s;
      background-color: #225298;
      &:disabled {
        background-color: #cecece;
      }
    }
  }
  .information-wrap {
    margin-top: 0.8rem;
    text-align: center;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.2rem;
    margin-bottom: 2.4rem;
    p:first-child {
      margin-bottom: 0.4rem;
    }
    p {
      color: #575757;
    }
    p > span {
      text-decoration: underline;
      text-decoration-color: #575757;
    }
  }
  .marketing-alram-wrap {
    padding: 2.4rem 0;
    .checkbox-input {
      display: flex;
      align-items: center;
      gap: 0.8rem;
      input[type="checkbox"] {
        width: 1.6rem;
        height: 1.6rem;
        border-radius: 0;
        margin: 0;
      }
      label {
        font-size: 1.2rem;
        line-height: 1.6rem;
        font-weight: 400;
      }
    }
    p {
      margin-left: 2.4rem;
      margin-top: 0.7rem;
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 1.6rem;
      color: #a1a1a1;
    }
  }
`;

export const LengthCount = styled.div`
  position: absolute;
  bottom: -2rem;
  right: 0.4rem;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.6rem;
  color: #a1a1a1;
`;

export const EmailAuthInputWrap = styled.div`
  .input-box {
    position: relative;
    margin-bottom: 2rem;
    span {
      display: flex;
      position: absolute;
      bottom: 0;
      left: 50%;
      background-color: #1f75fe;
      width: 0;
      height: 2px;
      border-radius: 2px;
      transform: translateX(-50%);
      transition: all 0.5s;
    }
    input:focus ~ span {
      width: 100%;
    }

    input[type="text"] {
      width: 100%;
      border: 0;
      border-bottom: 1px solid #cecece;
      padding: 0.8rem 0.8rem 0.7rem;
      line-height: 2.4rem;
      font-size: 1.2rem;
      border-radius: 0;
      &:focus {
        outline: none;
      }
    }
  }
  .submit-btn {
    margin-top: 4.4rem;
    input[type="submit"] {
      width: 100%;
      outline: none;
      border: none;
      padding: 1rem;
      color: white;

      border-radius: 0.5rem;
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 2rem;
      transition: background-color 0.3s;
      background-color: #225298;
      &:disabled {
        background-color: #cecece;
      }
    }
    button {
      width: 100%;
      outline: none;
      border: none;
      padding: 1rem;
      color: white;
      border-radius: 0.5rem;
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 2rem;
      transition: background-color 0.3s;
      background-color: #225298;
      &:disabled {
        background-color: #cecece;
      }
    }
  }

  .retry-btn-box {
    margin-top: 2.4rem;
    text-align: center;
    font-size: 1.2rem;
    line-height: 1.6rem;
    font-weight: 400;
    padding: 0.4rem;
    span {
      color: #225298;
    }
  }
`;
