import { useState } from "react";
import { useForm } from "react-hook-form";
import { AnimatePresence } from "framer-motion";
import JoinPassword from "components/auth/join/join.password";
import JoinHeader from "components/auth/join/join.header";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Container, Step } from "style/join";
import JoinDefaultInfo from "components/auth/join/join.default.info";
import JoinProfile from "components/auth/join/join.profile";
import { joinHandler } from "api/join";
import JoinEmailAuth from "components/auth/join/join.email.auth";

interface JoinFormValue {
  userId: string;
  birthDate: string;
  role: string;
  username: string;
  sex: string;
  password: string;
  email: string;
  profileImg?: FileList;
}

const Join = () => {
  const {
    register,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm<JoinFormValue>();
  const [step, setStep] = useState(0); // 현재 스텝을 관리
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const navigate = useNavigate();

  const handleNext = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const backArrowHandler = () => {
    if (step === 0) {
      navigate("/");
    } else {
      setStep((prevStep) => prevStep - 1);
    }
  };

  const onSubmit = async (data: JoinFormValue) => {
    try {
      const formData = new FormData();

      const formattedBirthDate = data.birthDate.replace(/ - /g, "");

      formData.append(
        "data",
        JSON.stringify({
          mobile: "", // 모바일 번호는 하드코딩되거나 추가 입력값에서 가져와야 함
          userId: email, // 이메일이 없을 경우 기본 생성
          birthDate: formattedBirthDate, // 기본값 또는 입력값
          address: "", // 기본값 또는 입력값
          role: "ROLE_USER",
          userName: data.username,
          gender: data.sex,
          password: data.password,
          email: email, // 이메일 값
        })
      );

      // 파일 추가
      if (data.profileImg) {
        formData.append("files", data.profileImg[0]); // 파일은 FileList 형태에서 첫 번째 파일 선택
      }

      const response = await joinHandler(formData);

      if (response.result) {
        navigate("/");
      } else {
        alert("회원가입 실패");
      }
    } catch (error) {
      console.error("회원가입 실패:", error);
      alert("회원가입 실패");
    }
  };
  return (
    <Container>
      <JoinHeader onClick={backArrowHandler} headerTitle="회원가입" />
      <AnimatePresence mode="wait" initial={false}>
        {step === 0 && (
          <Step
            key={0}
            initial={{ opacity: 0, y: 20 }} // 초기 상태 (살짝 오른쪽에서 시작)
            animate={{ opacity: 1, y: 0 }} // 나타날 때
            exit={{ opacity: 0, y: -20 }} // 사라질 때 (왼쪽으로 이동하며 사라짐)
            transition={{ duration: 0.3 }} // 애니메이션 속도
          >
            <JoinEmailAuth
              email={email}
              handleNext={handleNext}
              forPasswordReset={false}
            />
          </Step>
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          {step === 1 && (
            <Step
              key={1}
              initial={{ opacity: 0, y: 20 }} // 초기 상태 (살짝 오른쪽에서 시작)
              animate={{ opacity: 1, y: 0 }} // 나타날 때
              exit={{ opacity: 0, y: -20 }} // 사라질 때 (왼쪽으로 이동하며 사라짐)
              transition={{ duration: 0.3 }} // 애니메이션 속도
            >
              <JoinPassword
                register={register}
                handleNext={handleNext}
                watch={watch}
                setError={setError}
                clearErrors={clearErrors}
                errors={errors}
              />
            </Step>
          )}

          {step === 2 && (
            <Step
              key={2}
              initial={{ opacity: 0, y: 20 }} // 초기 상태 (살짝 오른쪽에서 시작)
              animate={{ opacity: 1, y: 0 }} // 나타날 때
              exit={{ opacity: 0, y: -20 }} // 사라질 때 (왼쪽으로 이동하며 사라짐)
              transition={{ duration: 0.3 }} // 애니메이션 속도
            >
              <JoinDefaultInfo
                register={register}
                handleNext={handleNext}
                watch={watch}
                setError={setError}
                clearErrors={clearErrors}
                errors={errors}
                setValue={setValue}
              />
            </Step>
          )}

          {step === 3 && (
            <Step
              key={3}
              initial={{ opacity: 0, y: 20 }} // 초기 상태 (살짝 오른쪽에서 시작)
              animate={{ opacity: 1, y: 0 }} // 나타날 때
              exit={{ opacity: 0, y: -20 }} // 사라질 때 (왼쪽으로 이동하며 사라짐)
              transition={{ duration: 0.3 }} // 애니메이션 속도
            >
              <JoinProfile
                register={register}
                setValue={setValue}
                watch={watch}
                errors={errors}
              />
            </Step>
          )}
        </form>
      </AnimatePresence>
    </Container>
  );
};

export default Join;
