import styled from "styled-components";

export const BestReviewListBox = styled.div``;

export const TopBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.2rem 0.8rem;
  p {
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 2rem;
    letter-spacing: -0.06rem;
  }
  .total-review-btn {
    display: flex;
    align-items: center;
    padding: 0.4rem;
    gap: 0.4rem;
    font-size: 1.2rem;
    line-height: 1.6rem;
    font-size: 400;
    .icon-box {
      display: flex;
      width: 1.6rem;
      height: 1.6rem;
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
`;

export const BestReviewContentsBox = styled.div`
  .inner {
    display: flex;
    gap: 2.4rem;
    overflow: scroll hidden;
    &::-webkit-scrollbar {
      display: none;
    }
    .list-item {
      display: flex;
      flex-direction: column;
      gap: 0.7rem;
      width: 16.8rem;
      .img-box {
        width: 16.8rem;
        height: 16.8rem;
        border-radius: 1rem;
        overflow: hidden;
        position: relative;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .img-bottom-con-box {
          position: absolute;
          left: 0;
          bottom: 0;
          padding: 0.4rem 0.8rem;
          background-color: rgba(0, 0, 0, 0.5);
          width: 100%;
          display: flex;
          gap: 1.6rem;
          justify-content: flex-end;
          .like-box {
            display: flex;
            align-items: center;
            gap: 0.4rem;
            .icon-box {
              width: 1.4rem;
              height: 1.4rem;
              svg {
                width: 100%;
                height: 100%;
              }
            }
            span {
              color: white;
              font-size: 1.2rem;
              line-height: 1.6rem;
              font-size: 400;
            }
          }
          .comment-box {
            display: flex;
            align-items: center;
            gap: 0.4rem;
            .icon-box {
              width: 1.4rem;
              height: 1.4rem;
              svg {
                width: 100%;
                height: 100%;
              }
            }
            span {
              color: white;
              font-size: 1.2rem;
              line-height: 1.6rem;
              font-size: 400;
            }
          }
        }
      }
    }
    .sub-box {
      .review-title {
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 2.4rem;
        min-height: 5.2rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* 2줄로 제한 */
        -webkit-box-orient: vertical;
      }
      .info-box {
        display: flex;
        align-items: center;
        gap: 1rem;
        .profile-img-box {
          width: 2.4rem;
          height: 2.4rem;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 100px;
          }
        }
        span {
          font-size: 1.6rem;
          font-weight: 400;
          line-height: 2.4rem;
        }
      }
    }
  }
`;
