import { fetchProfileInfo } from "api/profile";
import { userInformation, userInfoUpdateSuccess } from "atoms/user.info";
import Logout from "components/setting/setting/logout";
import { UserInfoDt } from "interface/interface.profile";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import styled from "styled-components";
import { formatDateTime, getAccountType, maskEmail } from "utils/util";
import { Slide, ToastContainer, toast } from "react-toastify";

const SettingProfile = () => {
  const [profileDt, setProfileDt] = useState<UserInfoDt>();
  const navigate = useNavigate();

  // user info save
  const setUserInfoDt = useSetRecoilState(userInformation);
  const [userInfoUpdateComplete, setUserInfoUpdateComplete] = useRecoilState(
    userInfoUpdateSuccess
  );

  useEffect(() => {
    if (userInfoUpdateComplete) {
      loadToast();
      setUserInfoUpdateComplete(false);
    }
  }, [userInfoUpdateComplete]);

  useEffect(() => {
    const loadProfileInfo = async () => {
      try {
        const response = await fetchProfileInfo();
        setProfileDt(response.result);
        setUserInfoDt(response.result);
      } catch (error) {
        console.log(error);
      }
    };

    loadProfileInfo();
  }, [setUserInfoDt]);

  const moveToProfileUpdate = () => {
    navigate("/setting/settingProfile/update");
  };

  const loadToast = () => {
    toast(`프로필 변경사항이 적용되었습니다.`, {
      position: "bottom-center",
      style: {
        backgroundColor: "rgba(0,0,0,0.83)",
        color: "#fff",
        fontSize: "1.2rem",
        borderRadius: "1rem",
        fontWeight: "500",
        letterSpacing: "-0.06rem",
        justifyContent: "center",
        padding: "0.8rem",
        minHeight: "auto",
        boxShadow: "1px 1px 4px 0 #0000004D",
      },
      transition: Slide,
    });
  };

  const goToDeleteAccount = () => {
    navigate("/setting/deleteAccount");
  };

  return (
    <ProfileContainer>
      {profileDt && (
        <ProfileAuthBox>
          <p>
            {getAccountType(profileDt.type)}({maskEmail(profileDt?.userId)})
          </p>
          <span>가입일 : {formatDateTime(profileDt.regDt)}</span>
        </ProfileAuthBox>
      )}
      {profileDt && (
        <ProfileInfoBox>
          <div className="img-box">
            {profileDt.files && profileDt.files.length > 0 ? (
              <img src={profileDt.files[0].accessUrl} alt="" />
            ) : (
              <img src="/assets/icon/userprofile.png" alt="" />
            )}
          </div>
          <div className="user-name">{profileDt.userName}</div>
        </ProfileInfoBox>
      )}
      <BtnBox>
        <button className="profile-update-btn" onClick={moveToProfileUpdate}>
          프로필 수정
        </button>
      </BtnBox>
      <HrBox />
      <EtcBox>
        <Logout />
      </EtcBox>
      <EtcBox onClick={goToDeleteAccount}>회원 탈퇴</EtcBox>

      <ToastContainer
        hideProgressBar
        closeButton={false}
        autoClose={1000}
        style={{
          padding: "1.6rem 3.2rem 4.7rem",
          borderRadius: "1rem",
        }}
      />
    </ProfileContainer>
  );
};

export default SettingProfile;

const ProfileContainer = styled.div`
  padding: 1.6rem;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`;

const ProfileAuthBox = styled.div`
  display: flex;
  flex-direction: column;
  p {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.6rem;
  }
  span {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.2rem;
    color: #575757;
  }
`;

const ProfileInfoBox = styled.div`
  display: flex;
  gap: 0.8rem;
  align-items: center;
  .img-box {
    width: 4rem;
    height: 4rem;
    display: flex;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10rem;
    }
  }
  .user-name {
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1.8rem;
    letter-spacing: -0.06rem;
  }
`;

const BtnBox = styled.div`
  .profile-update-btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    background-color: #225298;
    color: white;
    padding: 1rem;
    border-radius: 0.5rem;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: -0.06rem;
  }
`;

const HrBox = styled.div`
  border: 1px solid #d9d9d9;
`;

const EtcBox = styled.div`
  padding: 0.6rem 0;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.6rem;
`;
