import { atom } from "recoil";

export const adminLoginModalState = atom<{
  isOpen: boolean;
  isVisible: boolean;
}>({
  key: "adminLoginModalState",
  default: {
    isOpen: false,
    isVisible: false,
  },
});

export const inquiryAnswerRegistModalState = atom<{
  isOpen: boolean;
  isVisible: boolean;
}>({
  key: "inquiryAnswerRegistModalState",
  default: {
    isOpen: false,
    isVisible: false,
  },
});

export const inquiryAnswerRegistCancelConfirmState = atom<{
  isOpen: boolean;
  isVisible: boolean;
  onClick?: () => void;
}>({
  key: "inquiryAnswerRegistCancelConfirmState",
  default: {
    isOpen: false,
    isVisible: false,
  },
});

export const reviewCommentHideConfirmModalState = atom<{
  isOpen: boolean;
  isVisible: boolean;
  onClick?: () => void;
}>({
  key: "reviewCommentHideConfirmModalState",
  default: {
    isOpen: false,
    isVisible: false,
  },
});

export const reviewCommentShowConfirmModalState = atom<{
  isOpen: boolean;
  isVisible: boolean;
  onClick?: () => void;
}>({
  key: "reviewCommentShowConfirmModalState",
  default: {
    isOpen: false,
    isVisible: false,
  },
});

export const reviewHideConfirmModalState = atom<{
  isOpen: boolean;
  isVisible: boolean;
  onClick?: () => void;
}>({
  key: "reviewHideConfirmModalState",
  default: {
    isOpen: false,
    isVisible: false,
  },
});

export const reviewShowConfirmModalState = atom<{
  isOpen: boolean;
  isVisible: boolean;
  onClick?: () => void;
}>({
  key: "reviewShowConfirmModalState",
  default: {
    isOpen: false,
    isVisible: false,
  },
});

export const bestReviewUpdateState = atom<{
  isOpen: boolean;
  isVisible: boolean;
  onClick?: () => void;
}>({
  key: "bestReviewUpdateState",
  default: {
    isOpen: false,
    isVisible: false,
  },
});

export const bestReviewCancelUpdateState = atom<{
  isOpen: boolean;
  isVisible: boolean;
}>({
  key: "bestReviewCancelUpdateState",
  default: {
    isOpen: false,
    isVisible: false,
  },
});
