import { formatDateTime, formatNumber } from "utils/util";

interface ContentsProps {
  title: string;
  useYn: "Y" | "N";
  regUserId: string;
  regDt: string;
  views: number;
}

const AdminNoticeDetailTopContents = ({
  useYn,
  regDt,
  title,
  regUserId,
  views,
}: ContentsProps) => {
  return (
    <div className="info-box">
      <div className="tbox">
        <p>{title}</p>
        <span className={`hide-item ${useYn === "Y" ? "bluebox" : "redbox"}`}>
          {useYn === "Y" ? "정상노출" : "숨겨짐"}
        </span>
      </div>
      <div className="bbox">
        <div className="regUserName">
          <span>{regUserId}</span>
        </div>
        <div className="date">
          <span>{formatDateTime(String(regDt))}</span>
        </div>
        <div className="view">
          <span>조회</span> <span>{formatNumber(views)}</span>
        </div>
      </div>
    </div>
  );
};

export default AdminNoticeDetailTopContents;
