import styled from "styled-components";

export const ColumnListWrap = styled.div``;

export const ColumnInner = styled.div`
  padding: 2.4rem 1.6rem;
`;

export const ListBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  .list-wrap {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
    .review-item {
      padding: 0.8rem 0;
      display: flex;
      gap: 0.8rem;
      border-bottom: 1px solid #ececec;
      .l-box {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 0.4rem;
        .title {
          font-size: 1.4rem;
          font-weight: 700;
          line-height: 1.8rem;
          letter-spacing: -0.06rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis; /* 말줄임 적용 */
          width: 20.4rem;
        }
        .contents {
          font-size: 1.2rem;
          font-weight: 400;
          line-height: 1.6rem;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          width: 20.4rem;
          min-height: 3.2rem;
        }
        .profile-box {
          display: flex;
          align-items: center;
          gap: 0.4rem;
          .username {
            font-size: 1rem;
            font-weight: 700;
            line-height: 1.4rem;
          }
          .profile-img-box {
            width: 2rem;
            height: 2rem;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
        .sub-items {
          display: flex;
          align-items: center;
          gap: 0.8rem;
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.2rem;
          > span {
            display: flex;
            align-items: center;
            gap: 0.2rem;
            color: #a1a1a1;
            .icon-box {
              display: flex;
            }
          }
        }
      }
      .r-box {
        width: 7.2rem;
        height: 7.2rem;
        overflow: hidden;
        position: relative;
        border-radius: 1rem;
        .img-box {
          width: 7.2rem;
          height: 7.2rem;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .img-count-box {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          background-color: rgba(0, 0, 0, 0.5);
          padding: 0.4rem 0.8rem;
          color: white;
          font-weight: 400;
          font-size: 1.2rem;
          line-height: 1.6rem;
        }
      }
    }
  }
`;

export const ColumnDetailWrap = styled.div`
  min-height: calc(100vh - 5.6rem);
  @media screen and (min-width: 785px) {
    padding-bottom: 7.5rem;
  }
`;

export const UserInfoBox = styled.div`
  padding: 0.8rem 1.6rem;
  display: grid;
  grid-template-columns: 4rem auto;
  gap: 0.8rem;
  .profile-img {
    img {
      max-width: 100%;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
      border: 0;
      outline: 0;
    }
  }
  .info {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    .user-name {
      font-size: 1.4rem;
      font-weight: 700;
      line-height: 1.8rem;
      letter-spacing: -0.06rem;
    }
    .date {
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 1.6rem;
    }
  }
`;

export const ContentsBox = styled.div`
  padding: 1.6rem;
  background-color: #fafafa;
  .title {
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 2rem;
    letter-spacing: -0.06rem;
    margin-bottom: 1rem;
  }
  .contents-text {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.6rem;
    white-space: pre-wrap;
  }
`;

export const SliderWrapper = styled.div`
  margin-top: 2.4rem;
  position: relative;
  .inner {
    padding: 0 1.4rem;
    .img-box {
      width: 31.5rem;
      height: 19.3rem;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 0.5rem;
      }
    }
    .slick-arrow-custom {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      &.slick-arrow-left {
        left: -1.4rem;
      }
      &.slick-arrow-right {
        right: -1.4rem;
      }
    }
  }
  .slide-counter {
    position: absolute;
    bottom: 0.4rem;
    right: 2rem;
    padding: 0.2rem 0.4rem;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border-radius: 10rem;
    font-weight: 400;
    line-height: 1.2rem;
    font-size: 1rem;
  }
`;

export const SubContentsBox = styled.div`
  display: grid;
  grid-template-columns: auto 2.4rem;
  gap: 1rem;
  margin-top: 2.4rem;
  .review-count {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    color: #000;
    gap: 0.4rem;
    font-weight: 400;
    line-height: 1.6rem;
  }
  > .icon-box {
    display: flex;
    transition: all 0.2s;
    width: 100%;
    height: 100%;
    &.animate-icon {
      transform: scale(1.3); /* 하트 아이콘이 커졌다 작아지는 효과 */
    }
    svg {
      width: 100%;
      height: 100%;
    }
  }
`;
