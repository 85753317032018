import { ClickedItem, NoiseData } from "interface/interface.measure";
import { LogValueItemsWrapper } from "style/measure";

interface NoiseLogListProps {
  title: string;
  data: NoiseData[];
  clickedItemHandler: (item: ClickedItem) => void;
  clickedItem?: ClickedItem; // 선택된 항목 정보
}

const MeasureRecordResultLogItem = ({
  data,
  title,
  clickedItemHandler,
  clickedItem,
}: NoiseLogListProps) => {
  return (
    <LogValueItemsWrapper>
      <h3>{title}</h3>
      <ul>
        {data.map((item, index) => (
          <li
            key={index}
            onClick={() => {
              clickedItemHandler({ time: item.time, value: item.value });
            }}
            className={
              clickedItem?.time === item.time &&
              clickedItem?.value === item.value
                ? "active"
                : ""
            }
          >
            <span className="time">{item.time}</span>
            <span className="value">{item.value.toFixed(2)}</span>
          </li>
        ))}
      </ul>
    </LogValueItemsWrapper>
  );
};

export default MeasureRecordResultLogItem;
