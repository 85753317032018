import { isHomePathState } from "atoms/layout.state";
import { reviewEditModalState, snsModalState } from "atoms/modal.state";
import {
  BackArrowIcon,
  MainSettingIcon,
  ReveiwDetailHeaderLeftIcon,
  ReviewDetailHeaderRightIcon,
} from "cssReset/icon";
import { HeaderTitleProps } from "interface/interface.header";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import styled from "styled-components";

const BackArrowHeader = ({
  headerTitle,
  detailBtnBox = false,
  alramSettingBtn = false,
  backPath,
}: HeaderTitleProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isHomePath = useRecoilValue(isHomePathState);

  const handleOnclick = () => {
    console.log(location.pathname);
    console.log(location.pathname.startsWith("/setting"));
    if (backPath) {
      if (isHomePath === "mypage" && location.pathname === "/setting") {
        navigate("/mypage"); // ✅ homePath가 설정된 경우만 /mypage 이동
      } else {
        navigate(backPath); // ✅ backPath가 현재 위치와 다르면 이동
      }
    } else {
      navigate(-1); // ✅ 브라우저 히스토리를 활용하여 정상적으로 뒤로 가기
    }
  };

  const setSnsModal = useSetRecoilState(snsModalState);
  const setReviewEditModal = useSetRecoilState(reviewEditModalState);

  const openSnsModal = () => {
    setSnsModal({ isOpen: true, isVisible: true });
  };

  const openReviewEditModal = () => {
    setReviewEditModal({ isOpen: true, isVisible: true });
  };

  const alramSettingBtnClicked = () => {
    navigate("/alram/setting");
  };

  return (
    <HeaderContainer>
      <div className="icon-box" onClick={handleOnclick}>
        <BackArrowIcon />
      </div>
      <div className="header-title">
        <p>{headerTitle}</p>
      </div>
      {detailBtnBox && (
        <div className="review-detail-btn-box">
          <div className="icon-box" onClick={openSnsModal}>
            <ReveiwDetailHeaderLeftIcon />
          </div>
          <div className="icon-box" onClick={openReviewEditModal}>
            <ReviewDetailHeaderRightIcon />
          </div>
        </div>
      )}
      {alramSettingBtn && (
        <div className="review-detail-btn-box">
          <div className="icon-box" onClick={alramSettingBtnClicked}>
            <MainSettingIcon />
          </div>
        </div>
      )}
    </HeaderContainer>
  );
};

export default BackArrowHeader;

const HeaderContainer = styled.header`
  height: 5.6rem;
  display: grid;
  grid-template-columns: 4.8rem auto;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  background-color: white;
  width: 100%;
  z-index: 99;
  .icon-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    svg {
      width: 2.4rem;
      height: 2.4rem;
    }
  }
  .header-title {
    display: flex;
    align-items: center;
    p {
      font-size: 1.6rem;
      font-weight: 500;
      letter-spacing: -0.06rem;
      line-height: 2rem;
      transform: translateY(1px);
    }
  }
  .review-detail-btn-box {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    display: flex;
    .icon-box {
      display: flex;
      width: 4.8rem;
      align-items: center;
      justify-content: center;
      svg {
        width: 2.4rem;
        height: 2.4rem;
      }
    }
  }
  .inquiry-header-var {
  }
  &.inquiry-type {
    grid-template-columns: 4.8rem auto 4.8rem;
    .header-title {
      justify-content: center;
    }
  }
`;
