import { fetchLoadMeasureResultList } from "api/measure";
import { DoubleArrowIcon, RightArrowIconX16 } from "cssReset/icon";
import {
  MeasureParams,
  SimpleMeasureResult,
} from "interface/interface.measure";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container } from "style/measure";
import {
  formatDateTimeAddSec,
  formatDateTimeMTime,
  getTimeDifference,
} from "utils/util";

const MeasureHome = () => {
  const [data, setData] = useState<SimpleMeasureResult[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const authStateRaw = localStorage.getItem("authState");

    if (!authStateRaw) return; // ✅ 아예 없으면 로그인 안 된 상태
    const auth = JSON.parse(authStateRaw);

    if (!auth?.isAuthenticated) return; // ✅ 로그인 되어 있지 않으면 중단

    const loadMeasureResultList = async () => {
      try {
        const params: MeasureParams = {
          orderBy: "REGDT",
          orderByDir: "DESC",
          pageSize: 5,
        };
        const response = await fetchLoadMeasureResultList(params);
        if (response.result) setData(response.result);
      } catch (error) {
        console.error(error);
      }
    };

    loadMeasureResultList();
  }, []);

  const simpleMeasureClicked = () => {
    const authStateRaw = localStorage.getItem("authState");

    // 로그인 안된 경우 → 비로그인용 경로로 이동
    if (!authStateRaw) {
      navigate("/measure/unauthenticated/step");
      return;
    }

    try {
      const auth = JSON.parse(authStateRaw);
      if (!auth?.isAuthenticated) {
        navigate("/measure/unauthenticated/step");
        return;
      }
    } catch (e) {
      console.warn("authState 파싱 오류:", e);
      navigate("/measure/unauthenticated/step");
      return;
    }

    // 로그인 상태 → 정상 경로로 이동
    navigate("/measure/simple/step");
  };

  return (
    <Container>
      <div className="simple-measure-box" onClick={simpleMeasureClicked}>
        <div className="l">
          <span>층간소음 간이 측정</span>
          <p>
            우리집 소음을 스마트폰으로 <br />
            간단히 측정해보세요.
          </p>
        </div>
        <div className="r">
          <DoubleArrowIcon />
        </div>
      </div>
      <div className="deep-measure-box">
        <div className="l">
          <span>층간소음 전문 측정 (키트 구입 필요)</span>
          <p>
            층간소음 전용 측정 키트로 <br />
            충격 소음까지 꼼꼼하게 잡아내세요.
          </p>
        </div>
        <div className="r">
          <DoubleArrowIcon />
        </div>
      </div>
      <div className="recently-record-box">
        <div className="t">
          <span>최근 측정 기록</span>
          <button>
            전체 보기{" "}
            <span>
              <RightArrowIconX16 />
            </span>
          </button>
        </div>
        <div className="record">
          {data && data.length > 0 ? (
            data.map((item, index) => (
              <div key={index}>
                <div className="title">
                  <p>{formatDateTimeMTime(item.regDt)}</p>
                  <span>간이 측정</span>
                </div>
                <ul>
                  <li>
                    <div>측정 장소</div>
                    <div>{item.location}</div>
                  </li>
                  <li>
                    <div>측정 기간</div>
                    <div>{`${formatDateTimeAddSec(
                      item.startDt
                    )} ~ ${formatDateTimeAddSec(
                      item.endDt
                    )} (${getTimeDifference(item.startDt, item.endDt)})`}</div>
                  </li>
                  <li>
                    <div>측정 요약</div>
                    <div>{item.location}</div>
                    {/* 1분 등가소음도의 평균치 중 가장 높은거 기입 */}
                    {/* 5분 등가소음도 동일 */}
                  </li>
                </ul>
              </div>
            ))
          ) : (
            <div>측정 결과가 없습니다.</div>
          )}
        </div>
      </div>
    </Container>
  );
};

export default MeasureHome;
