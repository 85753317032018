import { fetchLoadAlramSetting, updateNotificationSettings } from "api/alram";
import { NotificationSettings } from "interface/interface.alram";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  AlramMenuItem,
  AlramMenuTitle,
  AlramSettingContainer,
  AlramSettingMenuWrap,
  AlramSettingTopBox,
  Spacer,
} from "style/alram";
import { AlramSettingMenuData } from "utils/datas";

const AlramSetting = () => {
  const { register, handleSubmit } = useForm();
  const [settingDt, setSettingDt] = useState<NotificationSettings>();
  const [checkBoxState, setCheckBoxState] = useState<Record<string, boolean>>(
    {}
  ); // 체크박스 상태 관리

  useEffect(() => {
    const loadNotificationSetting = async () => {
      try {
        const response = await fetchLoadAlramSetting();
        setSettingDt(response.result);
      } catch (error) {
        console.error(error);
      }
    };

    loadNotificationSetting();
  }, [checkBoxState]);

  useEffect(() => {
    if (settingDt && !Object.keys(checkBoxState).length) {
      // checkBoxState가 비어 있으면 한 번만 실행
      setCheckBoxState({
        abnormalNoiseNotify: settingDt.abnormalNoiseNotify === "Y",
        announcementNotify: settingDt.announcementNotify === "Y",
        commentNotify: settingDt.commentNotify === "Y",
        kitErrorNotify: settingDt.kitErrorNotify === "Y",
        likeNotify: settingDt.likeNotify === "Y",
        marketingNotify: settingDt.marketingNotify === "Y",
      });
    }
  }, [settingDt, checkBoxState]);

  const onSubmit = async (data: any) => {
    try {
      // data에서 boolean 값을 받아서 "Y" 또는 "N"으로 변환
      const setting = {
        abnormalNoiseNotify: data.abnormalNoiseNotify ? "Y" : "N",
        announcementNotify: data.announcementNotify ? "Y" : "N",
        commentNotify: data.commentNotify ? "Y" : "N",
        kitErrorNotify: data.kitErrorNotify ? "Y" : "N",
        likeNotify: data.likeNotify ? "Y" : "N",
        marketingNotify: data.marketingNotify ? "Y" : "N",
      };

      // 서버에 설정 업데이트 요청 보내기
      await updateNotificationSettings(setting);
    } catch (error) {
      console.error("알림 설정 업데이트 실패:", error);
    }
  };

  // 체크박스 변경 시 상태 업데이트
  const onChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    checkBoxName: string
  ) => {
    const { checked } = e.target;
    setCheckBoxState((prevState) => {
      const updatedState = {
        ...prevState,
        [checkBoxName]: checked, // 체크박스 상태 업데이트
      };
      // 체크박스 상태가 변경될 때마다 바로 저장 (자동 저장)
      onSubmit(updatedState);
      return updatedState;
    });
  };

  return (
    <AlramSettingContainer>
      <AlramSettingTopBox>
        <p>
          알림을 끄면 휴대폰 푸시 알림이 발송되지 않습니다. <br />
          (알림을 끄더라도 앱 내 알림함에서는 확인할 수 있습니다.)
        </p>
      </AlramSettingTopBox>
      <AlramSettingMenuWrap>
        <form onSubmit={handleSubmit(onSubmit)}>
          {AlramSettingMenuData.map((menu, index, arr) => {
            const shouldAddSpace =
              index > 0 && arr[index - 1].isTitle === false && menu.isTitle; // ✅ 앞 요소가 일반 메뉴이고, 현재 요소가 제목이면 공백 추가

            return (
              <React.Fragment key={index}>
                {shouldAddSpace && (
                  <li>
                    <Spacer />
                  </li>
                )}{" "}
                {/* ✅ 빈 li 추가 */}
                <li className="menu-item">
                  {menu.isTitle ? (
                    <AlramMenuTitle>{menu.title}</AlramMenuTitle>
                  ) : (
                    <AlramMenuItem>
                      <p>{menu.title}</p>
                      {menu.checkBoxName && (
                        <div className="input-box">
                          <input
                            type="checkbox"
                            {...register(menu.checkBoxName)}
                            checked={checkBoxState[menu.checkBoxName] || false} // 상태를 동기화
                            onChange={(e) =>
                              onChange(e, menu.checkBoxName || "")
                            } // 상태 업데이트
                            id={menu.checkBoxName}
                          />
                          <label
                            htmlFor={menu.checkBoxName}
                            className={
                              checkBoxState[menu.checkBoxName] ? "isActive" : ""
                            }
                          >
                            {checkBoxState[menu.checkBoxName] ? "on" : "off"}
                          </label>
                        </div>
                      )}
                    </AlramMenuItem>
                  )}
                </li>
              </React.Fragment>
            );
          })}
        </form>
      </AlramSettingMenuWrap>
    </AlramSettingContainer>
  );
};

export default AlramSetting;
