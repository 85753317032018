import { FileUploadImg } from "components/pieces/uploadImg";
import { useEffect, useState } from "react";
import {
  ErrorMessage,
  JoinDescription,
  LengthCount,
  ProfileWrap,
} from "style/join";

const JoinProfile = ({ register, setValue, watch, errors }: any) => {
  const [isButtonEnabled, setIsButtonEnabled] = useState(false); // 버튼 활성화 상태

  const username = watch("username") || "";

  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    if (inputValue.length <= 15) {
      setValue("username", inputValue); // 15자 이하일 경우만 업데이트
    }
  };

  useEffect(() => {
    // username 값이 비어 있지 않으면 버튼 활성화
    setIsButtonEnabled(username.trim().length > 0);
  }, [username]); // username이 변경될 때마다 실행

  return (
    <div>
      <JoinDescription>
        <p>프로필 정보를 설정해주세요.</p>
        <span>
          {
            "앱에서 사용할 프로필 사진과 이름을 설정해주세요. \n이름은 문자, 숫자, 특수문자로 최대 15자까지 입력할 수 있습니다."
          }
        </span>
      </JoinDescription>
      <ProfileWrap>
        <FileUploadImg
          name="profileImg"
          register={register}
          title=""
          height="100"
          setValue={setValue}
        />
        <div className="input-box">
          <div>
            <input
              type="text"
              placeholder="다른 사용자에게 보여질 이름을 입력하세요."
              {...register("username", {
                required: "이름을 입력해주세요.",
                validate: (value: string) =>
                  value.length <= 15 || "이름은 최대 15자까지 입력 가능합니다.",
              })}
              value={username} // 입력값 동기화
              onChange={handleUsernameChange} // 입력값 제한
            />
            <span></span>
          </div>
          {errors.username && (
            <ErrorMessage>{errors.username.message}</ErrorMessage>
          )}
          <LengthCount>{`${username.length} / 15`}</LengthCount>
        </div>
        <div className="submit-btn">
          <input
            type="submit"
            value="가입 완료하기"
            disabled={!isButtonEnabled} // 버튼 활성화 조건
          />
        </div>
        <div className="information-wrap">
          <p>가입 완료하기 버튼을 선택하면,</p>
          <p>
            RS101의 <span>이용약관</span> 및 <span>개인정보 취급방침</span> 에
            동의하는 것입니다.
          </p>
        </div>
        <div className="marketing-alram-wrap">
          <div className="checkbox-input">
            <input
              type="checkbox"
              id="alramAgree"
              {...register("alramAgree")}
            />
            <label htmlFor="alramAgree">
              RS101에서 보내는 마케팅 알림을 받지 않습니다.
            </label>
          </div>
          <p>
            {
              "RS101의 새로운 서비스 및 이벤트 안내, 고객혜택 등 다양한 정보를 받을 수 없습니다. 설정>알림설정 에서 언제든지 알림 수신을 거부할 수 있습니다."
            }
          </p>
        </div>
      </ProfileWrap>
    </div>
  );
};

export default JoinProfile;
