import { atom } from "recoil";

interface InquiryAtomProps {
  centerMode: boolean;
  btnMode: boolean;
}

interface InquiryHeaerBtnProps {
  watchTitle?: boolean;
  watchContents?: boolean;
}

interface ModalStateProps {
  isOpen: boolean; // 모달 열림 여부
  isVisible: boolean; // 모달 표시 여부
}

export const inquiryHeaderTypeState = atom<InquiryAtomProps>({
  key: "inquiryHeaderTypeState",
  default: {
    centerMode: false,
    btnMode: false,
  },
});

export const inquiryConfirmModalState = atom<ModalStateProps>({
  key: "inquiryConfirmModalState",
  default: {
    isOpen: false, // 모달 열림 여부
    isVisible: false, // 모달 표시 여부
  },
});

export const inquiryCancelModalState = atom<ModalStateProps>({
  key: "inquiryCancelModalState",
  default: {
    isOpen: false, // 모달 열림 여부
    isVisible: false, // 모달 표시 여부
  },
});

export const inquiryHeaderBtnState = atom<InquiryHeaerBtnProps>({
  key: "inquiryHeaderBtnState",
  default: {
    watchTitle: false,
    watchContents: false,
  },
});
