import { color } from "./../../node_modules/@types/d3-color/index.d";
import styled from "styled-components";
import { motion } from "framer-motion";

export const Container = styled.div`
  padding: 2.4rem 1.6rem;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  .simple-measure-box {
    display: flex;
    align-items: center;
    background-color: #225298;
    padding: 1.6rem;
    border-radius: 1rem;
    box-shadow: 1px 1px 4px 0 #00000040;
    gap: 2.4rem;
    cursor: pointer;
    .l {
      flex: 1;
      color: white;
      display: flex;
      flex-direction: column;
      gap: 1.1rem;
      span {
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 2rem;
      }
      p {
        font-size: 1.4rem;
        font-weight: 700;
        line-height: 2rem;
        letter-spacing: -0.06rem;
      }
    }
    .r {
      display: flex;
      svg {
        width: 2.4rem;
        height: 2.4rem;
      }
    }
  }
  .deep-measure-box {
    display: flex;
    align-items: center;
    background: linear-gradient(90deg, #6a23e3 0%, #9bcedf 100%);
    padding: 1.6rem;
    border-radius: 1rem;
    box-shadow: 1px 1px 4px 0 #00000040;
    gap: 2.4rem;
    .l {
      flex: 1;
      color: white;
      display: flex;
      flex-direction: column;
      gap: 1.1rem;
      span {
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 2rem;
      }
      p {
        font-size: 1.4rem;
        font-weight: 700;
        line-height: 2rem;
        letter-spacing: -0.06rem;
      }
    }
    .r {
      display: flex;
      svg {
        width: 2.4rem;
        height: 2.4rem;
      }
    }
  }
  .recently-record-box {
    .t {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1.2rem 0.8rem;
      > span {
        font-size: 1.6rem;
        font-weight: 700;
        line-height: 2rem;
        letter-spacing: -0.06rem;
      }
      button {
        padding: 0.4rem;
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 1.6rem;
        outline: none;
        border: none;
        background-color: transparent;
        display: flex;
        gap: 0.4rem;
        align-items: center;
        justify-content: center;
        span {
          display: flex;
        }
      }
    }
  }
`;

export const SimpleContainer = styled.div`
  height: 100vh;
`;

export const Step = styled(motion.div)`
  display: flex;
  flex-direction: column;
  height: calc(100% - 5.6rem);
  padding-bottom: 8rem;
  form {
    height: 100%;
  }
`;

export const StepWrap = styled.div`
  padding: 2.4rem 1.6rem;
  background-color: #fafafa;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
  overflow: hidden scroll;
  .box {
    padding: 0.8rem 1.6rem;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    .box-item-1 {
      font-size: 1.3rem;
      font-weight: 700;
      line-height: 2rem;
      letter-spacing: -0.06rem;
    }
    .box-item-2 {
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 2rem;
      display: flex;
      flex-direction: column;
      gap: 0.8rem;
      .img-box {
        width: 100%;
        img {
          max-width: 100%;
        }
      }
    }
  }
`;

export const FtBtnBox = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1.6rem 1.6rem 3.7rem;
  background-color: white;
  button {
    width: 100%;
    background-color: #225298;
    color: white;
    border: none;
    outline: none;
    border-radius: 0.5rem;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 2.4rem;
    padding: 1.6rem;
    &:disabled {
      cursor: default;
      background-color: #cecece;
    }
  }
`;

export const StepFormWrap = styled.div`
  padding: 2.4rem 1.6rem;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
  overflow: hidden scroll;
  position: relative;

  .input-box {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0 1.6rem;
    .title {
      font-weight: 700;
      font-size: 1.3rem;
      line-height: 1.8rem;
      letter-spacing: -6%;
    }
    .sub {
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 2rem;
      letter-spacing: 0%;
    }
    .input-form {
      position: relative;
      margin-bottom: 2rem;
      span {
        display: flex;
        position: absolute;
        bottom: 0;
        left: 50%;
        background-color: #1f75fe;
        width: 0;
        height: 2px;
        border-radius: 2px;
        transform: translateX(-50%);
        transition: all 0.5s;
      }
      input:focus ~ span {
        width: 100%;
      }
      input[type="text"] {
        width: 100%;
        border: 0;
        border-bottom: 1px solid #cecece;
        background-color: transparent;
        padding: 0.8rem 0.8rem 0.7rem;
        line-height: 2.4rem;
        font-size: 1.2rem;
        border-radius: 0;
        &:focus {
          outline: none;
        }
      }
      .count {
        position: absolute;
        right: 0;
        margin-top: 0.4rem;
        font-size: 1rem;
        color: #a1a1a1;
      }
    }
    .input-form-radio {
      min-height: 20rem;
      .wrapper {
        input[type="radio"] {
          display: none;
        }
        label {
          padding: 0.4rem 0.8rem;
          display: flex;
          align-items: center;
          gap: 0.8rem;
          font-weight: 400;
          font-size: 1.2rem;
          line-height: 2rem;
          letter-spacing: 0%;
          .icon-box {
            display: flex;
          }
        }
      }
    }
  }
  .bt-info {
    padding: 1.2rem 1.6rem;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    .title {
      font-weight: 700;
      font-size: 1.3rem;
      line-height: 1.8rem;
      letter-spacing: -0.06rem;
    }
    .sub {
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 2rem;
      letter-spacing: -0.06rem;
    }
  }
`;

export const SimpleMeasureRecordingWrap = styled.div``;

export const RecordingInfoWrap = styled.div`
  padding: 1.6rem;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  margin-bottom: 2.4rem;
  .item {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    .title {
      font-weight: 700;
      font-size: 1.2rem;
      line-height: 1.6rem;
      letter-spacing: -0.06rem;
    }
    .value {
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 2rem;
    }
    &.double {
      display: flex;
      justify-content: space-between;
      > div {
        display: flex;
        align-items: center;
        gap: 0.8rem;
      }
    }
  }
`;

export const MeasureHeader = styled.header`
  height: 5.6rem;
  display: grid;
  grid-template-columns: 5.6rem auto 5.6rem;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  background-color: white;
  width: 100%;
  z-index: 99;
  .header-title {
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      font-size: 1.6rem;
      font-weight: 500;
      letter-spacing: -0.06rem;
      line-height: 2rem;
      transform: translateY(1px);
    }
  }
  .btn-box {
    padding: 1.2rem 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      width: 100%;
      padding: 0.6rem 0.8rem;
      font-size: 1.4rem;
      line-height: 2rem;
      letter-spacing: -0.06rem;
      border: none;
      outline: none;
      border-radius: 0.5rem;
      background-color: #225298;
      color: white;
      &:disabled {
        background-color: #cecece;
      }
    }
  }
  .closed-btn-box {
    padding: 1.6rem;
    button {
      display: flex;
      border: none;
      outline: none;
      background-color: transparent;
      width: 2.4rem;
      height: 2.4rem;
      padding: 0;
      .icon-box {
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
`;

export const MeasureChartWrapper = styled.div`
  width: 100%;
  .title {
    padding: 0 1.6rem;
    display: flex;
    position: relative;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    span {
      font-weight: 700;
      font-size: 1.4rem;
      line-height: 1.8rem;
      letter-spacing: -0.06rem;
    }
    .legend-tooltip {
      display: flex;
    }
  }
`;

export const LegendWrapper = styled(motion.div)`
  padding: 0.8rem;
  background-color: white;
  box-shadow: 0 4px 4px 0 #00000040;
  border: 1px solid #ececec;
  border-radius: 1rem;
  position: absolute;
  z-index: 11;
  right: 3.5rem;
  width: 21.6rem;

  ul {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    li {
      display: flex;
      align-items: center;
      gap: 0.4rem;
      width: 24rem;
      span {
        display: flex;
        width: 1.6rem;
        height: 1.6rem;
        &.skyblue {
          background-color: #9cd0de;
        }
        &.pink {
          background-color: #ffa29d;
        }
        &.red {
          background-color: #ff2c20;
        }
        &.line {
          height: 1px;
          background-color: #242629;
        }
        &.night-line {
          height: 1px;
          background-color: yellow;
        }
      }
    }
  }
`;

export const NoiseTooltipWrap = styled(motion.div)`
  padding: 0.8rem;
  background-color: white;
  box-shadow: 0 4px 4px 0 #00000040;
  border: 1px solid #ececec;
  border-radius: 1rem;
  position: absolute;
  z-index: 11;
  right: 1.5rem;
  width: 27.3rem;
  ul {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    li {
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.4rem;
      span {
        font-weight: 700;
      }
    }
  }
`;

export const MeasureResultContainer = styled.div``;

export const ResultWrapper = styled.div``;

export const ResultInfoWrap = styled.div`
  padding: 1.6rem;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  .item {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    .title {
      font-weight: 700;
      font-size: 1.2rem;
      line-height: 1.6rem;
      letter-spacing: -0.06rem;
      min-width: 5rem;
    }
    .value {
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 1.6rem;
      .elapsed-time {
        display: inline-flex;
      }
    }
    &.another {
      .measure-depth {
        display: flex;
        span {
          border: 1px solid #225298;
          color: #225298;
          padding: 0.3rem 0.8rem;
          border-radius: 100px;
          font-size: 1.2rem;
          line-height: 1.4rem;
          font-weight: 700;
        }
      }
      .value {
        font-weight: 700;
      }
    }
    &.align-start {
      align-items: flex-start;
    }
  }
`;

export const InstantaneousNoiseLevelWrap = styled.div`
  padding: 2.4rem 1.6rem;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  > .title {
    font-weight: 700;
    font-size: 1.4rem;
    line-height: 1.8rem;
    letter-spacing: -0.06rem;
  }
  .wrapper {
    padding: 1.6rem;
    background-color: #ececec;
    border-radius: 1rem;
    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 0.8rem;
      .title {
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 1.8rem;
      }
      .value {
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 1.8rem;
        span {
          font-weight: 700;
        }
      }
    }
    .info {
      font-weight: 400;
      font-size: 1.1rem;
      line-height: 1.4rem;
      margin-top: 1.2rem;
    }
  }
`;

export const MeasureRecordResultSummaryContainer = styled.div`
  padding: 2.4rem 1.6rem;
  .summary-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.6rem;
  }
  .info {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4rem;
    text-align: right;
    margin-bottom: 0.4rem;
  }
  .noise-detault-table-wrap {
    margin-top: 1.6rem;
    padding: 0 1.6rem;
    display: flex;
    justify-content: flex-end;
    .inner {
      display: flex;
      align-items: center;
      gap: 0.4rem;
      border-bottom: 1px solid #242629;
      padding: 0.4rem;
      position: relative;
      .title {
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.4rem;
      }
      .icon-box {
        width: 1.6rem;
        height: 1.6rem;
        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
`;

export const ResultSummaryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
`;

export const TopThreeValueWrapper = styled.div`
  padding: 1.6rem;
  border: 0.5px solid #cecece;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  h3 {
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 1.6rem;
    letter-spacing: -0.06rem;
    span {
      margin-left: 1.6rem;
      display: inline-flex;
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.4rem;
      border-radius: 100px;

      color: white;
      padding: 0.2rem 0.4rem;
      align-items: center;
      justify-content: center;
      &.red {
        background-color: #f1414a;
      }
      &.blue {
        background-color: #4683fb;
      }
    }
  }
  ul {
    display: flex;
    flex-direction: column;
    padding: 0 1.6rem;
    li {
      display: flex;
      gap: 0.8rem;
      padding: 0.4rem 0;
      span {
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 1.8rem;
      }
    }
  }
`;

export const BackgoundNoiseWrap = styled.div`
  padding: 1.6rem;
  border: 0.5px solid #cecece;
  border-radius: 0.5rem;
  display: flex;
  gap: 1.6rem;
  h3 {
    width: 8rem;
  }
  ul {
    display: flex;
    flex-direction: column;
    li {
      display: flex;
      gap: 0.8rem;
      span {
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 1.8rem;
      }
    }
  }
`;

// 💅 스타일 컴포넌트
export const ResultChartContainer = styled.div`
  width: 100%;
  overflow: hidden;
`;

export const GraphWrapper = styled.div`
  display: flex;
  position: relative;
  height: 288px;
  padding: 0;
  width: calc(100% - 3.6rem);
  margin: 0 auto;
`;

export const YAxisFixed = styled.div`
  position: sticky;
  left: 0;
  z-index: 10;
  background: white;
`;

export const ChartContainer = styled.div`
  flex: 1;
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  align-items: center;
`;

export const ChartScrollArea = styled.div`
  min-width: 375px;
  display: inline-block;
`;

export const ResultAudioContainer = styled.div`
  margin-top: 1.2rem;
  padding: 0 1.05rem;
  .audio-container {
    display: flex;
    align-items: center;
    padding: 1.4rem 1.6rem;
    background-color: #ececec;
    gap: 0.8rem;
    .play-btn {
      border: none;
      outline: none;
      background-color: transparent;
      width: 2.4rem;
      height: 2.4rem;
      padding: 0;
    }
    span.date {
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 2.2rem;
    }
    .slider-container {
      flex: 1;
      position: relative;
      height: 0.4rem;
      background-color: #a1a1a1;
      cursor: pointer;
      .slider-progress {
        position: absolute;
        height: 100%;
        background-color: #225298;
      }
      .slider-handle {
        position: absolute;
        width: 1.2rem;
        height: 1.2rem;
        border-radius: 50%;
        background-color: #225298;
        transform: translate(-50%, 0);
        top: -0.4rem;
      }
    }
  }
`;

export const ResultNoiseLogContainer = styled.div`
  margin-top: 1.2rem;
  padding: 1.2rem 1.6rem;
  .noise-log-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    .info-wrap {
      display: flex;
      flex-direction: column;
      gap: 1.2rem;
      h3 {
        font-weight: 700;
        font-size: 1.2rem;
        line-height: 1.6rem;
        letter-spacing: -0.06rem;
      }
      span {
        font-weight: 600;
        font-size: 1.2rem;
        line-height: 1.6rem;
        border-radius: 100px;
        color: #575757;
      }
    }
    .log-box {
      height: 26rem;
      overflow: hidden scroll;
      border: 1px solid #a1a1a1;
      border-radius: 0.5rem;
      padding: 0.8rem 1.6rem;
      display: flex;
      flex-direction: column;
      gap: 1.6rem;
    }
  }
`;

export const LogValueItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  h3 {
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 1.6rem;
    letter-spacing: -0.06rem;
    padding: 0.4rem 0;
  }
  ul {
    li {
      display: flex;
      gap: 0.4rem;
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 1.8rem;

      span.time {
        width: 12rem;
        color: #0075ff;
        letter-spacing: -0.06rem;
        white-space: nowrap;
      }
      span.value {
        padding: 0 0.4rem;
      }
      &.active {
        font-weight: 700;
      }
    }
  }
`;

export const NoiseDefaultTableWrapper = styled.div`
  background-color: white;
  padding: 0 1.6rem;
  table {
    width: 100%;
    border-collapse: collapse;
    font-weight: 700;
    font-size: 0.8rem;
    line-height: 1.4rem;
    th {
      background-color: #9cd0de;
      vertical-align: middle;
      padding: 0.4rem 0.6rem;
    }
    td {
      padding: 0.8rem 0.4rem;
    }
    th,
    td {
      text-align: center;
    }
  }
  table,
  th,
  td {
    border: 1px solid #242629;
  }
  .info {
    div:first-child {
      font-weight: 500;
      font-size: 0.7rem;
      line-height: 1.4rem;
      letter-spacing: -0.07rem;
      span {
        text-decoration: underline;
      }
    }
    div:last-child {
      font-weight: 500;
      font-size: 0.9rem;
      line-height: 1.4rem;
      letter-spacing: 0%;
    }
  }
`;
