import { NoiseData } from "interface/interface.measure";

// 주간, 야간 구분 함수
export const classifyDayNightData = (
  data: { time: string; value: number }[]
) => {
  const dayData: NoiseData[] = [];
  const nightData: NoiseData[] = [];

  data.forEach((item) => {
    const hour = new Date(item.time).getHours();

    if (hour >= 6 && hour < 22) {
      dayData.push(item);
    } else {
      nightData.push(item);
    }
  });

  return { dayData, nightData };
};

// 토탈 데이터에서 주, 야간으로 구분 후 57, 52넘는 값만 추출
export const classifyAndCountExceedingValues = (
  data: { time: string; value: number }[]
) => {
  let dayCount = 0;
  let nightCount = 0;

  data.forEach((item) => {
    const hour = new Date(item.time).getHours();

    if (hour >= 6 && hour < 22) {
      // 주간: value가 57 이상이면 카운트 증가
      if (item.value >= 57) {
        dayCount++;
      }
    } else {
      // 야간: value가 52 이상이면 카운트 증가
      if (item.value >= 52) {
        nightCount++;
      }
    }
  });

  return {
    dayExceedCount: dayCount > 0 ? dayCount : 0,
    nightExceedCount: nightCount > 0 ? nightCount : 0,
  };
};

export const classifyAndFilterExceedingValues = (
  data: { time: string; value: number }[]
) => {
  const dayExceeded: { time: string; value: number }[] = [];
  const nightExceeded: { time: string; value: number }[] = [];

  data.forEach((item) => {
    const hour = new Date(item.time).getHours();

    if (hour >= 6 && hour < 22) {
      if (item.value >= 57) {
        dayExceeded.push(item);
      }
    } else {
      if (item.value >= 52) {
        nightExceeded.push(item);
      }
    }
  });

  return {
    dayExceeded,
    nightExceeded,
  };
};

// 상위 3가지 value 구하기
export const getTopThreeValues = (data: (NoiseData | null)[]) => {
  return data
    .filter((item): item is NoiseData => item !== null) // 🔥 null 값 필터링
    .sort((a, b) => b.value - a.value) // value 기준 내림차순 정렬
    .slice(0, 3) // 상위 3개 데이터 가져오기
    .map((item) => ({
      ...item,
      value: parseFloat(item.value.toFixed(2)), // ✅ 소수점 2자리까지 변환
    }));
};

// 1분 등가소음도(Leq) 계산 함수
export const calculateLeq = (
  noiseData: NoiseData[]
): { time: string; value: number }[] => {
  const groupedByMinute: { [key: string]: number[] } = {};

  noiseData.forEach(({ time, value }) => {
    const minuteKey = time.substring(0, 16); // "YYYY-MM-DD HH:mm" 형식으로 그룹화
    if (!groupedByMinute[minuteKey]) {
      groupedByMinute[minuteKey] = [];
    }
    groupedByMinute[minuteKey].push(Math.pow(10, value / 10)); // dB → 에너지 변환
  });

  return Object.entries(groupedByMinute).map(([minute, values]) => {
    const avgEnergy = values.reduce((sum, val) => sum + val, 0) / values.length;
    return {
      time: minute + ":00", // 초 단위 00 추가
      value: parseFloat((10 * Math.log10(avgEnergy)).toFixed(2)), // 다시 dB로 변환
    };
  });
};

// 최고 1분 등가소음도(Leq) 추출 단일 객체로 반환
// export const getMaxLeq = (
//   leqData: { time: string; value: number }[]
// ): { time: string; value: number } | null => {
//   if (leqData.length === 0) return null;
//   return leqData.reduce((max, data) => (data.value > max.value ? data : max));
// };

// 최고 1분 등가소음도(Leq) 추출 (배열로 반환)
export const getMaxLeq = (
  leqData: { time: string; value: number }[]
): { time: string; value: number }[] => {
  if (leqData.length === 0) return [];
  const max = leqData.reduce((max, data) =>
    data.value > max.value ? data : max
  );
  return [max]; // 🔥 단일 객체를 배열로 감싸서 반환
};

// 5분 등가소음도(Leq) 계산 함수
export const calculateFiveMinuteLeq = (
  leqData: { time: string; value: number }[]
): { time: string; value: number }[] => {
  const groupedByFiveMinutes: { [key: string]: number[] } = {};

  leqData.forEach(({ time, value }) => {
    const date = new Date(time);
    const hours = String(date.getHours()).padStart(2, "0");
    const roundedMinutes = Math.floor(date.getMinutes() / 5) * 5;
    const minutes = String(roundedMinutes).padStart(2, "0");
    const timeKey = `${date.getFullYear()}-${String(
      date.getMonth() + 1
    ).padStart(2, "0")}-${String(date.getDate()).padStart(
      2,
      "0"
    )} ${hours}:${minutes}:00`;

    if (!groupedByFiveMinutes[timeKey]) {
      groupedByFiveMinutes[timeKey] = [];
    }
    groupedByFiveMinutes[timeKey].push(Math.pow(10, value / 10)); // dB → 에너지 변환
  });

  return Object.entries(groupedByFiveMinutes).map(([minute, values]) => {
    const avgEnergy = values.reduce((sum, val) => sum + val, 0) / values.length;
    return {
      time: minute,
      value: parseFloat((10 * Math.log10(avgEnergy)).toFixed(2)), // dB 변환
    };
  });
};

// 최고 5분 등가소음도(Leq) 추출
export const getMaxFiveMinuteLeq = (
  fiveMinuteLeqData: { time: string; value: number }[]
): { time: string; value: number } | null => {
  if (fiveMinuteLeqData.length === 0) return null;
  return fiveMinuteLeqData.reduce((max, data) =>
    data.value > max.value ? data : max
  );
};

// 개별적으로 주간 또는 야간 데이터에서 배경 소음도 찾기
export const getMinBackgroundNoise = (data: NoiseData[]) => {
  return data.length > 0
    ? data.reduce((min, d) => (d.value < min.value ? d : min))
    : null;
};

// 차트 색상 지정 로직 (시간까지 고려)
export const colorSelector = (value: number, label: string) => {
  const hour = new Date(label).getHours();

  // 주간: 06 ~ 21시 (22시 미만), 야간: 22 ~ 05시
  const isDaytime = hour >= 6 && hour < 22;
  const threshold = isDaytime ? 57 : 52;

  if (value >= 70) {
    return "#FF2C20"; // 빨강 (70 이상)
  } else if (value >= threshold) {
    return "#FFA29D"; // 연한 빨강 (기준 이상)
  } else {
    return "#9CD0DE"; // 파랑 (기준 미만)
  }
};
