import { inquiryHeaderTypeState } from "atoms/inquiry.state";
import { RadioIconCheckedX16, RadioIconX16 } from "cssReset/icon";
import { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { InquiryTypeBox, InquiryTypeContainer } from "style/inquiry";
import { InquiryTypeDt } from "utils/datas";

interface InquiryTypeProps {
  handleNext: () => void;
  register: any;
  watch: any;
}

const InquiryType = ({ handleNext, register, watch }: InquiryTypeProps) => {
  const setInquiryHeaderType = useSetRecoilState(inquiryHeaderTypeState);

  const selectedValue = watch("inquiryType"); // 🔥 선택된 라디오 값 확인

  useEffect(() => {
    setInquiryHeaderType({ centerMode: true, btnMode: false });
  }, [setInquiryHeaderType]);
  return (
    <InquiryTypeContainer>
      <div className="title">어떤 사항을 문의하고 싶으신가요?</div>
      <InquiryTypeBox>
        {InquiryTypeDt.map((item, index) => (
          <div key={item.id} className="input-box">
            <label htmlFor={item.id}>
              <span className="icon-box">
                {selectedValue === item.id ? (
                  <RadioIconCheckedX16 />
                ) : (
                  <RadioIconX16 />
                )}
              </span>
              {item.name}
            </label>
            <input
              type="radio"
              {...register("inquiryType")}
              value={item.id}
              id={item.id}
            />
          </div>
        ))}
      </InquiryTypeBox>
      <div className="btn-box">
        <button
          onClick={handleNext}
          disabled={!selectedValue} // 🔥 라디오 선택이 안 되면 버튼 비활성화
        >
          문의 내용 작성하기
        </button>
      </div>
    </InquiryTypeContainer>
  );
};

export default InquiryType;
