import axiosInstance from "../plugin/axios";

// 내가 작성한 후기
export const fetchLoadMyReview = async (params: any) => {
  try {
    const response = await axiosInstance.get(`/review/my-review`, {
      params: params,
    });
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

// 내가 단 댓글
export const fetchLoadMyReviewComment = async (params: any) => {
  try {
    const response = await axiosInstance.get(`/review/commented-review`, {
      params: params,
    });
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

// 내가 좋아요한 후기
export const fetchLoadLikeMyReview = async (params: any) => {
  try {
    const response = await axiosInstance.get(`/review/liked-review`, {
      params: params,
    });
    return response.data;
  } catch (error) {
    throw new Error();
  }
};
