import styled from "styled-components";
import { useEffect, useState } from "react";
import {
  fetchLoadBannerList,
  fetchLoadBestReview,
  fetchLoadColumn,
  fetchLoadPopup,
} from "api/main.home";
import {
  BannerDto,
  ContentDTO,
  PopupData,
} from "interface/interface.main.home";
import BannerList from "components/main.home/banner";
import { ReviewItem } from "interface/interface.review";
import BestReviewList from "components/main.home/best.review";
import Rs101Column from "components/main.home/rs101.column";
import { useRecoilState, useSetRecoilState } from "recoil";
import { isHomePathState, mainBannerModalState } from "atoms/layout.state";
import ModalUpLayout from "components/modal/modal.up.layout";
import PopupBanner from "components/main.home/modal/popup.banner";
import { jwtDecode } from "jwt-decode"; // ✅ jwt-decode import
import { useLocation, useNavigate } from "react-router-dom";

const HomeView = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [bannerDt, setBannerDt] = useState<BannerDto[]>([]);
  const [bestReviewDt, setBestReviewDt] = useState<ReviewItem[]>([]);
  const [columnDt, setColumnDt] = useState<ContentDTO[]>([]);
  const [popupDt, setPopupDt] = useState<PopupData[]>([]);
  const setIsHomePath = useSetRecoilState(isHomePathState);
  const [mainBannerModal, setMainBannerModal] =
    useRecoilState(mainBannerModalState);

  useEffect(() => {
    const loadData = async () => {
      try {
        const [
          bannerResponse,
          bestReviewResponse,
          columnReviewResponse,
          popupResponse,
        ] = await Promise.all([
          fetchLoadBannerList(), // 배너 리스트 데이터 요청
          fetchLoadBestReview(), // 베스트 리뷰 데이터 요청
          fetchLoadColumn(), // RS 컬럼
          fetchLoadPopup(), // 푸터 팝업
        ]);

        setBannerDt(bannerResponse.result);
        setBestReviewDt(bestReviewResponse.result);
        setColumnDt(columnReviewResponse.result);
        setPopupDt(popupResponse.result);
        //localStorage.setItem("userId", userProfileResponse.result.userId); // user id 저장
      } catch (error) {
        console.error(error);
      }
    };

    loadData();
  }, []);

  useEffect(() => {
    setIsHomePath("home");
  }, []);

  useEffect(() => {
    // ✅ localStorage에서 저장된 날짜 가져오기
    const lastClosedDate = localStorage.getItem("hidePopupUntil");

    // ✅ 저장된 날짜가 있고, 오늘 이전이면 모달을 보이지 않게 설정
    if (lastClosedDate && new Date(lastClosedDate) > new Date()) {
      setMainBannerModal({ isOpen: false, isVisible: false });
    } else {
      setMainBannerModal({ isOpen: true, isVisible: true });
    }
  }, []);

  const mainBannerModalClosed = () => {
    setMainBannerModal({ isOpen: false, isVisible: false });
  };

  // ✅ "하루 동안 보지 않기" 버튼 클릭 시
  const hideForOneDay = () => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1); // 🚀 현재 날짜 +1일
    localStorage.setItem("hidePopupUntil", tomorrow.toISOString()); // 🚀 ISO 날짜로 저장
    mainBannerModalClosed(); // 🚀 모달 닫기
  };

  // ✅ JWT 디코딩 함수
  const decodeJwt = () => {
    const token = localStorage.getItem("accessToken"); // ✅ JWT 가져오기
    if (token) {
      try {
        const decoded = jwtDecode(token); // ✅ JWT 디코딩
        return decoded;
      } catch (error) {
        console.error("🚨 JWT 디코딩 오류:", error);
      }
    }
    return null;
  };

  useEffect(() => {
    const userInfo = decodeJwt(); // ✅ JWT 디코딩 실행
    if (userInfo) {
      localStorage.setItem("userId", userInfo.sub ?? ""); // user id 저장
    }
  }, []);

  // 안드로이드 로그인
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const accessToken = params.get("accessToken");
    const refreshToken = params.get("refreshToken");

    if (accessToken && refreshToken) {
      // ✅ 토큰 저장
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("refreshToken", refreshToken);
      // ✅ 인증 상태 업데이트
      localStorage.setItem(
        "authState",
        JSON.stringify({ isAuthenticated: true })
      );

      // ✅ URL 정리 (토큰 제거)
      navigate("/home", { replace: true });
    }
  }, []);

  return (
    <Container>
      <BannerWrap>
        <BannerList listData={bannerDt} />
      </BannerWrap>
      <BestReviewWrap>
        <BestReviewList listData={bestReviewDt} />
      </BestReviewWrap>
      <RsColumnWrap>
        <Rs101Column listData={columnDt} />
      </RsColumnWrap>

      {mainBannerModal.isOpen && popupDt.length > 0 && (
        <ModalUpLayout
          closedHandler={mainBannerModalClosed}
          isVisible={mainBannerModal.isVisible}
          noneBtn
          nonPadding
        >
          <PopupBanner
            data={popupDt}
            hideForOneDay={hideForOneDay}
            closedHandler={mainBannerModalClosed}
          />
        </ModalUpLayout>
      )}
    </Container>
  );
};

export default HomeView;

const Container = styled.div`
  padding: 2.4rem 0;
`;

const BannerWrap = styled.div``;

const BestReviewWrap = styled.div`
  padding: 0 1.6rem;
  margin-top: 2.4rem;
`;

const RsColumnWrap = styled.div`
  padding: 0 1.6rem;
  margin-top: 2.4rem;
`;
