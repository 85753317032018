import { useState } from "react";
import {
  InquiryContainer,
  SearchBox,
  TabWrapper,
  TapItem,
  TapMenuBox,
  TapMenuItem,
  TapMenuWrapper,
  UnderLine,
} from "style/admin/inquiry";
import { AnimatePresence, motion } from "framer-motion";
import InquiryPenddingList from "components/admin/inquiry/inquiry.pendding.list";
import { AdminSearchIcon } from "cssReset/icon";
import InquiryList from "components/admin/inquiry/inquiry.list";

const AdminInquiryHome = () => {
  const [tap, setTap] = useState(0);
  const [searchInput, setSearchInput] = useState(""); // ✅ 입력값 상태
  const [searchTerm, setSearchTerm] = useState(""); // ✅ API 요청을 위한 상태
  const [dataLengths, setDataLengths] = useState<{ [key: number]: boolean }>({
    0: false,
    1: false,
    2: false,
    3: false,
  }); // ✅ 각 탭별로 dataLength 저장

  // ✅ 탭 변경 핸들러
  const menuHandler = (idx: number) => {
    setTap(idx);
    setSearchInput("");
    setSearchTerm("");
  };

  // ✅ 검색 실행 (버튼 클릭 또는 Enter 입력 시 실행)
  const handleSearch = () => {
    //if (!searchInput.trim()) return;
    setSearchTerm(searchInput); // ✅ API를 호출하는 상태 변경
  };

  // ✅ Enter 키 입력 시 검색 실행
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  // ✅ 데이터 길이를 업데이트하는 핸들러
  const handleDataLengthUpdate = (tabIndex: number, length: boolean) => {
    setDataLengths((prev) => ({ ...prev, [tabIndex]: length }));
  };

  return (
    <InquiryContainer>
      <TapMenuWrapper>
        <TapMenuBox>
          {["답변 대기중 문의", "전체 문의"].map((menu, index) => (
            <TapMenuItem
              key={index}
              onClick={() => menuHandler(index)}
              className={tap === index ? "isActive" : ""}
              layout
              transition={{ type: "spring", stiffness: 300, damping: 20 }}
            >
              <motion.span transition={{ duration: 0.3 }} className="manu-name">
                {menu}
                {/* ✅ 탭이 0번일 때만 dot을 표시하고, 데이터가 존재할 경우 유지 */}
                {index === 0 && dataLengths[0] && <span className="dot"></span>}
              </motion.span>

              {tap === index && (
                <UnderLine
                  layoutId="underline"
                  className="underline"
                  transition={{ type: "spring", stiffness: 300, damping: 20 }}
                />
              )}
            </TapMenuItem>
          ))}
        </TapMenuBox>

        {/* ✅ 검색창 */}
        <SearchBox>
          <div className="icon-box" onClick={handleSearch}>
            {" "}
            {/* 🔍 버튼 클릭 시 실행 */}
            <AdminSearchIcon />
          </div>

          <input
            type="text"
            placeholder="제목, 내용, 작성자 검색"
            value={searchInput} // ✅ 입력값 반영
            onChange={(e) => setSearchInput(e.target.value)} // ✅ 상태 업데이트 (검색 실행 X)
            onKeyDown={handleKeyDown} // ✅ Enter 입력 시 검색 실행
          />
        </SearchBox>
      </TapMenuWrapper>

      <TabWrapper>
        <AnimatePresence mode="wait" initial={false}>
          {tap === 0 && (
            <TapItem
              key={0}
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -20 }}
              transition={{ duration: 0.3 }}
            >
              <InquiryPenddingList
                searchTerm={searchTerm}
                setDataLength={(length: any) =>
                  handleDataLengthUpdate(0, length)
                }
              />
            </TapItem>
          )}
          {tap === 1 && (
            <TapItem
              key={1}
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -20 }}
              transition={{ duration: 0.3 }}
            >
              <InquiryList
                searchTerm={searchTerm}
                setDataLength={(length: any) =>
                  handleDataLengthUpdate(1, length)
                }
              />
            </TapItem>
          )}
        </AnimatePresence>
      </TabWrapper>
    </InquiryContainer>
  );
};

export default AdminInquiryHome;
