import { useState, useEffect } from "react";
import { HeartIcon } from "cssReset/icon";
import { reviewLikeDownHandler, reviewLikeUpHandler } from "api/review";

interface ReviewLikeProps {
  reviewId: string | undefined;
  reviewLikeCount: number | undefined;
  likeYn: string | undefined;
}

const ReviewLike = (props: ReviewLikeProps) => {
  // 초기 좋아요 숫자를 props에서 가져오거나 기본값 0 설정
  const [likeCount, setLikeCount] = useState<number>(
    props.reviewLikeCount ?? 0
  );

  const [isAnimating, setIsAnimating] = useState(false); // 애니메이션 상태
  const [isLiked, setIsLiked] = useState(props.likeYn === "Y"); // likeYn에 따라 초기 좋아요 상태 설정

  // props.reviewLikeCount 또는 likeYn가 변경되면 상태 동기화
  useEffect(() => {
    if (props.reviewLikeCount !== undefined) {
      setLikeCount(props.reviewLikeCount);
    }
    if (props.likeYn !== undefined) {
      setIsLiked(props.likeYn === "Y");
    }
  }, [props.reviewLikeCount, props.likeYn]);

  const handleLikeClick = () => {
    setLikeCount((prev) => {
      const newCount = (prev ?? 0) + (isLiked ? -1 : 1); // prev가 undefined일 경우 0으로 처리
      return newCount;
    });

    if (isLiked) {
      // 좋아요 취소 처리
      if (props.reviewId) {
        reviewLikeDownHandler(Number(props.reviewId));
      }
    } else {
      // 좋아요 추가 처리
      if (props.reviewId) {
        reviewLikeUpHandler(Number(props.reviewId));
      }
    }

    setIsLiked((prev) => !prev); // 좋아요 상태 토글
    setIsAnimating(true); // 애니메이션 활성화
    setTimeout(() => setIsAnimating(false), 300); // 300ms 후 애니메이션 비활성화
  };
  return (
    <div
      className={`like-box ${isLiked ? "liked" : ""}`}
      onClick={handleLikeClick}
    >
      <span className={`icon-box ${isAnimating ? "animate-icon" : ""}`}>
        <HeartIcon />
      </span>
      <span className={`like-count ${isAnimating ? "animate-like" : ""}`}>
        {likeCount === 0 ? "좋아요" : likeCount}
      </span>
    </div>
  );
};

export default ReviewLike;
