import {
  ColumnParams,
  ColumnRegistParams,
  ColumnUpdateParams,
} from "interface/admin/interface.contents";
import axiosInstance from "plugin/axios";

export const fetchLoadAdminColumnList = async (params: ColumnParams) => {
  try {
    const response = await axiosInstance.get(`/admin/contents`, { params });
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

export const fetchLoadAdminColumnDetail = async (id: number) => {
  try {
    const response = await axiosInstance.get(`/admin/contents/${id}`);
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

export const fetchRestoreColumn = async (id: number) => {
  try {
    const response = await axiosInstance.put(`/admin/contents/restore/${id}`);
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

export const fetchDeleteColumn = async (id: number) => {
  try {
    const response = await axiosInstance.delete(`/admin/contents/${id}`);
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

export const fetchUpdateColumn = async (
  id: number,
  params: ColumnUpdateParams
) => {
  try {
    const response = await axiosInstance.put(`/admin/contents/${id}`, params);
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

export const fetchColumnRegist = async (data: ColumnRegistParams) => {
  try {
    const formData = new FormData();

    // ✅ 공지사항 데이터 추가 (객체는 JSON 문자열로 변환)
    formData.append(
      "data",
      JSON.stringify({
        title: data.title,
        contents: data.contents,
      })
    );

    // ✅ 파일이 있는 경우 추가
    if (data.files && data.files.length > 0) {
      data.files.forEach((file) => {
        formData.append("files", file);
      });
    }

    const response = await axiosInstance.post("/admin/contents", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    throw new Error();
  }
};
