import styled from "styled-components";
import { motion } from "framer-motion";

export const Container = styled.div`
  padding: 3rem;
  height: 100%;
`;

export const ListBox = styled.div`
  height: 100%;
`;

export const TapMenuBox = styled.div`
  display: flex;
  align-items: center;
  gap: 1.6rem;
  flex: 1;
`;

export const TapMenuItem = styled(motion.div)`
  width: 12rem;
  padding: 1.8rem 1.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 2.2rem;
  letter-spacing: -0.06rem;
  color: #a1a1a1;
  position: relative;
  cursor: pointer;
  &.isActive {
    color: black;
  }
  .manu-name {
    display: flex;
    gap: 0.4rem;
    span.dot {
      padding-top: 0.4rem;
      width: 0.6rem;
      height: 0.6rem;
      background-color: #ed2115;
      display: flex;
      border-radius: 0.6rem;
    }
  }
`;

export const DetailContainer = styled.div`
  padding: 3rem;
  height: 100%;
  .info-box {
    padding: 1.6rem;
    background-color: #ececec;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .tbox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      p {
        font-size: 2rem;
        font-weight: 700;
        line-height: 2.4rem;
        letter-spacing: -0.06rem;
      }
      span {
        margin-right: 0.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 8rem;
        padding: 0.8rem;
        font-size: 1.6rem;
        font-weight: 700;
        letter-spacing: -0.06rem;
        line-height: 2rem;
        color: white;
        border-radius: 100px;
        &.bluebox {
          background-color: #225298;
        }
        &.redbox {
          background-color: #ed2115;
        }
      }
    }
    .bbox {
      display: flex;
      align-items: center;
      gap: 2.4rem;
      .profile {
        display: flex;
        align-items: center;
        gap: 0.4rem;
        min-width: 16rem;
        .img-box {
          width: 2rem;
          height: 2rem;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
          }
        }
        span {
          font-size: 1.6rem;
          font-weight: 700;
          line-height: 2.4rem;
          max-width: 11rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .date,
      .view,
      .reple {
        display: flex;
        gap: 0.4rem;
      }
      .date span,
      .view span,
      .reple span {
        font-weight: 700;
        font-size: 1.6rem;
        line-height: 2rem;
        letter-spacing: -0.06rem;
        color: #575757;
      }
      .report {
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 2rem;
        letter-spacing: -0.06rem;
        text-decoration: underline;
        cursor: pointer;
        &.report-txt {
          color: #ed2115;
          font-weight: 500;
        }
      }
    }
  }
  .detail-wrapper {
    max-height: 67rem;
    overflow: hidden scroll;
    .contents-box {
      padding: 1.6rem 2.4rem;
      display: flex;
      flex-direction: column;
      gap: 1.6rem;
      .content-txt {
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 2.4rem;
      }
    }
  }
`;

export const SliderWrapper = styled.div`
  position: relative;
  .inner {
    padding: 0 4.8rem;
    width: 100%;
    height: 31.7rem;
    max-width: 111.2rem;
    margin: 0 auto;
    position: relative;
    .img-box {
      height: 31.7rem;
      img {
        max-width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 0.5rem;
        overflow: hidden;
        margin: 0 auto;
      }
    }
    .slick-prev,
    .slick-next {
      width: 4rem;
      height: 4rem;
    }
    .slick-prev {
      left: -4.8rem;
    }
    .slick-next {
      right: -4.8rem;
    }
  }
  .slide-counter {
    position: absolute;
    bottom: 0.6rem;
    right: 5.9rem;
    padding: 0.2rem 0.4rem;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border-radius: 10rem;
    font-weight: 400;
    line-height: 1.2rem;
    font-size: 1rem;
  }
`;

export const CommentContainer = styled.div`
  padding: 1.6rem;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  .count-box {
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1.8rem;
    display: flex;
    gap: 0.4rem;
    align-items: center;
    .count {
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 1.6rem;
    }
  }
`;

export const CommentBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  .inner {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    .parent-box {
      display: grid;
      gap: 0.8rem;
      grid-template-columns: 3rem auto;
      align-items: flex-start;
      padding: 0.8rem 0;
    }
    .children-wrap {
      display: flex;
      flex-direction: column;
      gap: 1.6rem;
    }
    .child-box {
      display: grid;
      gap: 0.8rem;
      grid-template-columns: 3rem auto;
      align-items: flex-start;
      padding: 0.8rem 0;
      padding-left: 4rem;
    }
    .profile-box {
      img {
        width: 3rem;
        height: 3rem;
        object-fit: cover;
      }
      .blocked-user-profile-img {
        display: flex;
        span {
          display: flex;
          width: 3rem;
          height: 3rem;
          border-radius: 3rem;
          background-color: #a1a1a1;
        }
      }
    }
    .comment-wrap {
      display: flex;
      flex-direction: column;
      gap: 0.4rem;
      .user-info-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        .lbox {
          display: flex;
          flex-direction: column;
          gap: 0.4rem;
          .user-name {
            font-size: 1.4rem;
            font-weight: 700;
            line-height: 1.8rem;
            letter-spacing: -0.06rem;
            color: #000;
            display: flex;
            gap: 0.8rem;
            align-items: center;
            .reg-user {
              background-color: #a1a1a1;
              border-radius: 0.5rem;
              font-size: 1rem;
              font-weight: 400;
              line-height: 1.4rem;
              color: white;
              display: flex;
              padding: 0.2rem 0.4rem;
            }
            &.blocked {
              color: #a1a1a1;
            }
          }
          .date {
            font-size: 1.2rem;
            font-weight: 400;
            line-height: 1.6rem;
            color: #575757;
          }
        }
        .btn-box {
          display: flex;
          button {
            border: 0;
            outline: 0;
            background-color: transparent;
            display: flex;
            padding: 0;
          }
        }
        .rbox {
          display: flex;
          gap: 1rem;
          align-items: center;
          .report {
            font-size: 1.4rem;
            font-weight: 500;
            line-height: 2rem;
            letter-spacing: -0.06rem;
            .report-txt {
              color: #ed2115;
            }
            .cursor {
              cursor: pointer;
              text-decoration: underline;
            }
          }
          button {
            border: 1px solid #cecece;
            border-radius: 0.5rem;
            box-shadow: 1px 1px 4px 0 #00000040;
            background-color: white;
            width: 7.2rem;
            padding: 0.1rem;
            font-size: 1.4rem;
            line-height: 2rem;
            font-weight: 500;
            letter-spacing: -0.06rem;
            cursor: pointer;
            &:disabled {
              color: #cecece;
              cursor: default;
            }
          }
        }
      }
      .comment-text-box {
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 2rem;
        color: #000;
        white-space: pre-wrap;
        .delete-comment-txt {
          font-weight: 400;
          font-size: 1.4rem;
          line-height: 2rem;
          color: #a1a1a1;
        }
        &.blocked {
          color: #a1a1a1;
          span {
            font-weight: 700;
          }
        }
      }
      .reple-btn-box {
        button {
          border: 0;
          outline: 0;
          background-color: transparent;
          display: flex;
          align-items: center;
          padding: 0;
          color: #575757;
          font-size: 1.2rem;
          line-height: 1.6rem;
          font-weight: 400;
          gap: 0.4rem;
          .icon-box {
            display: flex;
          }
        }
      }
    }
  }
`;
