import { UserProfile } from "interface/admin/interface.user";
import { UserDefaultInfoWrap } from "style/admin/user";
import { formatDateTime, getLatestTestDate } from "utils/util";

interface UserDetailInformationProps {
  data?: UserProfile; // ✅ data를 props로 받음 (선택적)
}

const UserDetailInformation = ({ data }: UserDetailInformationProps) => {
  // ✅ 회원 상태 판별 (타입 명확히 지정)
  const useYn: string | null = data?.useYn ?? null;
  const blockExpDt: string | null = data?.blockExpDt ?? null; // ✅ undefined 방지

  // ✅ 영구 제한 여부 확인 (문자열인지 먼저 체크)
  const isPermanentBlock = (blockExpDt ?? "").startsWith("9999-12-31");

  // ✅ 회원 상태 설정
  let userStatus;
  if (useYn !== "Y") {
    userStatus = <div className="withdraw item">탈퇴</div>;
  } else if (!blockExpDt) {
    // ✅ null 체크 (undefined 가능성 제거됨)
    userStatus = <div className="bluebox item">일반회원</div>;
  } else if (isPermanentBlock) {
    userStatus = <div className="graybox item">영구 제한</div>;
  } else {
    userStatus = <div className="redbox item">일시 제한</div>;
  }

  // ✅ "BLOCK"만 카운트
  const blockCount = data?.userBlockHistories
    ? data.userBlockHistories.filter((history) => history.type === "BLOCK")
        .length
    : 0;

  const getUserType = (type: string) => {
    switch (type) {
      case "google":
        return "구글 연동";
      case "apple":
        return "애플 연동";
      case "kakao":
        return "카카오 연동";
      default:
        return "일반 유저";
    }
  };

  return (
    <UserDefaultInfoWrap>
      <li>
        <div className="title">계정 상태</div>
        <div className="value">{userStatus}</div>
      </li>
      <li className="flex-start">
        <div className="title">이용제한내역</div>
        <div className="value">
          <div className="limit-wrap">
            <span>{blockCount} 회</span>
            {data?.userBlockHistories &&
              data?.userBlockHistories.map((item, index) => (
                <span key={index}>
                  {formatDateTime(item.regDt)}
                  {item.expDt ? ` ~ ${formatDateTime(item.expDt)}` : ""}{" "}
                  {/* ✅ expDt가 있을 때만 출력 */} <span>/</span> 사유{" "}
                  {item.reason}
                </span>
              ))}
          </div>
        </div>
      </li>
      <li>
        <div className="title">총 신고</div>
        <div className="value">
          <span className={Number(data?.reportCount) >= 10 ? "redbox" : ""}>
            {data?.reportCount}
          </span>
        </div>
      </li>
      <li>
        <div className="title">
          제한해제 후 <br />
          신고
        </div>
        <div className="value">
          <span
            className={
              Number(data?.reportCountAfterUnblock) >= 10 ? "redbox" : ""
            }
          >
            {data?.reportCountAfterUnblock}
          </span>
        </div>
      </li>
      <li>
        <div className="title">계정</div>
        <div className="value">
          <span className="flex">
            <span className="≈">{data?.type && getUserType(data?.type)}</span>
            <span>{data?.userId}</span>
          </span>
        </div>
      </li>
      <li>
        <div className="title">성별</div>
        <div className="value">{data?.gender === "M" ? "남" : "여"}</div>
      </li>
      <li>
        <div className="title">생년 월일</div>
        <div className="value">
          {data?.birthDate === "" ? "-" : data?.birthDate}
        </div>
      </li>
      <li>
        <div className="title">가입일시</div>
        <div className="value">
          {data?.regDt && formatDateTime(data?.regDt)}
        </div>
      </li>
      <li>
        <div className="title">검사횟수</div>
        <div className="value">
          <span className="flex">
            <span>
              {(data?.simpleTestResultCount ?? 0) +
                (data?.deepTestResultCount ?? 0)}
            </span>
            <span>
              {getLatestTestDate(
                data?.lastSimpleTestResultDt ?? null,
                data?.lastDeepTestResultDt ?? null
              ) &&
                `(최근 : ${getLatestTestDate(
                  data?.lastSimpleTestResultDt ?? null,
                  data?.lastDeepTestResultDt ?? null
                )})`}
            </span>
          </span>
        </div>
      </li>
      <li>
        <div className="title">측정횟수</div>
        <div className="value">{data?.simpleMeasureResultCount}</div>
      </li>
      <li>
        <div className="title">글 작성</div>
        <div className="value">
          <span className="flex">
            <span>{data?.reviewCount} </span>
            <span>
              {data?.lastReviewRegDt &&
                `(최근 : ${formatDateTime(data.lastReviewRegDt)})`}
            </span>
          </span>
        </div>
      </li>
      <li>
        <div className="title">댓글 작성</div>
        <div className="value">
          <span className="flex">
            <span>{data?.reviewCommentCount} </span>
            <span>
              {data?.lastReviewCommentRegDt &&
                `(최근 : ${formatDateTime(data.lastReviewCommentRegDt)})`}
            </span>
          </span>
        </div>
      </li>
    </UserDefaultInfoWrap>
  );
};

export default UserDetailInformation;
