import styled from "styled-components";

export const LoginWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  .login-box {
    min-width: 34.3rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    .logo {
      padding: 1.8rem;
      h1 {
        font-family: "Monoton";
        font-weight: 400;
        font-size: 4rem;
        line-height: 2rem;
        transform: translateY(3px);
        color: #242629;
        text-align: center;
      }
    }
  }
`;

export const EmailInputWrap = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  input[type="submit"] {
    width: 100%;
    outline: none;
    border: none;
    padding: 1.6rem;
    color: white;
    background-color: #cecece;
    border-radius: 0.5rem;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 2.4rem;
  }
`;

export const InputBox = styled.div`
  position: relative;
  margin-bottom: 2rem;
  input[type="text"] {
    width: 100%;
    border: 0;
    border-bottom: 1px solid #cecece;
    padding: 0.8rem 0.8rem 0.7rem;
    line-height: 2.4rem;
    font-size: 1.2rem;
    border-radius: 0;
    &:focus {
      outline: none;
    }
  }
  span {
    display: flex;
    position: absolute;
    bottom: 0;
    left: 50%;
    background-color: #1f75fe;
    width: 0;
    height: 2px;
    border-radius: 2px;
    transform: translateX(-50%);
    transition: all 0.5s;
  }
  input:focus ~ span {
    width: 100%;
  }
`;
