import { atom } from "recoil";

export const measureRecordingInfoState = atom<{
  location: string;
  recordingOption: string;
}>({
  key: "measureRecordingInfoState",
  default: {
    location: "",
    recordingOption: "",
  },
});

export const measureUnauthenticatedRecordingInfoState = atom<{
  location: string;
}>({
  key: "measureUnauthenticatedRecordingInfoState",
  default: {
    location: "",
  },
});

export const measureModalConfirmState = atom<{
  isOpen: boolean;
  isVisible: boolean;
}>({
  key: "measureModalConfirmState",
  default: {
    isOpen: false,
    isVisible: false,
  },
});

export const measureModalResultCompleteState = atom<{
  isOpen: boolean;
  isVisible: boolean;
}>({
  key: "measureModalResultCompleteState",
  default: {
    isOpen: false,
    isVisible: false,
  },
});
