import { reviewCommentDelete } from "api/review";
import styled from "styled-components";

interface Props {
  closedHandler: () => void;
  commentId: number;
  onUpdate: () => void;
}

const ModalReviewCommentDeleteConfirmBox = ({
  closedHandler,
  commentId,
  onUpdate,
}: Props) => {
  const handleReviewCommentDelete = async () => {
    try {
      const response = await reviewCommentDelete(commentId);
      console.log("삭제 결과:", response);
      closedHandler();
      onUpdate();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <BoxInner>
      <TextBox>
        <p>글을 삭제하시겠습니까?</p>
        <span>
          작성하신 글과 관련된 모든 데이터가 삭제되며, 되돌릴 수 없습니다.
        </span>
      </TextBox>
      <div className="btn-box">
        <button onClick={closedHandler}>취소</button>
        <span></span>
        <button className="del" onClick={handleReviewCommentDelete}>
          삭제
        </button>
      </div>
    </BoxInner>
  );
};

export default ModalReviewCommentDeleteConfirmBox;

const BoxInner = styled.div`
  display: flex;
  gap: 2.4rem;
  flex-direction: column;
  .btn-box {
    display: flex;
    position: relative;
    button {
      border: 0;
      outline: 0;
      flex: 1;
      background-color: transparent;
      padding: 1.8rem 0;
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 2rem;
      letter-spacing: -0.06rem;
      color: #575757;
      &.del {
        color: #ed2115;
      }
    }
    span {
      position: absolute;
      display: block;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 1px;
      height: 2.4rem;
      background-color: #a1a1a1;
    }
  }
`;

const TextBox = styled.div`
  display: flex;
  gap: 2.4rem;
  flex-direction: column;
  padding: 5.2rem 3rem 0;
  p {
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 2rem;
    letter-spacing: -0.06rem;
    text-align: center;
  }
  span {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 2rem;
    text-align: center;
    display: block;
    color: #575757;
  }
`;
