import {
  bannerAwayModalState,
  bannerSubmitTriggerState,
  isBannerChangedState,
} from "atoms/admin/contents/banner.state";
import { inquiryBackArrowState } from "atoms/admin/inquiry/admin.inquiry.state";
import {
  bestReviewCancelUpdateState,
  bestReviewUpdateState,
  inquiryAnswerRegistCancelConfirmState,
  reviewHideConfirmModalState,
  reviewShowConfirmModalState,
} from "atoms/admin/modal/modal.state";
import {
  bestReviewState,
  regUserIdState,
  reviewHideState,
} from "atoms/admin/review/admin.review.state";
import { userIdState } from "atoms/admin/user/admin.user.state";
import { BackArrowAdminIcon } from "cssReset/icon";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { AdminHeaderContainer } from "style/admin/layout";
import AdminNoticeBtnBox from "./components/admin.notice.btn.box";
import {
  isNoticeChangedState,
  isNoticeRegistChangedState,
  noticeAwayModalState,
  noticeRegistAwayModalState,
} from "atoms/admin/contents/notice.state";
import AdminColumnBtnBox from "./components/admin.column.btn.box";
import {
  columnAwayModalState,
  columnRegistAwayModalState,
  isColumnChangedState,
  isColumnRegistChangedState,
} from "atoms/admin/contents/column.state";
import AdminFooterPopupBtnBox from "./components/admin.footer.popup.btn.box";
import AdminUserBtnBox from "./components/admin.user.btn.box";

interface Props {
  headerTitle?: string;
  userInfoViewBtn?: boolean;
  backArrowBtn?: boolean;
  backArrowPath?: string;
  reviewDetail?: boolean;
  subTitle?: string;
  contents?: "banner" | "footer" | "notice" | "column";
  contentsDetail?: "banner" | "footer" | "notice" | "column";
  userDetail?: boolean;
}

const AdminHeader = ({
  headerTitle,
  userInfoViewBtn,
  backArrowBtn,
  backArrowPath = "/admin/inquiry",
  reviewDetail = false,
  subTitle,
  contents,
  contentsDetail,
  userDetail,
}: Props) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const setCancelModal = useSetRecoilState(
    inquiryAnswerRegistCancelConfirmState
  ); // ✅ 상태 업데이트를 위한 Hook
  const backArrowActive = useRecoilValue(inquiryBackArrowState);
  const userId = useRecoilValue(userIdState);
  const regUserId = useRecoilValue(regUserIdState);
  const reviewHideValue = useRecoilValue(reviewHideState);
  const setReviewHideModal = useSetRecoilState(reviewHideConfirmModalState);
  const setReviewShowModal = useSetRecoilState(reviewShowConfirmModalState);
  const setBestReviewModal = useSetRecoilState(bestReviewUpdateState);
  const setBestReviewCancelModal = useSetRecoilState(
    bestReviewCancelUpdateState
  );
  const setBannerAwayModal = useSetRecoilState(bannerAwayModalState);
  const bestReviewValue = useRecoilValue(bestReviewState);
  const setBannerSubmitTrigger = useSetRecoilState(bannerSubmitTriggerState);
  const isBannerChanged = useRecoilValue(isBannerChangedState);
  const isNoticeChanged = useRecoilValue(isNoticeChangedState);
  const isNoticeRegistChanged = useRecoilValue(isNoticeRegistChangedState);
  const isColumnChanged = useRecoilValue(isColumnChangedState);
  const isColumnRegistChanged = useRecoilValue(isColumnRegistChangedState);
  const setNoticeAwayModal = useSetRecoilState(noticeAwayModalState);
  const setNoticeRegistAwayModal = useSetRecoilState(
    noticeRegistAwayModalState
  );
  const setColumnAwayModal = useSetRecoilState(columnAwayModalState);
  const setColumnRegistAwayModal = useSetRecoilState(
    columnRegistAwayModalState
  );

  const backArrowClickedHandler = (path: string) => {
    if (backArrowActive) {
      setCancelModal({
        isOpen: true,
        isVisible: true,
        onClick: () => {
          navigate(path);
          setCancelModal({ isOpen: false, isVisible: false });
        }, // ✅ 모달에서 "나가기" 버튼 클릭 시 실행할 함수 전달
      });
    } else if (headerTitle === "공지 수정") {
      if (isNoticeChanged) {
        setNoticeAwayModal({ isOpen: true, isVisible: true });
      } else {
        const updatedPath = path.endsWith("/")
          ? `${path}${id}`
          : `${path}/${id}`;
        navigate(updatedPath);
      }
    } else if (headerTitle === "신규 공지 작성") {
      if (isNoticeRegistChanged) {
        setNoticeRegistAwayModal({ isOpen: true, isVisible: true });
      } else {
        navigate(path);
      }
    } else if (headerTitle === "칼럼수정") {
      if (isColumnChanged) {
        setColumnAwayModal({ isOpen: true, isVisible: true });
      } else {
        const updatedPath = path.endsWith("/")
          ? `${path}${id}`
          : `${path}/${id}`;
        navigate(updatedPath);
      }
    } else if (headerTitle === "신규 칼럼 작성") {
      if (isColumnRegistChanged) {
        setColumnRegistAwayModal({ isOpen: true, isVisible: true });
      } else {
        navigate(path);
      }
    } else if (
      headerTitle === "메인 배너 등록사항 수정" ||
      headerTitle === "하단 팝업배너 등록사항 수정" ||
      headerTitle === "하단 팝업배너 신규 등록"
    ) {
      if (isBannerChanged) {
        setBannerAwayModal({ isOpen: true, isVisible: true });
      } else {
        navigate(path);
      }
    } else {
      navigate(path);
    }
  };

  const bannerBackArrowClickHandler = (path: string) => {
    if (isBannerChanged) {
      setBannerAwayModal({
        isOpen: true,
        isVisible: true,
      });
    } else {
      navigate(path);
    }
  };

  const hostURL = process.env.REACT_APP_DEV_URL;

  const handleUserInfoClicked = (id: string) => {
    window.open(
      `${hostURL}/admin/user/info/detail/${id}`,
      "_blank",
      "width=1240,height=800,scrollbars=no,resizable=no,toolbar=no,menubar=no,location=no,status=no"
    );
  };

  const isModifyPage = location.pathname.includes("update");
  const isRegistPage = location.pathname.includes("/admin/notice/regist");
  const isColumnRegistPage = location.pathname.includes("/admin/column/regist");
  const isBannerRegist = location.pathname.includes("banner/regist");

  return (
    <AdminHeaderContainer
      className={`${userInfoViewBtn ? "flex-space" : ""} ${
        reviewDetail ? "review-detail-wrap" : ""
      } ${contentsDetail === "banner" ? "flex-space-banner" : ""}
      ${contents ? "flex-space-banner" : ""} ${
        contentsDetail === "notice" ||
        contentsDetail === "column" ||
        contentsDetail === "footer" ||
        userDetail
          ? "notice-btn-wrap"
          : ""
      }`}
    >
      {backArrowBtn ? (
        <div className="back-arrow-btn-box">
          <span
            className="icon-box"
            onClick={() =>
              contents === "banner"
                ? bannerBackArrowClickHandler("/admin/banner")
                : backArrowClickedHandler(backArrowPath)
            }
          >
            <BackArrowAdminIcon />
          </span>
          <h2>{headerTitle}</h2>
        </div>
      ) : (
        <h2>
          {headerTitle}{" "}
          {subTitle?.trim() && <span className="sub-title">{subTitle}</span>}
        </h2>
      )}
      {userInfoViewBtn && (
        <div className={`btn-box ${reviewDetail && "review-btn-box"}`}>
          <button
            className="userinfo-btn"
            onClick={() => {
              regUserId && handleUserInfoClicked(regUserId);
            }}
          >
            회원정보 보기
          </button>
          {reviewDetail && (
            <>
              {bestReviewValue === "N" ? (
                <button
                  onClick={() => {
                    setBestReviewModal({ isOpen: true, isVisible: true });
                  }}
                >
                  베스트 후기 지정
                </button>
              ) : (
                <button
                  onClick={() => {
                    setBestReviewCancelModal({ isOpen: true, isVisible: true });
                  }}
                >
                  베스트 후기 해제
                </button>
              )}
              <button
                className="review-hide-btn"
                onClick={() => {
                  setReviewHideModal({ isOpen: true, isVisible: true });
                }}
                disabled={reviewHideValue === "Y"}
              >
                글 숨기기
              </button>
              <button
                className="review-hide-btn"
                onClick={() => {
                  setReviewShowModal({ isOpen: true, isVisible: true });
                }}
                disabled={reviewHideValue === "N"}
              >
                글 노출하기
              </button>
            </>
          )}
        </div>
      )}
      {contents === "banner" ? (
        <div className="btn-box">
          <button
            onClick={() => setBannerSubmitTrigger(true)}
            disabled={!isBannerChanged}
          >
            변경사항 업데이트
          </button>
        </div>
      ) : contents === "notice" ? (
        <div className="btn-box">
          <button onClick={() => navigate("/admin/notice/regist")}>
            새글작성
          </button>
        </div>
      ) : contents === "column" ? (
        <div className="btn-box">
          <button onClick={() => navigate("/admin/column/regist")}>
            새글작성
          </button>
        </div>
      ) : contents === "footer" ? (
        <div className="btn-box">
          <button
            onClick={() => setBannerSubmitTrigger(true)}
            disabled={!isBannerChanged}
          >
            변경사항 업데이트
          </button>
        </div>
      ) : (
        ""
      )}
      {contentsDetail === "banner" ? (
        <div className="blue-btn-box">
          <button
            disabled={!isBannerChanged}
            onClick={() => setBannerSubmitTrigger(true)}
          >
            {isBannerRegist ? "등록하기" : "적용하기"}
          </button>
        </div>
      ) : contentsDetail === "notice" ? (
        <AdminNoticeBtnBox
          constentModify={isModifyPage ? "notice" : undefined}
          isRegistPage={isRegistPage} // ✅ 새롭게 추가한 props 전달
        />
      ) : contentsDetail === "column" ? (
        <AdminColumnBtnBox
          constentModify={isModifyPage ? "column" : undefined}
          isRegistPage={isColumnRegistPage} // ✅ 새롭게 추가한 props 전달
        />
      ) : contentsDetail === "footer" ? (
        <AdminFooterPopupBtnBox
          isRegistPage={isColumnRegistPage} // ✅ 새롭게 추가한 props 전달
        />
      ) : (
        ""
      )}
      {userDetail && <AdminUserBtnBox />}
    </AdminHeaderContainer>
  );
};

export default AdminHeader;
