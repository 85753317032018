import axiosInstance from "plugin/axios";

export const fetchNoticeList = async () => {
  try {
    const response = await axiosInstance.get(`/announcement`);
    return response.data;
  } catch (error) {
    throw new Error();
  }
};
