import { MeasureHeader } from "style/measure";

interface Props {
  isRecording: boolean;
  onStart: () => void;
  onStop: () => void;
}

const MeasureRecordingHeader = ({ isRecording, onStart, onStop }: Props) => {
  const handleIsAndroidStart = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage("android_simple_measure_start");
    }
    onStart();
  };

  return (
    <MeasureHeader>
      <div className="btn-box">
        {/* 녹음 중이 아닐 때만 시작 버튼 활성화 */}
        <button onClick={handleIsAndroidStart} disabled={isRecording}>
          시작
        </button>
      </div>
      <div className="header-title">
        <p>층간소음 간이측정</p>
      </div>
      <div className="btn-box">
        {/* 녹음 중일 때만 종료 버튼 활성화 */}
        <button onClick={onStop} disabled={!isRecording}>
          종료
        </button>
      </div>
    </MeasureHeader>
  );
};

export default MeasureRecordingHeader;
