import { useEffect, useState } from "react";
import { ListBox } from "style/admin/contents";
import AdminNoticeTable from "./admin.notice.table";
import { fetchLoadAdminNoticeList } from "api/admin/constent.notice";
import { NoticeParams } from "interface/admin/interface.contents";

interface ListProps {
  searchTerm: string;
  setDataLength?: any;
  reportCount?: number;
  useYn?: "Y" | "N" | null;
}

const AdminNoticeList = ({ searchTerm, useYn, setDataLength }: ListProps) => {
  const [data, setData] = useState<[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const loadAdminNoticeList = async () => {
      try {
        const params: NoticeParams = {
          keyword: searchTerm,
          orderBy: "REGDT",
          orderByDir: "DESC",
          useYn: useYn ? useYn : null,
        };
        const response = await fetchLoadAdminNoticeList(params);
        setData(response.result || []);
        setDataLength(response.result > 0);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    loadAdminNoticeList();
  }, [searchTerm]);

  return (
    <ListBox>
      {isLoading ? (
        <p>검색 중...</p> // ✅ 로딩 표시
      ) : (
        <AdminNoticeTable data={data} />
      )}
    </ListBox>
  );
};

export default AdminNoticeList;
