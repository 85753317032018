import React, { useEffect, useRef, useState } from "react";

const NoiseMeter = () => {
  const [dB, setDb] = useState(0);
  const audioContextRef = useRef<AudioContext | null>(null);
  const analyserRef = useRef<AnalyserNode | null>(null);
  const dataArrayRef = useRef<Uint8Array | null>(null);

  useEffect(() => {
    const initAudio = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        audioContextRef.current = new (window.AudioContext ||
          (window as any).webkitAudioContext)();
        const audioContext = audioContextRef.current;

        const source = audioContext.createMediaStreamSource(stream);

        analyserRef.current = audioContext.createAnalyser();
        analyserRef.current.fftSize = 256;

        const bufferLength = analyserRef.current.frequencyBinCount;
        dataArrayRef.current = new Uint8Array(bufferLength);

        source.connect(analyserRef.current);

        measureNoise();
      } catch (error) {
        console.error("마이크 접근 실패: ", error);
      }
    };

    initAudio();

    return () => {
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
    };
  }, []);

  const measureNoise = () => {
    const update = () => {
      if (analyserRef.current && dataArrayRef.current) {
        analyserRef.current.getByteTimeDomainData(dataArrayRef.current);

        const rms = Math.sqrt(
          dataArrayRef.current.reduce(
            (sum, value) => sum + (value - 128) ** 2,
            0
          ) / dataArrayRef.current.length
        );

        const db = 20 * Math.log10(rms / 128);
        setDb(parseFloat(db.toFixed(2))); // 문자열을 숫자로 변환
      }
      requestAnimationFrame(update);
    };
    update();
  };

  return (
    <div style={{ textAlign: "center", marginTop: "20px" }}>
      <h1>현재 소음 수준: {dB} dB</h1>
      <p>마이크를 활성화하여 주변 소음을 측정하세요.</p>
    </div>
  );
};

export default NoiseMeter;
