import { useNavigate } from "react-router-dom";
import { BoxInner, TextBox } from "style/modal";
import styled from "styled-components";

interface ModalProps {
  txt: string | null;
}

const LoginAlertModal = ({ txt }: ModalProps) => {
  const navigate = useNavigate();
  return (
    <ModalBoxInner>
      <TextBox>
        <p>{txt}</p>
      </TextBox>
      <div className="btn-box">
        <button
          onClick={() => {
            navigate("/");
          }}
        >
          확인
        </button>
      </div>
    </ModalBoxInner>
  );
};

export default LoginAlertModal;

const ModalBoxInner = styled(BoxInner)`
  gap: 1.4rem;
`;
