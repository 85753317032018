import { fetchDeleteFooterPopup } from "api/admin/contents.footer.popup";
import { BoxInner, TextBox } from "style/admin/layout";

interface ModalProps {
  closedHandler: () => void;
  orderNo?: number;
  imgUrl?: string;
  bannerId?: number;
}

const FooterPopupDeleteModal = ({
  closedHandler,
  orderNo,
  imgUrl,
  bannerId,
}: ModalProps) => {
  const bannerDeleteClicked = async (bannerId: number) => {
    try {
      const response = await fetchDeleteFooterPopup(bannerId);
      console.log(response);
      if (response.result) closedHandler();
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <BoxInner>
      <TextBox style={{ minHeight: "auto" }}>
        <p>아래 배너를 삭제하시겠습니까?</p>
        <span>표시 순번 {orderNo ? orderNo : ""}</span>
        <div className="banner-img-box">
          <img src={imgUrl ? imgUrl : ""} alt={imgUrl} />
        </div>
      </TextBox>
      <div className="btn-box">
        <button className="continue" onClick={closedHandler}>
          돌아가기
        </button>
        <span></span>
        <button
          className="del"
          onClick={() => {
            bannerId && bannerDeleteClicked(bannerId);
          }}
        >
          삭제하기
        </button>
      </div>
    </BoxInner>
  );
};

export default FooterPopupDeleteModal;
