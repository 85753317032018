import { fetchLoadUserList } from "api/admin/user";
import { UserListParams, UserProfile } from "interface/admin/interface.user";
import { useEffect, useState } from "react";
import { ListBox } from "style/admin/user";
import AdminUserTable from "./admin.user.table";

interface ListProps {
  searchTerm: string;
  setDataLength?: any;
  reportCountAfterUnblock?: number | undefined;
  blockYn?: "Y" | "N" | null;
}

const AdminUserList = ({
  searchTerm,
  setDataLength,
  reportCountAfterUnblock,
  blockYn = null,
}: ListProps) => {
  const [data, setData] = useState<UserProfile[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const loadUserList = async () => {
      try {
        const params: UserListParams = {
          blockYn: blockYn,
          keyword: searchTerm,
          orderBy: "REGDT",
          orderByDir: "DESC",
          reportCountAfterUnblock: reportCountAfterUnblock,
        };
        const response = await fetchLoadUserList(params);
        setData(response.result || []);
        setDataLength(response.result.length > 0);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    loadUserList();
  }, [searchTerm]);

  return (
    <ListBox>
      {isLoading ? (
        <p>검색 중...</p> // ✅ 로딩 표시
      ) : (
        <AdminUserTable data={data} />
      )}
    </ListBox>
  );
};

export default AdminUserList;
