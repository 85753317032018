import { fetchRegistBanner } from "api/admin/contents.banner";
import {
  bannerAwayModalState,
  bannerDetailCompleteModalState,
  bannerSubmitTriggerState,
  isBannerChangedState,
  orderIdSaveState,
} from "atoms/admin/contents/banner.state";
import AdminCommonModalLayout from "components/admin/common.modal.layout/admin.common.modal";
import AdminBannerAttachment from "components/admin/contents/banner/admin.banner.attechment";
import BannerDetailAwayModal from "components/admin/contents/banner/modal/banner.detail.away.modal";
import BannerRegistCompleteModal from "components/admin/contents/banner/modal/banner.regist.complete.modal";
import { BannerRegistParams } from "interface/admin/interface.contents";
import { useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { BannerDetailContainer } from "style/admin/contents";

const AdminBannerRegist = () => {
  const { register, handleSubmit, setValue, control } = useForm();
  const orderId = useRecoilValue(orderIdSaveState);
  const navigate = useNavigate();
  const setIsBannerChanged = useSetRecoilState(isBannerChangedState);
  const [bannerSubmitTrigger, setBannerSubmitTrigger] = useRecoilState(
    bannerSubmitTriggerState
  );
  const [bannerCompleteModal, setBannerCompleteModal] = useRecoilState(
    bannerDetailCompleteModalState
  );
  const [bannerAwayModal, setBannerAwayModal] =
    useRecoilState(bannerAwayModalState);

  const watchedContents = useWatch({ control, name: "pageLink" });
  const watchedFiles = useWatch({ control, name: "bannerFile" });

  useEffect(() => {
    // ✅ 새 파일 감지 (File 객체만 필터링)
    const newFiles =
      watchedFiles?.filter((file: any) => file instanceof File) || [];

    // ✅ 제목과 내용은 둘 다 입력되어야 함
    const isContentsValid = !!watchedContents && watchedContents.trim() !== "";

    // ✅ 파일은 없어도 괜찮음, 추가된 경우만 변경됨
    const isFileChanged = newFiles.length > 0;

    const isTextChanged = isContentsValid && isFileChanged;

    // ✅ 제목과 내용이 입력된 상태에서 파일이 추가되거나 텍스트가 변경되었을 때만 변경 감지
    setIsBannerChanged(isTextChanged);
  }, [watchedFiles, , watchedContents]);

  console.log(orderId);

  useEffect(() => {
    if (orderId === undefined) navigate("/admin/banner");
  }, []);

  const onSubmit = async (data: any) => {
    try {
      const requestData: BannerRegistParams = {
        title: `banner${orderId}`,
        contents: data.pageLink,
        order: orderId,
        files: data.bannerFile?.filter((file: any) => file instanceof File),
      };

      const response = await fetchRegistBanner(requestData);
      if (response.result) {
        setBannerCompleteModal({ isOpen: true, isVisible: true });
        setIsBannerChanged(false); // ✅ 적용 후 변경 없음 상태로 설정
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (bannerSubmitTrigger) {
      handleSubmit(onSubmit)(); // `onSubmit` 실행
      setBannerSubmitTrigger(false); // 상태 초기화 (중복 실행 방지)
    }
  }, [bannerSubmitTrigger, handleSubmit]);

  // modal handler
  const bannerCompleteModalClosed = () => {
    setBannerCompleteModal({ isOpen: false, isVisible: false });
  };
  const bannerAwayModalClosed = () => {
    setBannerAwayModal({ isOpen: false, isVisible: false });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <BannerDetailContainer>
        <div className="tbox">
          <p>배너 이미지</p>
          <span>가로 375, 세로 152 픽셀 / 파일형식 PNG, JPG</span>

          <AdminBannerAttachment
            name="bannerFile"
            register={register}
            setValue={setValue}
            initialFiles={[]}
            contents="banner"
          />
        </div>
        <div className="bbox">
          <p>배너와 연결된 페이지 링크</p>
        </div>
        <div className="input-box">
          <input
            type="text"
            {...register("pageLink")}
            placeholder="링크를 입력하세요."
          />
          <span></span>
        </div>

        {bannerCompleteModal && (
          <AdminCommonModalLayout
            isVisible={bannerCompleteModal.isVisible}
            closedHandler={bannerCompleteModalClosed}
          >
            <BannerRegistCompleteModal
              closedHandler={bannerCompleteModalClosed}
            />
          </AdminCommonModalLayout>
        )}

        {bannerAwayModal && (
          <AdminCommonModalLayout
            isVisible={bannerAwayModal.isVisible}
            closedHandler={bannerAwayModalClosed}
          >
            <BannerDetailAwayModal closedHandler={bannerAwayModalClosed} />
          </AdminCommonModalLayout>
        )}
      </BannerDetailContainer>
    </form>
  );
};

export default AdminBannerRegist;
