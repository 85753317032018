import { Controller, useForm } from "react-hook-form";
import { InquiryDetailRightBox } from "style/admin/inquiry";
import TextareaAutosize from "react-textarea-autosize";
import { useEffect, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  inquiryAnswerRegistCancelConfirmState,
  inquiryAnswerRegistModalState,
} from "atoms/admin/modal/modal.state";
import AdminCommonModalLayout from "../common.modal.layout/admin.common.modal";
import AnswerRegistConfirmModal from "./modal/answer.regist.confirm.modal";
import { fetchUpdateInquiryDetailAnswer } from "api/admin/inquiry";
import { useNavigate } from "react-router-dom";
import AnswerRegistCancelModal from "./modal/answer.regist.cancel.modal";
import { inquiryBackArrowState } from "atoms/admin/inquiry/admin.inquiry.state";

interface AnswerDataProps {
  answerDt: string | null;
  answerRegUSerId: string | null;
  answerId: number;
  answerYn: "Y" | "N";
  answer: string | null;
}

const defaultAnswerTxt = `안녕하세요!
RS101 고객만족 담당자 이슬입니다.
저희 서비스에 관심을 가지고 이용해 주셔서 감사합니다.



답변드린 내용이 도움 되셨기를 바랍니다.
추가 문의 사항이 있다면 언제든 다시 문의해 주세요. 

감사합니다.`;

const InquiryDetailRight = ({
  answerDt,
  answerRegUSerId,
  answerId,
  answerYn,
  answer,
}: AnswerDataProps) => {
  const { handleSubmit, control, watch } = useForm({
    defaultValues: { contents: defaultAnswerTxt },
  });

  const contentsValue = watch("contents"); // ✅ 입력된 내용 감시
  const [isModified, setIsModified] = useState(false); // ✅ 수정 여부 상태 추가
  // ✅ 모달에서 사용할 데이터 상태
  const [modalData, setModalData] = useState<{ contents: string } | null>(null);
  const navigate = useNavigate();

  const [confirmModal, setConfirmModal] = useRecoilState(
    inquiryAnswerRegistModalState
  );
  const [cancelModal, setCancelModal] = useRecoilState(
    inquiryAnswerRegistCancelConfirmState
  );
  const setBackArrowActive = useSetRecoilState(inquiryBackArrowState);

  // ✅ 기본 값과 비교하여 사용자가 수정했는지 확인
  useEffect(() => {
    if (contentsValue.trim() !== defaultAnswerTxt.trim()) {
      setIsModified(true);
      setBackArrowActive(true);
    } else {
      setIsModified(false);
      setBackArrowActive(false);
    }
  }, [contentsValue, setBackArrowActive]);

  // ✅ "등록하기" 버튼 클릭 시 모달을 열고 데이터 저장
  const onSubmit = (data: any) => {
    setModalData({ contents: data.contents }); // 모달에 보낼 데이터 저장
    handleModalOpen();
  };

  // ✅ 모달에서 "확인" 클릭 시 최종 제출
  const handleConfirmSubmit = async () => {
    if (!modalData) return; // 데이터가 없으면 실행 안 함

    try {
      const response = await fetchUpdateInquiryDetailAnswer(
        answerId,
        modalData.contents
      );
      if (response.result) {
        handleModalClosed(); // 모달 닫기
        navigate("/admin/inquiry");
      }
    } catch (error) {
      console.error("문의 답변 등록 실패:", error);
    }
  };

  // confirm modal
  const handleModalOpen = () => {
    setConfirmModal({ isOpen: true, isVisible: true });
  };
  const handleModalClosed = () => {
    setConfirmModal({ isOpen: false, isVisible: false });
  };

  // cancel modal
  const handleCancelModalClosed = () => {
    setCancelModal({ isOpen: false, isVisible: false });
  };

  return (
    <InquiryDetailRightBox>
      <div className="top-box">
        <div className="answer-date">
          <p>답변 등록일</p>
          <p>{answerDt === null ? "-" : answerDt}</p>
        </div>
        <div className="answer-reg-user">
          <p>답변 등록자</p>
          <p>{answerRegUSerId === null ? "-" : answerRegUSerId}</p>
        </div>
      </div>

      {answerYn === "N" ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="answer-contents-box">
            <div className="btn-box">
              <input
                type="submit"
                value="답변 등록하기"
                disabled={!isModified} // ✅ 사용자가 수정하지 않으면 비활성화
              />
            </div>
            <div className="textarea-wrapper">
              <Controller
                name="contents"
                control={control}
                rules={{
                  required: "문의 내용을 입력해주세요.",
                  maxLength: {
                    value: 1000,
                    message: "문의 내용은 최대 1,000자까지 입력 가능합니다.",
                  },
                }}
                render={({ field, fieldState }) => (
                  <>
                    <TextareaAutosize {...field} minRows={9} maxRows={28} />
                    {/* {fieldState.error && (
                  <span style={{ color: "red" }}>
                    {fieldState.error.message}
                  </span>
                )} */}
                  </>
                )}
              />
            </div>
          </div>
        </form>
      ) : (
        <div className="answer-box">
          <p>{answer}</p>
        </div>
      )}

      {confirmModal && (
        <AdminCommonModalLayout
          isVisible={confirmModal.isVisible}
          closedHandler={handleModalClosed}
        >
          <AnswerRegistConfirmModal
            closedHandler={handleModalClosed}
            onClick={handleConfirmSubmit}
          />
        </AdminCommonModalLayout>
      )}

      {cancelModal && (
        <AdminCommonModalLayout
          isVisible={cancelModal.isVisible}
          closedHandler={handleCancelModalClosed}
        >
          <AnswerRegistCancelModal
            closedHandler={handleCancelModalClosed}
            onClick={cancelModal.onClick || handleCancelModalClosed}
          />
        </AdminCommonModalLayout>
      )}
    </InquiryDetailRightBox>
  );
};

export default InquiryDetailRight;
