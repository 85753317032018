import styled from "styled-components";

export const AlramContainer = styled.div``;

export const AlramListWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  padding: 1.6rem;
  .none-block-user {
    padding: 4rem 0;
    text-align: center;
    p {
      font-size: 1.4rem;
      font-weight: 700;
      line-height: 2.4rem;
    }
  }
  .alrem-info {
    padding: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 1.6rem;
      color: #a1a1a1;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        height: 1px;
        width: 3.95rem;
        background-color: #a1a1a1;
        top: 50%;
        transform: translateY(-50%);
        left: -5.2rem;
      }
      &::after {
        content: "";
        position: absolute;
        height: 1px;
        width: 3.95rem;
        background-color: #a1a1a1;
        top: 50%;
        transform: translateY(-50%);
        right: -5.2rem;
      }
    }
  }
`;

export const AllDelItemsBox = styled.div`
  padding: 0 1.6rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  button {
    padding: 0 0.8rem;
    border: none;
    outline: none;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      display: flex;
      padding: 0.4rem;
      font-size: 1.2rem;
      line-height: 1.6rem;
      font-weight: 400;
      color: #242629;
      font-family: "Pretendard", sans-serif;
    }
  }
`;

export const AlramListItem = styled.div`
  padding: 1.6rem 0;
  display: flex;
  gap: 0.4rem;
  flex-direction: column;
  .t-alram-info {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    width: 100%;
    justify-content: space-between;
    .l {
      display: flex;
      align-items: center;
      gap: 0.2rem;
      .icon-box {
        display: flex;
        svg {
          width: 1.4rem;
          height: 1.4rem;
        }
      }
      .type-box {
        font-size: 1rem;
        line-height: 1.2rem;
        font-weight: 400;
        color: #575757;
        display: flex;
        font-family: "Pretendard", sans-serif;
        align-items: center;
      }
    }
    .r {
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.2rem;
      color: #575757;
    }
  }
  .contents-box {
    padding-left: 1.6rem;
    p {
      font-size: 1.4rem;
      line-height: 2rem;
      font-weight: 400;
    }
  }
`;

export const AlramSettingContainer = styled.div``;

export const AlramSettingTopBox = styled.div`
  padding: 1.6rem;
  p {
    font-size: 1.2rem;
    line-height: 1.6rem;
    font-weight: 400;
  }
`;

export const AlramSettingMenuWrap = styled.ul`
  padding: 1.6rem;
  form {
    display: grid;
    gap: 1.6rem;
  }
  .menu-item {
    height: 2.8rem;
    display: flex;
    align-items: center;
  }
`;

export const Spacer = styled.div`
  border: 1px solid #d9d9d9;
`;

export const AlramMenuTitle = styled.p`
  font-size: 1.4rem;
  color: black;
  font-weight: 700;
  line-height: 1.8rem;
  letter-spacing: -0.06rem;
`;

export const AlramMenuItem = styled.div`
  display: flex;
  gap: 2.7rem;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  p {
    font-size: 1.2rem;
    line-height: 1.6rem;
    font-weight: 400;
  }
  .input-box {
    input[type="checkbox"] {
      display: none;
    }
    label {
      border: 1px solid #225298;
      color: #225298;
      border-radius: 0.5rem;
      padding: 0.4rem;
      width: 4rem;
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 1.6rem;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: "Pretendard", sans-serif;
      transition: all 0.3s;
      &.isActive {
        color: white;
        background-color: #225298;
      }
    }
  }
`;
