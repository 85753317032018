import { atom } from "recoil";

export const bannerSubmitTriggerState = atom<boolean>({
  key: "bannerSubmitTriggerState",
  default: false, // 기본값 false
});

export const isBannerChangedState = atom<boolean>({
  key: "isBannerChangedState",
  default: false, // 기본값: 변경 없음
});

export const bannerDetailCompleteModalState = atom<{
  isOpen: boolean;
  isVisible: boolean;
}>({
  key: "bannerDetailCompleteModalState",
  default: {
    isOpen: false,
    isVisible: false,
  },
});

export const bannerAwayModalState = atom<{
  isOpen: boolean;
  isVisible: boolean;
}>({
  key: "bannerAwayModalState",
  default: {
    isOpen: false,
    isVisible: false,
  },
});

export const bannerDeleteModalState = atom<{
  isOpen: boolean;
  isVisible: boolean;
}>({
  key: "bannerDeleteModalState",
  default: {
    isOpen: false,
    isVisible: false,
  },
});

export const orderIdSaveState = atom<number>({
  key: "orderIdSaveState",
  default: undefined,
});
