import { useEffect, useState } from "react";

const TestTestView = () => {
  const [msg, setMsg] = useState("");

  useEffect(() => {
    console.log("React WebView Loaded");

    // ✅ iOS에서 메시지를 받을 수 있도록 이벤트 리스너 추가
    window.addEventListener("message", (event) => {
      console.log("✅ iOS에서 메시지 받음:", event.data);
      setMsg("✅ iOS에서 메시지 받음");
    });

    return () => {
      window.removeEventListener("message", () => {}); // ✅ 클린업
    };
  }, []);

  const handleClick = () => {
    if (window.webkit?.messageHandlers?.buttonClicked) {
      window.webkit.messageHandlers.buttonClicked.postMessage(
        "Button Clicked!"
      );
      console.log("✅ iOS로 버튼 클릭 메시지 전송됨");
    } else {
      console.log("⚠️ iOS WebView 메시지 핸들러를 찾을 수 없음");
    }
  };

  return (
    <div style={{ padding: "3rem" }}>
      <button
        onClick={handleClick}
        style={{ padding: "1rem", fontSize: "1.2rem" }}
      >
        iOS로 버튼 클릭 이벤트 보내기
      </button>

      {msg && <p>{msg}</p>}
    </div>
  );
};

export default TestTestView;
