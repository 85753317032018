import {
  userDateLimitLiftModal,
  userDateLimitModal,
  userDatePermanentLimitModal,
  userInfoState,
} from "atoms/admin/contents/user.state";
import { useRecoilValue, useSetRecoilState } from "recoil";
import styled from "styled-components";

const AdminUserBtnBox = () => {
  const userInfoValue = useRecoilValue(userInfoState);
  const setDateLimitModal = useSetRecoilState(userDateLimitModal);
  const setDateLimitLiftModal = useSetRecoilState(userDateLimitLiftModal);
  const setDateLimitPermanentModal = useSetRecoilState(
    userDatePermanentLimitModal
  );

  // ✅ 회원 상태 판별 (타입 명확히 지정)
  const useYn: string | null = userInfoValue?.useYn ?? null;
  const blockExpDt: string | null = userInfoValue?.blockExpDt ?? null; // ✅ undefined 방지

  // ✅ 영구 제한 여부 확인 (문자열인지 먼저 체크)
  const isPermanentBlock = (blockExpDt ?? "").startsWith("9999-12-31");

  // ✅ 회원 상태 설정
  let userStatus;
  let isLimited = false; // 제한 상태 확인용 변수

  if (useYn !== "Y") {
    userStatus = <div className="withdraw item">탈퇴</div>;
  } else if (!blockExpDt) {
    userStatus = <div className="bluebox item">일반회원</div>;
  } else if (isPermanentBlock) {
    userStatus = <div className="graybox item">영구 제한</div>;
    isLimited = true;
  } else {
    userStatus = <div className="redbox item">일시 제한</div>;
    isLimited = true;
  }

  return (
    <Wrapper>
      <div className="user-info">
        <div className="profile">
          <div className="img-box">
            <img
              src={
                userInfoValue.userProfileImg
                  ? userInfoValue.userProfileImg
                  : "/assets/icon/userprofile.png"
              }
              alt={`${userInfoValue.userName} 프로필`}
            />
          </div>
          <span>{userInfoValue.userName}</span> {/* ✅ 텍스트 올바르게 출력 */}
        </div>
        <div className="userStatus">
          <span>{userStatus}</span>
        </div>
      </div>
      <div className="btn-box">
        {/* ✅ 일반 회원일 경우 "일시 이용제한" & "영구 이용제한" 활성화 */}
        <button
          disabled={useYn !== "Y" || isLimited}
          onClick={() => {
            setDateLimitModal({ isOpen: true, isVisible: true });
          }}
        >
          일시 이용제한
        </button>
        <button
          disabled={useYn !== "Y" || isLimited}
          onClick={() => {
            setDateLimitPermanentModal({ isOpen: true, isVisible: true });
          }}
        >
          영구 이용제한
        </button>

        {/* ✅ 제한 상태일 경우 "제한 해제" 버튼 활성화 */}
        <button
          disabled={useYn !== "Y" || !isLimited}
          onClick={() => {
            setDateLimitLiftModal({ isOpen: true, isVisible: true });
          }}
        >
          제한 해제
        </button>
      </div>
    </Wrapper>
  );
};

export default AdminUserBtnBox;

const Wrapper = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  .user-info {
    display: flex;
    gap: 1rem;
    .profile {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.4rem;
      .img-box {
        width: 2rem;
        height: 2rem;
        overflow: hidden;
        display: flex;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          object-fit: cover;
        }
      }
      span {
        font-size: 1.6rem;
        font-weight: 700;
        line-height: 2.4rem;
        white-space: nowrap;
      }
    }
    .userStatus {
      span {
        .item {
          padding: 0.8rem 1.35rem;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid;
          border-radius: 100px;

          font-size: 1.6rem;
          font-weight: 700;
          line-height: 2rem;
          letter-spacing: -0.06rem;
          &.bluebox {
            border-color: #225298;
            color: #225298;
          }
          &.graybox {
            border-color: #575757;
            color: #575757;
          }
          &.redbox {
            border-color: #ed2115;
            color: #ed2115;
          }
          &.withdraw {
            border-color: #a1a1a1;
            color: #a1a1a1;
          }
        }
      }
    }
  }
`;
