import { BoxInner, TextBox } from "style/admin/layout";
import { UserBlockRequest } from "interface/admin/interface.user";
import { fetchUpdateUserBlock } from "api/admin/user";
import { useState } from "react";

interface ModalProps {
  closedHandler: () => void;
  userId: string;
}

const DateLimitLiftModal = ({ closedHandler, userId }: ModalProps) => {
  const [isLoading, setIsLoading] = useState(false);

  // ✅ 현재 시간 기준 14일 후의 날짜 계산
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + 14); // 14일 추가

  // ✅ YYYY-MM-DDTHH:MM:SS 형태로 변환
  const formattedExpDt = currentDate.toISOString(); // UTC 기준 ISO 형식 변환

  const handleClicked = async () => {
    setIsLoading(true);
    try {
      // ✅ 요청 데이터 생성
      const requestData: UserBlockRequest = {
        targetUserId: userId, // ✅ 이용 제한 대상 유저 ID
        type: "UNBLOCK", // ✅ "BLOCK" 또는 "UNBLOCK"
        reason: "이용 제한 해제", // ✅ 이용 제한 사유
        expDt: undefined,
      };

      // ✅ API 요청
      const response = await fetchUpdateUserBlock(requestData);
      if (response.result) {
        closedHandler();
      } // ✅ 성공 시 모달 닫기
    } catch (error) {
      console.error("🚨 이용 제한 처리 실패:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <BoxInner>
      {isLoading ? (
        <TextBox style={{ minHeight: "11rem" }}>
          <p>Loading...</p>
        </TextBox>
      ) : (
        <div className="inner">
          <TextBox style={{ minHeight: "auto" }}>
            <p>이 회원의 이용을 제한 해제 하시겠습니까?</p>
            <span>{`현재 일시 이용제한 중인 회원입니다.
제한해제일시 : ${formattedExpDt}

해제 소식이 회원에게 즉시 푸시 알림으로 발송됩니다.`}</span>
          </TextBox>
          <div className="btn-box">
            <button className="continue" onClick={closedHandler} type="button">
              돌아가기
            </button>
            <span></span>
            <button className="del" onClick={handleClicked}>
              제한하기
            </button>
          </div>
        </div>
      )}
    </BoxInner>
  );
};

export default DateLimitLiftModal;
