import styled from "styled-components";
import { AnimatePresence, motion } from "framer-motion";
import { ReactNode, useEffect } from "react";

interface ModalProps {
  onClick?: () => void;
  closedHandler: () => void;
  children?: ReactNode; // children prop 추가
  isVisible?: boolean; // 모달 표시 여부
  noneBtn?: boolean;
  btnTitle?: string;
  nonPadding?: boolean;
}

const ModalUpLayout = ({
  closedHandler,
  btnTitle = "닫기",
  children,
  isVisible,
  noneBtn,
  onClick,
  nonPadding,
}: ModalProps) => {
  // 모달 표시 상태에 따라 스크롤 제한
  useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = "hidden"; // 스크롤 비활성화
    } else {
      document.body.style.overflow = ""; // 스크롤 복원
    }

    // 컴포넌트 언마운트 시 overflow 복원
    return () => {
      document.body.style.overflow = "";
    };
  }, [isVisible]);
  return (
    <AnimatePresence>
      {isVisible && ( // 조건부 렌더링 추가
        <ModalContainer
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            duration: 0.3,
            type: "fade",
          }}
        >
          <div className="overlay"></div>
          <ModalInner
            initial={{ y: "100%" }}
            animate={{ y: "0%" }}
            exit={{ y: "100%" }}
            transition={{
              type: "spring",
              stiffness: 400,
              damping: 40,
            }}
            className={nonPadding ? "non-padding" : ""}
          >
            {children}
            {noneBtn ? (
              ""
            ) : (
              <div className="btn-box">
                <button onClick={closedHandler}>{btnTitle}</button>
              </div>
            )}
          </ModalInner>
        </ModalContainer>
      )}
    </AnimatePresence>
  );
};

export default ModalUpLayout;

const ModalContainer = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: "pointer";
  width: 100%;
  height: 100%;
  .overlay {
    display: flex;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
`;

const ModalInner = styled(motion.div)`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 99;
  background-color: white;
  width: 100%;
  border-radius: 1rem 1rem 0 0;
  overflow: hidden;
  padding: 1.6rem 1.6rem 4.5rem;
  &.non-padding {
    padding: 0;
  }
  .btn-box {
    button {
      width: 100%;
      border: 0;
      outline: 0;
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 2rem;
      letter-spacing: -0.06rem;
      padding: 1.8rem;
      border-radius: 0.5rem;
    }
  }
`;
