import { atom } from "recoil";

export const reviewHideState = atom<"Y" | "N">({
  key: "reviewHideState",
  default: "N",
});

export const reviewHideInfoState = atom<{
  reviewId: number;
  hideYn: "Y" | "N";
}>({
  key: "reviewHideInfoState",
  default: {
    reviewId: 0,
    hideYn: "N",
  },
});

export const reviewShowUpdateTrigger = atom<boolean>({
  key: "reviewShowUpdateTrigger",
  default: false,
});

export const bestReviewState = atom<"Y" | "N">({
  key: "bestReviewState",
  default: "N",
});

export const regUserIdState = atom<string | null>({
  key: "regUserIdState",
  default: null,
});
