import { isReviewModifyChangedState } from "atoms/review.state";
import { CancelIcon } from "cssReset/icon";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import styled from "styled-components";

interface ReviewHeaderProps {
  reviewTitle: string;
  closedPath?: string;
}

const ReviewRegistHeader = ({
  reviewTitle,
  closedPath = "/review",
}: ReviewHeaderProps) => {
  const navigate = useNavigate();
  const isReviewModifyChanged = useRecoilValue(isReviewModifyChangedState);

  const handleOnclick = () => {
    navigate(closedPath);
  };
  return (
    <HeaderContainer>
      <div className="icon-box" onClick={handleOnclick}>
        <CancelIcon />
      </div>
      <div className="header-title">
        <p>{reviewTitle}</p>
      </div>
      <div className="complete-btn-box">
        <button disabled={!isReviewModifyChanged}>완료</button>
      </div>
    </HeaderContainer>
  );
};

export default ReviewRegistHeader;

const HeaderContainer = styled.header`
  height: 5.6rem;
  display: grid;
  grid-template-columns: 5.6rem auto 5.6rem;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  background-color: white;
  width: 100%;
  z-index: 99;
  .icon-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  .header-title {
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      font-size: 1.6rem;
      font-weight: 500;
      letter-spacing: -0.06rem;
      line-height: 2rem;
      transform: translateY(1px);
    }
  }
  .complete-btn-box {
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      border: 0;
      outline: 0;
      font-size: 1.2rem;
      line-height: 1.6rem;
      font-weight: 400;
      padding: 0.4rem 0.8rem;
      background-color: #225298;
      color: white;
      border-radius: 0.5rem;
      &:disabled {
        background-color: #cecece;
      }
    }
  }
`;
