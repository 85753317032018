import { atom } from "recoil";

interface alramClickedStateProps {
  linkYn: boolean;
  commentId: number | null;
  targetType: string | null;
}

interface AllRemoveProps {
  isOpen: boolean; // 모달 열림 여부
  isVisible: boolean; // 모달 표시 여부
}

export const alramClickedState = atom<alramClickedStateProps>({
  key: "alramClickedState",
  default: {
    linkYn: false,
    commentId: null,
    targetType: null,
  },
});

export const allRemoveConfirmModalState = atom<AllRemoveProps>({
  key: "allRemoveConfirmModalState",
  default: {
    isOpen: false, // 모달 열림 여부
    isVisible: false, // 모달 표시 여부
  },
});
