import axiosInstance from "plugin/axios";

export const fetchLoadInquityList = async () => {
  try {
    const response = await axiosInstance.get(`/inquiry`);
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

export const fetchLoadMyInquiryList = async () => {
  try {
    const response = await axiosInstance.get(`/inquiry/my-inquiry`, {
      params: {
        orderBy: "REGDT",
        orderByDir: "DESC",
        pageSize: 1000,
        offset: 0,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

export const fetchRegistInquiry = async (data: FormData) => {
  try {
    const response = await axiosInstance.post(`/inquiry`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json;charset=UTF-8",
      },
    });

    return response.data;
  } catch (error) {
    throw new Error();
  }
};
