import { BackArrowIcon } from "cssReset/icon";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

interface JoinHeaderProps {
  onClick?: () => void;
  headerTitle: string;
}

const JoinHeader = ({ onClick, headerTitle }: JoinHeaderProps) => {
  const navigate = useNavigate();
  return (
    <HeaderContainer>
      <div className="icon-box" onClick={onClick}>
        <BackArrowIcon />
      </div>
      <div className="header-title">
        <p>{headerTitle}</p>
      </div>
    </HeaderContainer>
  );
};

export default JoinHeader;

const HeaderContainer = styled.header`
  height: 5.6rem;
  display: grid;
  grid-template-columns: 4.8rem auto;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  background-color: white;
  width: 100%;
  z-index: 99;
  .icon-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  .header-title {
    display: flex;
    align-items: center;
    p {
      font-size: 1.6rem;
      font-weight: 500;
      letter-spacing: -0.06rem;
      line-height: 2rem;
      transform: translateY(1px);
    }
  }
`;
