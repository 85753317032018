import { CancelSmIcon, CaptureIcon } from "cssReset/icon";
import { ChangeEvent, useEffect, useState } from "react";
import { UseFormRegister, UseFormSetValue } from "react-hook-form";
import styled from "styled-components";
import { ReviewFile } from "view/review/children/review.modify";

type AttachmentFile = File | ReviewFile;

interface FileUploadProps {
  name: string;
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  initialFiles?: AttachmentFile[]; // 초기 파일 목록
}

const ReviewFileAttachment = ({
  register,
  setValue,
  name,
  initialFiles = [],
}: FileUploadProps) => {
  // src url 배포시 수정
  const BASE_URL = ""; // 베이스 URL

  const [files, setFiles] = useState<AttachmentFile[]>(initialFiles); // File 또는 ReviewFile 관리
  const [previews, setPreviews] = useState<string[]>([]); // 초기에는 빈 배열

  console.log("files", files);
  console.log("initialFiles", initialFiles);

  // initialFiles 변경 시 files와 previews 업데이트
  useEffect(() => {
    if (!initialFiles || !Array.isArray(initialFiles)) return;

    const newPreviews = initialFiles.map((file) =>
      file instanceof File
        ? URL.createObjectURL(file)
        : file.accessUrl
        ? `${BASE_URL}${file.accessUrl}`
        : "/default-image.png"
    );

    if (JSON.stringify(previews) !== JSON.stringify(newPreviews)) {
      setFiles(initialFiles);
      setPreviews(newPreviews);
    }
  }, [JSON.stringify(initialFiles)]);

  useEffect(() => {
    setValue(name, files); // 초기 파일 목록 설정
  }, [files, name, setValue]);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files;
    if (selectedFiles) {
      const newFiles = Array.from(selectedFiles);

      // 최대 10개 파일 제한
      if (files.length + newFiles.length > 10) {
        alert("최대 10개의 파일만 첨부할 수 있습니다.");
        return;
      }

      const updatedFiles = [...files, ...newFiles];
      const updatedPreviews = [
        ...previews,
        ...newFiles.map((file) => URL.createObjectURL(file)),
      ];

      setFiles(updatedFiles);
      setPreviews(updatedPreviews);
      setValue(name, updatedFiles); // useForm에 파일 목록 설정
    }
  };

  const handleRemoveFile = (index: number) => {
    const updatedFiles = files.filter((_, i) => i !== index);
    const updatedPreviews = previews.filter((_, i) => i !== index);

    setFiles(updatedFiles);
    setPreviews(updatedPreviews);
    setValue(name, updatedFiles); // useForm에 업데이트된 파일 목록 반영
  };

  return (
    <FileAttachWrap>
      <PreviewContainer>
        {files.length > 0 && (
          <div className="preview-item-wrapper">
            {previews.map((preview, index) => (
              <div key={index} className="preview-item">
                <img src={preview} alt={`첨부된 파일 ${index + 1}`} />
                <button
                  type="button"
                  onClick={() => handleRemoveFile(index)}
                  className="remove-button"
                >
                  <CancelSmIcon />
                </button>
              </div>
            ))}
          </div>
        )}
      </PreviewContainer>
      <label className="file-attach-box">
        <div className="icon-box">
          <CaptureIcon />
        </div>
        <span>사진 첨부하기 (최대 10장)</span>
        <input
          type="file"
          multiple // 여러 파일 선택 가능
          accept="image/*" // 이미지 파일만 선택 가능
          onChange={(e) => {
            handleFileChange(e);
            register(name).onChange(e); // register의 onChange 핸들러 호출
          }}
        />
      </label>
    </FileAttachWrap>
  );
};

export default ReviewFileAttachment;

const FileAttachWrap = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  .file-attach-box {
    border-top: 1px solid #cecece;
    padding: 0.7rem 1.6rem 0.8rem;
    display: flex;
    align-items: center;
    gap: 0.4rem;
    .icon-box {
      display: flex;
    }
    span {
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 1.6rem;
    }
    input[type="file"] {
      display: none;
    }
  }
`;

const PreviewContainer = styled.div`
  .preview-item-wrapper {
    display: flex;
    align-items: center;
    padding: 1.6rem;
    overflow: scroll hidden;
    /* ( 크롬, 사파리, 오페라, 엣지 ) 동작 */
    &::-webkit-scrollbar {
      display: none;
    }
    .preview-item {
      width: 6rem;
      height: 6rem;
      padding: 0.4rem;
      position: relative;
      flex: 0 0 auto;
      img {
        max-width: 100%;
        overflow: hidden;
        object-fit: cover;
        border-radius: 0.5rem;
        width: 100%;
        height: 100%;
      }
      button {
        border: 0;
        outline: 0;
        width: 1.6rem;
        height: 1.6rem;
        background-color: transparent;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }
`;
