import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

const AndroidLoadingView = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // 안드로이드 로그인
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const accessToken = params.get("accessToken");
    const refreshToken = params.get("refreshToken");

    if (accessToken && refreshToken) {
      // ✅ 토큰 저장
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("refreshToken", refreshToken);
      // ✅ 인증 상태 업데이트
      localStorage.setItem(
        "authState",
        JSON.stringify({ isAuthenticated: true })
      );

      // ✅ URL 정리 (토큰 제거)
      navigate("/home", { replace: true });
    }
  }, []);

  return <LoadingWrap>loading...</LoadingWrap>;
};

export default AndroidLoadingView;

const LoadingWrap = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
