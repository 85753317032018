import { fetchLoadAdminInquiry } from "api/admin/inquiry";
import { useEffect, useState } from "react";
import { InquiryPenddingListBox } from "style/admin/inquiry";
import InquiryTable from "./inquiry.table";
import { InquiryParams } from "interface/admin/interface.inquiry";

interface ListProps {
  searchTerm: string;
  setDataLength: any;
}

const InquiryList = ({ searchTerm, setDataLength }: ListProps) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const loadNotAnswerInquiryList = async () => {
      setIsLoading(true); // ✅ 로딩 시작
      try {
        const params: InquiryParams = {
          keyword: searchTerm, // ✅ 검색어 반영
          orderBy: "REGDT",
          orderByDir: "DESC",
        };
        const response = await fetchLoadAdminInquiry(params);
        setData(response.result || []);
        setDataLength(response.result.length > 0);
      } catch (error) {
        console.error("데이터 로딩 실패:", error);
      } finally {
        setIsLoading(false); // ✅ 로딩 종료
      }
    };

    loadNotAnswerInquiryList();
  }, [searchTerm]); // ✅ `searchTerm`이 변경될 때마다 실행

  return (
    <InquiryPenddingListBox>
      {isLoading ? (
        <p>검색 중...</p> // ✅ 로딩 표시
      ) : (
        <InquiryTable data={data} />
      )}
    </InquiryPenddingListBox>
  );
};

export default InquiryList;
