import ReviewRegistHeader from "components/layout/review.regist.header";
import { Controller, useForm, useWatch } from "react-hook-form";
import { Container } from "style/join";
import { ReviewRegistContainer } from "style/review";
import TextareaAutosize from "react-textarea-autosize";
import { useEffect, useState } from "react";
import { useVirtualKeyboard } from "utils/custom.hook";
import { useNavigate, useParams } from "react-router-dom";
import ReviewFileAttachment from "components/review/review.file.attachment";
import { reviewDetailHandler, reviewModifyHandler } from "api/review";
import { fetchDeleteFileId, fetchRegistFile } from "api/files";
import { FileDto } from "interface/admin/interface.contents";
import { useSetRecoilState } from "recoil";
import { isReviewModifyChangedState } from "atoms/review.state";

interface ReviewFormData {
  reviewTitle: string; // 제목
  reviewContents: string; // 내용
  reviewFile: File[]; // 첨부된 파일 리스트
}

interface ReviewDetailProps {
  reviewId: number; // 리뷰 ID
  type: string; // 리뷰 타입 (예: "review")
  title: string; // 리뷰 제목
  contents: string; // 리뷰 내용
  views: number; // 조회수
  likeYn: string; // 좋아요 여부 ("Y" 또는 "N")
  reviewLikeCount: number; // 좋아요 수
  reviewCommentCount: number; // 댓글 수
  fileGroupId: string; // 파일 그룹 ID
  files: ReviewFile[]; // 첨부 파일 목록
  regDt: string; // 등록일 (ISO 8601 형식의 문자열)
  updtDt: string | null; // 업데이트일 (null 가능)
  useYn: string; // 사용 여부 ("Y" 또는 "N")
  regUserId: string; // 등록 사용자 ID
  userName: string; // 사용자 이름
  userProfileUrl: string | null; // 사용자 프로필 URL (null 가능)
}

export interface ReviewFile {
  fileId: number; // 파일 ID
  fileGroupId: string; // 파일 그룹 ID
  fileType: string; // 파일 타입 (예: "review")
  realFileName: string; // 실제 파일 이름
  fileSize: number; // 파일 크기 (바이트 단위)
  accessUrl: string; // 파일 접근 URL
  regDt: string; // 파일 등록일 (ISO 8601 형식의 문자열)
  regUserId: string; // 파일 등록 사용자 ID
  updtDt: string | null; // 파일 업데이트일 (null 가능)
}

const ReviewModify = () => {
  const { register, handleSubmit, control, setValue } =
    useForm<ReviewFormData>();

  const { id } = useParams<{ id: string }>();
  const [data, setData] = useState<ReviewDetailProps>();
  const [formFiles, setFormFiles] = useState<FileDto[]>([]);
  const fileGroupId = data?.fileGroupId;
  const [maxRows, setMaxRows] = useState(10); // 초기 maxRows 값
  const isKeyboardVisible = useVirtualKeyboard(); // 가상 키보드 상태 확인
  const navigate = useNavigate();
  const setIsReviewModifyChanged = useSetRecoilState(
    isReviewModifyChangedState
  );

  // 각 줄의 최대 글자 수와 최대 줄 수
  const MAX_ROWS = 2;
  const MAX_CHARS_PER_ROW = 24; // 한 줄에 들어갈 최대 글자 수 (폰트, 너비에 따라 다를 수 있음)
  const MAX_TOTAL_CHARS = MAX_ROWS * MAX_CHARS_PER_ROW;

  useEffect(() => {
    const fetchReviewDetail = async () => {
      try {
        if (!id) return; // id가 없으면 함수 실행 중단
        const response = await reviewDetailHandler(Number(id));

        setData(response.result);
        setFormFiles(response.result.files || []);
      } catch (error) {
        console.log(error);
      }
    };

    fetchReviewDetail();
  }, [id]);

  // data 상태가 변경될 때 setValue 호출
  useEffect(() => {
    if (data) {
      setValue("reviewTitle", data.title || "");
      setValue("reviewContents", data.contents || "");
    }
  }, [data, setValue]);

  const watchedFile = useWatch({ control, name: "reviewFile" });
  const watchedTitle = useWatch({ control, name: "reviewTitle" });
  const watchedContent = useWatch({ control, name: "reviewContents" });

  useEffect(() => {
    // ✅ 새 파일 감지 (File 객체만 필터링)
    const newFiles =
      watchedFile?.filter((file: any) => file instanceof File) || [];

    // ✅ 기존 파일 중에서 삭제된 파일 찾기
    const removedFiles = formFiles.filter(
      (file) =>
        !watchedFile?.some((newFile: any) => newFile.fileId === file.fileId)
    );

    // ✅ 변경 여부 확인
    const isFileChanged = newFiles.length > 0 || removedFiles.length > 0;
    const isTitleChanged = watchedTitle !== data?.title;
    const isContentsChanged = watchedContent !== data?.contents;

    setIsReviewModifyChanged(
      isFileChanged || isTitleChanged || isContentsChanged
    );
  }, [watchedFile, formFiles, watchedTitle, watchedContent, data]);

  // maxRows 업데이트
  useEffect(() => {
    if (isKeyboardVisible) {
      setMaxRows(5); // 키보드가 열리면 maxRows를 5로 줄임
    } else {
      // 키보드가 닫히면 화면 크기에 맞게 다시 계산
      const totalHeight = window.innerHeight;
      const headerHeight = 56;
      const footerHeight = 124;
      const titleHeight = 60;
      const titleMargin = 24 * 2;
      const availableHeight =
        totalHeight - headerHeight - footerHeight - titleHeight - titleMargin;

      const lineHeight = 16; // 한 줄 높이
      const calculatedRows = Math.floor(availableHeight / lineHeight);
      setMaxRows(calculatedRows);
    }
  }, [isKeyboardVisible]);

  const onSubmit = async (data: ReviewFormData) => {
    try {
      let isFileChanged = false;

      // ✅ 새로운 파일이 있는지 확인 (이전 코드 수정)
      const newFiles =
        data.reviewFile?.filter(
          (file: any) => file instanceof File && file.name && file.size
        ) || [];

      // ✅ 기존 파일이 있는 경우 삭제된 파일 찾기
      const removedFiles = formFiles.filter(
        (file) =>
          !data.reviewFile?.some(
            (newFile: any) => newFile.fileId === file.fileId
          )
      );

      // ✅ 파일 변경 여부 확인
      if (newFiles.length > 0 || removedFiles.length > 0) {
        isFileChanged = true;
      }

      // ✅ 기존 파일 삭제 (변경된 경우에만 실행)
      if (isFileChanged && removedFiles.length > 0) {
        for (const file of removedFiles) {
          if ("fileId" in file) {
            console.log(`🗑️ 삭제 요청 파일:`, file);
            const deleteResponse = await fetchDeleteFileId(file.fileId);
            console.log(`✅ 파일 삭제 완료:`, deleteResponse);
          }
        }
      }

      // ✅ 새 파일 등록 (변경된 경우에만 실행)
      if (isFileChanged && newFiles.length > 0) {
        await fetchRegistFile({
          fileGroupId: fileGroupId || "", // 기존 파일 그룹 ID 유지
          fileType: "banner",
          files: newFiles,
        });
      } else {
        console.log("🟢 새 파일 업로드 없음");
      }

      const requestData = {
        title: data.reviewTitle,
        contents: data.reviewContents,
      };

      const response = await reviewModifyHandler(Number(id), requestData);

      if (response.result) {
        navigate(`/review/detail/${id}`);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ReviewRegistHeader
          reviewTitle="후기 수정하기"
          closedPath={`/review/detail/${id}`}
        />
        <ReviewRegistContainer>
          <div className="title-box">
            <Controller
              name="reviewTitle"
              control={control}
              rules={{ required: "제목은 필수입니다." }}
              render={({ field, fieldState }) => (
                <>
                  <TextareaAutosize
                    {...field}
                    minRows={1}
                    maxRows={2}
                    placeholder="제목을 입력해주세요."
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault(); // 엔터 키 입력 방지
                      }
                    }}
                    onChange={(e) => {
                      const sanitizedValue = e.target.value.replace(/\n/g, ""); // 줄바꿈 제거
                      if (sanitizedValue.length <= MAX_TOTAL_CHARS) {
                        field.onChange(sanitizedValue); // 제한된 길이 내에서 업데이트
                      }
                    }}
                  />
                  {fieldState.error && (
                    <span style={{ color: "red" }}>
                      {fieldState.error.message}
                    </span>
                  )}
                </>
              )}
            />
          </div>
          <div className="contents-box">
            <Controller
              name="reviewContents"
              control={control}
              rules={{ required: "제목은 필수입니다." }}
              render={({ field, fieldState }) => (
                <>
                  <TextareaAutosize
                    {...field}
                    minRows={5}
                    maxRows={maxRows}
                    placeholder={`RS101과 함께한 층간소음 극복 후기를 나눠보세요. \n\n첨부한 이미지는 글 하단에 표시됩니다. \n(최대 10개, 개당 최대 10MB)`}
                  />
                  {fieldState.error && (
                    <span style={{ color: "red" }}>
                      {fieldState.error.message}
                    </span>
                  )}
                </>
              )}
            />
          </div>
        </ReviewRegistContainer>
        <ReviewFileAttachment
          name="reviewFile"
          register={register}
          setValue={setValue}
          initialFiles={data?.files || []} // ReviewFile[] 그대로 전달
        />
      </form>
    </Container>
  );
};

export default ReviewModify;
