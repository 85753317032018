import {
  FooterPopupParams,
  FooterPopupRegistParams,
  OrderUpdateList,
  PopupOrderUpdateList,
  UpdateParams,
} from "interface/admin/interface.contents";
import axiosInstance from "plugin/axios";

export const fetchLoadAdminFooterPopupList = async (
  params: FooterPopupParams
) => {
  try {
    const response = await axiosInstance.get(`/admin/popup`, { params });
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

export const fetchLoadFooterPopupDetail = async (id: number) => {
  try {
    const response = await axiosInstance.get(`/admin/popup/${id}`);
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

export const fetchUpdateFooterPopup = async (
  id: number,
  data: UpdateParams
) => {
  try {
    const response = await axiosInstance.put(`/admin/popup/${id}`, data);
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

export const fetchDeleteFooterPopup = async (id: number) => {
  try {
    const response = await axiosInstance.delete(`/admin/popup/${id}`);
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

export const fetchRegistFooterPopup = async (data: FooterPopupRegistParams) => {
  try {
    const formData = new FormData();

    // ✅ 공지사항 데이터 추가 (객체는 JSON 문자열로 변환)
    formData.append(
      "data",
      JSON.stringify({
        title: data.title,
        contents: data.contents,
        order: data.order,
      })
    );

    // ✅ 파일이 있는 경우 추가
    if (data.files && data.files.length > 0) {
      data.files.forEach((file) => {
        formData.append("files", file);
      });
    }

    const response = await axiosInstance.post(`/admin/popup`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

export const fetchFooterPopupSortUpdate = async (
  sortedBannerList: PopupOrderUpdateList
) => {
  try {
    console.log(sortedBannerList);
    const response = await axiosInstance.put(
      "/admin/popup/update-order",
      sortedBannerList
    );
    return response.data;
  } catch (error) {
    throw new Error();
  }
};
