import { useNavigate } from "react-router-dom";
import { BoxInner, TextBox } from "style/admin/layout";

interface ModalProps {
  closedHandler: () => void;
}

const FooterPopupDetailUpdateCompleteModal = ({
  closedHandler,
}: ModalProps) => {
  const navigate = useNavigate();
  const clickHandler = () => {
    closedHandler();
    navigate(`/admin/footerpopup`);
  };
  return (
    <BoxInner>
      <TextBox style={{ minHeight: "auto" }}>
        <p>배너가 수정되었습니다.</p>
        <span>확인을 클릭하면 배너 리스트로 돌아갑니다.</span>
      </TextBox>
      <div className="btn-box">
        <button className="continue" onClick={clickHandler}>
          확인
        </button>
      </div>
    </BoxInner>
  );
};

export default FooterPopupDetailUpdateCompleteModal;
