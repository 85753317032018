import { forgotPasswordHandler } from "api/join";
import { PasswordInput } from "components/pieces/input";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  ErrorMessage,
  InputWarpper,
  JoinDescription,
  LengthCount,
  NextBtn,
} from "style/join";
import styled from "styled-components";

const ForgotPasswordBox = ({
  resetToken,
  email,
}: {
  resetToken: string | null;
  email: string | null;
}) => {
  const {
    register,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);

  const password = watch("password", ""); // 비밀번호 실시간 감시
  const confirmPassword = watch("confirmPassword", ""); // 비밀번호 확인 실시간 감시

  const validatePassword = () => {
    if (!password) {
      setError("password", {
        type: "required",
        message: "비밀번호는 필수 입력입니다.",
      });
      setIsButtonEnabled(false);
      return false;
    }
    if (password.length < 8 || password.length > 20) {
      setError("password", {
        type: "length",
        message: "비밀번호는 8~20 자로 입력해주세요.",
      });
      setIsButtonEnabled(false);
      return false;
    }
    if (
      !/[A-Za-z]/.test(password) ||
      !/\d/.test(password) ||
      !/[!@#$%^&*]/.test(password)
    ) {
      setError("password", {
        type: "format",
        message: "문자, 숫자, 특수문자를 포함해야 합니다.",
      });
      setIsButtonEnabled(false);
      return false;
    }
    clearErrors("password");
    return true;
  };

  const validateConfirmPassword = () => {
    if (password !== confirmPassword) {
      setError("confirmPassword", {
        type: "match",
        message: "비밀번호가 일치하지 않습니다.",
      });
      setIsButtonEnabled(false);
      return false;
    }
    clearErrors("confirmPassword");
    return true;
  };

  useEffect(() => {
    // 비밀번호와 비밀번호 확인이 모두 유효하면 버튼 활성화
    const isPasswordValid = validatePassword();
    const isConfirmPasswordValid = validateConfirmPassword();
    setIsButtonEnabled(isPasswordValid && isConfirmPasswordValid);
  }, [password, confirmPassword]); // password와 confirmPassword가 변경될 때마다 실행

  const onSubmit = async (data: any) => {
    try {
      const requestData = {
        userId: email ?? "",
        newPassword: data.password,
        token: resetToken ?? "",
      };
      const response = await forgotPasswordHandler(requestData);
      if (response.result) {
        navigate("/");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container>
      <JoinDescription>
        <p>새로운 비밀번호를 설정해주세요.</p>
        <span>문자, 숫자, 특수문자를 조합하여 8~20 자로 입력해주세요.</span>
      </JoinDescription>

      <form onSubmit={handleSubmit(onSubmit)}>
        <InputWarpper>
          <div style={{ position: "relative" }}>
            <PasswordInput
              name="password"
              placeholder="비밀번호"
              register={register}
              validation={{
                required: "비밀번호는 필수 입력입니다.",
              }}
              onBlur={validatePassword} // 입력 종료 시 검증
              onChange={validatePassword} // 입력 중 실시간 검증
            />
            {errors.password && (
              <ErrorMessage>{String(errors.password.message)}</ErrorMessage>
            )}
            <LengthCount
              style={{ color: password.length > 20 ? "#ed2115" : "#a1a1a1" }}
            >
              {`${password.length} / 20`}
            </LengthCount>
          </div>
          <div style={{ position: "relative" }}>
            <PasswordInput
              name="confirmPassword"
              placeholder="비밀번호 확인"
              register={register}
              validation={{
                required: "비밀번호 확인은 필수 입력입니다.",
              }}
              onBlur={validateConfirmPassword} // 입력 종료 시 검증
              onChange={validateConfirmPassword} // 입력 중 실시간 검증
            />
            {errors.confirmPassword && (
              <ErrorMessage>
                {String(errors.confirmPassword.message)}
              </ErrorMessage>
            )}
            <LengthCount
              style={{
                color: confirmPassword.length > 20 ? "#ed2115" : "#a1a1a1",
              }}
            >
              {`${confirmPassword.length} / 20`}
            </LengthCount>
          </div>
        </InputWarpper>
        <NextBtn type="submit" disabled={!isButtonEnabled}>
          완료
        </NextBtn>
      </form>
    </Container>
  );
};

export default ForgotPasswordBox;

const Container = styled.div``;
