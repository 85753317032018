import { fetchUpdateBestReview } from "api/admin/review";
import { BoxInner, TextBox } from "style/admin/layout";

interface ModalProps {
  closedHandler: () => void;
  reviewId: number;
}

const modalTxt = `베스트 후기로 지정합니다.

[베스트 후기] 버튼을 눌러주세요.`;

const BestReviewUpdateModal = ({ closedHandler, reviewId }: ModalProps) => {
  const updateBestReview = async () => {
    try {
      const response = await fetchUpdateBestReview(reviewId);
      console.log(response);
      if (response.result) closedHandler();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <BoxInner>
      <TextBox style={{ minHeight: "auto" }}>
        <p>베스트 후기로 지정하시겠습니까?</p>
        <span>{modalTxt}</span>
      </TextBox>
      <div className="btn-box">
        <button className="continue" onClick={closedHandler} type="button">
          돌아가기
        </button>
        <span></span>
        <button className="del" onClick={updateBestReview}>
          베스트 후기 지정하기
        </button>
      </div>
    </BoxInner>
  );
};

export default BestReviewUpdateModal;
