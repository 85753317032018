import { reviewCommentModify, reviewCommentRegist } from "api/review";
import { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import styled from "styled-components";
import TextareaAutosize from "react-textarea-autosize";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  reviewCommentBeforeUpdateValue,
  reviewCommentUpdateState,
} from "atoms/modal.state";

interface ReviewCommentFormData {
  reviewComment: string;
}

interface ReviewCommentBoxProps {
  reviewId: string | undefined;
  commentParentId: number | null;
  triggerUpdate: () => void;
}

// ref의 타입을 명시적으로 정의
const ReviewCommentUpdateInput = forwardRef<
  { focus: () => void }, // ref 객체에 추가할 커스텀 메서드 타입
  ReviewCommentBoxProps
>((props, ref) => {
  const { handleSubmit, reset, setValue, control } =
    useForm<ReviewCommentFormData>();

  const commentBeforeUpdateValue = useRecoilValue(
    reviewCommentBeforeUpdateValue
  );
  const setCommentUpdateState = useSetRecoilState(reviewCommentUpdateState);
  const reviewCommentValue = useWatch({ control, name: "reviewComment" });

  const textareaRef = useRef<HTMLTextAreaElement | null>(null); // 🔹 textarea 참조 생성

  useEffect(() => {
    setValue("reviewComment", commentBeforeUpdateValue.commentContnets ?? "");
    textareaRef.current?.focus();
  }, [commentBeforeUpdateValue.commentId]);

  useImperativeHandle(ref, () => ({
    focus: () => {
      const textarea = document.querySelector<HTMLTextAreaElement>(
        'textarea[name="reviewComment"]'
      );
      textarea?.focus();
    },
  }));

  // 각 줄의 최대 글자 수와 최대 줄 수
  const MAX_ROWS = 5;
  const MAX_CHARS_PER_ROW = 24; // 한 줄에 들어갈 최대 글자 수 (폰트, 너비에 따라 다를 수 있음)
  const MAX_TOTAL_CHARS = MAX_ROWS * MAX_CHARS_PER_ROW;

  const onSubmit = async (data: any) => {
    console.log(data);
    if (!props) {
      console.error("Review ID is missing");
      return;
    }

    try {
      await reviewCommentModify(
        Number(commentBeforeUpdateValue.commentId),
        data.reviewComment
      ); // JSON 데이터 전송

      setCommentUpdateState(false);
      reset({ reviewComment: "" }); // ✅ 명확한 초기값 전달
      props.triggerUpdate();
    } catch (err) {
      console.error("Error submitting comment:", err);
    }
  };

  return (
    <RegistInputWrap>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="reviewComment"
          control={control}
          rules={{ required: "제목은 필수입니다." }}
          render={({ field }) => (
            <TextareaAutosize
              {...field}
              ref={textareaRef}
              minRows={1}
              maxRows={5}
              placeholder="제목을 입력해주세요."
              onChange={(e) => {
                const sanitizedValue = e.target.value;
                if (sanitizedValue.length <= MAX_TOTAL_CHARS) {
                  field.onChange(sanitizedValue); // 제한된 길이 내에서 업데이트
                }
              }}
            />
          )}
        />
        <input
          type="submit"
          value="수정"
          className={reviewCommentValue ? "isActive" : ""}
          disabled={reviewCommentValue ? false : true}
        />
      </form>
    </RegistInputWrap>
  );
});

export default ReviewCommentUpdateInput;

const RegistInputWrap = styled.div`
  form {
    padding: 0.8rem 1.6rem;
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    display: grid;
    grid-template-columns: auto 3.7rem;
    align-items: flex-end;
    gap: 0.8rem;
    background-color: white;
    z-index: 11;
    padding-bottom: 2.1rem;
    input[type="text"] {
      background-color: #ececec;
      border: 0;
      outline: 0;
      border-radius: 0.5rem;
      padding: 0.4rem;
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 1.6rem;
    }
    textarea {
      background-color: #ececec;
      border: 0;
      outline: 0;
      border-radius: 0.5rem;
      padding: 0.4rem;
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 1.6rem;
      resize: none;
    }
    input[type="submit"] {
      border: 0;
      outline: 0;
      border-radius: 0.5rem;
      padding: 0.4rem 0.8rem;
      color: white;
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 1.6rem;
      background-color: #cecece;
      &.isActive {
        background-color: #225298;
      }
    }
  }
  @media screen and (min-width: 785px) {
    position: fixed;
    bottom: 0;
    width: 37.3rem;
    form {
      position: static;
    }
  }
`;
