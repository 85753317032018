import { googleCodeHandler } from "api/login";
import { loginModalState } from "atoms/modal.state";
import ModalCenterLayout from "components/modal/modal.center.layout";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { closeModal } from "utils/modal.util";
import LoginAlertModal from "view/auth/modal/login.alert.modal";

// 삭제 예정

const GoogleRedirectPage = () => {
  const navigate = useNavigate();
  const [isFetching, setIsFetching] = useState(false);
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code") || "";
  const [loginModal, setLoginModal] = useRecoilState(loginModalState);
  const [modalTxt, setModalTxt] = useState<string | null>(null);

  useEffect(() => {
    const googleLogin = async () => {
      if (isFetching) return;
      setIsFetching(true);

      let fcmToken: string | undefined;
      let isAndroid: boolean = false;

      // ✅ iOS WebView인 경우에만 토큰 요청
      if (window.webkit?.messageHandlers?.getFcmToken) {
        try {
          const tokenPromise = new Promise<string>((resolve) => {
            const handleToken = (event: any) => {
              if (event.detail?.token) {
                console.log("✅ 받은 FCM 토큰:", event.detail.token);
                window.removeEventListener("fcm-token", handleToken);
                resolve(event.detail.token);
              }
            };
            window.addEventListener("fcm-token", handleToken);
          });

          // ✅ iOS에게 토큰 요청
          window.webkit.messageHandlers.getFcmToken.postMessage("request");
          fcmToken = await tokenPromise;
          console.log(fcmToken);
        } catch (error) {
          console.warn("🔥 FCM 토큰 요청 중 오류 발생:", error);
        }
      }

      // ✅ 그 외 플랫폼은 localStorage에서 FCM 토큰 시도
      try {
        const state = searchParams.get("state"); // 예: "android|eLIXur..."
        // 분리
        const [platform, androidFcmToken] = state?.split("|") || [];
        if (androidFcmToken) {
          console.log("✅ 로컬스토리지에서 FCM 토큰 가져옴:", androidFcmToken);
          isAndroid = true;
          fcmToken = androidFcmToken;
          localStorage.removeItem("fcmToken");
        } else {
          console.warn("⚠️ 로컬스토리지에 FCM 토큰 없음");
        }
      } catch (error) {
        console.warn("🔥 FCM 토큰 로딩 중 오류:", error);
      }

      try {
        const androidState = isAndroid ? "Y" : "N";
        const response = await googleCodeHandler(code, fcmToken, androidState);

        const { accessToken, refreshToken } = response.result;

        const state = searchParams.get("state");
        const [platform, androidFcmToken] = state?.split("|") || [];

        if (platform === "android") {
          // ✅ 앱으로 돌아가는 딥링크 처리
          window.location.href = `sombat://auth/google?accessToken=${accessToken}&refreshToken=${refreshToken}`;
        }

        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("refreshToken", refreshToken);

        // 로컬 스토리지에 사용자 유형 저장
        localStorage.setItem(
          "authState",
          JSON.stringify({ isAuthenticated: true })
        );

        navigate("/android/loading");
      } catch (error: any) {
        if (error.response?.status === 409) {
          console.warn("⚠️ 이미 존재하는 사용자입니다.");
          const serverMgs = error.response.data?.message;

          if (serverMgs === "is not kakao user.") {
            console.warn("⚠️ 이미 가입된 이메일 주소입니다.");
            setModalTxt("⚠️ 이미 가입된 이메일 주소입니다.");
            setLoginModal({ isOpen: true, isVisible: true });
          } else if (serverMgs === "already deleted user.") {
            console.warn("⚠️ 탈퇴한 회원입니다.");
            setModalTxt("⚠️ 탈퇴한 회원입니다.");
            setLoginModal({ isOpen: true, isVisible: true });
          } else {
            console.warn("⚠️ 기타 409 충돌:", error.response.data?.message);
          }
        } else {
          console.error(
            "구글 로그인 에러:",
            error.response?.data || error.message
          );
          navigate("/android/loading");
        }
      } finally {
        setIsFetching(false);
      }
    };

    googleLogin();
  }, []);

  return (
    <LoadingWrap>
      loading...
      {loginModal.isOpen && (
        <ModalCenterLayout
          closedHandler={() => {
            closeModal(setLoginModal);
          }}
          isVisible={loginModal.isVisible}
        >
          <LoginAlertModal txt={modalTxt} />
        </ModalCenterLayout>
      )}
    </LoadingWrap>
  );
};

export default GoogleRedirectPage;

const LoadingWrap = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
