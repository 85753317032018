import {
  inquiryHeaderBtnState,
  inquiryHeaderTypeState,
} from "atoms/inquiry.state";
import { BackArrowIcon } from "cssReset/icon";
import { useRecoilValue } from "recoil";
import styled from "styled-components";

interface Props {
  onClick?: () => void;
  onSubmit: () => void; // 🔥 여기서 함수 타입으로 설정
}

const BackArrowInquiryHeader = ({ onClick, onSubmit }: Props) => {
  const inquiryTypeValue = useRecoilValue(inquiryHeaderTypeState);
  const inquiryHeaderBtnStatus = useRecoilValue(inquiryHeaderBtnState);

  const btnStatus =
    inquiryHeaderBtnStatus.watchContents && inquiryHeaderBtnStatus.watchTitle;

  return (
    <HeaderContainer
      className={inquiryTypeValue.centerMode ? "inquiry-type" : ""}
    >
      <div className="icon-box" onClick={onClick}>
        <BackArrowIcon />
      </div>
      <div className="header-title">
        <p>{inquiryTypeValue.centerMode ? "문의하기" : "1:1 문의"}</p>
      </div>
      {inquiryTypeValue.centerMode && (
        <div className="inquiry-header-var">
          {inquiryTypeValue.btnMode && (
            <button disabled={!btnStatus} onClick={onSubmit}>
              등록
            </button>
          )}
        </div>
      )}
    </HeaderContainer>
  );
};

export default BackArrowInquiryHeader;

const HeaderContainer = styled.header`
  height: 5.6rem;
  display: grid;
  grid-template-columns: 4.8rem auto;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  background-color: white;
  width: 100%;
  z-index: 99;
  .icon-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    svg {
      width: 2.4rem;
      height: 2.4rem;
    }
  }
  .header-title {
    display: flex;
    align-items: center;
    p {
      font-size: 1.6rem;
      font-weight: 500;
      letter-spacing: -0.06rem;
      line-height: 2rem;
      transform: translateY(1px);
    }
  }
  .review-detail-btn-box {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    display: flex;
    .icon-box {
      display: flex;
      width: 4.8rem;
      align-items: center;
      justify-content: center;
      svg {
        width: 2.4rem;
        height: 2.4rem;
      }
    }
  }
  .inquiry-header-var {
    button {
      border: none;
      outline: none;
      color: white;
      background-color: #225298;
      border-radius: 0.5rem;
      padding: 0.8rem;
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 1.6rem;
      font-family: "Pretendard", sans-serif;
      &:disabled {
        background-color: #cecece;
      }
    }
  }
  &.inquiry-type {
    grid-template-columns: 4.8rem auto 4.8rem;
    .header-title {
      justify-content: center;
    }
  }
`;
