import {
  reviewDetailHandler,
  reviewDetailImgFileSearchHandler,
  reviewViewCountHandler,
} from "api/review";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  ContentsBox,
  ReviewDetailContainer,
  SliderWrapper,
  SubContentsBox,
  UserInfoBox,
} from "style/review";
import { formatDateTime, formatNumber } from "utils/util";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { LeftArrowIcon, RightArrowIcon, ShareIcon } from "cssReset/icon";
import ReviewLike from "components/review/review.like";
import ReviewCommentBox from "components/review/reveiw.comment.box";
import ReviewCommentRegistInput from "components/review/review.comment.regist.input";
import ModalUpLayout from "components/modal/modal.up.layout";
import ModalShareBox from "./modal/modal.share.box";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  reviewCommentUpdateState,
  reviewDeleteConfirmModalState,
  reviewEditModalState,
  reviewInformationState,
  snsModalState,
} from "atoms/modal.state";
import ModalReviewEditBox from "./modal/modal.review.edit.box";
import { ReviewDetailProps } from "interface/interface.review";
import ModalCenterLayout from "components/modal/modal.center.layout";
import ModalReviewDeleteConfirmBox from "./modal/modal.review.delete.confirm.box";
import ReviewCommentUpdateInput from "components/review/review.comment.update.input";

const ReviewDetail = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [data, setData] = useState<ReviewDetailProps | null>(null);
  const [currentSlide, setCurrentSlide] = useState(1); // 현재 슬라이드 번호
  const [commentParentId, setCommentParentId] = useState<number | null>(0);
  const inputRef = useRef<HTMLInputElement>(null);
  const [updateTrigger, setUpdateTrigger] = useState(false); // 업데이트 트리거 추가

  const [snsModal, setSnsModal] = useRecoilState(snsModalState);
  const [reviewEditModal, setReviewEditModal] =
    useRecoilState(reviewEditModalState);
  const [reviewDeleteModal, setReviewDeleteModal] = useRecoilState(
    reviewDeleteConfirmModalState
  );
  const setReviewInfoState = useSetRecoilState(reviewInformationState);
  const commentUpdateState = useRecoilValue(reviewCommentUpdateState);

  useEffect(() => {
    // 페이지 로드 시 모달 상태 초기화
    setSnsModal({ isOpen: false, isVisible: false });
    setReviewEditModal({ isOpen: false, isVisible: false });
  }, [setSnsModal, setReviewEditModal]);

  useEffect(() => {
    setReviewInfoState({
      reviewRegUserId: data?.regUserId ?? null,
      blockUserName: data?.userName ?? null,
      reviewId: data?.reviewId ?? null,
    });
  });

  useEffect(() => {
    const fetchReviewViewsCount = async () => {
      try {
        await reviewViewCountHandler(Number(id));
      } catch (err) {
        console.log(err);
      }
    };

    const fetchReviewDetail = async () => {
      try {
        if (!id) return; // id가 없으면 함수 실행 중단
        const response = await reviewDetailHandler(Number(id));

        if (!response?.result) {
          // id가 유효하지 않으면 404 페이지로 이동
          navigate("/404");
          return;
        }

        setData(response.result);

        // 파일 그룹 ID로 이미지 데이터 가져오기
        if (response.result.fileGroupId) {
          await reviewDetailImgFileSearchHandler(response.result.fileGroupId);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchReviewViewsCount();
    fetchReviewDetail();
  }, [id, navigate]);

  const regDate = formatDateTime(data?.regDt || "");

  const sliderSetting = {
    infinite: data?.files && data.files.length > 1, // data와 files가 존재할 경우에만 확인
    speed: 500,
    slidesToShow: 1, // 3개 이하일 경우 슬라이드를 하나만 보여줌
    rows: 1, // 4개 이상일 경우에만 3개 행을 보여줌
    slidesToScroll: 1,
    prevArrow: <LeftArrowIcon color="#000" />, // 이전 버튼에 아이콘 추가
    nextArrow: <RightArrowIcon color="#000" />, // 다음 버튼에 아이콘 추가
    afterChange: (current: number) => setCurrentSlide(current + 1), // 현재 슬라이드 번호 업데이트
  };

  const focusInput = () => {
    inputRef.current?.focus();
  };

  const triggerUpdate = () => {
    setUpdateTrigger((prev) => !prev); // 상태 변경으로 `useEffect` 재실행
  };

  // sns modla
  const handleModalClose = () => {
    setSnsModal({ isOpen: false, isVisible: false });
  };
  const handleModalOpen = () => {
    setSnsModal({ isOpen: true, isVisible: true });
  };
  // review edit modal
  const handleReviewEditModalClose = () => {
    setReviewEditModal({ isOpen: false, isVisible: false });
  };
  // review delete modal
  const handleReviewDeleteConfirmModalOpen = () => {
    setReviewEditModal({ isOpen: false, isVisible: false });
    setReviewDeleteModal({ isOpen: true, isVisible: true });
  };
  const handleReivewDeleteModalClose = () => {
    setReviewDeleteModal({ isOpen: false, isVisible: false });
  };

  return (
    <ReviewDetailContainer>
      <UserInfoBox>
        <div className="profile-img">
          <img src="" alt="" />
        </div>
        <div className="info">
          <span className="user-name">{data?.userName}</span>
          <span className="date">{regDate}</span>
        </div>
      </UserInfoBox>
      <ContentsBox>
        <h4 className="title">{data?.title}</h4>
        <p className="contents-text">{data?.contents}</p>
        {data?.files && data.files.length > 0 ? (
          <SliderWrapper>
            <div className="inner">
              <Slider {...sliderSetting}>
                {data.files.map((img, index) => (
                  <div className="img-box" key={img.fileId}>
                    {/* src url 배포시 수정 */}
                    <img src={img.accessUrl} alt={img.realFileName} />
                  </div>
                ))}
              </Slider>
            </div>
            {/* 현재 슬라이드 위치 표시 */}
            <div className="slide-counter">
              {currentSlide} / {data.files.length}
            </div>
          </SliderWrapper>
        ) : (
          ""
        )}
        <SubContentsBox>
          <div className="review-count">
            <span>조회</span>
            {formatNumber(Number(data?.views))}
          </div>
          <div className="icon-box share-btn" onClick={handleModalOpen}>
            <ShareIcon />
          </div>
          <ReviewLike
            reviewId={id}
            reviewLikeCount={data?.reviewLikeCount}
            likeYn={data?.likeYn}
          />
        </SubContentsBox>
      </ContentsBox>
      {id && (
        <ReviewCommentBox
          reviewId={id}
          regUserId={data?.regUserId}
          setCommentParentId={setCommentParentId}
          focusInput={focusInput} // input 포커스 함수 전달
          updateTrigger={updateTrigger}
        />
      )}
      {id && (
        <>
          {/* 수정 버튼 클릭 시 수정으로 변경 */}
          {commentUpdateState ? (
            <ReviewCommentUpdateInput
              reviewId={id}
              commentParentId={commentParentId}
              ref={inputRef} // input에 ref 연결
              triggerUpdate={triggerUpdate}
            />
          ) : (
            <ReviewCommentRegistInput
              reviewId={id}
              commentParentId={commentParentId}
              ref={inputRef} // input에 ref 연결
              triggerUpdate={triggerUpdate}
            />
          )}
        </>
      )}
      {/* 공유하기 모달 */}
      {snsModal.isOpen && (
        <ModalUpLayout
          closedHandler={handleModalClose}
          isVisible={snsModal.isVisible}
        >
          <ModalShareBox />
        </ModalUpLayout>
      )}
      {/* 수정/삭제 모달 */}
      {reviewEditModal.isOpen && (
        <ModalUpLayout
          closedHandler={handleReviewEditModalClose}
          isVisible={reviewEditModal.isVisible}
        >
          <ModalReviewEditBox
            reviewId={id}
            handleReviewDeleteConfirmModalOpen={
              handleReviewDeleteConfirmModalOpen
            }
          />
        </ModalUpLayout>
      )}
      {/* 리뷰 삭제 모달 */}
      {reviewDeleteModal.isOpen && (
        <ModalCenterLayout
          closedHandler={handleReivewDeleteModalClose}
          isVisible={reviewDeleteModal.isVisible}
        >
          <ModalReviewDeleteConfirmBox
            reviewId={id}
            closedHandler={handleReivewDeleteModalClose}
          />
        </ModalCenterLayout>
      )}
    </ReviewDetailContainer>
  );
};

export default ReviewDetail;
