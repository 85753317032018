import {
  BannerParams,
  BannerRegistParams,
  OrderUpdateList,
  UpdateParams,
} from "interface/admin/interface.contents";
import axiosInstance from "plugin/axios";

export const fetchLoadAdminBanner = async (params: BannerParams) => {
  try {
    const response = await axiosInstance.get(`/admin/banner`, { params });
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

export const fetchLoadBannerDetail = async (bannerId: number) => {
  try {
    const response = await axiosInstance.get(`/admin/banner/${bannerId}`);
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

export const fetchBannerUpdate = async (
  bannerId: number,
  params: UpdateParams
) => {
  try {
    const response = await axiosInstance.put(
      `/admin/banner/${bannerId}`,
      params
    );
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

export const fetchDeleteBanner = async (bannerId: number) => {
  try {
    const response = await axiosInstance.delete(`/admin/banner/${bannerId}`);
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

export const fetchRegistBanner = async (data: BannerRegistParams) => {
  try {
    const formData = new FormData();

    // ✅ 공지사항 데이터 추가 (객체는 JSON 문자열로 변환)
    formData.append(
      "data",
      JSON.stringify({
        title: data.title,
        contents: data.contents,
        order: data.order,
      })
    );

    // ✅ 파일이 있는 경우 추가
    if (data.files && data.files.length > 0) {
      data.files.forEach((file) => {
        formData.append("files", file);
      });
    }

    const response = await axiosInstance.post(`/admin/banner`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

export const fetchBannerSortUpdate = async (
  sortedBannerList: OrderUpdateList
) => {
  try {
    const response = await axiosInstance.put(
      "/admin/banner/update-order",
      sortedBannerList
    );
    return response.data;
  } catch (error) {
    throw new Error();
  }
};
