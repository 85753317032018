import axiosInstance from "plugin/axios";

export const fetchBlockUserList = async ({
  pageSize,
  offset,
}: {
  pageSize: number;
  offset: number;
}) => {
  try {
    const response = await axiosInstance.get(`/block`, {
      params: {
        pageSize: pageSize,
        offset: offset,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

export const fetchBlockUserDelete = async (blockUserId: string) => {
  try {
    const response = await axiosInstance.delete(`/block`, {
      params: {
        blockUserId: blockUserId,
      },
    });

    return response.data;
  } catch (error) {
    throw new Error();
  }
};
