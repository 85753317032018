import { Controller, useForm, useWatch } from "react-hook-form";
import { NoticeRegistContainer } from "style/admin/contents";
import TextareaAutosize from "react-textarea-autosize";
import AdminNoticeAttachment from "components/admin/contents/notice/admin.notice.attachment";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  isNoticeRegistChangedState,
  noticeRegistAwayModalState,
  noticeRegistCompleteModalState,
  noticeRegistSubmitTriggerState,
} from "atoms/admin/contents/notice.state";
import { useEffect } from "react";
import { fetchNoticeRegist } from "api/admin/constent.notice";
import AdminCommonModalLayout from "components/admin/common.modal.layout/admin.common.modal";
import NoticeRegistCompleteModal from "components/admin/contents/notice/modal/notice.regist.complete.modal";
import NoticeRegistAwayModal from "components/admin/contents/notice/modal/notice.regist.away.modal";

const AdminNoticeRegist = () => {
  const { register, handleSubmit, control, setValue } = useForm();
  const [noticeSubmitTrigger, setNoticeSubmitTrigger] = useRecoilState(
    noticeRegistSubmitTriggerState
  );
  const [noticeRegistCompleteModal, setNoticeRegistCompleteModal] =
    useRecoilState(noticeRegistCompleteModalState);
  const [noticeAwayModal, setNoticeAwayModal] = useRecoilState(
    noticeRegistAwayModalState
  );
  const setIsNoticeChanged = useSetRecoilState(isNoticeRegistChangedState);

  const watchedTitle = useWatch({ control, name: "title" });
  const watchedContents = useWatch({ control, name: "contents" });
  const watchedFiles = useWatch({ control, name: "noticeFiles" });

  useEffect(() => {
    // ✅ 새 파일 감지 (File 객체만 필터링)
    const newFiles =
      watchedFiles?.filter((file: any) => file instanceof File) || [];

    // ✅ 제목과 내용은 둘 다 입력되어야 함
    const isTitleValid = !!watchedTitle && watchedTitle.trim() !== "";
    const isContentsValid = !!watchedContents && watchedContents.trim() !== "";

    // ✅ 제목과 내용이 모두 입력되었을 때만 변경 여부 판단
    const isTextChanged = isTitleValid && isContentsValid;

    // ✅ 파일은 없어도 괜찮음, 추가된 경우만 변경됨
    const isFileChanged = newFiles.length > 0;

    // ✅ 제목과 내용이 입력된 상태에서 파일이 추가되거나 텍스트가 변경되었을 때만 변경 감지
    setIsNoticeChanged(isTextChanged && (isFileChanged || isTextChanged));
  }, [watchedFiles, watchedTitle, watchedContents]);

  const onSubmit = async (data: any) => {
    console.log(data);
    try {
      const requestData = {
        title: data.title,
        contents: data.contents,
        files: data.noticeFiles?.filter((file: any) => file instanceof File),
      };

      const response = await fetchNoticeRegist(requestData);
      if (response.result) {
        setNoticeRegistCompleteModal({ isOpen: true, isVisible: true });
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (noticeSubmitTrigger) {
      handleSubmit(onSubmit)();
      setNoticeSubmitTrigger(false);
    }
  }, [noticeSubmitTrigger, handleSubmit]);

  // modal handler
  const noticeCompleteModalClosed = () => {
    setNoticeRegistCompleteModal({ isOpen: false, isVisible: false });
  };
  const noticeAwayModalClosed = () => {
    setNoticeAwayModal({ isOpen: false, isVisible: false });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <NoticeRegistContainer>
        <div className="title-box">
          <input
            type="text"
            {...register("title")}
            placeholder="제목을 입력하세요."
          />
          <span></span>
        </div>
        <div className="contents-box">
          <Controller
            name="contents"
            control={control}
            rules={{ required: "제목은 필수입니다." }}
            render={({ field }) => (
              <TextareaAutosize
                {...field}
                minRows={17}
                maxRows={17}
                placeholder="내용을 입력하세요."
              />
            )}
          />
          <span></span>
        </div>
        <div className="file-box">
          <AdminNoticeAttachment
            name="noticeFiles"
            register={register}
            setValue={setValue}
            initialFiles={[]} // ReviewFile[] 그대로 전달
          />
        </div>

        {noticeRegistCompleteModal && (
          <AdminCommonModalLayout
            isVisible={noticeRegistCompleteModal.isVisible}
            closedHandler={noticeCompleteModalClosed}
          >
            <NoticeRegistCompleteModal
              closedHandler={noticeCompleteModalClosed}
            />
          </AdminCommonModalLayout>
        )}

        {noticeAwayModal && (
          <AdminCommonModalLayout
            isVisible={noticeAwayModal.isVisible}
            closedHandler={noticeAwayModalClosed}
          >
            <NoticeRegistAwayModal closedHandler={noticeAwayModalClosed} />
          </AdminCommonModalLayout>
        )}
      </NoticeRegistContainer>
    </form>
  );
};

export default AdminNoticeRegist;
