import { CheckIconX24 } from "cssReset/icon";
import styled from "styled-components";

interface OrderByModalProps {
  orderByState: string;
  modalClosed: () => void;
  orderByClickHandler: (orderBy: string) => void;
}

const ModalReviewListOrderByModal = ({
  orderByState,
  modalClosed,
  orderByClickHandler,
}: OrderByModalProps) => {
  console.log(orderByState);
  const selectHandler = (orderBy: string) => {
    orderByClickHandler(orderBy);
    modalClosed();
  };
  return (
    <BoxInner>
      <ShareItemBox>
        <li
          onClick={() => {
            selectHandler("REGDT");
          }}
        >
          최신순
          <span className="icon-box">
            <CheckIconX24
              color={orderByState === "REGDT" ? "black" : "#ECECEC"}
            />
          </span>
        </li>
        <li
          onClick={() => {
            selectHandler("LIKES");
          }}
        >
          좋아요순
          <span className="icon-box">
            <CheckIconX24
              color={orderByState !== "REGDT" ? "black" : "#ECECEC"}
            />
          </span>
        </li>
      </ShareItemBox>
    </BoxInner>
  );
};

export default ModalReviewListOrderByModal;

const BoxInner = styled.div``;

const ShareItemBox = styled.ul`
  li {
    display: flex;
    justify-content: center;
    padding: 1.6rem 1rem;
    gap: 1rem;
    align-items: center;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: -0.06rem;
    border-bottom: 1px solid #ececec;
    position: relative;
    .icon-box {
      display: flex;
      width: 2.4rem;
      height: 2.4rem;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      img,
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
`;
