import { fetchBlockUserDelete } from "api/block.user";
import { BlockUserDto } from "interface/interface.block.user";
import styled from "styled-components";
import { formatDateTime } from "utils/util";

interface BlockUserProps {
  blockUserDt: BlockUserDto;
  handleListTrigger: () => void;
  loadToast: (userName: string) => void;
}
const BlockUserList = ({
  blockUserDt,
  handleListTrigger,
  loadToast,
}: BlockUserProps) => {
  const cancelBlockUser = async (blockUserId: string) => {
    try {
      const response = await fetchBlockUserDelete(blockUserId);
      if (response.result) {
        handleListTrigger();
        loadToast(blockUserDt.blockUserName);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <BlockUserListBox>
      <div className="l-box">
        <div className="profile-img-box">
          {blockUserDt.blockUserProfileUrl === null ? (
            <img src="/assets/icon/userprofile.png" alt="default" />
          ) : (
            <img src={blockUserDt.blockUserProfileUrl} alt="profileImg" />
          )}
        </div>
        <div className="profile-box">
          <p>{blockUserDt.blockUserName}</p>
          <span>
            <b>차단일</b> {formatDateTime(blockUserDt.regDt)}
          </span>
        </div>
      </div>
      <div className="r-box">
        <button
          onClick={() => {
            cancelBlockUser(blockUserDt.blockUserId);
          }}
        >
          차단 해제
        </button>
      </div>
    </BlockUserListBox>
  );
};

export default BlockUserList;

const BlockUserListBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.6rem 0.8rem 1.5rem;
  border-bottom: 1px solid #cecece;
  gap: 0.8rem;
  .l-box {
    display: flex;
    gap: 0.8rem;
    align-items: center;
    .profile-img-box {
      width: 4rem;
      height: 4rem;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .profile-box {
      display: flex;
      flex-direction: column;
      gap: 0.4rem;
      p {
        font-size: 1.4rem;
        font-weight: 700;
        line-height: 1.8rem;
        letter-spacing: -0.06rem;
      }
      span {
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 1.6rem;
        color: #575757;
        b {
          font-weight: 400;
          color: #242629;
        }
      }
    }
  }
  .r-box {
    button {
      border: none;
      outline: none;
      border-radius: 0.5rem;
      padding: 0.4rem 0.95rem;
      background-color: #225298;
      color: white;
      font-family: "Pretendard", sans-serif;
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 1.6rem;
    }
  }
`;
