import axiosInstance from "plugin/axios";

export const fetchLoadBannerList = async () => {
  try {
    const response = await axiosInstance.get(`/home/banner`, {
      params: {
        orderBy: "REGDT",
        orderByDir: "DESC",
        pageSize: 10,
        offset: 0,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

export const fetchLoadBestReview = async () => {
  try {
    const response = await axiosInstance.get(`/home/best-review`, {
      params: {
        orderBy: "REGDT",
        orderByDir: "DESC",
        pageSize: 10,
        offset: 0,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

// contents
export const fetchLoadColumn = async () => {
  try {
    const response = await axiosInstance.get(`/contents`);
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

export const fetchLoadColumnDetail = async (id: number) => {
  try {
    const response = await axiosInstance.get(`/contents/${id}`);
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

export const fetchLoadPopup = async () => {
  try {
    const params = {
      orderBy: "REGDT",
      orderByDir: "DESC",
    };
    const response = await axiosInstance.get(`/home/popup`, {
      params,
    });
    return response.data;
  } catch (error) {
    throw new Error();
  }
};
