import { fetchBlockUserList } from "api/block.user";
import BlockUserList from "components/setting/block.user/block.user.list";
import { BlockUserDto } from "interface/interface.block.user";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { Slide, ToastContainer, toast } from "react-toastify";

const BlockUserManage = () => {
  const [blockUserDt, setBlockUserDt] = useState<BlockUserDto[]>([]);
  const [listTrigger, setListTrigger] = useState(false);

  useEffect(() => {
    const loadBlockUserList = async () => {
      try {
        const response = await fetchBlockUserList({
          pageSize: 10,
          offset: 0,
        });

        if (response.result) {
          setBlockUserDt(response.result);
        }
        console.log(response);
      } catch (error) {
        console.log(error);
      }
    };

    loadBlockUserList();
  }, [listTrigger]);

  const handleListTrigger = () => {
    setListTrigger((prev) => !prev);
  };

  const loadToast = (userName: string) => {
    toast(`${userName}의 차단을 해제하였습니다.`, {
      position: "bottom-center",
      style: {
        backgroundColor: "rgba(0,0,0,0.83)",
        color: "#fff",
        fontSize: "1.2rem",
        borderRadius: "1rem",
        fontWeight: "500",
        letterSpacing: "-0.06rem",
        justifyContent: "center",
        padding: "0.8rem",
        minHeight: "auto",
        boxShadow: "1px 1px 4px 0 #0000004D",
      },
      transition: Slide,
    });
  };

  return (
    <BlockUserContainer>
      {Array.isArray(blockUserDt) && blockUserDt.length > 0 ? (
        <BlockUserListWrap>
          {blockUserDt.map((item, index) => (
            <BlockUserList
              key={index}
              blockUserDt={item}
              handleListTrigger={handleListTrigger}
              loadToast={loadToast}
            />
          ))}
        </BlockUserListWrap>
      ) : (
        <BlockUserListWrap>
          <div className="none-block-user">
            <p>차단한 사용자가 없습니다.</p>
          </div>
        </BlockUserListWrap>
      )}
      <ToastContainer
        hideProgressBar
        closeButton={false}
        autoClose={1000}
        style={{
          padding: "1.6rem 3.2rem 4.7rem",
          borderRadius: "1rem",
        }}
      />
    </BlockUserContainer>
  );
};

export default BlockUserManage;

const BlockUserContainer = styled.div`
  padding: 1.6rem;
`;

const BlockUserListWrap = styled.div`
  .none-block-user {
    padding: 4rem 0;
    text-align: center;
    p {
      font-size: 1.4rem;
      font-weight: 700;
      line-height: 2.4rem;
    }
  }
`;
