import {
  fetchBannerUpdate,
  fetchLoadBannerDetail,
} from "api/admin/contents.banner";
import { fetchDeleteFileId, fetchRegistFile } from "api/files";
import {
  bannerAwayModalState,
  bannerDetailCompleteModalState,
  bannerSubmitTriggerState,
  isBannerChangedState,
} from "atoms/admin/contents/banner.state";
import AdminCommonModalLayout from "components/admin/common.modal.layout/admin.common.modal";
import AdminBannerAttachment from "components/admin/contents/banner/admin.banner.attechment";
import BannerDetailAwayModal from "components/admin/contents/banner/modal/banner.detail.away.modal";
import BannerDetailUpdateCompleteModal from "components/admin/contents/banner/modal/banner.detail.update.complete.modal";
import { AdminBannerDto, FileDto } from "interface/admin/interface.contents";
import { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { BannerDetailContainer } from "style/admin/contents";

const AdminBannerDetail = () => {
  const { id } = useParams();
  const [data, setData] = useState<AdminBannerDto>();
  const [formFiles, setFormFiles] = useState<FileDto[]>([]); // ✅ 기존 파일 저장
  const { register, setValue, handleSubmit, control } = useForm();

  const [bannerSubmitTrigger, setBannerSubmitTrigger] = useRecoilState(
    bannerSubmitTriggerState
  );
  const setIsBannerChanged = useSetRecoilState(isBannerChangedState); // ✅ 변경 감지 상태 추가
  const [bannerCompleteModal, setBannerCompleteModal] = useRecoilState(
    bannerDetailCompleteModalState
  );
  const [bannerAwayModal, setBannerAwayModal] =
    useRecoilState(bannerAwayModalState);
  const bannerId = data?.bannerId;
  const order = data?.order;
  const fileGroupId = data?.fileGroupId;

  const watchedPageLink = useWatch({ control, name: "pageLink" });
  const [currentFiles, setCurrentFiles] = useState<FileDto[]>([]);

  // ✅ 배너 상세 정보 불러오기
  const loadBannerDetail = async () => {
    try {
      const response = await fetchLoadBannerDetail(Number(id));
      if (response.result) {
        setData(response.result);
        setFormFiles(response.result.files || []); // ✅ 기존 파일 저장
        setCurrentFiles(response.result.files || []);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    loadBannerDetail();
  }, [id]);

  useEffect(() => {
    if (data) setValue("pageLink", data.contents);
  }, [data, setValue]);

  const watchedReviewFile = useWatch({ control, name: "reviewFile" });

  useEffect(() => {
    // ✅ 새 파일 목록 가져오기
    const newFiles =
      watchedReviewFile?.filter((file: any) => file instanceof File) || [];

    // ✅ 기존 파일과 비교하여 변경 여부 확인
    const isFileChanged =
      newFiles.length > 0 ||
      JSON.stringify(formFiles) !== JSON.stringify(data?.files);

    // ✅ 페이지 링크 변경 여부 확인
    const isPageLinkChanged = watchedPageLink !== data?.contents;

    // ✅ 변경 여부 업데이트 (파일 변경 || 페이지 링크 변경)
    setIsBannerChanged(isFileChanged || isPageLinkChanged);
  }, [watchedReviewFile, formFiles, watchedPageLink, data]);

  const onSubmit = async (data: any) => {
    try {
      let newFileGroupId = fileGroupId; // 기본값: 기존 파일 그룹 ID 유지
      let isFileChanged = false;

      // ✅ 기존 파일과 새 파일 비교 → 변경 여부 확인
      const newFiles =
        data.reviewFile?.filter((file: any) => file instanceof File) || [];
      if (newFiles.length > 0 || formFiles.length === 0) {
        isFileChanged = true; // 새 파일이 추가되었거나 기존 파일이 없으면 변경됨
      }

      // ✅ 변경된 경우에만 기존 파일 삭제
      if (isFileChanged) {
        for (const file of formFiles) {
          if ("fileId" in file) {
            await fetchDeleteFileId(file.fileId);
          }
        }
        console.log("파일 삭제 완료");
      }

      // ✅ 변경된 경우에만 새 파일 등록
      if (isFileChanged && newFiles.length > 0) {
        const uploadResponse = await fetchRegistFile({
          fileGroupId: fileGroupId || "", // 기존 파일 그룹 ID 유지
          fileType: "banner",
          files: newFiles,
        });

        if (uploadResponse.result) {
          newFileGroupId = uploadResponse.result.fileGroupId;
        }
        console.log("파일 등록 완료");
      }

      // ✅ 배너 내용 업데이트
      const params = {
        title: "test",
        contents: data.pageLink,
        order: Number(order),
      };

      const response = await fetchBannerUpdate(Number(bannerId), params);

      if (response.result) {
        setBannerCompleteModal({ isOpen: true, isVisible: true });
        await loadBannerDetail();
        setIsBannerChanged(false); // ✅ 적용 후 변경 없음 상태로 설정
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (bannerSubmitTrigger) {
      handleSubmit(onSubmit)(); // `onSubmit` 실행
      setBannerSubmitTrigger(false); // 상태 초기화 (중복 실행 방지)
    }
  }, [bannerSubmitTrigger, handleSubmit]);

  // modal handler
  const bannerCompleteModalClosed = () => {
    setBannerCompleteModal({ isOpen: false, isVisible: false });
  };
  const bannerAwayModalClosed = () => {
    setBannerAwayModal({ isOpen: false, isVisible: false });
  };

  return (
    <BannerDetailContainer>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="tbox">
          <p>배너 이미지</p>
          <span>가로 375, 세로 152 픽셀 / 파일형식 PNG, JPG</span>
          <AdminBannerAttachment
            name="reviewFile"
            register={register}
            setValue={setValue}
            initialFiles={data?.files || []} // ReviewFile[] 그대로 전달
            contents="banner"
          />
        </div>
        <div className="bbox">
          <p>배너와 연결된 페이지 링크</p>
        </div>
        <div className="input-box">
          <input type="text" {...register("pageLink")} />
        </div>
      </form>

      {bannerCompleteModal && (
        <AdminCommonModalLayout
          isVisible={bannerCompleteModal.isVisible}
          closedHandler={bannerCompleteModalClosed}
        >
          <BannerDetailUpdateCompleteModal
            closedHandler={bannerCompleteModalClosed}
          />
        </AdminCommonModalLayout>
      )}

      {bannerAwayModal && (
        <AdminCommonModalLayout
          isVisible={bannerAwayModal.isVisible}
          closedHandler={bannerAwayModalClosed}
        >
          <BannerDetailAwayModal closedHandler={bannerAwayModalClosed} />
        </AdminCommonModalLayout>
      )}
    </BannerDetailContainer>
  );
};

export default AdminBannerDetail;
