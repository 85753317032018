import {
  fetchLoadAdminNoticeDetail,
  fetchUpdateNotice,
} from "api/admin/constent.notice";
import { useEffect, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useParams } from "react-router-dom";
import { NoticeModifyContainer } from "style/admin/contents";
import TextareaAutosize from "react-textarea-autosize";
import { NoticeDto } from "interface/interface.notice";
import AdminNoticeAttachment from "components/admin/contents/notice/admin.notice.attachment";
import { fetchDeleteFileId, fetchRegistFile } from "api/files";
import { FileDto } from "interface/admin/interface.contents";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  isNoticeChangedState,
  noticeAwayModalState,
  noticeSubmitTriggerState,
  noticeUpdateCompleteModalState,
} from "atoms/admin/contents/notice.state";
import AdminCommonModalLayout from "components/admin/common.modal.layout/admin.common.modal";
import NoticeUpdateCompleteModal from "components/admin/contents/notice/modal/notice.update.complete.modal";
import NoticeUpdateAwayModal from "components/admin/contents/notice/modal/notice.update.away.modal";

const AdminNoticeModify = () => {
  const { register, setValue, handleSubmit, control } = useForm();
  const [data, setData] = useState<NoticeDto>();
  const [formFiles, setFormFiles] = useState<FileDto[]>([]); // ✅ 기존 파일 저장
  const { id } = useParams();
  const fileGroupId = data?.fileGroupId;
  const noticeId = data?.announcementId;
  const [currentFiles, setCurrentFiles] = useState<FileDto[]>([]); // ✅ 현재 유지해야 하는 파일 추적

  const [noticeSubmitTrigger, setNoticeSubmitTrigger] = useRecoilState(
    noticeSubmitTriggerState
  );
  const [noticeUpdateCompleteModal, setNoticeUpdateCompleteModal] =
    useRecoilState(noticeUpdateCompleteModalState);
  const [noticeAwayModal, setNoticeAwayModal] =
    useRecoilState(noticeAwayModalState);
  const setIsNoticeChanged = useSetRecoilState(isNoticeChangedState);

  const loadAdminNoticeDetail = async () => {
    try {
      const response = await fetchLoadAdminNoticeDetail(Number(id));
      setData(response.result);
      setValue("title", response.result?.title);
      setValue("contents", response.result?.contents);
      setFormFiles(response.result.files || []); // ✅ 기존 파일 저장
      setCurrentFiles(response.result.files || []); // ✅ 현재 유지해야 하는 파일 초기값 설정
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    loadAdminNoticeDetail();
  }, [id]);

  const watchedFile = useWatch({ control, name: "noticeFiles" });
  const watchedTitle = useWatch({ control, name: "title" });
  const watchedContent = useWatch({ control, name: "contents" });

  useEffect(() => {
    // ✅ 새 파일 감지 (File 객체만 필터링)
    const newFiles =
      watchedFile?.filter((file: any) => file instanceof File) || [];

    // ✅ 기존 파일 중에서 삭제된 파일 찾기
    const removedFiles = formFiles.filter(
      (file) =>
        !watchedFile?.some((newFile: any) => newFile.fileId === file.fileId)
    );

    // ✅ 변경 여부 확인
    const isFileChanged = newFiles.length > 0 || removedFiles.length > 0;
    const isTitleChanged = watchedTitle !== data?.title;
    const isContentsChanged = watchedContent !== data?.contents;

    setIsNoticeChanged(isFileChanged || isTitleChanged || isContentsChanged);
  }, [watchedFile, formFiles, watchedTitle, watchedContent, data]);

  const onSubmit = async (data: any) => {
    try {
      let isFileChanged = false;

      // ✅ 새로운 파일이 있는지 확인 (이전 코드 수정)
      const newFiles =
        data.noticeFiles?.filter(
          (file: any) => file instanceof File && file.name && file.size
        ) || [];

      // ✅ 기존 파일이 있는 경우 삭제된 파일 찾기
      const removedFiles = formFiles.filter(
        (file) =>
          !data.noticeFiles?.some(
            (newFile: any) => newFile.fileId === file.fileId
          )
      );

      // ✅ 파일 변경 여부 확인
      if (newFiles.length > 0 || removedFiles.length > 0) {
        isFileChanged = true;
      }

      // ✅ 기존 파일 삭제 (변경된 경우에만 실행)
      if (isFileChanged && removedFiles.length > 0) {
        for (const file of removedFiles) {
          if ("fileId" in file) {
            console.log(`🗑️ 삭제 요청 파일:`, file);
            const deleteResponse = await fetchDeleteFileId(file.fileId);
            console.log(`✅ 파일 삭제 완료:`, deleteResponse);
          }
        }
      }

      // ✅ 새 파일 등록 (변경된 경우에만 실행)
      if (isFileChanged && newFiles.length > 0) {
        await fetchRegistFile({
          fileGroupId: fileGroupId || "", // 기존 파일 그룹 ID 유지
          fileType: "banner",
          files: newFiles,
        });
      } else {
        console.log("🟢 새 파일 업로드 없음");
      }

      const params = {
        title: data.title,
        contents: data.contents,
      };

      const response = await fetchUpdateNotice(Number(noticeId), params);
      if (response.result) {
        setNoticeUpdateCompleteModal({ isOpen: true, isVisible: true });
        setIsNoticeChanged(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (noticeSubmitTrigger) {
      handleSubmit(onSubmit)();
      setNoticeSubmitTrigger(false);
    }
  }, [noticeSubmitTrigger, handleSubmit]);

  // modal handler
  const noticeCompleteModalClosed = () => {
    setNoticeUpdateCompleteModal({ isOpen: false, isVisible: false });
  };
  const noticeAwayModalClosed = () => {
    setNoticeAwayModal({ isOpen: false, isVisible: false });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <NoticeModifyContainer>
        <div className="title-box">
          <input type="text" {...register("title")} />
          <span></span>
        </div>
        <div className="contents-box">
          <Controller
            name="contents"
            control={control}
            rules={{ required: "제목은 필수입니다." }}
            render={({ field }) => (
              <TextareaAutosize {...field} minRows={17} maxRows={17} />
            )}
          />
          <span></span>
        </div>
        <div className="img-box">
          <AdminNoticeAttachment
            name="noticeFiles"
            register={register}
            setValue={setValue}
            initialFiles={data?.files || []} // ReviewFile[] 그대로 전달
          />
        </div>

        {noticeUpdateCompleteModal && (
          <AdminCommonModalLayout
            isVisible={noticeUpdateCompleteModal.isVisible}
            closedHandler={noticeCompleteModalClosed}
          >
            <NoticeUpdateCompleteModal
              closedHandler={noticeCompleteModalClosed}
            />
          </AdminCommonModalLayout>
        )}

        {noticeAwayModal && (
          <AdminCommonModalLayout
            isVisible={noticeAwayModal.isVisible}
            closedHandler={noticeAwayModalClosed}
          >
            <NoticeUpdateAwayModal closedHandler={noticeAwayModalClosed} />
          </AdminCommonModalLayout>
        )}
      </NoticeModifyContainer>
    </form>
  );
};

export default AdminNoticeModify;
