import { fetchLoadMyInquiryList, fetchRegistInquiry } from "api/inquiry";
import {
  inquiryCancelModalState,
  inquiryConfirmModalState,
  inquiryHeaderBtnState,
} from "atoms/inquiry.state";
import InquiryRegist from "components/inquiry/inquiry.regist";
import InquiryList from "components/inquiry/inquity.list";
import InquiryType from "components/inquiry/inquity.type";
import BackArrowInquiryHeader from "components/layout/back.arrow.inquity.header";
import ModalCenterLayout from "components/modal/modal.center.layout";
import { AnimatePresence } from "framer-motion";
import { InquiryDTO } from "interface/interface.inquiry";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { InquiryContainer, Step } from "style/inquiry";
import CancelModal from "./children/modal/cancel.modal";
import ConfirmModal from "./children/modal/confirm.modal";

const Inquiry = () => {
  const [inquiryDt, setInquiryDt] = useState<InquiryDTO[]>([]);
  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    setError,
    formState: { errors },
    clearErrors,
    reset,
  } = useForm();
  const navigate = useNavigate();

  const [step, setStep] = useState(0);
  const [inquiryHeaderBtnStatus, setInquiryHeaderBtnStatus] = useRecoilState(
    inquiryHeaderBtnState
  );

  const [cancelModalStatus, setCancleModalStatus] = useRecoilState(
    inquiryCancelModalState
  );
  const [confirmModalStatus, setConfirmModalStatus] = useRecoilState(
    inquiryConfirmModalState
  );

  const loadInquiryList = async () => {
    try {
      //const response = await fetchLoadInquityList();
      const response = await fetchLoadMyInquiryList();
      setInquiryDt(response.result);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    loadInquiryList();
  }, []);

  const onSubmit = async (data: any) => {
    try {
      const formData = new FormData();

      formData.append(
        "data",
        JSON.stringify({
          title: data.title,
          contents: data.contents,
          inquiryType: data.inquiryType,
        })
      );

      // 파일 데이터 추가 (여러 파일 처리)
      if (data.reviewFile && data.reviewFile.length > 0) {
        data.reviewFile.forEach((file: any) => {
          formData.append("files", file);
        });
      }

      const response = await fetchRegistInquiry(formData);
      if (response.result) {
        setInquiryHeaderBtnStatus({ watchContents: false, watchTitle: false });
        loadInquiryList();
        handleConfirmModalOpened();
        reset();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleNext = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const backArrowHandler = () => {
    if (step === 0) {
      navigate("/setting");
    } else {
      if (
        inquiryHeaderBtnStatus.watchTitle ||
        inquiryHeaderBtnStatus.watchContents
      ) {
        console.log("hi");
        handleCancelModalOpened();
      } else {
        reset();
        setInquiryHeaderBtnStatus({ watchContents: false, watchTitle: false });
        setStep((prevStep) => prevStep - 1);
      }
    }
  };

  // confirm modal
  const handleConfirmModalClosed = () => {
    setConfirmModalStatus({ isOpen: false, isVisible: false });
    setStep(0);
  };
  const handleConfirmModalOpened = () => {
    setConfirmModalStatus({ isOpen: true, isVisible: true });
  };

  // cancel modal
  const handleCancelModalClosed = () => {
    setCancleModalStatus({ isOpen: false, isVisible: false });
  };
  const handleCancelModalOpened = () => {
    setCancleModalStatus({ isOpen: true, isVisible: true });
  };
  const writeCancelHandler = () => {
    reset();
    setInquiryHeaderBtnStatus({ watchContents: false, watchTitle: false });
    setStep(0);
    handleCancelModalClosed();
  };

  return (
    <InquiryContainer>
      <BackArrowInquiryHeader
        onClick={backArrowHandler}
        onSubmit={handleSubmit(onSubmit)} // 🔥 `handleSubmit`을 실행한 결과 함수를 전달
      />
      <AnimatePresence mode="wait" initial={false}>
        {step === 0 ? (
          <Step
            key={0}
            initial={{ opacity: 0, y: 20 }} // 초기 상태 (살짝 오른쪽에서 시작)
            animate={{ opacity: 1, y: 0 }} // 나타날 때
            exit={{ opacity: 0, y: -20 }} // 사라질 때 (왼쪽으로 이동하며 사라짐)
            transition={{ duration: 0.3 }} // 애니메이션 속도
          >
            <InquiryList data={inquiryDt} handleNext={handleNext} />
          </Step>
        ) : null}
        <form onSubmit={handleSubmit(onSubmit)}>
          {step === 1 ? (
            <Step
              key={1}
              initial={{ opacity: 0, y: 20 }} // 초기 상태 (살짝 오른쪽에서 시작)
              animate={{ opacity: 1, y: 0 }} // 나타날 때
              exit={{ opacity: 0, y: -20 }} // 사라질 때 (왼쪽으로 이동하며 사라짐)
              transition={{ duration: 0.3 }} // 애니메이션 속도
            >
              <InquiryType
                handleNext={handleNext}
                register={register}
                watch={watch}
              />
            </Step>
          ) : null}
          {step === 2 ? (
            <Step
              key={2}
              initial={{ opacity: 0, y: 20 }} // 초기 상태 (살짝 오른쪽에서 시작)
              animate={{ opacity: 1, y: 0 }} // 나타날 때
              exit={{ opacity: 0, y: -20 }} // 사라질 때 (왼쪽으로 이동하며 사라짐)
              transition={{ duration: 0.3 }} // 애니메이션 속도
            >
              <InquiryRegist
                register={register}
                control={control}
                setValue={setValue}
                watch={watch}
                setError={setError}
                errors={errors}
                clearErrors={clearErrors}
                reset={reset}
              />
            </Step>
          ) : null}
          {step > 2 ? null : null} {/* 🚀 `undefined` 방지! */}
        </form>
      </AnimatePresence>

      {/* 캔슬 모달 */}
      {cancelModalStatus.isOpen ? (
        <ModalCenterLayout
          closedHandler={handleCancelModalClosed}
          isVisible={cancelModalStatus.isVisible}
        >
          <CancelModal
            closedHandler={handleCancelModalClosed}
            writeCancelHandler={writeCancelHandler}
          />
        </ModalCenterLayout>
      ) : null}

      {/* 확인 모달 */}
      {confirmModalStatus.isOpen ? (
        <ModalCenterLayout
          closedHandler={handleConfirmModalClosed}
          isVisible={confirmModalStatus.isVisible}
        >
          <ConfirmModal closedHandler={handleConfirmModalClosed} />
        </ModalCenterLayout>
      ) : null}
    </InquiryContainer>
  );
};

export default Inquiry;
