import { MeasureDataRequest, MeasureParams } from "interface/interface.measure";
import axiosInstance from "plugin/axios";

export const fetchLoadMeasureResultList = async (params: MeasureParams) => {
  try {
    const response = await axiosInstance.get(`/measure-result`, { params });
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

export const fetchMeasureDataRegist = async (formData: FormData) => {
  try {
    const response = await axiosInstance.post(
      `/measure-result/simple`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json;charset=UTF-8",
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

export const fetchLoadMeasureRecordResult = async () => {
  try {
    const response = await axiosInstance.get(`/measure-result/result`);
    return response.data;
  } catch (error) {
    throw new Error();
  }
};
