import * as lamejs from "lamejs";

export const formatDateTime = (datetime: string) => {
  const date = new Date(datetime);

  // 원하는 형식으로 반환
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // 월은 0부터 시작
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${year}.${month}.${day} ${hours}:${minutes}`;
};

export const formatDateTimeAddSec = (datetime: string) => {
  const date = new Date(datetime);

  // 원하는 형식으로 반환
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // 월은 0부터 시작
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0"); // ✅ 초 추가

  return `${year}.${month}.${day} ${hours}:${minutes}:${seconds}`;
};

export const formatDateTimeAddSecYear = (datetime: string) => {
  const date = new Date(datetime);

  // 원하는 형식으로 반환
  const year = String(date.getFullYear()).slice(-2); // 마지막 두 자리만 가져오기
  const month = String(date.getMonth() + 1).padStart(2, "0"); // 월은 0부터 시작
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0"); // ✅ 초 추가

  return `${year}.${month}.${day} ${hours}:${minutes}:${seconds}`;
};

export const formatDateTimeAddSecUDTVer = (datetime: string) => {
  const date = new Date(datetime); // ✅ KST 값 그대로 사용

  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, "0");
  const day = String(date.getUTCDate()).padStart(2, "0");
  const hours = String(date.getUTCHours()).padStart(2, "0");
  const minutes = String(date.getUTCMinutes()).padStart(2, "0");
  const seconds = String(date.getUTCSeconds()).padStart(2, "0");

  return `${year}.${month}.${day} ${hours}:${minutes}:${seconds}`;
};

export const formatDateTimeMTime = (datetime: string) => {
  const date = new Date(datetime);

  // 원하는 형식으로 반환
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // 월은 0부터 시작
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}.${month}.${day}`;
};

export const formatNumber = (number: number) => {
  return number.toLocaleString("en-US");
};

// 불러온 날짜가 오늘과 매칭 되면 오늘 리턴
export const formatDateTimeToToday = (datetime: string) => {
  const date = new Date(datetime);
  const today = new Date(); // 현재 날짜

  // 날짜 비교를 위해 날짜의 연, 월, 일만 비교
  const isToday =
    today.getFullYear() === date.getFullYear() &&
    today.getMonth() === date.getMonth() &&
    today.getDate() === date.getDate();

  // 원하는 형식으로 반환
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // 월은 0부터 시작
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  if (isToday) {
    return `오늘 ${hours}:${minutes}`; // 오늘이면 "오늘 13:00" 형식으로 반환
  }

  return `${year}.${month}.${day} ${hours}:${minutes}`; // 오늘이 아니면 기본 포맷
};

// 이메일을 마스킹하는 함수
export const maskEmail = (email: string | null): string => {
  if (!email) return ""; // 이메일이 없을 경우 빈 문자열 반환
  const [localPart, domain] = email.split("@"); // 이메일을 @ 기준으로 분리
  if (localPart.length <= 3) {
    return `${localPart}***@${domain}`; // 로컬 부분이 3자 이하일 경우 처리
  }
  const visiblePart = localPart.slice(0, 3); // 로컬 부분에서 처음 3자리만 노출
  const maskedPart = "*".repeat(localPart.length - 3); // 나머지는 *로 마스킹
  return `${visiblePart}${maskedPart}@${domain}`;
};

export const getAccountType = (type: string) => {
  switch (type) {
    case "general":
      return "이메일 계정 로그인";
    case "kakao":
      return "카카오 연동 로그인";
    case "google":
      return "구글 연동 로그인";
    case "apple":
      return "애플 연동 로그인";
    default:
      return ""; // 기본값
  }
};

export const getLatestTestDate = (
  date1: string | null,
  date2: string | null
) => {
  if (!date1 && !date2) return ""; // 둘 다 null이면 빈 문자열 반환
  if (!date1) return formatDateTime(date2 ?? ""); // date1이 없으면 date2 반환 (null 방지)
  if (!date2) return formatDateTime(date1 ?? ""); // date2가 없으면 date1 반환 (null 방지)

  return new Date(date1) > new Date(date2)
    ? formatDateTime(date1)
    : formatDateTime(date2);
};

// ✅ 시간 차이 계산 함수 추가
export const getTimeDifference = (start: string, end: string) => {
  const startDate = new Date(start);
  const endDate = new Date(end);

  // 밀리초 단위 차이 계산
  const diffMs = endDate.getTime() - startDate.getTime();

  // 시간, 분, 초 계산
  const hours = Math.floor(diffMs / (1000 * 60 * 60));
  const minutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((diffMs % (1000 * 60)) / 1000);

  return `${hours}시간 ${minutes}분 ${seconds}초`;
};

export const convertWavToMp3 = async (wavBlob: Blob): Promise<Blob> => {
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onload = () => {
      const wavData = new Uint8Array(reader.result as ArrayBuffer);
      const wavView = new DataView(wavData.buffer);
      const sampleRate = wavView.getUint32(24, true); // 샘플링 레이트 읽기
      const numChannels = wavView.getUint16(22, true); // 채널 수 읽기

      // ✅ MP3 인코더 생성
      const mp3Encoder = new lamejs.Mp3Encoder(numChannels, sampleRate, 128);
      const samples = new Int16Array(wavData.buffer, 44); // WAV 헤더 제거
      const mp3Data: Uint8Array[] = [];
      let remaining = samples.length;
      let maxSamples = 1152;

      for (let i = 0; i < remaining; i += maxSamples) {
        const mono = samples.subarray(i, i + maxSamples);
        const mp3buf = mp3Encoder.encodeBuffer(mono);
        if (mp3buf.length > 0) {
          mp3Data.push(new Uint8Array(mp3buf));
        }
      }

      const mp3buf = mp3Encoder.flush();
      if (mp3buf.length > 0) {
        mp3Data.push(new Uint8Array(mp3buf));
      }

      const mp3Blob = new Blob(mp3Data, { type: "audio/mp3" });
      resolve(mp3Blob);
    };

    reader.onerror = (error) => reject(error);
    reader.readAsArrayBuffer(wavBlob);
  });
};

export const fixWavHeader = async (audioBlob: Blob): Promise<Blob> => {
  const buffer = await audioBlob.arrayBuffer();
  const view = new DataView(buffer);

  // RIFF 헤더 확인
  if (view.getUint32(0, false) !== 0x52494646) {
    console.error("🚨 WAV 파일이 올바른 RIFF 헤더로 시작하지 않습니다.");
    return audioBlob;
  }

  console.log("✅ WAV 파일 헤더 정상 확인됨.");
  return new Blob([buffer], { type: "audio/wav" });
};

export const addWavHeader = async (audioBlob: Blob): Promise<Blob> => {
  const sampleRate = 44100; // 일반적인 샘플 레이트
  const numChannels = 1; // 모노 녹음
  const bitsPerSample = 16; // 16비트 PCM
  const byteRate = sampleRate * numChannels * (bitsPerSample / 8);

  const buffer = await audioBlob.arrayBuffer();
  const dataLength = buffer.byteLength;
  const chunkSize = 36 + dataLength;

  const wavHeader = new ArrayBuffer(44);
  const view = new DataView(wavHeader);

  // "RIFF" Chunk
  view.setUint32(0, 0x52494646, false); // "RIFF"
  view.setUint32(4, chunkSize, true); // File Size - 8
  view.setUint32(8, 0x57415645, false); // "WAVE"

  // "fmt " Chunk
  view.setUint32(12, 0x666d7420, false); // "fmt "
  view.setUint32(16, 16, true); // Subchunk1 Size (16 for PCM)
  view.setUint16(20, 1, true); // Audio Format (1 = PCM)
  view.setUint16(22, numChannels, true); // Num Channels
  view.setUint32(24, sampleRate, true); // Sample Rate
  view.setUint32(28, byteRate, true); // Byte Rate
  view.setUint16(32, numChannels * (bitsPerSample / 8), true); // Block Align
  view.setUint16(34, bitsPerSample, true); // Bits per Sample

  // "data" Chunk
  view.setUint32(36, 0x64617461, false); // "data"
  view.setUint32(40, dataLength, true); // Data Size

  // WAV 헤더 + 오디오 데이터 합치기
  const newWavBuffer = new Uint8Array(44 + dataLength);
  newWavBuffer.set(new Uint8Array(wavHeader), 0);
  newWavBuffer.set(new Uint8Array(buffer), 44);

  return new Blob([newWavBuffer], { type: "audio/wav" });
};

// WAV 헤더 작성 함수
export const writeWavHeader = (
  view: DataView,
  sampleRate: number,
  numChannels: number,
  bitsPerSample: number,
  dataLength: number
) => {
  view.setUint32(0, 0x52494646, false); // "RIFF"
  view.setUint32(4, 36 + dataLength, true); // 전체 파일 크기
  view.setUint32(8, 0x57415645, false); // "WAVE"

  view.setUint32(12, 0x666d7420, false); // "fmt "
  view.setUint32(16, 16, true); // Subchunk1Size (PCM은 16)
  view.setUint16(20, 1, true); // Audio Format (1 = PCM)
  view.setUint16(22, numChannels, true); // 채널 수 (1 = Mono, 2 = Stereo)
  view.setUint32(24, sampleRate, true); // 샘플링 레이트
  view.setUint32(28, sampleRate * numChannels * (bitsPerSample / 8), true); // Byte Rate
  view.setUint16(32, numChannels * (bitsPerSample / 8), true); // Block Align
  view.setUint16(34, bitsPerSample, true); // Bits per Sample

  view.setUint32(36, 0x64617461, false); // "data"
  view.setUint32(40, dataLength, true); // 오디오 데이터 크기
};

// 시간 변환 함수 (서버에 보낼 용도로 사용)
// ISO 8601 형식 "2025-03-18T21:00:00.000Z"
export const getKSTTime = () => {
  const now = new Date();
  now.setHours(now.getHours() + 9); // UTC → KST 변환
  return now.toISOString(); // "2025-03-18T21:00:00.000Z"
};

// 일반 시간 형식 "YYYY-MM-DD HH:mm:ss"
export const getKSTTimeForLogs = () => {
  const now = new Date();
  now.setHours(now.getHours() + 9); // UTC → KST 변환
  return now.toISOString().replace("T", " ").split(".")[0]; // "YYYY-MM-DD HH:mm:ss"
};

// 경과 시간 함수
export const calculateElapsedTime = (
  startDt: string | null,
  isRecording: boolean,
  setElapsedTime: (time: string) => void
) => {
  if (!startDt || !isRecording) {
    setElapsedTime("00:00:00");
    return;
  }

  const startTime = new Date(startDt).getTime();

  if (isNaN(startTime)) {
    console.error("❌ startDt 변환 오류! startDt:", startDt);
    return;
  }

  // ✅ `setInterval`로 실시간 업데이트
  const elapsedTimeInterval = setInterval(() => {
    const now = new Date().getTime() + 9 * 60 * 60 * 1000; // ✅ now를 KST 기준으로 변환
    let diff = now - startTime; // ✅ diff 재계산

    if (diff < 0) {
      console.warn("⚠️ startTime이 미래 시간입니다. diff를 0으로 설정합니다.");
      diff = 0;
    }

    const hours = String(Math.floor(diff / (1000 * 60 * 60))).padStart(2, "0");
    const minutes = String(
      Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60))
    ).padStart(2, "0");
    const seconds = String(Math.floor((diff % (1000 * 60)) / 1000)).padStart(
      2,
      "0"
    );

    setElapsedTime(`${hours}:${minutes}:${seconds}`);
  }, 1000);

  return elapsedTimeInterval; // ✅ 인터벌 ID 반환
};

export const formatTodayDate = () => {
  // 오늘 날짜 구함
  const now = new Date();
  const year = String(now.getFullYear()).slice(-2); // 연도의 마지막 두 자리
  const month = String(now.getMonth() + 1).padStart(2, "0"); // 월 (0부터 시작하므로 +1)
  const day = String(now.getDate()).padStart(2, "0"); // 일

  return `${year}${month}${day}`; // YYMMDD 형식 반환
};

export const recordResultCalculateElapsedTime = (
  startDt?: string,
  endDt?: string
): string => {
  if (!startDt || !endDt) return "00:00:00"; // 값이 없으면 기본값 반환

  const startTime = new Date(startDt).getTime();
  const endTime = new Date(endDt).getTime();
  let diff = endTime - startTime; // 밀리초 단위 차이

  if (isNaN(startTime) || isNaN(endTime) || diff < 0) return "00시간 00분 00초"; // 예외 처리

  const hours = String(Math.floor(diff / (1000 * 60 * 60))).padStart(2, "0");
  const minutes = String(
    Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60))
  ).padStart(2, "0");
  const seconds = String(Math.floor((diff % (1000 * 60)) / 1000)).padStart(
    2,
    "0"
  );

  return `${hours}시간 ${minutes}분 ${seconds}초`;
};
