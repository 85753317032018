import { fetchLoadAdminColumnList } from "api/admin/content.column";
import { ColumnParams } from "interface/admin/interface.contents";
import { useEffect, useState } from "react";
import { ListBox } from "style/admin/contents";
import AdminColumnTable from "./admin.column.table";

interface ListProps {
  searchTerm: string;
  setDataLength?: any;
  reportCount?: number;
  useYn?: "Y" | "N" | null;
}

const AdminColumnList = ({ searchTerm, useYn, setDataLength }: ListProps) => {
  const [data, setData] = useState<[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  console.log(data);

  useEffect(() => {
    const loadAdminColumnList = async () => {
      try {
        const params: ColumnParams = {
          keyword: searchTerm,
          orderBy: "REGDT",
          orderByDir: "DESC",
          useYn: useYn ? useYn : null,
        };
        const response = await fetchLoadAdminColumnList(params);
        setData(response.result || []);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    loadAdminColumnList();
  }, []);

  return (
    <ListBox>
      {isLoading ? (
        <p>검색 중...</p> // ✅ 로딩 표시
      ) : (
        <AdminColumnTable data={data} />
      )}
    </ListBox>
  );
};

export default AdminColumnList;
