import axios from "axios";
import axiosInstance from "../plugin/axios";

interface JoinRequest {
  userId: string;
  birthDate: string;
  role: string;
  userName: string;
  gengder: string;
  password: string;
  email: string;
  mobile: string;
  address: string;
  profileImg?: FileList;
}

interface JoinResponse {
  result: boolean;
  message: string;
}

interface ConfirmAuthCodeRequest {
  email: string | null;
  authCode: string;
  forPasswordReset: boolean;
}

export const joinHandler = async (data: FormData): Promise<JoinResponse> => {
  try {
    const response = await axiosInstance.post<JoinResponse>("/signup", data, {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json;charset=UTF-8",
      },
    });
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

export const forgotPasswordHandler = async (data: {
  userId: string;
  newPassword: string;
  token: string;
}) => {
  console.log(data);
  try {
    const response = await axiosInstance.put(`/user/${data.userId}/password`, {
      newPassword: data.newPassword,
      token: data.token,
    });

    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      console.error("비밀번호 재설정 실패:", error.response?.data);
      throw new Error(
        error.response?.data?.message || "비밀번호 재설정 중 오류 발생"
      );
    } else {
      throw new Error("네트워크 오류 또는 서버 응답 없음");
    }
  }
};

// 이메일 인증
export const emailAuthHandler = async (email: string) => {
  try {
    const response = await axiosInstance.post(
      `/email-auth/send-auth-code`,
      null,
      {
        params: {
          email: email,
        },
      }
    );

    console.log(response);
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

export const emailConfirmCodeHandler = async (data: ConfirmAuthCodeRequest) => {
  try {
    const response = await axiosInstance.post(
      `/email-auth/confirm-auth-code`,
      data
    );
    return response.data;
  } catch (error) {
    throw new Error();
  }
};
