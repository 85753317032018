import ForgotPasswordBox from "components/auth/forgot/forgot.password.box";
import JoinEmailAuth from "components/auth/join/join.email.auth";
import JoinHeader from "components/auth/join/join.header";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";

const PasswordForgot = () => {
  const [step, setStep] = useState(0); // 현재 스텝을 관리
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const navigate = useNavigate();
  const [resetToken, setResetToken] = useState<string | null>(null);

  const handleNext = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const backArrowHandler = () => {
    if (step === 0) {
      navigate("/");
    } else {
      setStep((prevStep) => prevStep - 1);
    }
  };

  const resetTokenHandler = (token: string) => {
    setResetToken(token);
  };

  return (
    <Container>
      <JoinHeader onClick={backArrowHandler} headerTitle="비밀번호 재설정" />
      <AnimatePresence mode="wait" initial={false}>
        {step === 0 && (
          <Step
            key={0}
            initial={{ opacity: 0, y: 20 }} // 초기 상태 (살짝 오른쪽에서 시작)
            animate={{ opacity: 1, y: 0 }} // 나타날 때
            exit={{ opacity: 0, y: -20 }} // 사라질 때 (왼쪽으로 이동하며 사라짐)
            transition={{ duration: 0.3 }} // 애니메이션 속도
          >
            <JoinEmailAuth
              email={email}
              handleNext={handleNext}
              forPasswordReset={true}
              resetTokenHandler={resetTokenHandler}
            />
          </Step>
        )}
        {step === 1 && (
          <Step
            key={1}
            initial={{ opacity: 0, y: 20 }} // 초기 상태 (살짝 오른쪽에서 시작)
            animate={{ opacity: 1, y: 0 }} // 나타날 때
            exit={{ opacity: 0, y: -20 }} // 사라질 때 (왼쪽으로 이동하며 사라짐)
            transition={{ duration: 0.3 }} // 애니메이션 속도
          >
            <ForgotPasswordBox resetToken={resetToken} email={email} />
          </Step>
        )}
      </AnimatePresence>
    </Container>
  );
};

export default PasswordForgot;

const Container = styled.div``;

const Step = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.6rem;
`;
