import { atom } from "recoil";

export const userInfoState = atom({
  key: "userInfoState",
  default: {
    userId: null,
    userName: null,
    userState: null,
    userProfileImg: null,
    useYn: null,
    blockExpDt: null,
  },
});

export const userDateLimitModal = atom<{
  isOpen: boolean;
  isVisible: boolean;
}>({
  key: "userDateLimitModal",
  default: {
    isOpen: false,
    isVisible: false,
  },
});

export const userDatePermanentLimitModal = atom<{
  isOpen: boolean;
  isVisible: boolean;
}>({
  key: "userDatePermanentLimitModal",
  default: {
    isOpen: false,
    isVisible: false,
  },
});

export const userDateLimitLiftModal = atom<{
  isOpen: boolean;
  isVisible: boolean;
}>({
  key: "userDateLimitLiftModal",
  default: {
    isOpen: false,
    isVisible: false,
  },
});
