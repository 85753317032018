import { useEffect, useState } from "react";
import { ListBox } from "style/review";
import AdminUserDetailTable from "./admin.user.detail.inquiry.table";
import {
  fetchLoadUserDetailInquiry,
  fetchLoadUserDetailReview,
  fetchLoadUserDetailReviewComment,
} from "api/admin/user";
import {
  UserReviewCommentParams,
  UserReviewParams,
} from "interface/admin/interface.user";
import AdminUserDetailInquiryTable from "./admin.user.detail.inquiry.table";
import AdminUserDetailReviewTable from "./admin.user.detail.review.table";
import AdminUserDetailReviewCommentTable from "./admin.user.detail.review.comment.table";

interface ListProps {
  listIndex: number;
  userId: string;
}

const AdminUserDetailList = ({ listIndex, userId }: ListProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  console.log(data);

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      try {
        let response;
        if (listIndex === 1) {
          const params = {
            orderBy: "REGDT",
            orderByDir: "DESC",
          };

          response = await fetchLoadUserDetailInquiry(userId, params);
        } else if (listIndex === 2) {
          const params: UserReviewParams = {
            orderBy: "REGDT",
            orderByDir: "DESC",
            userId: userId,
            reportCount: 0,
          };

          response = await fetchLoadUserDetailReview(params);
        } else {
          const params: UserReviewCommentParams = {
            userId: userId,
          };

          response = await fetchLoadUserDetailReviewComment(params);
        }

        if (response.result) setData(response.result);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    loadData();
  }, [listIndex, userId]);

  return (
    <ListBox>
      {isLoading ? (
        <p>검색 중...</p> // ✅ 로딩 표시
      ) : listIndex === 1 ? (
        <AdminUserDetailInquiryTable data={data} />
      ) : listIndex === 2 ? (
        <AdminUserDetailReviewTable data={data} />
      ) : (
        <AdminUserDetailReviewCommentTable data={data} />
      )}
    </ListBox>
  );
};

export default AdminUserDetailList;
