import { BoxInner, TextBox } from "style/modal";

interface ModalProps {
  closedHandler: () => void;
}

const AfterDeleteAccountModal = ({ closedHandler }: ModalProps) => {
  return (
    <BoxInner>
      <TextBox>
        <p>탈퇴가 완료되었습니다.</p>
        <span>{`더 좋은 모습으로 다시 만나뵙겠습니다. \n감사합니다.`}</span>
      </TextBox>
      <div className="column-btn-box">
        <div className="btn-box">
          <button onClick={closedHandler}>닫기</button>
        </div>
      </div>
    </BoxInner>
  );
};

export default AfterDeleteAccountModal;
