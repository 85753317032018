import { BoxInner, TextBox } from "style/modal";

interface ModalProps {
  closedHandler: () => void;
  writeCancelHandler: () => void;
}

const CancelModal = ({ closedHandler, writeCancelHandler }: ModalProps) => {
  return (
    <BoxInner>
      <TextBox>
        <p>문의 작성을 취소하시겠습니까?</p>
        <span>작성중이던 글은 저장되지 않습니다.</span>
      </TextBox>
      <div className="btn-box">
        <button className="continue" onClick={closedHandler}>
          계속 작성
        </button>
        <span></span>
        <button className="del" onClick={writeCancelHandler}>
          작성 취소
        </button>
      </div>
    </BoxInner>
  );
};

export default CancelModal;
