import { formatDateTime } from "utils/util";

interface ContentsProps {
  title: string;
  hideYn: "Y" | "N";
  userProfileUrl: string | null;
  userName: string;
  regDt: string;
  views: number;
  reviewCommentCount: number;
  reviewReportCount: number;
  reviewId: number;
}

const AdminDetailTopContents = ({
  hideYn,
  regDt,
  reviewCommentCount,
  reviewReportCount,
  title,
  userName,
  userProfileUrl,
  views,
  reviewId,
}: ContentsProps) => {
  const hostURL = process.env.REACT_APP_DEV_URL;

  const handleClickedReportlist = (id: string, type: string) => {
    window.open(
      `${hostURL}/admin/report/list/${id}?type=${type}`,
      "_blank",
      "width=1240,height=800,scrollbars=no,resizable=no,toolbar=no,menubar=no,location=no,status=no"
    );
  };

  return (
    <div className="info-box">
      <div className="tbox">
        <p>{title}</p>
        <span className={`hide-item ${hideYn === "N" ? "bluebox" : "redbox"}`}>
          {hideYn === "N" ? "정상노출" : "숨겨짐"}
        </span>
      </div>
      <div className="bbox">
        <div className="profile">
          <div className="img-box">
            <img
              src={
                userProfileUrl ? userProfileUrl : "/assets/icon/userprofile.png"
              }
              alt={`${userName} 프로필`}
            />
          </div>
          <span>{userName}</span>
        </div>
        <div className="date">
          <span>{formatDateTime(String(regDt))}</span>
        </div>
        <div className="view">
          <span>조회</span> <span>{views}</span>
        </div>
        <div className="reple">
          <span>댓글</span> <span>{reviewCommentCount}</span>
        </div>
        <div
          className={`report ${
            Number(reviewReportCount) >= 10 ? "report-txt" : ""
          }`}
          onClick={() => handleClickedReportlist(String(reviewId), "REVIEW")}
        >{`신고(${reviewReportCount})`}</div>
      </div>
    </div>
  );
};

export default AdminDetailTopContents;
