import { fetchLoadAdminInquiryDetail } from "api/admin/inquiry";
import { userIdState } from "atoms/admin/user/admin.user.state";
import InquiryDetailLeft from "components/admin/inquiry/inquiry.detail.left";
import InquiryDetailRight from "components/admin/inquiry/inquiry.detail.right";
import { NotAnswerInquiryDto } from "interface/admin/interface.inquiry";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { InquiryDetailContainer, InquiryDetailHr } from "style/admin/inquiry";

const AdminUserInquiryView = () => {
  const [data, setData] = useState<NotAnswerInquiryDto | null>(null);
  const { id } = useParams();

  const setUserId = useSetRecoilState(userIdState);

  useEffect(() => {
    const loadInquiryDetail = async () => {
      try {
        const response = await fetchLoadAdminInquiryDetail(Number(id));

        setData(response.result);
      } catch (error) {
        console.error(error);
      }
    };

    loadInquiryDetail();
  }, []);

  useEffect(() => {
    data && setUserId(String(data?.inquiryId));
  }, [setUserId, data]);

  return (
    <InquiryDetailContainer>
      {data ? (
        <>
          <InquiryDetailLeft data={data} />
          <InquiryDetailHr />
          <InquiryDetailRight
            answerDt={data?.answerDt}
            answerRegUSerId={data?.answerRegUserId}
            answerId={Number(id)}
            answerYn={data.answerYn}
            answer={data.answer}
          />
        </>
      ) : (
        <div>네트워크 연결을 확인해주세요.</div>
      )}
    </InquiryDetailContainer>
  );
};

export default AdminUserInquiryView;
