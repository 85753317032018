import styled from "styled-components";
import { ChangeEvent, useState } from "react";
import { UseFormRegister, UseFormSetValue } from "react-hook-form";
import { CameraIcon } from "cssReset/icon";

interface FileUploadProps {
  title: string;
  height?: string;
  name: string;
  errors?: string[];
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  userImgUrl?: string;
  onImageChange?: (file: File) => void;
  onImageChangeRemove?: () => void;
}

export const FileUploadImg = ({
  title,
  height = "auto",
  name,
  register,
  setValue,
  errors = [],
  userImgUrl,
  onImageChange,
  onImageChangeRemove,
}: FileUploadProps) => {
  const [fileName, setFileName] = useState<string>("");
  const [fileUrl, setFileUrl] = useState<string>("");

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      setFileName(file.name);
      setFileUrl(URL.createObjectURL(file));
      setValue(name, file); // useForm에 파일 설정

      // 이미지 변경 시 콜백 호출
      if (onImageChange) {
        onImageChange(file); // 파일이 변경되면 onImageChange 호출
      }
    }
  };

  const handleRemoveFile = () => {
    setFileName("");
    setFileUrl("");
    setValue(name, null); // 파일 제거 시 useForm의 값도 제거

    // 이미지 변경 시 콜백 호출
    if (onImageChangeRemove) {
      onImageChangeRemove(); // 파일이 변경되면 onImageChange 호출
    }
  };

  return (
    <>
      <FileUpload height={height}>
        {!fileUrl ? (
          <div className="camera-box">
            <div className="icon-box">
              {userImgUrl ? (
                <img src={userImgUrl} alt="" />
              ) : (
                <img src="/assets/icon/profileIcon.png" alt="" />
              )}
            </div>
            <label>
              <div className="camera-icon-box">
                <CameraIcon />
              </div>
              <input
                type="file"
                onChange={(e) => {
                  handleFileChange(e);
                  register(name).onChange(e); // register의 onChange 핸들러 호출
                }}
              />
            </label>
          </div>
        ) : (
          <ImagePreview>
            <img src={fileUrl} alt={fileName} />
            <button onClick={handleRemoveFile}>
              <CameraIcon />
            </button>
          </ImagePreview>
        )}
      </FileUpload>
      {errors.map((error, index) => (
        <ValTxt key={index}>{error}</ValTxt>
      ))}
    </>
  );
};

// styled components
const FileUpload = styled.div<{ height: string }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0.8rem;
  overflow: hidden;
  margin-bottom: 0.8rem;
  .icon-box {
    display: flex;
    width: 100%;
    height: 100%;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10rem;
    }
  }
  .camera-box {
    position: relative;
    width: 10rem;
    height: 10rem;
    padding: 0.4rem;
    label {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 2.4rem;
      height: 2.4rem;
      position: absolute;
      bottom: 0.8rem;
      right: 0.4rem;
      .camera-icon-box {
        display: flex;
        width: 100%;
        height: 100%;
        svg {
          width: 100%;
          height: 100%;
        }
      }
      span {
        font-size: 1.3rem;
        font-weight: 500;
        color: ${(props) => props.theme.gray500};
      }
      input[type="file"] {
        display: none;
      }
    }
  }
`;

const ImagePreview = styled.div`
  max-width: 100%;
  height: 10rem;
  width: 10rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.4rem;
  overflow: hidden;
  img {
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10rem;
  }
  button {
    border: 0;
    outline: none;
    display: flex;
    background: none;
    padding: 0;
    position: absolute;
    bottom: 0.8rem;
    right: 0.4rem;
  }
`;

const ValTxt = styled.span`
  width: 100%;
  color: ${(props) => props.theme.errorColor};
  font-size: 1.2rem;
  font-weight: 400;
  display: flex;
  margin: 1rem 1.7rem 0;
`;
