import { UserInfoDt } from "interface/interface.profile";
import { atom } from "recoil";

export const userInformation = atom<UserInfoDt>({
  key: "userInformation",
  default: {
    address: "", // 기본 주소는 빈 문자열
    birthDate: "", // 기본 생년월일은 빈 문자열
    email: "", // 기본 이메일은 빈 문자열
    fileGroupId: null, // 기본 파일 그룹 ID는 null
    files: [], // 기본 파일 배열은 빈 배열
    gender: "", // 기본 성별은 빈 문자열
    mobile: "", // 기본 모바일 번호는 빈 문자열
    regDt: "", // 기본 등록 날짜는 빈 문자열
    role: "ROLE_USER", // 기본 역할은 "ROLE_USER"
    type: "", // 기본 사용자 유형은 "general"
    updtDt: null, // 기본 수정된 날짜는 null
    userId: "", // 기본 사용자 ID는 빈 문자열
    userName: "", // 기본 사용자 이름은 빈 문자열
  },
});

export const userInfoUpdateSuccess = atom<boolean>({
  key: "userInfoUpdateSuccess",
  default: false,
});
