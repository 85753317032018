import { useEffect, useState } from "react";
import { MeasureResultContainer, ResultWrapper } from "style/measure";
import { NoiseData } from "interface/interface.measure";
import MeasureRecordResultTopInfo from "components/measure/components/measure.record.result.top.info";
import MeasureRecordResultTotalCount from "components/measure/components/measure.record.result.total.count";
import MeasureRecordResultSummary from "components/measure/components/measure.record.result.summay";
import MeasureRecordingResultHeader from "../measure.recording.result.header";
import { useRecoilValue } from "recoil";
import { measureUnauthenticatedRecordingInfoState } from "atoms/measure.state";
import UnauthenticatedMeasureRecordResultChart from "components/measure/components/unauthenticated/unauthenticated.measure.record.result.chart";

const UnauthenticatedMeasureRecordingResult = () => {
  const [data, setData] = useState<NoiseData[] | null>([]);
  const isLoading = !data; // 데이터가 없으면 로딩 중

  const [firstTime, setFirstTime] = useState<string | null>(null);
  const [endTime, setEndTime] = useState<string | null>(null);
  const measureRecordingInfo = useRecoilValue(
    measureUnauthenticatedRecordingInfoState
  );

  useEffect(() => {
    if (data && data.length > 0) {
      setFirstTime(data[0].time);
      setEndTime(data[data.length - 1].time);
    }
  }, [data]);

  useEffect(() => {
    const handleBack = () => {
      window.history.pushState(null, "", window.location.href);
    };

    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", handleBack);

    return () => {
      window.removeEventListener("popstate", handleBack);
    };
  }, []);

  useEffect(() => {
    const raw = localStorage.getItem("logsData");
    if (!raw) return;

    try {
      const parsed: NoiseData[] = JSON.parse(raw);
      setData(parsed);
    } catch (e) {
      console.error("❌ 로컬스토리지 logsData 파싱 실패:", e);
    }
  }, []);

  return (
    <MeasureResultContainer>
      <MeasureRecordingResultHeader />
      <ResultWrapper>
        {isLoading ? (
          <p>로딩 중...</p>
        ) : (
          <>
            <MeasureRecordResultTopInfo
              startDt={firstTime ?? ""}
              endDt={endTime ?? ""}
              location={measureRecordingInfo.location}
              simpleMeasureResultId={0}
            />
            <MeasureRecordResultTotalCount resultData={data ?? []} />
            <MeasureRecordResultSummary resultData={data ?? []} />
            <UnauthenticatedMeasureRecordResultChart resultData={data ?? []} />
          </>
        )}
      </ResultWrapper>
    </MeasureResultContainer>
  );
};

export default UnauthenticatedMeasureRecordingResult;
