import { loginHandler } from "api/login";
import { PasswordInput } from "components/pieces/input";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";

interface LoginFormData {
  userId: string;
  password: string;
}

const LoginPassword = () => {
  const { register, handleSubmit, setFocus, watch } = useForm<LoginFormData>();
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const email = searchParams.get("email");
  const passwordValue = watch("password", "");

  useEffect(() => {
    setIsSubmitDisabled(passwordValue.length < 8);
  }, [passwordValue]);

  useEffect(() => {
    const authState = localStorage.getItem("authState");
    const accessToken = localStorage.getItem("accessToken");

    if (authState && accessToken) {
      const { isAuthenticated } = JSON.parse(authState);

      if (isAuthenticated) {
        navigate("/home"); // 로그인 상태라면 /home으로 리다이렉트
        return;
      }
    }

    // 쿼리 파라미터 유지
    const currentParams = new URLSearchParams(window.location.search);
    navigate(`/login/password?${currentParams.toString()}`);
  }, [navigate]);

  useEffect(() => {
    setFocus("password");
  }, []);

  if (!email) {
    return (
      <div>
        <p>유효한 이메일이 제공되지 않았습니다.</p>
      </div>
    );
  }

  const onSubmit = async (data: any) => {
    let fcmToken: string | undefined;
    let isAndroid: boolean = false;

    // ✅ iOS WebView인 경우에만 토큰 요청
    if (window.webkit?.messageHandlers?.getFcmToken) {
      try {
        const tokenPromise = new Promise<string>((resolve) => {
          const handleToken = (event: any) => {
            if (event.detail?.token) {
              console.log("✅ 받은 FCM 토큰:", event.detail.token);
              window.removeEventListener("fcm-token", handleToken);
              resolve(event.detail.token);
            }
          };
          window.addEventListener("fcm-token", handleToken);
        });

        // ✅ iOS에게 토큰 요청
        window.webkit.messageHandlers.getFcmToken.postMessage("request");
        fcmToken = await tokenPromise;
      } catch (error) {
        console.warn("🔥 FCM 토큰 요청 중 오류 발생:", error);
      }
    } else if (window.ReactNativeWebView) {
      // ✅ Android 처리
      try {
        const tokenPromise = new Promise<string>((resolve) => {
          const handleToken = (event: any) => {
            if (event.detail?.token) {
              console.log("✅ [Android] 받은 FCM 토큰:", event.detail.token);
              window.removeEventListener("fcm-token", handleToken);
              resolve(event.detail.token);
            }
          };
          window.addEventListener("fcm-token", handleToken);
        });

        // 👉 안드로이드에게 토큰 요청
        window.ReactNativeWebView.postMessage("fcm_token_request");

        isAndroid = true;
        fcmToken = await tokenPromise;
      } catch (err) {
        console.warn("🔥 [Android] 토큰 요청 실패:", err);
      }
    }

    try {
      const response = await loginHandler({
        userId: email,
        password: data.password,
        deviceToken: fcmToken,
        isAndroid: isAndroid ? "Y" : "N",
      });

      const { accessToken, refreshToken } = response.result;

      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("refreshToken", refreshToken);

      // 로컬 스토리지에 사용자 유형 저장
      localStorage.setItem(
        "authState",
        JSON.stringify({ isAuthenticated: true })
      );

      navigate("/home");
    } catch (error: any) {
      console.error("로그인 실패:", error.message);
    }
  };

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <EmailInputWrap>
          <PasswordInput
            name="password"
            placeholder="비밀번호"
            register={register}
          />
          <input
            type="submit"
            value="로그인"
            disabled={isSubmitDisabled}
            style={{
              backgroundColor: isSubmitDisabled ? "#cecece" : "#225298",
            }}
          />
        </EmailInputWrap>
      </form>
      <ForgotPassowrd>
        <Link
          to={`/join/forgotPassword?email=${encodeURIComponent(email || "")}`}
        >
          비밀번호를 잊으셨나요?
        </Link>
      </ForgotPassowrd>
    </Container>
  );
};

export default LoginPassword;

const Container = styled.div`
  height: calc(100vh - 5.6rem);
  padding: 1.6rem;
  form {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
  }
`;

const EmailInputWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  position: relative;
  input[type="submit"] {
    width: 100%;
    outline: none;
    border: none;
    padding: 1rem;
    color: white;
    background-color: #cecece;
    border-radius: 0.5rem;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 2rem;
  }
`;

const ForgotPassowrd = styled.div`
  margin-top: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.4rem;
  a {
    font-size: 1.2rem;
    line-height: 1.6rem;
    font-weight: 400;
  }
`;
