import {
  isNoticeChangedState,
  isNoticeRegistChangedState,
  noticeHideConfirmModalState,
  noticeHideState,
  noticeRegistSubmitTriggerState,
  noticeShowConfirmModalState,
  noticeSubmitTriggerState,
} from "atoms/admin/contents/notice.state";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import styled from "styled-components";

interface Props {
  constentModify?: "notice" | "column";
  isRegistPage?: boolean; // ✅ 새로 추가된 props
}

const AdminNoticeBtnBox = ({ constentModify, isRegistPage }: Props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const noticeHideValue = useRecoilValue(noticeHideState);
  const setNoticeHideModal = useSetRecoilState(noticeHideConfirmModalState);
  const setNoticeShowModal = useSetRecoilState(noticeShowConfirmModalState);
  const modifyBtnClicked = () => navigate(`/admin/notice/update/${id}`);
  const setNoticeSubmitTrigger = useSetRecoilState(noticeSubmitTriggerState);
  const isNoticeChanged = useRecoilValue(isNoticeChangedState);
  const setNoticeRegistSubmitTrigger = useSetRecoilState(
    noticeRegistSubmitTriggerState
  );
  const isNoticeRegistChanged = useRecoilValue(isNoticeRegistChangedState);

  return (
    <BtnsWrap>
      {isRegistPage ? (
        // ✅ 등록 페이지일 때 보이는 버튼
        <div className="blue-btn-box">
          <button
            disabled={!isNoticeRegistChanged}
            onClick={() => {
              setNoticeRegistSubmitTrigger(true);
            }}
          >
            등록하기
          </button>
        </div>
      ) : constentModify !== "notice" && constentModify !== "column" ? (
        <>
          <button onClick={modifyBtnClicked}>수정</button>
          <button
            disabled={noticeHideValue === "N"}
            onClick={() =>
              setNoticeHideModal({ isOpen: true, isVisible: true })
            }
          >
            글 숨기기
          </button>
          <button
            disabled={noticeHideValue === "Y"}
            onClick={() =>
              setNoticeShowModal({ isOpen: true, isVisible: true })
            }
          >
            글 노출하기
          </button>
        </>
      ) : (
        <div className="blue-btn-box">
          <button
            disabled={!isNoticeChanged}
            onClick={() => {
              setNoticeSubmitTrigger(true);
            }}
          >
            적용하기
          </button>
        </div>
      )}
    </BtnsWrap>
  );
};

export default AdminNoticeBtnBox;

export const BtnsWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  button {
    border: 1px solid #cecece;
    outline: none;
    padding: 1.4rem 1.6rem;
    font-size: 2rem;
    line-height: 2.8rem;
    font-weight: 700;
    background-color: white;
    border-radius: 0.5rem;
    color: #242629;
    box-shadow: 1px 1px 4px 0 #00000040;
    cursor: pointer;
    &:disabled {
      color: #cecece;
      cursor: default;
    }
  }
  .blue-btn-box {
    button {
      &:disabled {
        color: white;
        cursor: default;
      }
    }
  }
`;
