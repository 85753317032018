import { Helmet } from "react-helmet-async";
import { Outlet, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import AdminAside from "./admin.aside";
import { Container, ContainerWrapper, MainWrapper } from "style/admin/layout";
import AdminHeader from "./admin.header";

interface LayoutProps {
  headerTitle?: string;
  userInfoViewBtn?: boolean;
  backArrowBtn?: boolean;
  backArrowPath?: string;
  reviewDetail?: boolean;
  subTitle?: string;
  contents?: "banner" | "footer" | "notice" | "column";
  contentsDetail?: "banner" | "footer" | "notice" | "column";
  constentModify?: "notice" | "column";
  userDetail?: boolean;
}

const AdminLayout = ({
  headerTitle,
  userInfoViewBtn,
  backArrowBtn,
  backArrowPath,
  reviewDetail = false,
  subTitle,
  contents,
  contentsDetail,
  userDetail,
}: LayoutProps) => {
  const location = useLocation(); // 현재 경로 가져오기

  const pageTitle = location.pathname.replace("/admin/home", "") || "Home";
  return (
    <Container>
      <Helmet>
        <title>RS101 | {pageTitle}</title>
        <meta property="og:type" content="website" />
      </Helmet>
      <AdminAside />
      <AnimatePresence mode="wait" initial={true}>
        <ContainerWrapper>
          <AdminHeader
            headerTitle={headerTitle}
            userInfoViewBtn={userInfoViewBtn}
            backArrowBtn={backArrowBtn}
            backArrowPath={backArrowPath}
            reviewDetail={reviewDetail}
            subTitle={subTitle}
            contents={contents}
            contentsDetail={contentsDetail}
            userDetail={userDetail}
          />
          <MainWrapper
            key={location.pathname} // 경로가 변경될 때마다 애니메이션 트리거
            initial={{ opacity: 0, x: 20 }} // 초기 상태 (살짝 오른쪽에서 시작)
            animate={{ opacity: 1, x: 0 }} // 나타날 때
            exit={{ opacity: 0, x: -20 }} // 사라질 때 (왼쪽으로 이동하며 사라짐)
            transition={{ duration: 0.3 }} // 애니메이션 속도
          >
            <Outlet />
          </MainWrapper>
        </ContainerWrapper>
      </AnimatePresence>
    </Container>
  );
};

export default AdminLayout;
