import styled from "styled-components";

export const Container = styled.div``;

export const WindowHeader = styled.header`
  padding: 3rem 4rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
  .left {
    display: flex;
    align-items: center;
    gap: 1rem;
    h2 {
      font-size: 2.8rem;
      font-weight: 600;
      line-height: 3.6rem;
      color: #242629;
    }
    .icon-box {
      cursor: pointer;
    }
  }
  .right {
    display: flex;
    gap: 1rem;
    align-items: center;
    button {
      border: 1px solid #cecece;
      outline: none;
      padding: 1.4rem 1.6rem;
      font-size: 2rem;
      line-height: 2.8rem;
      font-weight: 700;
      background-color: white;
      border-radius: 0.5rem;
      color: #242629;
      box-shadow: 1px 1px 4px 0 #00000040;
      cursor: pointer;
      &:disabled {
        color: #cecece;
        cursor: default;
      }
    }
    .btn-box {
      display: flex;
      gap: 1rem;
    }
    .user-info {
      display: flex;
      gap: 1rem;
      .profile {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.4rem;
        .img-box {
          width: 2rem;
          height: 2rem;
          overflow: hidden;
          display: flex;
          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
          }
        }
        span {
          font-size: 1.6rem;
          font-weight: 700;
          line-height: 2.4rem;
          white-space: nowrap;
        }
      }
      .userStatus {
        span {
          .item {
            padding: 0.8rem 1.35rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid;
            border-radius: 100px;

            font-size: 1.6rem;
            font-weight: 700;
            line-height: 2rem;
            letter-spacing: -0.06rem;
            &.bluebox {
              border-color: #225298;
              color: #225298;
            }
            &.graybox {
              border-color: #575757;
              color: #575757;
            }
            &.redbox {
              border-color: #ed2115;
              color: #ed2115;
            }
            &.withdraw {
              border-color: #a1a1a1;
              color: #a1a1a1;
            }
          }
        }
      }
    }
  }
`;

export const ContainerWrapper = styled.div``;

export const MainWrapper = styled.main``;

export const Wrapper = styled.div`
  padding: 3rem;
`;
