import {
  fetchProfileUserImgUpdate,
  fetchProfileUserNameUpdate,
} from "api/profile";
import { userInformation, userInfoUpdateSuccess } from "atoms/user.info";
import { FileUploadImg } from "components/pieces/uploadImg";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  ErrorMessage,
  LengthCount,
  ProfileAuthBox,
  ProfileWrap,
} from "style/setting";
import { formatDateTime, getAccountType, maskEmail } from "utils/util";

const SettingProfileUpdate = () => {
  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [isButtonEnabled, setIsButtonEnabled] = useState(false); // 버튼 활성화 상태
  const [initialUserName, setInitialUserName] = useState<string>("");
  const [fileUrl, setFileUrl] = useState<string>(""); // 업로드된 파일 URL 상태

  const username = watch("username") || "";
  const navigate = useNavigate();
  const setUserUpdateComplete = useSetRecoilState(userInfoUpdateSuccess);

  const userInformationData = useRecoilValue(userInformation);
  console.log(userInformationData);

  useEffect(() => {
    if (!userInformationData.email) {
      navigate("/profile/settingprofile");
    }

    if (userInformationData.userName) {
      setValue("username", userInformationData.userName);
      setInitialUserName(userInformationData.userName); // 최초 username 값을 설정
    }
  }, [userInformationData, navigate, setValue]);

  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    if (inputValue.length <= 15) {
      setValue("username", inputValue); // 15자 이하일 경우만 업데이트

      // 입력값과 최초값을 비교하여 버튼 활성화
      if (inputValue !== initialUserName || fileUrl) {
        setIsButtonEnabled(true); // 값이 변경되면 버튼 활성화
      } else {
        setIsButtonEnabled(false); // 값이 동일하면 버튼 비활성화
      }
    }
  };

  // 이미지가 변경되었을 때 버튼 활성화
  const handleImageChange = (file: File) => {
    const fileUrl = URL.createObjectURL(file);
    setFileUrl(fileUrl); // 파일 URL 저장
    setIsButtonEnabled(true); // 이미지가 변경되면 버튼 활성화
  };
  const handleImageChangeRemove = () => {
    setFileUrl(""); // 파일 URL 초기화
    if (username === initialUserName) {
      setIsButtonEnabled(false); // 이미지가 변경되면 버튼 활성화
    }
  };

  const onSubmit = async (data: any) => {
    try {
      // 1. 사용자 이름 업데이트
      if (data.username) {
        const userNameUpdateResponse = await fetchProfileUserNameUpdate(
          data.username
        );
        console.log("Username updated:", userNameUpdateResponse);
      }

      // 2. 프로필 이미지 업데이트
      if (data.profileImg) {
        const profileImgUpdateResponse = await fetchProfileUserImgUpdate(
          data.profileImg
        );
        console.log("Profile image updated:", profileImgUpdateResponse);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setUserUpdateComplete(true);
      navigate("/setting/settingprofile");
    }
  };

  return (
    <ProfileWrap>
      <ProfileAuthBox style={{ marginBottom: "1.6rem" }}>
        <p>
          {getAccountType(userInformationData.type)}(
          {maskEmail(userInformationData.userId)})
        </p>
        <span>가입일 : {formatDateTime(userInformationData.regDt)}</span>
      </ProfileAuthBox>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FileUploadImg
          name="profileImg"
          register={register}
          title=""
          height="100"
          setValue={setValue}
          userImgUrl={
            userInformationData.files[0] &&
            userInformationData.files[0].accessUrl
          }
          onImageChange={handleImageChange}
          onImageChangeRemove={handleImageChangeRemove}
        />
        <div className="input-box">
          <div className="input-inner">
            <input
              type="text"
              placeholder="다른 사용자에게 보여질 이름을 입력하세요."
              {...register("username", {
                required: "이름을 입력해주세요.",
                validate: (value: string) =>
                  value.length <= 15 || "이름은 최대 15자까지 입력 가능합니다.",
              })}
              value={username} // 입력값 동기화
              onChange={handleUsernameChange} // 입력값 제한
            />
            <span></span>
          </div>
          {/* 에러 메시지 출력 */}
          {errors.username && typeof errors.username.message === "string" && (
            <ErrorMessage>{errors.username.message}</ErrorMessage> // 문자열일 경우 출력
          )}
          <LengthCount>{`${username.length} / 15`}</LengthCount>
        </div>
        <div className="submit-btn">
          <input
            type="submit"
            value="변경사항 저장"
            disabled={!isButtonEnabled} // 버튼 활성화 조건
          />
        </div>
      </form>
    </ProfileWrap>
  );
};

export default SettingProfileUpdate;
