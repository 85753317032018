import {
  inquiryHeaderBtnState,
  inquiryHeaderTypeState,
} from "atoms/inquiry.state";
import { useEffect } from "react";
import { Controller } from "react-hook-form";
import { useSetRecoilState } from "recoil";
import { InfoTxt, InquiryRegistWrap, TextLengthBox } from "style/inquiry";
import TextareaAutosize from "react-textarea-autosize";
import ReviewFileAttachment from "components/review/review.file.attachment";

const InquiryRegist = ({
  register,
  control,
  setValue,
  watch,
  setError,
  errors,
  clearErrors,
  reset,
}: any) => {
  const setInquiryHeaderType = useSetRecoilState(inquiryHeaderTypeState);
  const setInquiryHeaderBtnStatus = useSetRecoilState(inquiryHeaderBtnState);

  const titleValue = watch("title") || "";
  const contentsValue = watch("contents") || "";

  useEffect(() => {
    setInquiryHeaderType({ centerMode: true, btnMode: true });
  }, [setInquiryHeaderType]);

  // 🔥 reset 실행 시 textarea 값 초기화
  useEffect(() => {
    if (!contentsValue) {
      setValue("contents", "");
    }
  }, [contentsValue, setValue]);

  // 제목 길이 검증 (30자 이상 입력 불가)
  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    if (inputValue.length > 0) {
      setInquiryHeaderBtnStatus((prevState) => ({
        ...prevState, // 🔥 기존 상태 유지
        watchTitle: true,
      }));
    } else {
      setInquiryHeaderBtnStatus((prevState) => ({
        ...prevState,
        watchTitle: false,
      }));
    }

    if (inputValue.length > 30) {
      setError("title", {
        type: "maxLength",
        message: "제목은 최대 30자까지 입력 가능합니다.",
      });

      // 🔥 입력값을 30자까지만 유지하도록 강제 설정
      setValue("title", inputValue.slice(0, 30));
    } else {
      clearErrors("title"); // 🔥 에러 초기화
      setValue("title", inputValue);
    }
  };

  // 내용 길이 검증 (1000자 이상 입력 불가)
  const handleContentsChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const inputValue = e.target.value;
    if (inputValue.length > 0) {
      setInquiryHeaderBtnStatus((prevState) => ({
        ...prevState, // 🔥 기존 상태 유지
        watchContents: true,
      }));
    } else {
      setInquiryHeaderBtnStatus((prevState) => ({
        ...prevState,
        watchContents: false,
      }));
    }

    if (inputValue.length > 1000) {
      setError("contents", {
        type: "maxLength",
        message: "문의 내용은 최대 1,000자까지 입력 가능합니다.",
      });

      // 🔥 입력값을 1000자까지만 유지하도록 강제 설정
      setValue("contents", inputValue.slice(0, 1000));
    } else {
      clearErrors("contents"); // 🔥 에러 초기화
      setValue("contents", inputValue);
    }
  };

  return (
    <InquiryRegistWrap>
      <div className="title-box">
        <div className="input-box">
          <input
            type="text"
            {...register("title", {
              required: "제목을 입력해주세요.",
              maxLength: {
                value: 30,
                message: "제목은 최대 30자까지 입력 가능합니다.",
              },
            })}
            value={titleValue} // 🔥 입력값 동기화
            onChange={handleTitleChange} // 입력 중 실시간 검증
            placeholder="제목을 입력해주세요."
          />
          <span></span>
        </div>
        <TextLengthBox>
          <span>{`${titleValue.length} / 30`}</span>
        </TextLengthBox>

        {/* 🔥 에러 메시지 표시 */}
        {errors.title && (
          <span style={{ color: "red", fontSize: "12px" }}>
            {errors.title.message}
          </span>
        )}
      </div>

      <div className="contents-box">
        <Controller
          name="contents"
          control={control}
          rules={{
            required: "문의 내용을 입력해주세요.",
            maxLength: {
              value: 1000,
              message: "문의 내용은 최대 1,000자까지 입력 가능합니다.",
            },
          }}
          render={({ field, fieldState }) => (
            <>
              <TextareaAutosize
                {...field}
                minRows={9}
                maxRows={9}
                placeholder={`문의 내용을 입력해주세요. (최대 1,000자)`}
                onChange={(e) => {
                  field.onChange(e); // react-hook-form의 값 업데이트
                  handleContentsChange(e); // 입력 제한 적용
                }}
              />
              <TextLengthBox>
                <span>{`${contentsValue.length} / 1,000`}</span>
              </TextLengthBox>
              {fieldState.error && (
                <span style={{ color: "red" }}>{fieldState.error.message}</span>
              )}
            </>
          )}
        />
      </div>

      <InfoTxt>
        <p>
          {`사진 및 동영상은 최대 10개, 1GB 까지 등록 가능합니다. \n\n문의 동록 이후 수정이 불가능합니다.`}
        </p>
        <p></p>
      </InfoTxt>

      <ReviewFileAttachment
        name="reviewFile"
        register={register}
        setValue={setValue}
      />
    </InquiryRegistWrap>
  );
};

export default InquiryRegist;
