import { ChangeEvent, useEffect, useState } from "react";
import { UseFormRegister, UseFormSetValue } from "react-hook-form";
import styled from "styled-components";
import { ReviewFile } from "view/review/children/review.modify";

type AttachmentFile = File | ReviewFile;

interface FileUploadProps {
  name: string;
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  initialFiles?: AttachmentFile[]; // 초기 파일 목록
  contents: "banner" | "footer";
}

const AdminBannerAttachment = ({
  register,
  setValue,
  name,
  initialFiles = [],
  contents,
}: FileUploadProps) => {
  const BASE_URL = ""; // 베이스 URL

  const [files, setFiles] = useState<AttachmentFile[]>(initialFiles);
  const [previews, setPreviews] = useState<string[]>([]);

  useEffect(() => {
    if (!initialFiles || !Array.isArray(initialFiles)) return;

    const newPreviews = initialFiles.map((file) =>
      file instanceof File
        ? URL.createObjectURL(file)
        : file.accessUrl
        ? `${BASE_URL}${file.accessUrl}`
        : "/default-image.png"
    );

    if (JSON.stringify(previews) !== JSON.stringify(newPreviews)) {
      setFiles(initialFiles);
      setPreviews(newPreviews);
    }
  }, [JSON.stringify(initialFiles)]);

  useEffect(() => {
    setValue(name, files);
  }, [files, name, setValue]);

  // ✅ 한 장의 파일만 허용하며, PNG & JPG만 업로드 가능하도록 설정
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files;
    if (!selectedFiles || selectedFiles.length === 0) return;

    const newFile = selectedFiles[0];

    // ✅ 파일 형식 검사 (PNG & JPG만 허용)
    if (!["image/png", "image/jpeg"].includes(newFile.type)) {
      alert("PNG 또는 JPG 형식의 파일만 업로드할 수 있습니다.");
      return;
    }

    // ✅ 기존 파일을 새 파일로 덮어쓰기
    setFiles([newFile]);
    setPreviews([URL.createObjectURL(newFile)]);
    setValue(name, [newFile]);
  };

  const handleRemoveFile = () => {
    setFiles([]);
    setPreviews([]);
    setValue(name, []);
  };

  return (
    <FileAttachWrap>
      <PreviewContainer>
        {files.length > 0 ? (
          <div className="preview-item-wrapper">
            {previews.map((preview, index) => (
              <div key={index} className={`preview-item ${contents}`}>
                <img src={preview} alt="첨부된 파일" />
                {/* <button
                  type="button"
                  onClick={handleRemoveFile}
                  className="remove-button"
                >
                  <CancelSmIcon />
                </button> */}
              </div>
            ))}
          </div>
        ) : (
          <div className="blank-box">
            <div className={`blank-img ${contents}`}></div>
          </div>
        )}
      </PreviewContainer>
      <label className="file-attach-box">
        <span>이미지 업로드</span>
        <input
          type="file"
          accept="image/png, image/jpeg" // ✅ PNG & JPG만 허용
          onChange={(e) => {
            handleFileChange(e);
            register(name).onChange(e); // react-hook-form 이벤트 호출
          }}
        />
      </label>
    </FileAttachWrap>
  );
};

export default AdminBannerAttachment;

const FileAttachWrap = styled.div`
  width: 100%;
  max-width: 41.5rem;
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  align-items: center;
  .file-attach-box {
    display: flex;
    align-items: center;
    gap: 0.4rem;
    border: 1px solid #cecece;
    box-shadow: 1px 1px 4px 0 #00000040;
    padding: 1rem;
    border-radius: 0.5rem;
    cursor: pointer;
    span {
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 2rem;
      letter-spacing: -0.06rem;
    }
    input[type="file"] {
      display: none;
    }
  }
`;

const PreviewContainer = styled.div`
  .preview-item-wrapper {
    display: flex;
    align-items: center;
    padding: 1rem 2rem;
    overflow: scroll hidden;
    &::-webkit-scrollbar {
      display: none;
    }
    .preview-item {
      width: 37.5rem;
      height: 15.2rem;
      position: relative;
      flex: 0 0 auto;
      &.banner {
        height: 15.2rem;
      }
      &.foote {
        height: 17.6rem;
      }
      img {
        max-width: 100%;
        object-fit: contain;
        border-radius: 0.5rem;
        width: 100%;
        height: 100%;
      }
      button {
        border: 0;
        outline: 0;
        width: 1.6rem;
        height: 1.6rem;
        background-color: transparent;
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }
  .blank-box {
    padding: 1rem 2rem;
    .blank-img {
      width: 37.5rem;
      height: 15.2rem;
      background-color: #d9d9d9;
      display: flex;
      &.banner {
        height: 15.2rem;
      }
      &.footer {
        height: 17.6rem;
      }
    }
  }
`;
