import { reviewCommentRegist } from "api/review";
import { forwardRef, useImperativeHandle } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import styled from "styled-components";
import TextareaAutosize from "react-textarea-autosize";

interface ReviewCommentFormData {
  reviewComment: string;
}

interface ReviewCommentBoxProps {
  reviewId: string | undefined;
  commentParentId: number | null;
  triggerUpdate: () => void;
}

// ref의 타입을 명시적으로 정의
const ReviewCommentRegistInput = forwardRef<
  { focus: () => void }, // ref 객체에 추가할 커스텀 메서드 타입
  ReviewCommentBoxProps
>((props, ref) => {
  const { handleSubmit, reset, setValue, control } =
    useForm<ReviewCommentFormData>();
  const reviewCommentValue = useWatch({ control, name: "reviewComment" });

  useImperativeHandle(ref, () => ({
    focus: () => {
      const textarea = document.querySelector<HTMLTextAreaElement>(
        'textarea[name="reviewComment"]'
      );
      textarea?.focus();
    },
  }));

  // 각 줄의 최대 글자 수와 최대 줄 수
  const MAX_ROWS = 5;
  const MAX_CHARS_PER_ROW = 24; // 한 줄에 들어갈 최대 글자 수 (폰트, 너비에 따라 다를 수 있음)
  const MAX_TOTAL_CHARS = MAX_ROWS * MAX_CHARS_PER_ROW;

  const onSubmit = async (data: any) => {
    if (!props) {
      console.error("Review ID is missing");
      return;
    }

    try {
      const requestData = {
        reviewId: Number(props.reviewId), // 서버에서 요구하는 형식으로 변환
        contents: data.reviewComment,
        parentCommentId: props.commentParentId, // 기본값으로 설정
      };

      await reviewCommentRegist(requestData); // JSON 데이터 전송

      reset({ reviewComment: "" }); // ✅ 명확한 초기값 전달
      props.triggerUpdate();
    } catch (err) {
      console.error("Error submitting comment:", err);
    }
  };

  return (
    <RegistInputWrap>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* <input
          type="text"
          {...register("reviewComment", { required: "댓글을 입력해주세요." })}
          placeholder="댓글을 남겨보세요."
          onChange={(e) => setValue("reviewComment", e.target.value)} // react-hook-form의 값을 업데이트
        /> */}
        <Controller
          name="reviewComment"
          control={control}
          rules={{ required: "제목은 필수입니다." }}
          render={({ field }) => (
            <TextareaAutosize
              {...field}
              minRows={1}
              maxRows={5}
              placeholder="제목을 입력해주세요."
              onChange={(e) => {
                const sanitizedValue = e.target.value;
                if (sanitizedValue.length <= MAX_TOTAL_CHARS) {
                  field.onChange(sanitizedValue); // 제한된 길이 내에서 업데이트
                }
              }}
            />
          )}
        />
        <input
          type="submit"
          value="등록"
          className={reviewCommentValue ? "isActive" : ""}
          disabled={reviewCommentValue ? false : true}
        />
      </form>
    </RegistInputWrap>
  );
});

export default ReviewCommentRegistInput;

const RegistInputWrap = styled.div`
  form {
    padding: 0.8rem 1.6rem;
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    display: grid;
    grid-template-columns: auto 3.7rem;
    align-items: flex-end;
    gap: 0.8rem;
    background-color: white;
    z-index: 11;
    padding-bottom: 2.1rem;
    input[type="text"] {
      background-color: #ececec;
      border: 0;
      outline: 0;
      border-radius: 0.5rem;
      padding: 0.4rem;
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 1.6rem;
    }
    textarea {
      background-color: #ececec;
      border: 0;
      outline: 0;
      border-radius: 0.5rem;
      padding: 0.4rem;
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 1.6rem;
      resize: none;
    }
    input[type="submit"] {
      border: 0;
      outline: 0;
      border-radius: 0.5rem;
      padding: 0.4rem 0.8rem;
      color: white;
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 1.6rem;
      background-color: #cecece;
      &.isActive {
        background-color: #225298;
      }
    }
  }
  @media screen and (min-width: 785px) {
    position: fixed;
    bottom: 0;
    width: 37.3rem;
    form {
      position: static;
    }
  }
`;
