import RedirectPage from "components/auth/login/redirect.page";
import BackArrowLayout from "components/layout/back.arrow.layout";
import Layout from "components/layout/layout";
import { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PasswordForgot from "view/auth/fotgot/password.forgot";
import Join from "view/auth/join/join";
import LoginView from "view/auth/login";
import LoginPassword from "view/auth/login.password";
import TestView from "view/auth/test.view";
import NotFound from "view/error/error.404";
import HomeView from "view/home";
import InspectHome from "view/inspect/home";
import StepOne from "view/inspect/inspect.process/step.one";
import MeasureHome from "view/measure/home";
import ProfileHome from "view/setting/home";
import ReviewHome from "view/review/home";
import ReviewDetail from "view/review/children/review.detail";
import ReviewRegist from "view/review/children/review.regist";
import { initKakao } from "kakao-js-sdk";
import ReviewModify from "view/review/children/review.modify";
import BackArrowReviewLayout from "components/layout/back.arrow.review.layout";
import ReviewDeclaration from "view/review/children/review.declaration";
import SettingProfile from "view/setting/children/setting.profile/setting.profile";
import BlockUserManage from "view/setting/children/block.user.manage/block.user.manage";
import Notice from "view/setting/children/notice/notice";
import Inquiry from "view/setting/children/inquiry/inquiry";
import UseTerm from "view/setting/children/use.term.privacy/use.term";
import Privacy from "view/setting/children/use.term.privacy/privacy";
import SettingProfileUpdate from "view/setting/children/setting.profile/setting.profile.update";
import GoogleRedirectPageTest from "components/auth/login/google.redirect.page.test";
import AlramHome from "view/alram/alram";
import AlramSetting from "view/alram/children/alram.setting";
import NoiseMeter from "view/auth/test";
import DeleteAccount from "view/setting/children/setting.profile/delete.account/delete.account";
import MypageHome from "view/mypage/home";
import AdminLogin from "view/admin/auth/login";
import AdminLayout from "components/admin/layout/admin.layout";
import ColumnLists from "view/rs101.column/column.lists";
import ColumnDetail from "view/rs101.column/column.detail";
import AdminReviewHome from "view/admin/review/admin.review.home";
import AdminInquiryHome from "view/admin/inquiry/admin.inquiry.home";
import AdminInquiryDetail from "view/admin/inquiry/children/admin.inquiry.detail";
import AdminUserHome from "view/admin/user/admin.user.home";
import AdminBannerHome from "view/admin/contents/banner/admin.banner.home";
import AdminFooterPopupHome from "view/admin/contents/footer.popup/admin.footer.popup.home";
import AdminColumnHome from "view/admin/contents/column/admin.column.home";
import AdminDashboardHome from "view/admin/dashboard/admin.dashboard.home";
import AdminNoticeHome from "view/admin/contents/notice/admin.notice.home";
import AdminReviewDetail from "view/admin/review/children/admin.review.detail";
import AdminReportList from "view/admin/review/children/new.window/admin.report.list";
import AdminWindowLayout from "components/admin/layout/new.window/admin.window.layout";
import AdminBannerDetail from "view/admin/contents/banner/children/admin.banner.detail";
import AdminNoticeDetail from "view/admin/contents/notice/children/admin.notice.detail";
import AdminNoticeModify from "view/admin/contents/notice/children/admin.notice.modify";
import AdminNoticeRegist from "view/admin/contents/notice/children/admin.notice.regist";
import AppleRedirectPage from "components/auth/login/apple.redirect.page";
import AdminColumnDetail from "view/admin/contents/column/children/admin.column.detail";
import AdminColumnModify from "view/admin/contents/column/children/admin.column.modify";
import AdminColumnRegist from "view/admin/contents/column/children/admin.column.regist";
import AdminBannerRegist from "view/admin/contents/banner/children/admin.banner.regist";
import AdminFooterPopupDetail from "view/admin/contents/footer.popup/children/admin.footer.popup.detail";
import AdminFooterPopupRegist from "view/admin/contents/footer.popup/children/admin.footer.popup.regist";
import AdminUserDetail from "view/admin/user/children/admin.user.detail";
import AdminUserInquiryView from "view/admin/user/new.window/admin.user.inquiry.view";
import AdminUserReviewView from "view/admin/user/new.window/admin.user.review.view";
import AdminUserInfoView from "view/admin/user/new.window/admin.user.info.view";
import SimpleMeasureStep from "view/measure/children/simple.measure.step";
import SimpleMeasureRecording from "view/measure/children/simple.measure.recording";
import TestTestView from "view/auth/test.test";
import MeasureRecordingResult from "view/measure/children/measure.recording.result";
import MeasureRecordingResultList from "view/measure/children/measure.recording.result.list";
import UnauthenticatedSimpleMeasureStep from "view/measure/children/unauthenticated/unauthenticated.simple.measure.step";
import UnauthenticatedSimpleMeasureRecording from "view/measure/children/unauthenticated/unauthenticated.simple.measure.recording";
import UnauthenticatedMeasureRecordingResult from "view/measure/children/unauthenticated/unauthenticated.measure.recording.result";
import AndroidLoadingView from "components/auth/login/android.loading.view";

function App() {
  useEffect(() => {
    const handleResize = () => {
      const baseFontSize = 10; // 1920px 기준 기본 폰트 크기
      const minViewport = 320;
      const maxViewport = 785;

      if (
        window.innerWidth >= minViewport &&
        window.innerWidth <= maxViewport
      ) {
        // 320~785px 범위에서만 적용
        const newFontSize = `${
          (window.innerWidth / minViewport) * baseFontSize
        }px`;
        document.documentElement.style.fontSize = newFontSize;
      } else {
        // 785px 이상이면 기본 폰트 크기로 복귀
        document.documentElement.style.fontSize = "";
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // ✅ 초기 실행

    return () => {
      window.removeEventListener("resize", handleResize); // ✅ 컴포넌트 언마운트 시 이벤트 제거
    };
  }, []);

  useEffect(() => {
    const handlePushNavigation = (event: any) => {
      if (event.data?.type === "PUSH_NAVIGATION") {
        console.log("🔔 받은 푸시 URL:", event.data.url);
        window.location.href = event.data.url;
      }
    };

    window.addEventListener("message", handlePushNavigation);
    return () => window.removeEventListener("message", handlePushNavigation);
  }, []);

  initKakao(`${process.env.REACT_APP_KAKAO_JAVASCRIPY_KEY}`);

  return (
    <BrowserRouter basename="/">
      <Routes>
        {/* 기본 Layout */}
        <Route element={<BackArrowLayout />}>
          <Route index element={<LoginView />} />
        </Route>

        <Route
          path="login/password"
          element={<BackArrowLayout headerTitle="로그인" />}
        >
          <Route index element={<LoginPassword />} />
        </Route>
        <Route path="join">
          <Route index element={<Join />} />
        </Route>
        <Route path="join/forgotPassword">
          <Route index element={<PasswordForgot />} />
        </Route>

        <Route path="home" element={<Layout headerTitle="LOGO" />}>
          <Route index element={<HomeView />} />
        </Route>
        {/* 측정 */}
        <Route
          path="inspect"
          element={<Layout headerTitle="층간소음 민감도 검사" />}
        >
          <Route index element={<InspectHome />} />
        </Route>
        <Route path="measure" element={<Layout headerTitle="층간소음 측정" />}>
          <Route index element={<MeasureHome />} />
        </Route>
        <Route path="measure/simple/step">
          <Route index element={<SimpleMeasureStep />} />
        </Route>
        <Route path="measure/simple/recording">
          <Route index element={<SimpleMeasureRecording />} />
        </Route>
        <Route path="measure/simple/recording/result">
          <Route index element={<MeasureRecordingResult />} />
        </Route>
        <Route path="measure/simple/recording/result/list">
          <Route index element={<MeasureRecordingResultList />} />
        </Route>
        {/* unauthenticated measure */}
        <Route path="measure/unauthenticated/step">
          <Route index element={<UnauthenticatedSimpleMeasureStep />} />
        </Route>
        <Route path="measure/unauthenticated/simple/recording">
          <Route index element={<UnauthenticatedSimpleMeasureRecording />} />
        </Route>
        <Route path="measure/unauthenticated/simple/recording/result">
          <Route index element={<UnauthenticatedMeasureRecordingResult />} />
        </Route>

        {/* setting */}
        <Route
          path="setting"
          element={<BackArrowLayout headerTitle="설정" backPath="/home" />}
        >
          <Route index element={<ProfileHome />} />
        </Route>
        <Route
          path="setting/settingProfile"
          element={
            <BackArrowLayout headerTitle="계정 및 프로필" backPath="/setting" />
          }
        >
          <Route index element={<SettingProfile />} />
        </Route>
        <Route
          path="setting/settingProfile/update"
          element={<BackArrowLayout headerTitle="프로필 수정" />}
        >
          <Route index element={<SettingProfileUpdate />} />
        </Route>
        <Route
          path="setting/blockUser"
          element={
            <BackArrowLayout
              headerTitle="차단 사용자 관리"
              backPath="/setting"
            />
          }
        >
          <Route index element={<BlockUserManage />} />
        </Route>
        <Route
          path="setting/notice"
          element={
            <BackArrowLayout headerTitle="공지사항" backPath="/setting" />
          }
        >
          <Route index element={<Notice />} />
        </Route>
        <Route
          path="setting/inquiry"
          element={
            <BackArrowLayout headerTitle="1:1 문의" backPath="/setting" />
          }
        >
          <Route index element={<Inquiry />} />
        </Route>
        <Route
          path="setting/useTerm"
          element={
            <BackArrowLayout headerTitle="이용 약관" backPath="/setting" />
          }
        >
          <Route index element={<UseTerm />} />
        </Route>
        <Route
          path="setting/privacy"
          element={
            <BackArrowLayout
              headerTitle="개인정보 보호방침"
              backPath="/setting"
            />
          }
        >
          <Route index element={<Privacy />} />
        </Route>
        {/* 회원 탈퇴 */}
        <Route
          path="setting/deleteAccount"
          element={
            <BackArrowLayout headerTitle="탈퇴하기" backPath="/setting" />
          }
        >
          <Route index element={<DeleteAccount />} />
        </Route>

        {/* / mypage */}
        <Route path="mypage" element={<Layout headerTitle="마이페이지" />}>
          <Route index element={<MypageHome />} />
        </Route>

        <Route
          path="review"
          element={<BackArrowLayout headerTitle="후기" backPath="/home" />}
        >
          <Route index element={<ReviewHome />} />
        </Route>
        <Route path="review/regist" element={<ReviewRegist />} />
        <Route path="review/modify/:id" element={<ReviewModify />} />
        <Route path="declaration" element={<BackArrowLayout />}>
          <Route index element={<ReviewDeclaration />} />
        </Route>

        {/* login redirect page */}
        <Route path="kakao/oauth">
          <Route index element={<RedirectPage />} />
        </Route>
        <Route path="google/oauth">
          <Route index element={<GoogleRedirectPageTest />} />
        </Route>
        <Route path="apple/oauth">
          <Route index element={<AppleRedirectPage />} />
        </Route>
        <Route path="android/loading">
          <Route index element={<AndroidLoadingView />} />
        </Route>

        {/* Alram */}
        <Route
          path="alram"
          element={
            <BackArrowLayout
              headerTitle="알림"
              alramSettingBtn
              backPath="/home"
            />
          }
        >
          <Route index element={<AlramHome />} />
        </Route>
        <Route
          path="alram/setting"
          element={
            <BackArrowLayout headerTitle="알림 설정" backPath="/alram" />
          }
        >
          <Route index element={<AlramSetting />} />
        </Route>

        {/* back arrow Layout */}
        <Route
          path="inspect/step1"
          element={<BackArrowLayout headerTitle="층간소음 민감도 간이 검사" />}
        >
          <Route index element={<StepOne />} />
        </Route>

        <Route
          path="review/detail/:id"
          element={<BackArrowReviewLayout detailBtnBox backPath="/review" />}
        >
          <Route index element={<ReviewDetail />} />
        </Route>

        <Route
          path="column"
          element={<BackArrowLayout headerTitle="RS101 칼럼" />}
        >
          <Route index element={<ColumnLists />} />
        </Route>
        <Route
          path="column/detail/:id"
          element={<BackArrowReviewLayout columnMode backPath="/column" />}
        >
          <Route index element={<ColumnDetail />} />
        </Route>

        <Route path="test">
          <Route index element={<NoiseMeter />} />
        </Route>

        <Route path="testview">
          <Route index element={<TestView />} />
        </Route>

        <Route path="testtest/view">
          <Route index element={<TestTestView />} />
        </Route>

        {/* admin */}
        <Route path="admin">
          <Route index element={<AdminLogin />} />
        </Route>

        <Route
          path="admin/inquiry"
          element={<AdminLayout headerTitle="문의 관리" />}
        >
          <Route index element={<AdminInquiryHome />} />
          <Route path="detail/:id" index element={<AdminInquiryDetail />} />
        </Route>
        <Route
          path="admin/inquiry/detail/:id"
          element={
            <AdminLayout
              headerTitle="문의 상세"
              userInfoViewBtn
              backArrowBtn
              backArrowPath="/admin/inquiry"
            />
          }
        >
          <Route index element={<AdminInquiryDetail />} />
        </Route>
        <Route
          path="admin/review"
          element={<AdminLayout headerTitle="후기 관리" />}
        >
          <Route index element={<AdminReviewHome />} />
        </Route>

        <Route
          path="admin/review/detail/:id"
          element={
            <AdminLayout
              headerTitle="후기 상세"
              userInfoViewBtn
              backArrowBtn
              backArrowPath="/admin/review"
              reviewDetail
            />
          }
        >
          <Route index element={<AdminReviewDetail />} />
        </Route>

        <Route
          path="admin/user"
          element={<AdminLayout headerTitle="회원 관리" />}
        >
          <Route index element={<AdminUserHome />} />
        </Route>
        <Route
          path="admin/user/detail/:id"
          element={
            <AdminLayout
              headerTitle="회원 상세"
              backArrowBtn
              backArrowPath="/admin/user"
              userDetail
            />
          }
        >
          <Route index element={<AdminUserDetail />} />
        </Route>

        {/* 새창 열기 */}
        <Route
          path="admin/user/info/detail/:id"
          element={<AdminWindowLayout headerTitle="회원 상세" userBtnBox />}
        >
          <Route index element={<AdminUserInfoView />} />
        </Route>
        <Route
          path="admin/report/list/:id"
          element={<AdminWindowLayout headerTitle="신고내역보기" />}
        >
          <Route index element={<AdminReportList />} />
        </Route>
        <Route
          path="admin/user/detail/inquiry/:id"
          element={<AdminWindowLayout headerTitle="문의 상세" />}
        >
          <Route index element={<AdminUserInquiryView />} />
        </Route>
        <Route
          path="admin/user/detail/review/:id"
          element={<AdminWindowLayout headerTitle="후기 상세" reviewBtnBox />}
        >
          <Route index element={<AdminUserReviewView />} />
        </Route>

        <Route
          path="admin/banner"
          element={
            <AdminLayout
              headerTitle="콘텐츠 관리"
              subTitle="메인 배너"
              contents="banner"
            />
          }
        >
          <Route index element={<AdminBannerHome />} />
        </Route>
        <Route
          path="admin/banner/detail/:id"
          element={
            <AdminLayout
              headerTitle="메인 배너 등록사항 수정"
              backArrowBtn
              backArrowPath="/admin/banner"
              contentsDetail="banner"
            />
          }
        >
          <Route index element={<AdminBannerDetail />} />
        </Route>
        <Route
          path="admin/banner/regist"
          element={
            <AdminLayout
              headerTitle="메인 배너 신규 등록"
              backArrowBtn
              backArrowPath={`/admin/banner`}
              contentsDetail="banner"
            />
          }
        >
          <Route index element={<AdminBannerRegist />} />
        </Route>

        <Route
          path="admin/footerpopup"
          element={
            <AdminLayout
              headerTitle="콘텐츠 관리"
              subTitle="하단 팝업배너"
              contents="footer"
            />
          }
        >
          <Route index element={<AdminFooterPopupHome />} />
        </Route>
        <Route
          path="admin/footerpopup/detail/:id"
          element={
            <AdminLayout
              headerTitle="하단 팝업배너 등록사항 수정"
              backArrowBtn
              backArrowPath="/admin/footerpopup"
              contentsDetail="footer"
            />
          }
        >
          <Route index element={<AdminFooterPopupDetail />} />
        </Route>
        <Route
          path="admin/footerpopup/regist"
          element={
            <AdminLayout
              headerTitle="하단 팝업배너 신규 등록"
              backArrowBtn
              backArrowPath={`/admin/footerpopup`}
              contentsDetail="footer"
            />
          }
        >
          <Route index element={<AdminFooterPopupRegist />} />
        </Route>

        <Route
          path="admin/notice"
          element={
            <AdminLayout
              headerTitle="콘텐츠 관리"
              subTitle="공지사항"
              contents="notice"
            />
          }
        >
          <Route index element={<AdminNoticeHome />} />
        </Route>
        <Route
          path="admin/notice/detail/:id"
          element={
            <AdminLayout
              headerTitle="공지상세"
              backArrowBtn
              backArrowPath="/admin/notice"
              contentsDetail="notice"
            />
          }
        >
          <Route index element={<AdminNoticeDetail />} />
        </Route>
        <Route
          path="admin/notice/regist"
          element={
            <AdminLayout
              headerTitle="신규 공지 작성"
              backArrowBtn
              backArrowPath={`/admin/notice`}
              contentsDetail="notice"
            />
          }
        >
          <Route index element={<AdminNoticeRegist />} />
        </Route>
        <Route
          path="admin/notice/update/:id"
          element={
            <AdminLayout
              headerTitle="공지 수정"
              backArrowBtn
              backArrowPath={`/admin/notice/detail/`}
              contentsDetail="notice"
            />
          }
        >
          <Route index element={<AdminNoticeModify />} />
        </Route>

        <Route
          path="admin/column"
          element={
            <AdminLayout
              headerTitle="콘텐츠 관리"
              subTitle="RS101 칼럼"
              contents="column"
            />
          }
        >
          <Route index element={<AdminColumnHome />} />
        </Route>
        <Route
          path="admin/column/detail/:id"
          element={
            <AdminLayout
              headerTitle="칼럼상세"
              backArrowBtn
              backArrowPath="/admin/column"
              contentsDetail="column"
            />
          }
        >
          <Route index element={<AdminColumnDetail />} />
        </Route>
        <Route
          path="admin/column/update/:id"
          element={
            <AdminLayout
              headerTitle="칼럼수정"
              backArrowBtn
              backArrowPath={`/admin/column/detail/`}
              contentsDetail="column"
            />
          }
        >
          <Route index element={<AdminColumnModify />} />
        </Route>
        <Route
          path="admin/column/regist"
          element={
            <AdminLayout
              headerTitle="신규 칼럼 작성"
              backArrowBtn
              backArrowPath={`/admin/column`}
              contentsDetail="column"
            />
          }
        >
          <Route index element={<AdminColumnRegist />} />
        </Route>

        <Route
          path="admin/dashboard"
          element={<AdminLayout headerTitle="대시보드" />}
        >
          <Route index element={<AdminDashboardHome />} />
        </Route>

        {/* 404 페이지 */}
        <Route path="/404" element={<NotFound />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
