import { BannerDto } from "interface/interface.main.home";
import Slider from "react-slick";
import styled from "styled-components";

interface BannerDTOProps {
  listData: BannerDto[];
}

const BannerList = ({ listData }: BannerDTOProps) => {
  const sliderSetting = {
    infinite: listData.some((item) => item.files.length > 1), // listData에 두 개 이상의 배너가 있을 때만 슬라이드를 사용할 수 있게 설정
    speed: 500,
    slidesToShow: 1, // 3개 이하일 경우 슬라이드를 하나만 보여줌
    rows: 1, // 4개 이상일 경우에만 3개 행을 보여줌
    slidesToScroll: 1,
    arrows: false,
    dots: true,
  };
  return (
    <BannerListBox>
      <SliderWrapper>
        <div className="inner">
          <Slider {...sliderSetting}>
            {listData.map((banner) => (
              <div key={banner.bannerId} className="img-box">
                {banner.files.map((file) => (
                  <img
                    key={file.fileId}
                    src={file.accessUrl}
                    alt={file.realFileName}
                  />
                ))}
              </div>
            ))}
          </Slider>
        </div>
      </SliderWrapper>
    </BannerListBox>
  );
};

export default BannerList;

const BannerListBox = styled.div``;

export const SliderWrapper = styled.div`
  position: relative;
  .inner {
    padding-bottom: 2.9rem;
    .img-box {
      width: 31.5rem;
      height: 15.3rem;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .slick-arrow-custom {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      &.slick-arrow-left {
        left: -1.4rem;
      }
      &.slick-arrow-right {
        right: -1.4rem;
      }
    }
  }
  .slide-counter {
    position: absolute;
    bottom: 0.4rem;
    right: 2rem;
    padding: 0.2rem 0.4rem;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border-radius: 10rem;
    font-weight: 400;
    line-height: 1.2rem;
    font-size: 1rem;
  }
  .slick-dots {
    display: flex !important;
    justify-content: center;
    align-items: center;
    gap: 1.1rem;
    bottom: -1.7rem;
    li {
      width: auto;
      height: auto;
      margin: 0;
      button {
        width: 0.5rem;
        height: 0.5rem;
        padding: 0;
        &::before {
          width: 0.5rem;
          height: 0.5rem;
          line-height: 0.5rem;
          font-size: 0.5rem;
          border-radius: 40px;
          background-color: #d9d9d9;
          font-size: 0;
          content: "";
          opacity: 1;
        }
      }
      &.slick-active {
        button {
          &::before {
            opacity: 1;
            background-color: #242629;
          }
        }
      }
    }
  }
`;
