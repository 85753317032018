import { useEffect, useState } from "react";
import { MeasureResultContainer, ResultWrapper } from "style/measure";
import MeasureRecordingResultHeader from "./measure.recording.result.header";
import { fetchLoadMeasureRecordResult } from "api/measure";
import { SimpleMeasureRecordResult } from "interface/interface.measure";
import MeasureRecordResultTopInfo from "components/measure/components/measure.record.result.top.info";
import MeasureRecordResultTotalCount from "components/measure/components/measure.record.result.total.count";
import MeasureRecordResultSummary from "components/measure/components/measure.record.result.summay";
import { parseResultData } from "utils/data.parsing";
import MeasureRecordResultChart from "components/measure/components/measure.record.result.chart";

const MeasureRecordingResult = () => {
  const [data, setData] = useState<SimpleMeasureRecordResult | null>(null);
  const parsedData = data && parseResultData(data?.result);
  const isLoading = !data; // 데이터가 없으면 로딩 중

  useEffect(() => {
    const handleBack = () => {
      window.history.pushState(null, "", window.location.href);
    };

    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", handleBack);

    return () => {
      window.removeEventListener("popstate", handleBack);
    };
  }, []);

  useEffect(() => {
    const laodRecordResult = async () => {
      try {
        const response = await fetchLoadMeasureRecordResult();
        setData(response.result[0]);
      } catch (error) {
        console.error(error);
      }
    };

    laodRecordResult();
  }, []);

  return (
    <MeasureResultContainer>
      <MeasureRecordingResultHeader />
      <ResultWrapper>
        {isLoading ? (
          <p>로딩 중...</p>
        ) : (
          <>
            <MeasureRecordResultTopInfo
              startDt={data!.startDt}
              endDt={data!.endDt}
              location={data!.location}
              simpleMeasureResultId={data!.simpleMeasureResultId}
            />
            <MeasureRecordResultTotalCount resultData={parsedData ?? []} />
            <MeasureRecordResultSummary resultData={parsedData ?? []} />
            <MeasureRecordResultChart
              resultData={parsedData ?? []}
              audioPath={data.recordingFileUrl}
            />
          </>
        )}
      </ResultWrapper>
    </MeasureResultContainer>
  );
};

export default MeasureRecordingResult;
