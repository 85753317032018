import styled from "styled-components";

export const ProfileWrap = styled.div`
  padding: 1.6rem;
  form {
    .input-box {
      position: relative;
      margin: 0 2.4rem 2rem;
      span {
        display: flex;
        position: absolute;
        bottom: 0;
        left: 50%;
        background-color: #1f75fe;
        width: 0;
        height: 2px;
        border-radius: 2px;
        transform: translateX(-50%);
        transition: all 0.5s;
      }
      input:focus ~ span {
        width: 100%;
      }

      input[type="text"] {
        width: 100%;
        border: 0;
        border-bottom: 1px solid #cecece;
        padding: 0.8rem 0.8rem 0.7rem;
        line-height: 2.4rem;
        font-size: 1.2rem;
        border-radius: 0;
        &:focus {
          outline: none;
        }
      }
    }
    .submit-btn {
      margin-top: 3.6rem;
      input[type="submit"] {
        width: 100%;
        outline: none;
        border: none;
        padding: 1rem;
        color: white;

        border-radius: 0.5rem;
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 2rem;
        transition: background-color 0.3s;
        background-color: #225298;
        &:disabled {
          background-color: #cecece;
        }
      }
    }
  }
`;

export const LengthCount = styled.div`
  position: absolute;
  bottom: -2rem;
  right: 0.4rem;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.6rem;
  color: #a1a1a1;
`;

export const ErrorMessage = styled.div`
  color: #ed2115;
  font-size: 1rem;
  margin-top: 0.4rem;
  position: absolute;
  left: 0.4rem;
  top: 4rem;
`;

export const ProfileAuthBox = styled.div`
  display: flex;
  flex-direction: column;
  p {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.6rem;
  }
  span {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.2rem;
    color: #575757;
  }
`;

export const DeleteAccountWrap = styled.div`
  padding: 1.6rem;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  .top-box {
    padding: 4rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.2rem;
    p {
      font-size: 1.6rem;
      font-weight: 700;
      line-height: 2.4rem;
    }
    span {
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 1.6rem;
    }
  }
  .bottom-box {
    display: flex;
    flex-direction: column;
    gap: 1.4rem;
    .info-txt-box {
      padding: 1.6rem;
      border: 1px solid black;
      border-radius: 0.5rem;
      p {
        white-space: pre-wrap;
        font-size: 1.1rem;
        line-height: 1.6rem;
        font-weight: 400;
      }
    }
    .input-box {
      display: flex;
      align-items: center;
      gap: 0.8rem;
      padding: 0.8rem;
      margin-bottom: 2.9rem;
      input[type="checkbox"] {
        display: none;
      }
      label {
        display: flex;
        gap: 0.8rem;
        align-items: center;
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 2rem;
        .icon-box {
          width: 1.6rem;
          height: 1.6rem;
          svg {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  .btn-box {
    display: flex;
    gap: 1.6rem;
    button {
      width: 100%;
      padding: 1rem;
      outline: none;
      border-radius: 0.5rem;
      font-size: 1.4rem;
      line-height: 2rem;
      letter-spacing: -0.06rem;
      font-weight: 500;
      &.l-btn {
        border: 1px solid #225298;
        color: #225298;
        background-color: white;
        &:disabled {
          background-color: #cecece;
          border-color: #cecece;
          color: white;
        }
      }
      &.r-btn {
        border: 1px solid #225298;
        background-color: #225298;
        color: white;
      }
    }
  }
`;

export const ProfileInfoBox = styled.div`
  display: flex;
  gap: 0.8rem;
  align-items: center;
  .img-box {
    width: 4rem;
    height: 4rem;
    display: flex;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10rem;
    }
  }
  .user-name {
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1.8rem;
    letter-spacing: -0.06rem;
  }
`;
