import { LogoutHandler } from "api/login";
import { logoutToastStatus } from "atoms/auth.state";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import styled from "styled-components";

const Logout = () => {
  const setLogoutState = useSetRecoilState(logoutToastStatus);
  const navigate = useNavigate();

  const logOutHandler = async () => {
    try {
      const response = await LogoutHandler();
      if (response.result) {
        setLogoutState(true);
        localStorage.clear();
        navigate("/");
      }
    } catch (error) {
      console.error(error);
    }
  };
  return <LogoutWrap onClick={logOutHandler}>로그 아웃</LogoutWrap>;
};

export default Logout;

const LogoutWrap = styled.div`
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
`;
