import {
  userDateLimitLiftModal,
  userDateLimitModal,
  userDatePermanentLimitModal,
  userInfoState,
} from "atoms/admin/contents/user.state";
import {
  reviewHideConfirmModalState,
  reviewShowConfirmModalState,
} from "atoms/admin/modal/modal.state";
import { reviewHideState } from "atoms/admin/review/admin.review.state";
import { AdminModalClosedIcon } from "cssReset/icon";
import { Helmet } from "react-helmet-async";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  Container,
  ContainerWrapper,
  MainWrapper,
  WindowHeader,
} from "style/admin/window.layout";

interface LayoutProps {
  headerTitle: string;
  reviewBtnBox?: boolean;
  userBtnBox?: boolean;
}

const AdminWindowLayout = ({
  headerTitle,
  reviewBtnBox,
  userBtnBox,
}: LayoutProps) => {
  const location = useLocation(); // 현재 경로 가져오기
  const params = new URLSearchParams(location.search);
  const userId = params.get("userId");
  const reviewHideValue = useRecoilValue(reviewHideState);
  const setReviewHideModal = useSetRecoilState(reviewHideConfirmModalState);
  const setReviewShowModal = useSetRecoilState(reviewShowConfirmModalState);

  const userInfoValue = useRecoilValue(userInfoState);
  const setDateLimitModal = useSetRecoilState(userDateLimitModal);
  const setDateLimitLiftModal = useSetRecoilState(userDateLimitLiftModal);
  const setDateLimitPermanentModal = useSetRecoilState(
    userDatePermanentLimitModal
  );

  const pageTitle = location.pathname.replace("/admin/home", "") || "Home";

  const closedHandler = () => {
    window.close();
  };

  const hostURL = process.env.REACT_APP_DEV_URL;

  const handleUserInfoClicked = (id: string) => {
    window.open(
      `${hostURL}/admin/user/info/detail/${id}`,
      "_blank",
      "width=1240,height=600,scrollbars=no,resizable=no,toolbar=no,menubar=no,location=no,status=no"
    );
  };

  // ✅ 회원 상태 판별 (타입 명확히 지정)
  const useYn: string | null = userInfoValue?.useYn ?? null;
  const blockExpDt: string | null = userInfoValue?.blockExpDt ?? null; // ✅ undefined 방지

  // ✅ 영구 제한 여부 확인 (문자열인지 먼저 체크)
  const isPermanentBlock = (blockExpDt ?? "").startsWith("9999-12-31");

  // ✅ 회원 상태 설정
  let userStatus;
  let isLimited = false; // 제한 상태 확인용 변수

  if (useYn !== "Y") {
    userStatus = <div className="withdraw item">탈퇴</div>;
  } else if (!blockExpDt) {
    userStatus = <div className="bluebox item">일반회원</div>;
  } else if (isPermanentBlock) {
    userStatus = <div className="graybox item">영구 제한</div>;
    isLimited = true;
  } else {
    userStatus = <div className="redbox item">일시 제한</div>;
    isLimited = true;
  }
  return (
    <Container>
      <Helmet>
        <title>RS101 | {pageTitle}</title>
        <meta property="og:type" content="website" />
      </Helmet>
      <WindowHeader>
        <div className="left">
          <div className="icon-box" onClick={closedHandler}>
            <AdminModalClosedIcon />
          </div>
          <h2>{headerTitle}</h2>
        </div>
        {reviewBtnBox && (
          <div className="right">
            <button onClick={() => handleUserInfoClicked(userId ?? "")}>
              회원 정보 보기
            </button>
            <button
              disabled={reviewHideValue === "Y"}
              onClick={() => {
                setReviewHideModal({ isOpen: true, isVisible: true });
              }}
            >
              글 숨기기
            </button>
            <button
              disabled={reviewHideValue === "N"}
              onClick={() => {
                setReviewShowModal({ isOpen: true, isVisible: true });
              }}
            >
              글 노출하기
            </button>
          </div>
        )}
        {userBtnBox && (
          <div className="right">
            <div className="user-info">
              <div className="profile">
                <div className="img-box">
                  <img
                    src={
                      userInfoValue.userProfileImg
                        ? userInfoValue.userProfileImg
                        : "/assets/icon/userprofile.png"
                    }
                    alt={`${userInfoValue.userName} 프로필`}
                  />
                </div>
                <span>{userInfoValue.userName}</span>{" "}
                {/* ✅ 텍스트 올바르게 출력 */}
              </div>
              <div className="userStatus">
                <span>{userStatus}</span>
              </div>
            </div>
            <div className="btn-box">
              {/* ✅ 일반 회원일 경우 "일시 이용제한" & "영구 이용제한" 활성화 */}
              <button
                disabled={useYn !== "Y" || isLimited}
                onClick={() => {
                  setDateLimitModal({ isOpen: true, isVisible: true });
                }}
              >
                일시 이용제한
              </button>
              <button
                disabled={useYn !== "Y" || isLimited}
                onClick={() => {
                  setDateLimitPermanentModal({ isOpen: true, isVisible: true });
                }}
              >
                영구 이용제한
              </button>

              {/* ✅ 제한 상태일 경우 "제한 해제" 버튼 활성화 */}
              <button
                disabled={useYn !== "Y" || !isLimited}
                onClick={() => {
                  setDateLimitLiftModal({ isOpen: true, isVisible: true });
                }}
              >
                제한 해제
              </button>
            </div>
          </div>
        )}
      </WindowHeader>
      <ContainerWrapper>
        <MainWrapper>
          <Outlet />
        </MainWrapper>
      </ContainerWrapper>
    </Container>
  );
};

export default AdminWindowLayout;
