import { BoxInner, TextBox } from "style/admin/layout";

interface ModalProps {
  closedHandler: () => void;
  onClick: () => void;
}

const AnswerRegistCancelModal = ({ closedHandler, onClick }: ModalProps) => {
  return (
    <BoxInner>
      <TextBox style={{ minHeight: "auto" }}>
        <p>답변을 등록을 중단하고 나가시겠습니까?</p>
        <span>작성 중인 내용은 저장되지 않습니다.</span>
      </TextBox>
      <div className="btn-box">
        <button className="continue" onClick={closedHandler}>
          머무르기
        </button>
        <span></span>
        <button className="del" onClick={onClick}>
          나가기
        </button>
      </div>
    </BoxInner>
  );
};

export default AnswerRegistCancelModal;
