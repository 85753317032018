import ReviewList from "components/review/reivew.list";
import { PlusIcon } from "cssReset/icon";
import { Link } from "react-router-dom";
import styled from "styled-components";

const ReviewHome = () => {
  return (
    <ReviewContainer>
      <ContentInner>
        <ReviewList />
      </ContentInner>
      <div className="regist-btn-box">
        <Link to="/review/regist">
          <span className="icon-box">
            <PlusIcon />
          </span>
          등록하기
        </Link>
      </div>
    </ReviewContainer>
  );
};

export default ReviewHome;

const ReviewContainer = styled.div`
  position: relative;
  height: 100%;
  .regist-btn-box {
    position: fixed;
    bottom: 3.7rem;
    right: 1.6rem;
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.4rem;
      background-color: #225298;
      padding: 1.6rem;
      border-radius: 10rem;
      color: white;
      font-size: 1.6rem;
      font-weight: 700;
      line-height: 2.4rem;
      .icon-box {
        display: flex;
        width: 1.6rem;
        height: 1.6rem;
        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
`;

const ContentInner = styled.div`
  padding: 2.4rem 1.6rem;
`;
