import {
  bannerSubmitTriggerState,
  isBannerChangedState,
} from "atoms/admin/contents/banner.state";
import { useRecoilValue, useSetRecoilState } from "recoil";
import styled from "styled-components";

interface Props {
  isRegistPage?: boolean; // ✅ 새로 추가된 props
}

const AdminFooterPopupBtnBox = ({ isRegistPage }: Props) => {
  const isBannerChanged = useRecoilValue(isBannerChangedState);
  const setBannerSubmitTrigger = useSetRecoilState(bannerSubmitTriggerState);

  return (
    <BtnsWrap>
      <div className="blue-btn-box">
        <button
          disabled={!isBannerChanged}
          onClick={() => setBannerSubmitTrigger(true)}
        >
          {isRegistPage ? "등록하기" : "적용하기"}
        </button>
      </div>
    </BtnsWrap>
  );
};

export default AdminFooterPopupBtnBox;

export const BtnsWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  button {
    border: 1px solid #cecece;
    outline: none;
    padding: 1.4rem 1.6rem;
    font-size: 2rem;
    line-height: 2.8rem;
    font-weight: 700;
    background-color: white;
    border-radius: 0.5rem;
    color: #242629;
    box-shadow: 1px 1px 4px 0 #00000040;
    cursor: pointer;
    &:disabled {
      color: #cecece;
      cursor: default;
    }
  }
  .blue-btn-box {
    button {
      &:disabled {
        color: white;
        cursor: default;
      }
    }
  }
`;
