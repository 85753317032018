import { useEffect, useState } from "react";
import {
  RadioBox,
  InputWarpper,
  JoinDescription,
  NextBtn,
  ErrorMessage,
} from "style/join";

const JoinDefaultInfo = ({
  register,
  handleNext,
  watch,
  setError,
  setValue,
  clearErrors,
}: any) => {
  const [errorMessage, setErrorMessage] = useState(""); // 에러 메시지 상태
  const [isButtonDisabled, setIsButtonDisabled] = useState(true); // 버튼 활성화 상태

  const birthDate = watch("birthDate", ""); // birthDate 값 감시
  const sex = watch("sex"); // 성별 값 감시

  const formatBirthDate = (value: string) => {
    // 숫자만 남기기
    const onlyNumbers = value.replace(/[^0-9]/g, "");

    // 형식에 맞게 변환
    const formatted = onlyNumbers
      .slice(0, 8) // 최대 8자리까지만
      .replace(/^(\d{4})(\d{0,2})(\d{0,2})$/, (match, year, month, day) => {
        return [year, month, day].filter(Boolean).join(" - ");
      });

    return formatted;
  };

  const validateBirthDate = () => {
    const dateRegex = /^\d{4} - \d{2} - \d{2}$/; // 생년월일 형식 검증
    if (!birthDate || !dateRegex.test(birthDate)) {
      setErrorMessage("올바른 생년월일 형식(YYYY - MM - DD)으로 입력해주세요.");
      return false;
    }

    const [year, month, day] = birthDate.split(" - ").map(Number); // 연, 월, 일 분리

    // 년도 검증
    if (year < 1900 || year > 2300) {
      setErrorMessage("년도는 1900년부터 2300년까지 입력 가능합니다.");
      return false;
    }

    // 월 검증
    if (month < 1 || month > 12) {
      setErrorMessage("월은 01부터 12까지 입력 가능합니다.");
      return false;
    }

    // 일 검증
    if (day < 1 || day > 31) {
      setErrorMessage("일은 01부터 31까지 입력 가능합니다.");
      return false;
    }

    // 유효한 날짜인지 확인
    const isValidDate = new Date(year, month - 1, day).getDate() === day;
    if (!isValidDate) {
      setErrorMessage("유효한 날짜를 입력해주세요.");
      return false;
    }

    // 14세 이상 검증
    const today = new Date();
    const birth = new Date(year, month - 1, day);
    const age = today.getFullYear() - birth.getFullYear();
    const isBirthdayPassed =
      today.getMonth() > birth.getMonth() ||
      (today.getMonth() === birth.getMonth() &&
        today.getDate() >= birth.getDate());
    const calculatedAge = isBirthdayPassed ? age : age - 1;

    if (calculatedAge < 14) {
      setErrorMessage("만 14세 이상만 회원가입이 가능합니다.");
      return false;
    }

    setErrorMessage(""); // 에러 메시지 초기화
    return true;
  };

  const handleBirthDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const formattedValue = formatBirthDate(inputValue);
    setValue("birthDate", formattedValue); // 포맷팅된 값 업데이트
    clearErrors("birthDate"); // 기존 에러 초기화
  };

  const handleNextStep = () => {
    if (validateBirthDate()) {
      handleNext(); // 다음 스텝으로 이동
    }
  };

  useEffect(() => {
    // 버튼 활성화 조건: 생년월일 유효성 + 성별 선택
    const isBirthDateValid = validateBirthDate();
    const isSexValid = !!sex; // 성별 값이 존재하는지 확인
    setIsButtonDisabled(!(isBirthDateValid && isSexValid));
  }, [birthDate, sex]); // 생년월일 또는 성별이 변경될 때마다 실행

  return (
    <div>
      <JoinDescription>
        <p>기본정보를 입력해주세요.</p>
        <span>
          {
            "만 14세 이상만 회원가입 및 서비스 이용이 가능합니다. \n입력해주신 정보는 공개되지 않습니다."
          }
        </span>
      </JoinDescription>
      <InputWarpper>
        <div className="input-box-custom" style={{ marginBottom: "4.4rem" }}>
          <label htmlFor="birthDate">생년월일</label>
          <input
            type="text"
            id="birthDate"
            placeholder="YYYY - MM - DD"
            {...register("birthDate", {
              required: "생년월일을 입력해주세요.",
              validate: () => validateBirthDate(),
            })}
            onChange={handleBirthDateChange} // 입력값 포맷팅 및 업데이트
          />
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </div>
        <div className="input-box-custom">
          <label htmlFor="birthDate">성별</label>
          <RadioBox>
            <div className="radio-box">
              <input
                type="radio"
                id="male"
                value="M"
                {...register("sex", { required: "성별을 선택해주세요." })}
              />
              <label htmlFor="male">남성</label>
            </div>
            <div className="radio-box">
              <input
                type="radio"
                id="female"
                value="F"
                {...register("sex", { required: "성별을 선택해주세요." })}
              />
              <label htmlFor="female">여성</label>
            </div>
          </RadioBox>
        </div>
      </InputWarpper>
      <NextBtn
        type="button"
        onClick={handleNextStep}
        disabled={isButtonDisabled}
      >
        다음
      </NextBtn>
    </div>
  );
};

export default JoinDefaultInfo;
