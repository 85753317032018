import { useState } from "react";
import {
  NoticeContainer,
  SearchBox,
  TabWrapper,
  TapItem,
  TapMenuBox,
  TapMenuItem,
  UnderLine,
} from "style/admin/contents";
import { TapMenuWrapper } from "style/admin/inquiry";
import { AnimatePresence, motion } from "framer-motion";
import { AdminSearchIcon } from "cssReset/icon";
import AdminNoticeList from "components/admin/contents/notice/admin.notice.list";

const AdminNoticeHome = () => {
  const [tap, setTap] = useState(0);
  const [searchInput, setSearchInput] = useState(""); // ✅ 입력값 상태
  const [searchTerm, setSearchTerm] = useState(""); // ✅ API 요청을 위한 상태

  // ✅ 탭 변경 핸들러
  const menuHandler = (idx: number) => {
    setTap(idx);
    setSearchInput("");
    setSearchTerm("");
  };

  // ✅ 검색 실행 (버튼 클릭 또는 Enter 입력 시 실행)
  const handleSearch = () => {
    setSearchTerm(searchInput); // ✅ API를 호출하는 상태 변경
  };

  // ✅ Enter 키 입력 시 검색 실행
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <NoticeContainer>
      <TapMenuWrapper>
        <TapMenuBox>
          {["정상노출", "숨겨짐", "전체글"].map((menu, index) => (
            <TapMenuItem
              key={index}
              onClick={() => menuHandler(index)}
              className={tap === index ? "isActive" : ""}
              layout
              transition={{ type: "spring", stiffness: 300, damping: 20 }}
            >
              <motion.span transition={{ duration: 0.3 }} className="manu-name">
                {menu}
              </motion.span>

              {tap === index && (
                <UnderLine
                  layoutId="underline"
                  className="underline"
                  transition={{ type: "spring", stiffness: 300, damping: 20 }}
                />
              )}
            </TapMenuItem>
          ))}
        </TapMenuBox>

        {/* ✅ 검색창 */}
        <SearchBox>
          <div className="icon-box" onClick={handleSearch}>
            <AdminSearchIcon />
          </div>

          <input
            type="text"
            placeholder="제목, 내용, 작성자 검색"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            onKeyDown={handleKeyDown}
          />
        </SearchBox>
      </TapMenuWrapper>

      <TabWrapper>
        <AnimatePresence mode="wait" initial={false}>
          {[0, 1, 2].map(
            (index) =>
              tap === index && (
                <TapItem
                  key={index}
                  initial={{ opacity: 0, x: 20 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: -20 }}
                  transition={{ duration: 0.3 }}
                >
                  <AdminNoticeList
                    searchTerm={searchTerm}
                    {...(index === 0 ? { useYn: "Y" } : {})}
                    {...(index === 1 ? { useYn: "N" } : {})}
                  />
                </TapItem>
              )
          )}
        </AnimatePresence>
      </TabWrapper>
    </NoticeContainer>
  );
};

export default AdminNoticeHome;
