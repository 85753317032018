import axiosInstance from "plugin/axios";

export const fetchProfileInfo = async () => {
  try {
    const response = await axiosInstance.get(`/user/my-info`);
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

export const fetchProfileUserNameUpdate = async (userName: string) => {
  try {
    const response = await axiosInstance.put(`/user/user-name`, null, {
      params: {
        userName: userName,
      },
    });

    return response.data;
  } catch (error) {
    throw new Error();
  }
};

export const fetchProfileUserImgUpdate = async (file: File) => {
  try {
    const formData = new FormData();
    formData.append("files", file);

    const response = await axiosInstance.put(`/user/profile`, formData, {
      headers: {
        "Content-Type": "multipart/form-data", // Content-Type을 multipart/form-data로 설정
      },
    });

    return response.data;
  } catch (error) {
    throw new Error();
  }
};

export const fetchDeleteAccount = async () => {
  try {
    const response = await axiosInstance.delete(`/user`);
    return response.data;
  } catch (error) {
    throw new Error();
  }
};
