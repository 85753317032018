import {
  deleteAccountConfirmModalState,
  deleteConfirmModalState,
} from "atoms/modal.state";
import { userInformation } from "atoms/user.info";
import ModalCenterLayout from "components/modal/modal.center.layout";
import { CheckBoxChecked, CheckboxDefault } from "cssReset/icon";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { DeleteAccountWrap, ProfileInfoBox } from "style/setting";
import { DeleteAccountInfoText } from "utils/datas";
import ConfirmDeleteModal from "./modal/confirm.delete.modal";
import { fetchDeleteAccount } from "api/profile";
import AfterDeleteAccountModal from "./modal/after.delete.account.modal";
import { logoutToastStatus } from "atoms/auth.state";

const DeleteAccount = () => {
  const userInfo = useRecoilValue(userInformation);
  const navigate = useNavigate();
  console.log(userInfo);
  const { register, handleSubmit, watch } = useForm();
  const setLogoutState = useSetRecoilState(logoutToastStatus);

  const isChecked = watch("deleteAccountChk");

  const [deleteConfirmModalStatus, setDeleteConfirmModalStatus] =
    useRecoilState(deleteConfirmModalState);
  const [deleteAccountModalStatus, setDeleteAccountModalStatus] =
    useRecoilState(deleteAccountConfirmModalState);

  const onSubmit = (data: any) => {
    if (data.deleteAccountChk) {
      handleConfirmDeletelModalOpened();
    }
  };

  const keepClickedHandler = () => {
    navigate("/setting/settingProfile");
  };

  // confirm delete modal
  const handleConfirmDeletelModalClosed = () => {
    setDeleteConfirmModalStatus({ isOpen: false, isVisible: false });
  };
  const handleConfirmDeletelModalOpened = () => {
    setDeleteConfirmModalStatus({ isOpen: true, isVisible: true });
  };

  // after delete modal
  const handleAfterDeletelConfirmModalClosed = () => {
    setDeleteAccountModalStatus({ isOpen: false, isVisible: false });
    setLogoutState(true);
    localStorage.clear();
    navigate("/");
  };
  const handleAfterDeletelConfirmModalOpened = () => {
    setDeleteAccountModalStatus({ isOpen: true, isVisible: true });
  };

  const deleteAccountHandler = async () => {
    try {
      const response = await fetchDeleteAccount();
      if (response.result) {
        handleAfterDeletelConfirmModalOpened();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <DeleteAccountWrap>
      <div className="top-box">
        {userInfo && (
          <ProfileInfoBox>
            <div className="img-box">
              {userInfo.files && userInfo.files.length > 0 ? (
                <img src={userInfo.files[0].accessUrl} alt="" />
              ) : (
                <img src="/assets/icon/userprofile.png" alt="" />
              )}
            </div>
            <div className="user-name">{userInfo.userName}</div>
          </ProfileInfoBox>
        )}
        <p>님, 정말 탈퇴하시나요?</p>
        <span>탈퇴하시기에 앞서 아래 내용을 반드시 확인해주세요.</span>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="bottom-box">
          <div className="info-txt-box">
            <p>{DeleteAccountInfoText}</p>
          </div>
          <div className="input-box">
            <input
              type="checkbox"
              {...register("deleteAccountChk")}
              id="deleteAccountChk"
            />
            <label htmlFor="deleteAccountChk">
              <span className="icon-box">
                {isChecked ? <CheckBoxChecked /> : <CheckboxDefault />}{" "}
              </span>
              위 내용을 모두 확인하였습니다.
            </label>
          </div>
        </div>

        <div className="btn-box">
          <button className="l-btn" type="submit" disabled={!isChecked}>
            회원 탈퇴
          </button>
          <button className="r-btn" onClick={keepClickedHandler}>
            회원 유지하기
          </button>
        </div>
      </form>

      {/* 전체 삭제 확인 모달 */}
      {deleteConfirmModalStatus.isOpen && (
        <ModalCenterLayout
          closedHandler={handleConfirmDeletelModalClosed}
          isVisible={deleteConfirmModalStatus.isVisible}
        >
          <ConfirmDeleteModal
            closedHandler={handleConfirmDeletelModalClosed}
            deleteAccountHandler={deleteAccountHandler}
          />
        </ModalCenterLayout>
      )}

      {/* 삭제 완료 후 모달 */}
      {deleteAccountModalStatus.isOpen && (
        <ModalCenterLayout
          closedHandler={handleAfterDeletelConfirmModalClosed}
          isVisible={deleteAccountModalStatus.isVisible}
        >
          <AfterDeleteAccountModal
            closedHandler={handleAfterDeletelConfirmModalClosed}
          />
        </ModalCenterLayout>
      )}
    </DeleteAccountWrap>
  );
};

export default DeleteAccount;
