import axiosInstance from "plugin/axios";
import { useEffect, useState } from "react";

export interface ApiResponse {
  message: string | null;
  result: ReviewResult;
}

export interface ReviewResult {
  reviewId: number;
  type: string;
  contents: string;
  fileGroupId: string;
  useYn: "Y" | "N";
  regUserId: string;
  updtUserId: string | null;
  regDt: string;
  updtDt: string | null;
}

const testviewHandler = async (): Promise<ApiResponse> => {
  try {
    const response = await axiosInstance.get(`/review/${14}`);
    return response.data;
  } catch (error: any) {
    throw new Error("데이터를 불러오는 데 실패했습니다.");
  }
};

const TestView = () => {
  const [data, setData] = useState<ApiResponse | null>(null); // ApiResponse 타입 적용
  const [error, setError] = useState<string | null>(null); // 에러 메시지 타입 지정

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await testviewHandler();
        setData(result); // 가져온 데이터 설정
      } catch (error: any) {
        setError(error.message); // 에러 메시지 설정
      }
    };
    fetchData();
  }, []);

  if (error) {
    return <div>에러 발생: {error}</div>;
  }

  if (!data) {
    return <div>로딩 중...</div>;
  }

  return (
    <div>
      <h1>리뷰 상세 정보</h1>
      <p>
        <strong>리뷰 ID:</strong> {data.result.reviewId}
      </p>
      <p>
        <strong>유형:</strong> {data.result.type}
      </p>
      <p>
        <strong>내용:</strong> {data.result.contents}
      </p>
      <p>
        <strong>파일 그룹 ID:</strong> {data.result.fileGroupId}
      </p>
      <p>
        <strong>사용 여부:</strong> {data.result.useYn}
      </p>
      <p>
        <strong>등록 사용자:</strong> {data.result.regUserId}
      </p>
      <p>
        <strong>수정 사용자:</strong> {data.result.updtUserId || "없음"}
      </p>
      <p>
        <strong>등록일:</strong> {new Date(data.result.regDt).toLocaleString()}
      </p>
      <p>
        <strong>수정일:</strong>{" "}
        {data.result.updtDt
          ? new Date(data.result.updtDt).toLocaleString()
          : "없음"}
      </p>
    </div>
  );
};

export default TestView;
