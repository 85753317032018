import { fetchLoadAdminNoticeDetail } from "api/admin/constent.notice";
import AdminNoticeDetailTopContents from "components/admin/contents/notice/admin.notice.detail.top.contents";
import { NoticeDto } from "interface/interface.notice";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DetailContainer, SliderWrapper } from "style/admin/contents";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import {
  CustomNextArrow,
  CustomPrevArrow,
} from "components/admin/slick.arrow/slick.arrow";
import AdminCommonModalLayout from "components/admin/common.modal.layout/admin.common.modal";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  noticeHideConfirmModalState,
  noticeHideInfoState,
  noticeHideState,
  noticeShowConfirmModalState,
} from "atoms/admin/contents/notice.state";
import HideNoticeModal from "components/admin/contents/notice/modal/hide.notice.modal";
import ShowNoticeModal from "components/admin/contents/notice/modal/show.notice.modal";

const AdminNoticeDetail = () => {
  const { id } = useParams();
  const [data, setData] = useState<NoticeDto>();
  const [currentSlide, setCurrentSlide] = useState(1); // 현재 슬라이드 번호
  const [noticeHideModal, setNoticeHideModal] = useRecoilState(
    noticeHideConfirmModalState
  );
  const [noticeShowModal, setNoticeShowModal] = useRecoilState(
    noticeShowConfirmModalState
  );
  const setNoticeHide = useSetRecoilState(noticeHideState);
  const setNoticeHideInfo = useSetRecoilState(noticeHideInfoState);

  const sliderSetting = {
    infinite: !!(data?.files && data.files.length > 1), // ✅ null 제거 및 boolean 변환
    speed: 500,
    slidesToShow: 1, // 3개 이하일 경우 슬라이드를 하나만 보여줌
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />, // ✅ 커스텀 컴포넌트 사용
    nextArrow: <CustomNextArrow />, // ✅ 커스텀 컴포넌트 사용
    afterChange: (current: number) => setCurrentSlide(current + 1), // 현재 슬라이드 번호 업데이트
  };

  useEffect(() => {
    const loadAdminNoticeDetail = async () => {
      try {
        const response = await fetchLoadAdminNoticeDetail(Number(id));
        setData(response.result);
        setNoticeHide(response.result.useYn);
        setNoticeHideInfo({
          announcementId: Number(id),
          hideYn: response.result.useYn,
        });
      } catch (error) {
        console.error(error);
      }
    };
    loadAdminNoticeDetail();
  }, [noticeHideModal, noticeShowModal]);

  // notice 노출 handler
  const noticeHideModalClosed = () => {
    setNoticeHideModal({ isOpen: false, isVisible: false });
  };
  const noticeShowModalClosed = () => {
    setNoticeShowModal({ isOpen: false, isVisible: false });
  };

  return (
    <DetailContainer>
      {data && (
        <AdminNoticeDetailTopContents
          title={data.title}
          useYn={data.useYn}
          regUserId={data.regUserId}
          regDt={data.regDt}
          views={data.views}
        />
      )}
      <div className="detail-wrapper">
        <div className="contents-box">
          <p className="content-txt">{data?.contents}</p>
          {data?.files && data.files.length > 0 ? (
            <SliderWrapper>
              <div className="inner">
                <Slider {...sliderSetting}>
                  {data.files.map((img, index) => (
                    <div className="img-box" key={img.fileId}>
                      {/* src url 배포시 수정 */}
                      <img src={img.accessUrl} alt={img.realFileName} />
                    </div>
                  ))}
                </Slider>
                {/* 현재 슬라이드 위치 표시 */}
                <div className="slide-counter">
                  {currentSlide} / {data.files.length}
                </div>
              </div>
            </SliderWrapper>
          ) : null}
        </div>
      </div>

      {/* 후기 숨기기 모달 */}
      {noticeHideModal && (
        <AdminCommonModalLayout
          isVisible={noticeHideModal.isVisible}
          closedHandler={noticeHideModalClosed}
        >
          {data && (
            <HideNoticeModal
              closedHandler={noticeHideModalClosed}
              announcementId={data?.announcementId}
            />
          )}
        </AdminCommonModalLayout>
      )}

      {/* 후기 노출하기 모달 */}
      {noticeShowModal && (
        <AdminCommonModalLayout
          isVisible={noticeShowModal.isVisible}
          closedHandler={noticeShowModalClosed}
        >
          {data && (
            <ShowNoticeModal
              closedHandler={noticeShowModalClosed}
              announcementId={data?.announcementId}
            />
          )}
        </AdminCommonModalLayout>
      )}
    </DetailContainer>
  );
};

export default AdminNoticeDetail;
