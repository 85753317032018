import { reviewDeclararionHandler } from "api/review";
import { declarationProps, duplicationModalState } from "atoms/modal.state";
import { useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { AnimatePresence, motion } from "framer-motion";
import ModalCenterLayout from "components/modal/modal.center.layout";
import ModalDuplicationDeclaration from "./modal/modal.duplication.declaration";

const MAX_LENGTH = 300;

interface FormDataProps {
  declarationText: string;
}

const ReviewDeclaration = () => {
  const [reviewDeclarationData, setReviewDeclarationData] =
    useRecoilState(declarationProps);
  const { register, handleSubmit, control } = useForm<FormDataProps>();
  const navigate = useNavigate();

  const [duplicationModalStatus, setDuplicationModalStatus] = useRecoilState(
    duplicationModalState
  );

  const [completeState, setCompleteState] = useState(false);
  const declarationText = useWatch({
    control,
    name: "declarationText",
    defaultValue: "",
  });

  const onSubmit = async (data: FormDataProps) => {
    try {
      const requstData = {
        type: reviewDeclarationData.reviewType ?? "",
        targetId: reviewDeclarationData.declarationTargetId ?? 0,
        reason: data.declarationText,
      };
      const response = await reviewDeclararionHandler(requstData);
      if (response.result) {
        setCompleteState(true);
      }
    } catch (error: any) {
      if (error.message === "409") {
        console.log("이미 접수된 신고입니다.");
        // 추후 modal로 변경
        handleDuplicationModalOpen();
      }
      console.log(error);
    }
  };

  const handleClickBtn = () => {
    reviewDeclarationData.reviewType === "REVIEW"
      ? navigate("/review")
      : navigate(`/review/detail/${reviewDeclarationData.reviewId}`);
  };

  // 중복 신고 팝업
  const handleDuplicationModalOpen = () => {
    setDuplicationModalStatus({ isOpen: true, isVisible: true });
  };
  const handleDuplicationModalClosed = () => {
    setDuplicationModalStatus({ isOpen: false, isVisible: false });
    handleClickBtn();
  };

  return (
    <DeclarationWrap>
      <AnimatePresence mode="wait">
        {!completeState ? (
          <form onSubmit={handleSubmit(onSubmit)}>
            <DeclarationContentBox>
              <textarea
                {...register("declarationText", {
                  required: "빈 칸으로는 제출할 수 없습니다.",
                  maxLength: 300,
                })}
                placeholder="신고 내용을 입력하여 주세요. (최대 300자)"
              ></textarea>
              <TextCount>
                {declarationText.length} / {MAX_LENGTH}
              </TextCount>
            </DeclarationContentBox>
            <DeclarationBtnBox>
              <input type="submit" value="신고하기" />
            </DeclarationBtnBox>
          </form>
        ) : (
          <CompleteWrap
            initial={{ opacity: 0, y: 20 }} // 초기 상태 (살짝 오른쪽에서 시작)
            animate={{ opacity: 1, y: 0 }} // 나타날 때
            exit={{ opacity: 0, y: -20 }} // 사라질 때 (왼쪽으로 이동하며 사라짐)
            transition={{ duration: 0.3 }} // 애니메이션 속도
          >
            <div className="txt-box">
              <div className="inner">
                <p>신고 되었습니다.</p>
                <span>
                  신고해주신 내용을 검토하여 빠르게 조치하겠습니다. <br />{" "}
                  감사합니다.
                </span>
              </div>
            </div>
            <div className="btn-box">
              <button onClick={handleClickBtn}>닫기</button>
            </div>
          </CompleteWrap>
        )}
      </AnimatePresence>

      {duplicationModalStatus.isOpen && (
        <ModalCenterLayout
          closedHandler={handleDuplicationModalClosed}
          isVisible={duplicationModalStatus.isVisible}
        >
          <ModalDuplicationDeclaration
            modalContents="이미 접수된 신고입니다."
            closedHandler={handleDuplicationModalClosed}
          />
        </ModalCenterLayout>
      )}
    </DeclarationWrap>
  );
};

export default ReviewDeclaration;

const DeclarationWrap = styled.div`
  height: calc(100vh - 5.6rem);
  form {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    input,
    textarea {
      font-family: "Pretendard", sans-serif;
    }
  }
`;

const DeclarationContentBox = styled.div`
  padding: 1.6rem;
  textarea {
    width: 100%;
    padding: 0.8rem;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.6rem;
    color: #242629;
    border: 1px solid #cecece;
    border-radius: 0.5rem;
    min-height: 16rem;
    resize: none;
  }
`;

const DeclarationBtnBox = styled.div`
  padding: 1.6rem 1.6rem 3.7rem;
  input[type="submit"] {
    width: 100%;
    background-color: #225298;
    color: white;
    border: none;
    outline: none;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 2.4rem;
    padding: 1.6rem;
    border-radius: 0.5rem;
  }
`;

const TextCount = styled.div`
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.6rem;
  color: #a1a1a1;
  text-align: right;
  margin-top: 0.4rem;
`;

const CompleteWrap = styled(motion.div)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .txt-box {
    padding: 1.6rem;
    .inner {
      padding: 4rem 0;
      display: flex;
      flex-direction: column;
      gap: 1.2rem;
      justify-content: center;
      p {
        font-size: 1.6rem;
        font-weight: 700;
        line-height: 2.4rem;
        text-align: center;
      }
      span {
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 1.6rem;
        text-align: center;
      }
    }
  }
  .btn-box {
    padding: 1.6rem 1.6rem 3.7rem;
    button {
      width: 100%;
      background-color: #225298;
      color: white;
      border: none;
      outline: none;
      font-size: 1.6rem;
      font-weight: 700;
      line-height: 2.4rem;
      padding: 1.6rem;
      border-radius: 0.5rem;
    }
  }
`;
