import { InquiryParams } from "interface/admin/interface.inquiry";
import axiosInstance from "plugin/axios";

export const fetchLoadAdminInquiryNotAnswer = async (params: InquiryParams) => {
  try {
    const response = await axiosInstance.get(`/inquiry/not-answer`, {
      params: params,
    });
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

export const fetchLoadAdminInquiry = async (params: InquiryParams) => {
  try {
    const response = await axiosInstance.get(`/inquiry`, {
      params: params,
    });

    return response.data;
  } catch (error) {
    throw new Error();
  }
};

// detail fetch
export const fetchLoadAdminInquiryDetail = async (id: number) => {
  try {
    const response = await axiosInstance.get(`/inquiry/${id}`);
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

export const fetchUpdateInquiryDetailAnswer = async (
  inquiryId: number,
  answer: string
) => {
  try {
    const response = await axiosInstance.put(`/inquiry/${inquiryId}`, {
      answer,
    });

    return response.data;
  } catch (error) {
    throw new Error();
  }
};
