import { fetchDeleteColumn } from "api/admin/content.column";
import { useNavigate, useParams } from "react-router-dom";
import { BoxInner, TextBox } from "style/admin/layout";

interface ModalProps {
  closedHandler: () => void;
}

const modalTxt = `수정 적용된 글은 숨겨진 상태입니다.

작성 내용을 확인하신 후, 
문제가 없다면 [글 노출하기] 버튼을 눌러주세요.`;

const ColumnUpdateCompleteModal = ({ closedHandler }: ModalProps) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const clickHandler = () => {
    hideClickedHandle(Number(id));
    closedHandler();
    navigate(`/admin/column/detail/${id}`);
  };

  const hideClickedHandle = async (id: number) => {
    try {
      const response = await fetchDeleteColumn(id);
      console.log(response);
      if (response.result) closedHandler();
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <BoxInner>
      <TextBox style={{ minHeight: "auto" }}>
        <p>칼럼이 수정되었습니다.</p>
        <span>{modalTxt}</span>
      </TextBox>
      <div className="btn-box">
        <button className="continue" onClick={clickHandler}>
          확인
        </button>
      </div>
    </BoxInner>
  );
};

export default ColumnUpdateCompleteModal;
