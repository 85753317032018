import {
  AlramDto,
  NotificationSettingsRequest,
} from "interface/interface.alram";
import axiosInstance from "plugin/axios";

// 알림 리스트
export const fetchAlramHandler = async (params: AlramDto) => {
  try {
    const response = await axiosInstance.get(`/notification`, { params });
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

// 알림 셋팅 정보
export const fetchLoadAlramSetting = async () => {
  try {
    const response = await axiosInstance.get(`/notification/setting`);
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

export const updateNotificationSettings = async (
  settings: NotificationSettingsRequest
) => {
  try {
    const response = await axiosInstance.put("/notification/setting", settings);
    return response.data; // 성공적으로 응답을 받으면 데이터 반환
  } catch (error) {
    console.error("알림 설정 수정 에러:", error);
    throw new Error(); // 오류 발생 시 에러를 throw
  }
};

// 알림 모두 삭제
export const fetchAlramAllDelete = async () => {
  try {
    const response = await axiosInstance.delete(`/notification/allRemove`);
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

// 읽음 처리 (알림을 클릭하면 읽음 처리 한다.)
export const fetchAlramReadHandler = async (notificationId: number) => {
  try {
    const response = await axiosInstance.put(
      `/notification/${notificationId}/read`
    );
    return response.data;
  } catch (error) {
    throw new Error();
  }
};
