import { KakaoSimbol } from "cssReset/icon";
import styled from "styled-components";

const KakaoSocialLogin = () => {
  const REST_API_KEY = process.env.REACT_APP_KAKAO_REST_API;
  const REDIRECT_URI = process.env.REACT_APP_DEV_URL;
  //const redirect_uri = "http://localhost:3000/kakao/oauth";
  //const redirect_uri = "http://221.143.21.225:59090/kakao/oauth";
  const redirect_uri = REDIRECT_URI + "/kakao/oauth";
  const kakaoURL = `https://kauth.kakao.com/oauth/authorize?client_id=${REST_API_KEY}&redirect_uri=${redirect_uri}&response_type=code`;
  const handleLogin = () => {
    window.location.href = kakaoURL;
  };
  return (
    <div>
      <KaKaoBtn onClick={handleLogin}>
        {/* <img src="/assets/icon/kakao_login_medium_wide.png" alt="" /> */}
        <span>
          <KakaoSimbol />
        </span>
        <p>카카오 로그인</p>
      </KaKaoBtn>
    </div>
  );
};

export default KakaoSocialLogin;

const KaKaoBtn = styled.button`
  border: 0;
  outline: 0;
  background-color: transparent;
  padding: 0;
  display: grid;
  grid-template-columns: 2rem auto;
  width: 100%;
  border-radius: 0.5rem;
  padding: 1rem 1.2rem;
  background-color: #fee500;
  img {
    max-width: 100%;
    width: 100%;
  }
  span {
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 2rem;
      height: 2rem;
    }
  }
  p {
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 2rem;
    color: rgba(0, 0, 0, 0.85);
  }
`;
