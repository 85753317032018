import { HomeIcon, InspectIcon, MeasureIcon, UserIcon } from "cssReset/icon";
import { AlramMenuItem, InquiryTypeProps } from "interface/interface.alram";
import { MenuItem } from "interface/interface.mypage";

export const FooterMenu = [
  { path: "/home", title: "홈", icon: HomeIcon },
  { path: "/inspect", title: "검사", icon: InspectIcon },
  { path: "/measure", title: "측정", icon: MeasureIcon },
  { path: "/mypage", title: "MY", icon: UserIcon },
];

export const SettingMenuData: MenuItem[] = [
  { title: "사용자 설정", isTitle: true },
  { title: "계정 및 프로필", isTitle: false, path: "/setting/settingProfile" },
  { title: "차단사용자 관리", isTitle: false, path: "/setting/blockUser" },
  { title: "프리미엄 측정", isTitle: true },
  { title: "프리미엄 자세히 알아보기", isTitle: false },
  { title: "기타", isTitle: true },
  { title: "공지사항", isTitle: false, path: "/setting/notice" },
  { title: "1:1 문의", isTitle: false, path: "/setting/inquiry" },
  { title: "이용 약관", isTitle: false, path: "/setting/useTerm" },
  { title: "개인정보 처리방침", isTitle: false, path: "/setting/privacy" },
  { title: "앱 버전", isTitle: false },
];

export const AlramSettingMenuData: AlramMenuItem[] = [
  { title: "커뮤니티 알림", isTitle: true, isBtn: false },
  {
    title: "작성한 글/댓글에 달린 댓글/답글 알림",
    isTitle: false,
    isBtn: true,
    checkBoxName: "commentNotify",
  },
  {
    title: "작성한 글에 달린 좋아요 알림",
    isTitle: false,
    isBtn: true,
    checkBoxName: "likeNotify",
  },
  {
    title: "프리미엄 측정 알림",
    isTitle: true,
    isBtn: false,
  },
  {
    title: "이상 소음 발생 시 알림",
    isTitle: false,
    isBtn: true,
    checkBoxName: "abnormalNoiseNotify",
  },
  {
    title: "측정 키트 연결 끊김 및 오류 발생 시 알림",
    isTitle: false,
    isBtn: true,
    checkBoxName: "kitErrorNotify",
  },
  {
    title: "혜택, 이벤트 및 기타 알림",
    isTitle: true,
    isBtn: false,
  },
  {
    title: "RS101 공지사항",
    isTitle: false,
    isBtn: true,
    checkBoxName: "announcementNotify",
  },
  {
    title: "마케팅 수신 동의",
    isTitle: false,
    isBtn: true,
    checkBoxName: "marketingNotify",
  },
];

export const InquiryTypeDt: InquiryTypeProps[] = [
  { id: "USER", name: "회원가입/정보관리" },
  { id: "SERVICE", name: "시스템/서비스 오류" },
  { id: "DEVICE", name: "디바이스" },
  { id: "PAY", name: "결제" },
  { id: "EVENT", name: "이벤트" },
  { id: "ETC", name: "기타" },
];

export const DeleteAccountInfoText = `회원 탈퇴가 완료되면 회원님의 계정과 관련된 모든 정보가 삭제되며 복구가 불가능합니다. 커뮤니티에서 작성한 게시글, 댓글, 좋아요 등의 기록 또한 삭제되며, 다른 회원들에게도 더 이상 표시되지 않습니다.

탈퇴 시 회원님이 진행했던 검사 결과와 분석 내용, 저장된 기록 등이 모두 영구적으로 삭제됩니다. 삭제된 데이터는 다시 복원할 수 없으니, 필요한 경우 탈퇴 전 데이터를 별도로 저장해 두시기 바랍니다.

유료 구독 서비스나 정기 결제 상품을 이용 중이라면, 탈퇴 전에 반드시 구독을 취소해야 합니다. 탈퇴 후에도 구독이 유지될 경우, 추가 결제가 발생할 수 있으니 [결제 관리] 메뉴에서 확인 및 취소를 진행해 주세요.

관계 법령에 따라 일정 기간 동안 일부 정보(결제 기록 등)가 보관될 수 있습니다. 이 정보는 법적 의무 이행을 위해서만 사용되며, 보관 기간이 만료되면 안전하게 파기됩니다.`;

export const AdminMenuDt = [
  { title: "문의 관리", path: "/admin/inquiry" },
  { title: "후기 관리", path: "/admin/review" },
  { title: "회원 관리", path: "/admin/user" },
  {
    title: "콘텐츠 관리",
    subMenu: [
      { title: "메인 배너", path: "/admin/banner" },
      { title: "하단 팝업배너", path: "/admin/footerpopup" },
      { title: "공지사항", path: "/admin/notice" },
      { title: "RS101 칼럼", path: "/admin/column" },
    ],
  },
  { title: "대시보드", path: "/admin/dashboard" },
];
