import { atom } from "recoil";

export const noticeHideConfirmModalState = atom<{
  isOpen: boolean;
  isVisible: boolean;
  onClick?: () => void;
}>({
  key: "noticeHideConfirmModalState",
  default: {
    isOpen: false,
    isVisible: false,
  },
});

export const noticeShowConfirmModalState = atom<{
  isOpen: boolean;
  isVisible: boolean;
  onClick?: () => void;
}>({
  key: "noticeShowConfirmModalState",
  default: {
    isOpen: false,
    isVisible: false,
  },
});

export const noticeHideState = atom<"Y" | "N">({
  key: "noticeHideState",
  default: "N",
});

export const noticeHideInfoState = atom<{
  announcementId: number;
  hideYn: "Y" | "N";
}>({
  key: "noticeHideInfoState",
  default: {
    announcementId: 0,
    hideYn: "N",
  },
});

export const noticeSubmitTriggerState = atom<boolean>({
  key: "noticeSubmitTriggerState",
  default: false, // 기본값 false
});

export const noticeUpdateCompleteModalState = atom<{
  isOpen: boolean;
  isVisible: boolean;
}>({
  key: "noticeUpdateCompleteModalState",
  default: {
    isOpen: false,
    isVisible: false,
  },
});

export const noticeAwayModalState = atom<{
  isOpen: boolean;
  isVisible: boolean;
}>({
  key: "noticeAwayModalState",
  default: {
    isOpen: false,
    isVisible: false,
  },
});

export const isNoticeChangedState = atom<boolean>({
  key: "isNoticeChangedState",
  default: false, // 기본값: 변경 없음
});

export const noticeRegistSubmitTriggerState = atom<boolean>({
  key: "noticeRegistSubmitTriggerState",
  default: false, // 기본값 false
});

export const noticeRegistCompleteModalState = atom<{
  isOpen: boolean;
  isVisible: boolean;
}>({
  key: "noticeRegistCompleteModalState",
  default: {
    isOpen: false,
    isVisible: false,
  },
});

export const noticeRegistAwayModalState = atom<{
  isOpen: boolean;
  isVisible: boolean;
}>({
  key: "noticeRegistAwayModalState",
  default: {
    isOpen: false,
    isVisible: false,
  },
});

export const isNoticeRegistChangedState = atom<boolean>({
  key: "isNoticeRegistChangedState",
  default: false, // 기본값: 변경 없음
});
