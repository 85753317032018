import { ClickedItem, NoiseData } from "interface/interface.measure";
import {
  calculateFiveMinuteLeq,
  calculateLeq,
  classifyAndFilterExceedingValues,
  classifyDayNightData,
} from "utils/measure.util";
import MeasureRecordResultLogItem from "./measure.record.result.log.item";

interface DataProps {
  resultData: NoiseData[];
  clickedItemHandler: (item: ClickedItem) => void;
  clickedItem?: ClickedItem;
}

const MeasureRecordResultNoiseLog = ({
  resultData,
  clickedItemHandler,
  clickedItem,
}: DataProps) => {
  const { dayData, nightData } = classifyDayNightData(resultData);

  const { dayExceeded, nightExceeded } =
    classifyAndFilterExceedingValues(resultData);

  // 1분 등가소음도 분석
  const dayLeqData = dayData.length > 0 ? calculateLeq(dayData) : [];
  const nightLeqData = nightData.length > 0 ? calculateLeq(nightData) : [];

  // 5분 등가소음도 분석
  const dayFiveMinuteLeqData =
    dayLeqData.length > 0 ? calculateFiveMinuteLeq(dayLeqData) : [];
  const nightFiveMinuteLeqData =
    nightLeqData.length > 0 ? calculateFiveMinuteLeq(nightLeqData) : [];

  return (
    <div className="noise-log-wrapper">
      <div className="info-wrap">
        <h3>기준치 이상 소음 발생 로그</h3>
        <span>
          목록에서 소음 발생 시각을 누르면, 상단 소음도 측정 그래프에서 해당
          시각으로 이동합니다.
        </span>
      </div>
      <div className="log-box">
        {dayLeqData.length !== 0 && (
          <MeasureRecordResultLogItem
            title="1분 등가소음도 (주간)"
            data={dayLeqData}
            clickedItemHandler={clickedItemHandler}
            clickedItem={clickedItem}
          />
        )}
        {nightLeqData.length !== 0 && (
          <MeasureRecordResultLogItem
            title="1분 등가소음도 (야간)"
            data={nightLeqData}
            clickedItemHandler={clickedItemHandler}
            clickedItem={clickedItem}
          />
        )}
        {dayFiveMinuteLeqData.length !== 0 && (
          <MeasureRecordResultLogItem
            title="5분 등가소음도(주간)"
            data={dayFiveMinuteLeqData}
            clickedItemHandler={clickedItemHandler}
            clickedItem={clickedItem}
          />
        )}
        {nightFiveMinuteLeqData.length !== 0 && (
          <MeasureRecordResultLogItem
            title="5분 등가소음도(야간)"
            data={nightFiveMinuteLeqData}
            clickedItemHandler={clickedItemHandler}
            clickedItem={clickedItem}
          />
        )}
        {dayData.length !== 0 && (
          <MeasureRecordResultLogItem
            title="순간소음도(주간)"
            data={dayExceeded}
            clickedItemHandler={clickedItemHandler}
            clickedItem={clickedItem}
          />
        )}
        {nightData.length !== 0 && (
          <MeasureRecordResultLogItem
            title="순간소음도(야간)"
            data={nightExceeded}
            clickedItemHandler={clickedItemHandler}
            clickedItem={clickedItem}
          />
        )}
      </div>
    </div>
  );
};

export default MeasureRecordResultNoiseLog;
