import { BoxInner, TextBox } from "style/admin/layout";
import { useNavigate } from "react-router-dom";

interface ModalProps {
  closedHandler: () => void;
}

const ColumnRegistAwayModal = ({ closedHandler }: ModalProps) => {
  const navigate = useNavigate();

  return (
    <BoxInner>
      <TextBox style={{ minHeight: "auto" }}>
        <p>공지 작성을 중단하고 나가시겠습니까?</p>
        <span>작성 중인 내용은 저장되지 않습니다.</span>
      </TextBox>
      <div className="btn-box">
        <button className="continue" onClick={closedHandler} type="button">
          머무르기
        </button>
        <span></span>
        <button
          className="del"
          onClick={() => {
            closedHandler();
            navigate(`/admin/column/`);
          }}
        >
          나가기
        </button>
      </div>
    </BoxInner>
  );
};

export default ColumnRegistAwayModal;
