import axios from "axios"; // 기본 axios 모듈에서 isAxiosError 가져오기
import axiosInstance from "../plugin/axios";

interface LoginRequest {
  userId: string;
  password: string;
  deviceToken?: string;
  isAndroid?: string;
}

interface LoginResponse {
  result: {
    accessToken: string;
    refreshToken: string;
    expire: number;
  };
  userType: string;
}

export interface AppleRequest {
  code: string;
  email?: string;
  name?: string;
}

export const loginHandler = async (
  data: LoginRequest
): Promise<LoginResponse> => {
  console.log("data:", data);
  try {
    const response = await axiosInstance.post<LoginResponse>("/signin", data);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(
        error.response?.data.message || "로그인 요청에 실패했습니다."
      );
    } else {
      throw new Error("Unexpected error occurred during login");
    }
  }
};

export const idValidHandler = async (userId: string) => {
  try {
    const response = await axiosInstance.get(`is-exists/${userId}`);
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

export const kakaoCodeHandler = async (
  code: string,
  deviceToken?: string,
  isAndroid?: string
) => {
  try {
    const response = await axiosInstance.post("/oauth2/kakao", {
      code,
      deviceToken,
      isAndroid,
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error; // ✅ 그대로 다시 throw
    } else {
      throw new Error("알 수 없는 오류 발생");
    }
  }
};

export const googleCodeHandler = async (
  code: string,
  deviceToken?: string,
  isAndroid?: string
) => {
  try {
    const response = await axiosInstance.post("/oauth2/google", {
      code,
      deviceToken,
      isAndroid,
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error; // ✅ 그대로 다시 throw
    } else {
      throw new Error("알 수 없는 오류 발생");
    }
  }
};

export const appleCodeHandler = async (data: AppleRequest) => {
  try {
    const response = await axiosInstance.post(`/oauth2/apple`, data);
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

export const appleTokenHandler = async (
  code: string,
  deviceToken?: string,
  isAndroid?: string
) => {
  try {
    const response = await axiosInstance.post(`/apple/token`, {
      code,
      deviceToken,
      isAndroid,
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error; // ✅ 그대로 다시 throw
    } else {
      throw new Error("알 수 없는 오류 발생");
    }
  }
};

export const LogoutHandler = async () => {
  try {
    const response = await axiosInstance.post(`/auth/logout`);
    return response.data;
  } catch (error) {
    throw new Error();
  }
};
