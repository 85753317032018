import { TableContainer, TableContents, TableTop } from "style/admin/table";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useMemo } from "react";
import { formatDateTime } from "utils/util";
import PaginationControls from "components/admin/table.components/pagination";
import { AdminReportDto } from "interface/admin/interface.report";

interface DataProps {
  data: AdminReportDto[];
}

const AdminReportListTable = ({ data }: DataProps) => {
  // ✅ 최신 등록일(`createdAt`)을 기준으로 내림차순 정렬
  const sortedData = useMemo(() => {
    return [...data].sort(
      (a, b) => new Date(b.regDt).getTime() - new Date(a.regDt).getTime()
    );
  }, [data]);

  const columns = useMemo<ColumnDef<AdminReportDto>[]>(
    () => [
      {
        header: "번호",
        id: "number", // 넘버링을 위한 id
        cell: ({ row }) => sortedData.length - row.index, // 각 행의 인덱스에 1을 더해 넘버링
        size: 80,
      },
      {
        header: "신고내용",
        accessorKey: "reason",
        cell: (info) => {
          const value = info.getValue();
          return (
            <div className="title-box" style={{ textAlign: "left" }}>
              {String(value)}
            </div>
          );
        },
        size: 757,
      },
      {
        header: "신고자",
        accessorKey: "userName",
        cell: (info) => {
          const row = info.row.original; // ✅ 현재 행(row)의 원본 데이터 가져오기
          const userName = row.userName;
          const userProfileUrl =
            row.userProfileUrl || "/assets/icon/userprofile.png"; // 기본 이미지 설정

          return userName ? (
            <div className="profile">
              <div className="img-box">
                <img
                  src={
                    userProfileUrl
                      ? userProfileUrl
                      : "/assets/icon/userprofile.png"
                  }
                  alt={`${userName} 프로필`}
                />
              </div>
              <span>{userName}</span> {/* ✅ 텍스트 올바르게 출력 */}
            </div>
          ) : (
            "-"
          );
        },
        size: 160,
      },
      {
        header: "신고일시",
        accessorKey: "regDt",
        cell: (info) => {
          const value = info.getValue(); // ✅ 값 가져오기
          return value ? formatDateTime(String(value)) : "-"; // ✅ 값이 없으면 "-" 반환
        },
        size: 180,
      },
    ],
    [sortedData]
  );

  const table = useReactTable({
    data: sortedData,
    columns,
    enableRowSelection: true,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: { pagination: { pageSize: 50 } }, // ✅ 초기 페이지 크기 설정
  });

  const paginationState = table.getState().pagination;
  const startRow = paginationState.pageIndex * paginationState.pageSize + 1;
  const endRow = startRow + table.getRowModel().rows.length - 1;
  const totalRows = sortedData.length;

  return (
    <TableContainer>
      <TableTop>
        <span className="table-count">
          {totalRows} 중 {startRow}-{endRow}
        </span>
        <PaginationControls table={table} />
      </TableTop>
      <TableContents style={{ maxHeight: "58.2rem" }}>
        <table
          className={
            table.getRowModel().rows.length === 0 ? "no-data-table" : ""
          }
        >
          <thead>
            {table.getHeaderGroups().map((item) => (
              <tr key={item.id}>
                {item.headers.map((header) => (
                  <th
                    key={header.id}
                    style={{ minWidth: `${header.getSize()}px` }}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.length > 0 ? (
              table.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    const isHideYn = cell.column.id === "hideYn";
                    const isTitle = cell.column.id === "title";
                    const isProfile = cell.column.id === "userName";

                    return (
                      <td
                        key={cell.id}
                        className={`${isHideYn ? "hide-state" : ""} ${
                          isTitle ? "title-cell" : ""
                        } ${isProfile ? "profile-cell" : ""}`.trim()} // ✅ 여러 개의 클래스 추가
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                </tr>
              ))
            ) : (
              // ✅ 데이터가 없을 경우 "검색 결과가 없습니다" 표시
              <tr className="no-data">
                <td colSpan={columns.length} className="no-data-td">
                  검색 결과가 없습니다.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </TableContents>
    </TableContainer>
  );
};

export default AdminReportListTable;
