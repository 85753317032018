import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import {
  TabWrapper,
  TapItem,
  TapMenuWrapper,
  UnderLine,
  Container,
  TapMenuBox,
  TapMenuItem,
} from "style/admin/user";
import UserDetailInformation from "components/admin/user/component/user.detail.information";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  userDateLimitLiftModal,
  userDateLimitModal,
  userDatePermanentLimitModal,
  userInfoState,
} from "atoms/admin/contents/user.state";
import AdminCommonModalLayout from "components/admin/common.modal.layout/admin.common.modal";
import DateLimitModal from "components/admin/user/modal/date.limit.modal";
import { useParams } from "react-router-dom";
import { UserProfile } from "interface/admin/interface.user";
import { fetchLoadUserInfoDetail } from "api/admin/user";
import DateLimitLiftModal from "components/admin/user/modal/date.limit.lift.modal";
import AdminUserDetailList from "components/admin/user/detail/admin.user.detail.list";

const AdminUserInfoView = () => {
  const [tap, setTap] = useState(0);
  const [dateLimitModal, setDateLimitModal] =
    useRecoilState(userDateLimitModal);
  const [dateLimitLiftModal, setDateLimitLiftModal] = useRecoilState(
    userDateLimitLiftModal
  );
  const [dateLimitPermanentModal, setDateLimitPermanentModal] = useRecoilState(
    userDatePermanentLimitModal
  );

  const { id } = useParams();
  const [data, setData] = useState<UserProfile>();
  const setUserInformation = useSetRecoilState(userInfoState);

  useEffect(() => {
    const loadUserInfo = async () => {
      try {
        const response = await fetchLoadUserInfoDetail(String(id));
        setData(response.result);
        setUserInformation({
          userId: response.result.userId,
          userName: response.result.userName,
          userState: response.result.blockExpDt,
          userProfileImg: response.result.userProfileUrl,
          useYn: response.result.useYn,
          blockExpDt: response.result.blockExpDt,
        });
      } catch (error) {
        console.error(error);
      }
    };

    loadUserInfo();
  }, [id, dateLimitModal, dateLimitLiftModal, dateLimitPermanentModal]);

  // ✅ 탭 변경 핸들러
  const menuHandler = (idx: number) => {
    setTap(idx);
  };

  const dateLimitModalClosed = () => {
    setDateLimitModal({ isOpen: false, isVisible: false });
  };

  const dateLimitLiftModalClosed = () => {
    setDateLimitLiftModal({ isOpen: false, isVisible: false });
  };

  const dateLimitPermanentModalClosed = () => {
    setDateLimitPermanentModal({ isOpen: false, isVisible: false });
  };

  return (
    <Container>
      <TapMenuWrapper>
        <TapMenuBox>
          {["기본정보", "문의내역", "작성글", "작성댓글"].map((menu, index) => (
            <TapMenuItem
              key={index}
              onClick={() => menuHandler(index)}
              className={tap === index ? "isActive" : ""}
              layout
              transition={{ type: "spring", stiffness: 300, damping: 20 }}
            >
              <motion.span transition={{ duration: 0.3 }} className="manu-name">
                {menu}
                {/* ✅ 탭이 0번일 때만 dot을 표시하고, 데이터가 존재할 경우 유지 */}
              </motion.span>

              {tap === index && (
                <UnderLine
                  layoutId="underline"
                  className="underline"
                  transition={{ type: "spring", stiffness: 300, damping: 20 }}
                />
              )}
            </TapMenuItem>
          ))}
        </TapMenuBox>
      </TapMenuWrapper>

      <TabWrapper>
        <AnimatePresence mode="wait" initial={false}>
          {tap === 0 && <UserDetailInformation data={data} />}
          {[1, 2, 3].map(
            (index) =>
              tap === index && (
                <TapItem
                  key={index}
                  initial={{ opacity: 0, x: 20 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: -20 }}
                  transition={{ duration: 0.3 }}
                >
                  <AdminUserDetailList
                    listIndex={index}
                    userId={data?.userId ?? ""}
                  />
                </TapItem>
              )
          )}
        </AnimatePresence>
      </TabWrapper>

      {dateLimitModal && (
        <AdminCommonModalLayout
          isVisible={dateLimitModal.isVisible}
          closedHandler={dateLimitModalClosed}
        >
          <DateLimitModal
            closedHandler={dateLimitModalClosed}
            userId={data?.userId ?? ""}
            blockTemporary
          />
        </AdminCommonModalLayout>
      )}

      {dateLimitPermanentModal && (
        <AdminCommonModalLayout
          isVisible={dateLimitPermanentModal.isVisible}
          closedHandler={dateLimitPermanentModalClosed}
        >
          <DateLimitModal
            closedHandler={dateLimitPermanentModalClosed}
            userId={data?.userId ?? ""}
          />
        </AdminCommonModalLayout>
      )}

      {dateLimitLiftModal && (
        <AdminCommonModalLayout
          isVisible={dateLimitLiftModal.isVisible}
          closedHandler={dateLimitLiftModalClosed}
        >
          <DateLimitLiftModal
            closedHandler={dateLimitLiftModalClosed}
            userId={data?.userId ?? ""}
          />
        </AdminCommonModalLayout>
      )}
    </Container>
  );
};

export default AdminUserInfoView;
