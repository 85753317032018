import styled from "styled-components";

interface ModalProps {
  closedHandler?: () => void;
  modalTitle?: string;
  modalContents?: string;
}

const ModalDuplicationDeclaration = ({
  closedHandler,
  modalTitle = "알림",
  modalContents = "내용을 입력해주세요.",
}: ModalProps) => {
  return (
    <BoxInner>
      <TextBox>
        <p>{modalTitle}</p>
        <span>{modalContents}</span>
      </TextBox>
      <div className="btn-box">
        <button onClick={closedHandler}>닫기</button>
      </div>
    </BoxInner>
  );
};

export default ModalDuplicationDeclaration;

const BoxInner = styled.div`
  display: flex;
  gap: 3.2rem;
  flex-direction: column;
  .btn-box {
    display: flex;
    position: relative;
    button {
      border: 0;
      outline: 0;
      flex: 1;
      background-color: transparent;
      padding: 1.8rem 0;
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 2rem;
      letter-spacing: -0.06rem;
      color: #575757;
      &.del {
        color: #ed2115;
      }
    }
    span {
      position: absolute;
      display: block;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 1px;
      height: 2.4rem;
      background-color: #a1a1a1;
    }
  }
`;

const TextBox = styled.div`
  display: flex;
  gap: 0.8rem;
  flex-direction: column;
  padding: 3.2rem 0 0;
  p {
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 2rem;
    letter-spacing: -0.06rem;
    text-align: center;
  }
  span {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 2rem;
    text-align: center;
    display: block;
    color: #575757;
  }
`;
