import styled from "styled-components";
import { motion } from "framer-motion";

export const Container = styled.div`
  padding: 3rem;
  height: 100%;
`;

export const ListBox = styled.div`
  height: 100%;
`;

export const BannerDetailContainer = styled.div`
  padding: 3rem 6rem;
  .tbox {
    padding: 2.4rem 0;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    p {
      font-size: 2rem;
      font-weight: 700;
      line-height: 2.4rem;
      letter-spacing: -0.06rem;
    }
    span {
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 1.6rem;
    }
  }
  .bbox {
    padding: 2.4rem 0;
    p {
      font-size: 2rem;
      font-weight: 700;
      line-height: 2.4rem;
      letter-spacing: -0.06rem;
    }
  }
  .input-box {
    padding: 1.6rem 0.8rem;
    border-bottom: 1px solid #a1a1a1;
    position: relative;
    input {
      border: none;
      width: 100%;
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 2.4rem;
    }
    span {
      display: flex;
      position: absolute;
      bottom: -1px;
      left: 50%;
      background-color: #1f75fe;
      width: 0;
      height: 2px;
      border-radius: 2px;
      transform: translateX(-50%);
      transition: all 0.5s;
    }
    input:focus ~ span {
      width: 100%;
    }
    input:focus {
      outline: none;
      border: none;
    }
  }
`;

// notice
export const NoticeContainer = styled.div`
  padding: 3rem;
  height: 100%;
`;
export const TapMenuBox = styled.div`
  display: flex;
  align-items: center;
  gap: 1.6rem;
  flex: 1;
`;

export const TapMenuItem = styled(motion.div)`
  width: 12rem;
  padding: 1.8rem 1.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 2.2rem;
  letter-spacing: -0.06rem;
  color: #a1a1a1;
  position: relative;
  cursor: pointer;
  &.isActive {
    color: black;
  }
  .manu-name {
    display: flex;
    gap: 0.4rem;
    span.dot {
      padding-top: 0.4rem;
      width: 0.6rem;
      height: 0.6rem;
      background-color: #ed2115;
      display: flex;
      border-radius: 0.6rem;
    }
  }
`;

export const UnderLine = styled(motion.div)`
  position: absolute;
  bottom: -1px;
  left: 0;
  height: 2px;
  width: 100%;
  background-color: black;
  border-radius: 2px;
`;

export const SearchBox = styled.div`
  width: 41.5rem;
  display: flex;
  align-items: center;
  gap: 1.6rem;
  padding: 0.8rem 1.6rem;
  border: 1px solid #cecece;
  border-radius: 0.5rem;
  .icon-box {
    display: flex;
    width: 2.4rem;
    height: 2.4rem;
    svg {
      width: 100%;
      height: 100%;
    }
  }
  input {
    width: 100%;
    border: 0;
    font-size: 1.6rem;
    font-weight: 700;
    letter-spacing: -0.06rem;
    line-height: 2rem;
  }
`;

export const TabWrapper = styled.div`
  height: calc(100% - 5.7rem);
`;

export const TapItem = styled(motion.div)`
  height: 100%;
`;

export const DetailContainer = styled.div`
  padding: 3rem;
  height: 100%;
  .info-box {
    padding: 1.6rem;
    background-color: #ececec;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .tbox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      p {
        font-size: 2rem;
        font-weight: 700;
        line-height: 2.4rem;
        letter-spacing: -0.06rem;
      }
      span {
        margin-right: 0.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 8rem;
        padding: 0.8rem;
        font-size: 1.6rem;
        font-weight: 700;
        letter-spacing: -0.06rem;
        line-height: 2rem;
        color: white;
        border-radius: 100px;
        &.bluebox {
          background-color: #225298;
        }
        &.redbox {
          background-color: #ed2115;
        }
      }
    }
    .bbox {
      display: flex;
      align-items: center;
      gap: 2.4rem;
      .profile {
        display: flex;
        align-items: center;
        gap: 0.4rem;
        min-width: 16rem;
        .img-box {
          width: 2rem;
          height: 2rem;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
          }
        }
        span {
          font-size: 1.6rem;
          font-weight: 700;
          line-height: 2.4rem;
          max-width: 11rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .regUserName {
        span {
          font-size: 1.6rem;
          font-weight: 700;
          line-height: 2.4rem;
          max-width: 11rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .date,
      .view,
      .reple {
        display: flex;
        gap: 0.4rem;
      }
      .date span,
      .view span,
      .reple span {
        font-weight: 700;
        font-size: 1.6rem;
        line-height: 2rem;
        letter-spacing: -0.06rem;
        color: #575757;
      }
      .report {
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 2rem;
        letter-spacing: -0.06rem;
        text-decoration: underline;
        cursor: pointer;
        &.report-txt {
          color: #ed2115;
          font-weight: 500;
        }
      }
    }
  }
  .detail-wrapper {
    max-height: 67rem;
    overflow: hidden scroll;
    .contents-box {
      padding: 1.6rem 2.4rem;
      display: flex;
      flex-direction: column;
      gap: 1.6rem;
      .content-txt {
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 2.4rem;
      }
    }
  }
`;

export const SliderWrapper = styled.div`
  position: relative;
  .inner {
    padding: 0 4.8rem;
    width: 100%;
    height: 31.7rem;
    max-width: 111.2rem;
    margin: 0 auto;
    position: relative;
    .img-box {
      height: 31.7rem;
      img {
        height: 100%;
        object-fit: contain;
        border-radius: 0.5rem;
        overflow: hidden;
        max-width: 100%;
        margin: 0 auto;
      }
    }
    .slick-prev,
    .slick-next {
      width: 4rem;
      height: 4rem;
    }
    .slick-prev {
      left: -4.8rem;
    }
    .slick-next {
      right: -4.8rem;
    }
  }
  .slide-counter {
    position: absolute;
    bottom: 0.6rem;
    right: 5.9rem;
    padding: 0.2rem 0.4rem;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border-radius: 10rem;
    font-weight: 400;
    line-height: 1.2rem;
    font-size: 1rem;
  }
`;

export const NoticeModifyContainer = styled.div`
  padding: 3rem 6rem;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  .title-box {
    padding: 1.6rem 0.8rem;
    border-bottom: 1px solid #a1a1a1;
    position: relative;
    input[type="text"] {
      width: 100%;
      border: none;
      outline: none;
      font-weight: 700;
      font-size: 2rem;
      line-height: 2.4rem;
      letter-spacing: -0.06rem;
    }
    span {
      display: flex;
      position: absolute;
      bottom: -1px;
      left: 50%;
      background-color: #1f75fe;
      width: 0;
      height: 2px;
      border-radius: 2px;
      transform: translateX(-50%);
      transition: all 0.5s;
    }
    input:focus ~ span {
      width: 100%;
    }
  }
  .contents-box {
    position: relative;
    padding: 1.6rem 0.8rem;
    border-bottom: 1px solid #a1a1a1;
    min-height: 40rem;
    textarea {
      width: 100%;
      border: none;
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 2.4rem;
      padding: 0;
      resize: none;
    }
    span {
      display: flex;
      position: absolute;
      bottom: -1px;
      left: 50%;
      background-color: #1f75fe;
      width: 0;
      height: 2px;
      border-radius: 2px;
      transform: translateX(-50%);
      transition: all 0.5s;
    }
    textarea:focus {
      border: none;
      outline: none;
    }
    textarea:focus ~ span {
      width: 100%;
    }
  }
`;

export const NoticeRegistContainer = styled.div`
  padding: 3rem 6rem;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  .title-box {
    padding: 1.6rem 0.8rem;
    border-bottom: 1px solid #a1a1a1;
    position: relative;
    input[type="text"] {
      width: 100%;
      border: none;
      outline: none;
      font-weight: 700;
      font-size: 2rem;
      line-height: 2.4rem;
      letter-spacing: -0.06rem;
    }
    span {
      display: flex;
      position: absolute;
      bottom: -1px;
      left: 50%;
      background-color: #1f75fe;
      width: 0;
      height: 2px;
      border-radius: 2px;
      transform: translateX(-50%);
      transition: all 0.5s;
    }
    input:focus ~ span {
      width: 100%;
    }
  }
  .contents-box {
    position: relative;
    padding: 1.6rem 0.8rem;
    border-bottom: 1px solid #a1a1a1;
    min-height: 40rem;
    textarea {
      width: 100%;
      border: none;
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 2.4rem;
      padding: 0;
      resize: none;
    }
    span {
      display: flex;
      position: absolute;
      bottom: -1px;
      left: 50%;
      background-color: #1f75fe;
      width: 0;
      height: 2px;
      border-radius: 2px;
      transform: translateX(-50%);
      transition: all 0.5s;
    }
    textarea:focus {
      border: none;
      outline: none;
    }
    textarea:focus ~ span {
      width: 100%;
    }
  }
`;
