import { ReportParams, ReviewParams } from "interface/admin/interface.review";
import axiosInstance from "plugin/axios";

export const fetchLoadReviewList = async (params: ReviewParams) => {
  try {
    const response = await axiosInstance.get(`/admin/review`, {
      params,
    });
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

export const fetchLoadReviewDetail = async (id: number) => {
  try {
    const response = await axiosInstance.get(`/admin/review/${id}`);
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

export const fetchLoadReviewComment = async (id: number) => {
  try {
    const response = await axiosInstance.get(`/admin/review/${id}/comment`);
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

export const fetchUpdateReviewHide = async (
  id: number,
  hideYn: "Y" | "N",
  reason: string
) => {
  try {
    const response = await axiosInstance.put(
      `/admin/review/${id}/hide-review`,
      null,
      {
        params: {
          Yn: hideYn,
          reason: reason,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

export const fetchUpdateReviewCommentHide = async (
  id: number,
  hideYn: "Y" | "N",
  reason: string
) => {
  try {
    const response = await axiosInstance.put(
      `/admin/review/${id}/hide-reviewcomment`,
      null,
      {
        params: {
          Yn: hideYn,
          reason: reason,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

export const fetchLoadReportList = async (params: ReportParams) => {
  try {
    const response = await axiosInstance.get(`/admin/report`, { params });
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

export const fetchUpdateBestReview = async (reviewId: number) => {
  try {
    const response = await axiosInstance.put(`/admin/review/${reviewId}/best`);
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

export const fetchUpdateBestReviewCancel = async (reviewId: number) => {
  try {
    const response = await axiosInstance.put(
      `/admin/review/${reviewId}/cancel-best`
    );
    return response.data;
  } catch (error) {
    throw new Error();
  }
};
