import { measureRecordingInfoState } from "atoms/measure.state";
import { RadioIconCheckedX16, RadioIconX16 } from "cssReset/icon";
import { useForm, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { FtBtnBox, StepFormWrap } from "style/measure";

const MeasureStepTwo = () => {
  const navigate = useNavigate();
  const { register, handleSubmit, control, setValue } = useForm({
    defaultValues: {
      location: "",
      recordingOption: "all", // ✅ 라디오 버튼 기본값 설정
    },
  });
  const setMeasureRecordingInfo = useSetRecoilState(measureRecordingInfoState);

  const locationName = useWatch({ control, name: "location" }) || ""; // 🚀 기본값 설정
  const recordingOption = useWatch({ control, name: "recordingOption" }) || ""; // 🚀 라디오 버튼 값 추적

  const onSubmit = (data: any) => {
    console.log(data);
    setMeasureRecordingInfo({
      location: data.location,
      recordingOption: data.recordingOption,
    });
    navigate("/measure/simple/recording");
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <StepFormWrap>
        <div className="input-box">
          <div className="title">측정장소 명칭을 입력해주세요.</div>
          <div className="sub">
            최대 20자까지 입력할 수 있습니다. <br />
            추후 기록을 확인하기 쉽도록 명칭을 입력해주세요.
          </div>
          <div className="input-form">
            <div style={{ position: "relative" }}>
              <input
                type="text"
                placeholder="예: 거실 중앙"
                {...register("location", { maxLength: 20 })} // ✅ maxLength 적용
                onInput={(e) => {
                  const target = e.target as HTMLInputElement;
                  if (target.value.length > 20) {
                    setValue("location", target.value.slice(0, 20)); // 🚀 20자 초과 입력 차단
                  }
                }}
              />
              <span></span>
            </div>
            <div className="count">{locationName.length}/20</div>
          </div>
        </div>
        <div className="input-box">
          <div className="title">소리 녹음 옵션을 선택해주세요.</div>
          <div className="sub">
            스마트폰 마이크로 들어온 소리를 녹음할 수 있습니다.{" "}
          </div>
          <div className="input-form-radio">
            <div className="wrapper">
              <input
                type="radio"
                id="record-all"
                value="all"
                {...register("recordingOption")}
              />
              <label htmlFor="record-all">
                <span className="icon-box">
                  {recordingOption === "all" ? (
                    <RadioIconCheckedX16 />
                  ) : (
                    <RadioIconX16 />
                  )}
                </span>
                전체 녹음
              </label>
            </div>
            <div className="wrapper">
              <input
                type="radio"
                id="record-none"
                value="none"
                {...register("recordingOption")}
              />
              <label htmlFor="record-none">
                <span className="icon-box">
                  {recordingOption === "none" ? (
                    <RadioIconCheckedX16 />
                  ) : (
                    <RadioIconX16 />
                  )}
                </span>
                녹음하지 않음
              </label>
            </div>
          </div>
        </div>
        <div className="bt-info">
          <div className="title">
            최대 2시간까지 측정 및 기록할 수 있습니다.
          </div>
          <div className="sub">
            측정 시작 후 2시간이 경과되면 측정이 자동 종료됩니다.
          </div>
        </div>
      </StepFormWrap>
      <FtBtnBox>
        <button disabled={!locationName || !recordingOption}>
          측정 시작하기
        </button>
      </FtBtnBox>
    </form>
  );
};

export default MeasureStepTwo;
