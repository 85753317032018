import { useNavigate } from "react-router-dom";
import styled from "styled-components";

interface ModalBlockAfterConfirmBoxProps {
  closedHandler: () => void;
  onUpdate: () => void;
}

const ModalBlockAfterConfirmBox = ({
  closedHandler,
  onUpdate,
}: ModalBlockAfterConfirmBoxProps) => {
  const navigate = useNavigate();
  const closedClickHandler = () => {
    onUpdate();
    closedHandler();
    navigate("/review");
  };
  return (
    <BoxInner>
      <TextBox>
        <p>차단이 완료되었습니다.</p>
        <span>{"설정 > 차단 사용자 관리에서 차단을 해제할 수 있습니다."}</span>
      </TextBox>
      <div className="btn-box">
        <button onClick={closedClickHandler}>닫기</button>
      </div>
    </BoxInner>
  );
};

export default ModalBlockAfterConfirmBox;

const BoxInner = styled.div`
  display: flex;
  gap: 2.4rem;
  flex-direction: column;
  .btn-box {
    display: flex;
    position: relative;
    padding: 1.6rem;
    button {
      border: 0;
      outline: 0;
      flex: 1;
      padding: 1rem;
      background-color: transparent;
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 2rem;
      letter-spacing: -0.06rem;
      color: #242629;
      &.del {
        color: #ed2115;
      }
    }
    span {
      position: absolute;
      display: block;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 1px;
      height: 2.4rem;
      background-color: #a1a1a1;
    }
  }
`;

const TextBox = styled.div`
  display: flex;
  gap: 2.4rem;
  flex-direction: column;
  padding: 5.2rem 3rem 0;
  p {
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 2rem;
    letter-spacing: -0.06rem;
    text-align: center;
  }
  span {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 2rem;
    text-align: center;
    display: block;
    color: #575757;
  }
`;
