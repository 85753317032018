import { fetchLoadAdminColumnDetail } from "api/admin/content.column";
import {
  columnHideConfirmModalState,
  columnHideInfoState,
  columnHideState,
  columnShowConfirmModalState,
} from "atoms/admin/contents/column.state";
import AdminNoticeDetailTopContents from "components/admin/contents/notice/admin.notice.detail.top.contents";
import {
  CustomNextArrow,
  CustomPrevArrow,
} from "components/admin/slick.arrow/slick.arrow";
import { ColumnDto } from "interface/admin/interface.contents";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { DetailContainer, SliderWrapper } from "style/admin/contents";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import AdminCommonModalLayout from "components/admin/common.modal.layout/admin.common.modal";
import HideColumnModal from "components/admin/contents/column/modal/hide.column.modal";
import ShowColumnModal from "components/admin/contents/column/modal/show.column.modal";

const AdminColumnDetail = () => {
  const { id } = useParams();
  const [data, setData] = useState<ColumnDto>();
  const [currentSlide, setCurrentSlide] = useState(1); // 현재 슬라이드 번호
  const [columnHideModal, setColumnHideModal] = useRecoilState(
    columnHideConfirmModalState
  );
  const [columnShowModal, setColumnShowModal] = useRecoilState(
    columnShowConfirmModalState
  );
  const setColumnHide = useSetRecoilState(columnHideState);
  const setColumnHideInfo = useSetRecoilState(columnHideInfoState);

  const sliderSetting = {
    infinite: !!(data?.files && data.files.length > 1), // ✅ null 제거 및 boolean 변환
    speed: 500,
    slidesToShow: 1, // 3개 이하일 경우 슬라이드를 하나만 보여줌
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />, // ✅ 커스텀 컴포넌트 사용
    nextArrow: <CustomNextArrow />, // ✅ 커스텀 컴포넌트 사용
    afterChange: (current: number) => setCurrentSlide(current + 1), // 현재 슬라이드 번호 업데이트
  };

  useEffect(() => {
    const loadAdminColumnDetail = async () => {
      try {
        const response = await fetchLoadAdminColumnDetail(Number(id));
        setData(response.result);
        setColumnHide(response.result.useYn);
        setColumnHideInfo({
          contentsId: Number(id),
          hideYn: response.result.useYn,
        });
      } catch (error) {
        console.error(error);
      }
    };
    loadAdminColumnDetail();
  }, [columnHideModal, columnShowModal]);

  // notice 노출 handler
  const hideModalClosed = () => {
    setColumnHideModal({ isOpen: false, isVisible: false });
  };
  const showModalClosed = () => {
    setColumnShowModal({ isOpen: false, isVisible: false });
  };

  return (
    <DetailContainer>
      {data && (
        <AdminNoticeDetailTopContents
          title={data.title}
          useYn={data.useYn}
          regUserId={data.regUserId}
          regDt={data.regDt}
          views={data.views}
        />
      )}
      <div className="detail-wrapper">
        <div className="contents-box">
          <p className="content-txt">{data?.contents}</p>
          {data?.files && data.files.length > 0 ? (
            <SliderWrapper>
              <div className="inner">
                <Slider {...sliderSetting}>
                  {data.files.map((img, index) => (
                    <div className="img-box" key={img.fileId}>
                      {/* src url 배포시 수정 */}
                      <img src={img.accessUrl} alt={img.realFileName} />
                    </div>
                  ))}
                </Slider>
                {/* 현재 슬라이드 위치 표시 */}
                <div className="slide-counter">
                  {currentSlide} / {data.files.length}
                </div>
              </div>
            </SliderWrapper>
          ) : null}
        </div>
      </div>

      {/* 후기 숨기기 모달 */}
      {columnHideModal && (
        <AdminCommonModalLayout
          isVisible={columnHideModal.isVisible}
          closedHandler={hideModalClosed}
        >
          {data && (
            <HideColumnModal
              closedHandler={hideModalClosed}
              contentsId={data?.contentsId}
            />
          )}
        </AdminCommonModalLayout>
      )}

      {/* 후기 노출하기 모달 */}
      {columnShowModal && (
        <AdminCommonModalLayout
          isVisible={columnShowModal.isVisible}
          closedHandler={showModalClosed}
        >
          {data && (
            <ShowColumnModal
              closedHandler={showModalClosed}
              contentsId={data?.contentsId}
            />
          )}
        </AdminCommonModalLayout>
      )}
    </DetailContainer>
  );
};

export default AdminColumnDetail;
