import ColumnList from "components/column/column.list";
import { ColumnInner, ColumnListWrap } from "style/column";

const ColumnLists = () => {
  return (
    <ColumnListWrap>
      <ColumnInner>
        <ColumnList />
      </ColumnInner>
    </ColumnListWrap>
  );
};

export default ColumnLists;
