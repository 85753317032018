import { NoiseData } from "interface/interface.measure";
import {
  BackgoundNoiseWrap,
  MeasureRecordResultSummaryContainer,
  ResultSummaryWrapper,
} from "style/measure";
import {
  calculateFiveMinuteLeq,
  calculateLeq,
  classifyDayNightData,
  getMaxFiveMinuteLeq,
  getMaxLeq,
  getMinBackgroundNoise,
  getTopThreeValues,
} from "utils/measure.util";
import MeasureRecordResultTopThreeValue from "./measure.record.result.top.three.value";
import { useState } from "react";
import { DownChevronX16, LegendIcon, UpChevronX16 } from "cssReset/icon";
import MeasureNoiseTooltip from "./measure.noise.tooltip";
import MeasureNoiseDefaultTable from "./measure.noise.default.table";

interface DataProps {
  resultData: NoiseData[];
}

const MeasureRecordResultSummary = ({ resultData }: DataProps) => {
  const { dayData, nightData } = classifyDayNightData(resultData);
  const [legendState, setLegendState] = useState(false);
  const [noiseDefaultTable, setNoiseDefaultTable] = useState(false);

  const topThreeDay = getTopThreeValues(dayData);
  const topThreeNight = getTopThreeValues(nightData);

  // 1분 등가소음도 분석
  const dayLeqData = dayData.length > 0 ? calculateLeq(dayData) : [];
  const nightLeqData = nightData.length > 0 ? calculateLeq(nightData) : [];

  // 5분 등가소음도 분석
  const dayFiveMinuteLeqData =
    dayLeqData.length > 0 ? calculateFiveMinuteLeq(dayLeqData) : [];
  const nightFiveMinuteLeqData =
    nightLeqData.length > 0 ? calculateFiveMinuteLeq(nightLeqData) : [];

  // 1분 등가 소음도 1개 추출
  const maxDayLeq = dayLeqData.length > 0 ? getMaxLeq(dayLeqData) : null;
  const maxNightLeq = nightLeqData.length > 0 ? getMaxLeq(nightLeqData) : null;

  // 5분 등가 소음도 1개 추출
  const maxDayFiveMinuteLeq =
    dayFiveMinuteLeqData.length > 0
      ? getMaxFiveMinuteLeq(dayFiveMinuteLeqData)
      : null;
  const maxNightFiveMinuteLeq =
    nightFiveMinuteLeqData.length > 0
      ? getMaxFiveMinuteLeq(nightFiveMinuteLeqData)
      : null;

  // 배경 소음도 계산 (최소값)
  const minDayBackgroundNoise = getMinBackgroundNoise(dayFiveMinuteLeqData);
  const minNightBackgroundNoise = getMinBackgroundNoise(nightFiveMinuteLeqData);

  return (
    <MeasureRecordResultSummaryContainer>
      <div className="summary-title">
        <h3>소음도 측정값 요약</h3>
        <div className="legend-tooltip">
          <div
            className="icon-box"
            onClick={() => {
              setLegendState((prev) => !prev);
            }}
          >
            <LegendIcon />
          </div>
          {legendState && <MeasureNoiseTooltip />}
        </div>
      </div>
      <div className="info">* 단위: 데시벨 dB(A)</div>
      <ResultSummaryWrapper>
        {/* 배경 소음도 표시 */}
        <BackgoundNoiseWrap>
          <h3>배경 소음도</h3>
          {minDayBackgroundNoise || minNightBackgroundNoise ? (
            <ul>
              {minDayBackgroundNoise && (
                <li>
                  <span>주간</span> <span>{minDayBackgroundNoise.value}</span>
                </li>
              )}
              {minNightBackgroundNoise && (
                <li>
                  <span>야간</span> <span>{minNightBackgroundNoise.value}</span>
                </li>
              )}
            </ul>
          ) : (
            <p>측정기간 내 미포함</p>
          )}
        </BackgoundNoiseWrap>

        {topThreeDay.length > 0 && (
          <MeasureRecordResultTopThreeValue
            title="최고소음도(주간)"
            data={topThreeDay}
          />
        )}
        {topThreeNight.length > 0 && (
          <MeasureRecordResultTopThreeValue
            title="최고소음도(야간)"
            data={topThreeNight}
          />
        )}

        {maxDayLeq !== null && (
          <MeasureRecordResultTopThreeValue
            title="1분 등가소음도 (주간)"
            data={maxDayLeq}
          />
        )}
        {maxNightLeq !== null && (
          <MeasureRecordResultTopThreeValue
            title="1분 등가소음도(야간)"
            data={maxNightLeq}
          />
        )}
        {maxDayFiveMinuteLeq !== null && (
          <MeasureRecordResultTopThreeValue
            title="5분 등가소음도 (주간)"
            data={[maxDayFiveMinuteLeq]} // 배열로 전달
          />
        )}
        {maxNightFiveMinuteLeq !== null && (
          <MeasureRecordResultTopThreeValue
            title="5분 등가소음도(야간)"
            data={[maxNightFiveMinuteLeq]} // 배열로 전달
          />
        )}
      </ResultSummaryWrapper>
      <div className="noise-detault-table-wrap">
        <div
          className="inner"
          onClick={() => {
            setNoiseDefaultTable((prev) => !prev);
          }}
        >
          <span className="title">공통주택 층간소음 기준표</span>
          <span className="icon-box">
            {noiseDefaultTable ? <UpChevronX16 /> : <DownChevronX16 />}
          </span>
        </div>
      </div>
      {noiseDefaultTable && <MeasureNoiseDefaultTable />}
    </MeasureRecordResultSummaryContainer>
  );
};

export default MeasureRecordResultSummary;
