import { emailAuthHandler, emailConfirmCodeHandler } from "api/join";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { EmailAuthInputWrap, JoinDescription } from "style/join";
import { maskEmail } from "utils/util";

interface EmailAuthProps {
  email: string | null;
  handleNext: () => void;
  forPasswordReset: boolean;
  resetTokenHandler?: (token: string) => void;
}

const JoinEmailAuth = ({
  email,
  handleNext,
  forPasswordReset,
  resetTokenHandler,
}: EmailAuthProps) => {
  const { register, handleSubmit, watch, setValue } = useForm();
  const [isDisabled, setIsDisabled] = useState(true); // 버튼 비활성화 상태 관리
  const [isNextBtnState, setIsNextBtnState] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // 로딩 상태 관리

  // 입력값 변경 감지
  const emailAuthCode = watch("emailAuthCode", ""); // 디폴트 값은 빈 문자열

  // 인증번호가 4자리 이상인지 확인하여 버튼 상태 업데이트
  useEffect(() => {
    setIsDisabled(emailAuthCode.length < 4);
  }, [emailAuthCode]);

  // 숫자만 입력하고, 최대 4자리로 제한하는 함수
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace(/[^0-9]/g, ""); // 숫자만 허용
    if (value.length <= 4) {
      setValue("emailAuthCode", value); // 값 업데이트
    }
  };

  const handleEmailAuthClicked = async () => {
    setIsLoading(true); // 로딩 시작
    try {
      const response = await emailAuthHandler(email || "");
      console.log(response);
      if (response.result) {
        setIsNextBtnState(true);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false); // 로딩 종료
    }
  };

  const onSubmit = async (data: any) => {
    if (data.emailAuthCode.length < 4) {
      alert("인증번호를 정확히 입력해주세요.");
      return;
    }
    try {
      const requestData = {
        email: email,
        authCode: data.emailAuthCode,
        forPasswordReset: forPasswordReset,
      };
      const response = await emailConfirmCodeHandler(requestData);
      console.log("인증 확인:", response);
      if (response.result.result) {
        handleNext();
        if (response.result.token && resetTokenHandler) {
          resetTokenHandler(response.result.token);
        }
      } else {
        alert("인증번호가 잘못되었습니다.");
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <JoinDescription>
        <p>이메일 주소를 인증해주세요.</p>
        <span>{maskEmail(email)} (으)로 전송된 인증번호를 입력해주세요.</span>
      </JoinDescription>
      <EmailAuthInputWrap>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="input-box">
            <input
              type="text"
              placeholder="인증번호를 입력하세요."
              {...register("emailAuthCode", {
                required: "인증번호를 입력해주세요.",
              })}
              value={emailAuthCode}
              onChange={handleInputChange} // 입력값 제어
            />
          </div>
          <div className="submit-btn">
            {isNextBtnState ? (
              <input type="submit" value="다음" disabled={isDisabled} />
            ) : (
              <button
                type="button"
                onClick={handleEmailAuthClicked}
                disabled={isLoading}
              >
                {isLoading ? "인증번호 보내기" : "인증번호 보내기"}
              </button>
            )}
          </div>
        </form>
        {isNextBtnState && (
          <div className="retry-btn-box">
            메일을 받지 못하셨나요?{" "}
            <span onClick={handleEmailAuthClicked}>
              {isLoading ? "재전송하기" : "재전송하기"}
            </span>
          </div>
        )}
      </EmailAuthInputWrap>
    </div>
  );
};

export default JoinEmailAuth;
