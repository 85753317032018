import { fetchLoadColumn } from "api/main.home";
import { ContentDTO } from "interface/interface.main.home";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ListBox } from "style/column";
import { formatDateTime } from "utils/util";

const ColumnList = () => {
  const [data, setData] = useState<ContentDTO[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const loadColumnList = async () => {
      try {
        const response = await fetchLoadColumn();
        setData(response.result);
      } catch (error) {
        console.error(error);
      }
    };

    loadColumnList();
  }, []);

  // 후기 상세 페이지 이동
  const reviewDetailEnter = (id: string) => {
    navigate(`/column/detail/${id}`);
  };

  return (
    <ListBox>
      <div className="list-wrap">
        {data.length > 0 ? (
          data.map((list) => (
            <div
              key={list.contentsId}
              className="review-item"
              onClick={() => reviewDetailEnter(String(list.contentsId))}
            >
              <div className="l-box">
                <p className="title">{list.title}</p>
                <p className="contents">{list.contents}</p>
                <div className="profile-box">
                  <div className="profile-img-box">
                    <img src="/assets/icon/admin-profile.png" alt="" />
                  </div>
                  <div className="username">관리자</div>
                  <span className="date">{formatDateTime(list.regDt)}</span>
                </div>
              </div>
              {list.files.length > 0 && (
                <div className="r-box">
                  <div className="img-box">
                    <img
                      src={list.files?.[0]?.accessUrl}
                      alt={list.files?.[0]?.realFileName}
                    />
                  </div>
                  {list.files.length > 1 && (
                    <div className="img-count-box">+ {list.files.length} </div>
                  )}
                </div>
              )}
            </div>
          ))
        ) : (
          <p className="no-data">칼럼이 없습니다.</p>
        )}
      </div>
    </ListBox>
  );
};

export default ColumnList;
