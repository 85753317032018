import { fetchLoadAdminInquiryNotAnswer } from "api/admin/inquiry";
import { fetchLoadReviewList } from "api/admin/review";
import { fetchLoadUserList } from "api/admin/user";
import { logoutToastStatus } from "atoms/auth.state";
import { ListDotIcon } from "cssReset/icon";
import { InquiryParams } from "interface/admin/interface.inquiry";
import { ReviewParams } from "interface/admin/interface.review";
import { UserListParams } from "interface/admin/interface.user";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { AsideLogo, AsideWrap, Logout, MenuBox } from "style/admin/layout";
import { AdminMenuDt } from "utils/datas";

const AdminAside = () => {
  const setLogoutState = useSetRecoilState(logoutToastStatus);
  const navigate = useNavigate();
  const location = useLocation();
  const [inquiryDt, setInquiryDt] = useState([]);
  const [reviewDt, setReviewDt] = useState([]);
  const [userDt, setUserDt] = useState([]);

  const logOutHandler = () => {
    setLogoutState(true);
    localStorage.clear();
    navigate("/admin");
  };

  useEffect(() => {
    const loadData = async () => {
      try {
        const inquiryParams: InquiryParams = {
          keyword: "", // ✅ 검색어 반영
          orderBy: "REGDT",
          orderByDir: "DESC",
        };
        const reviewParams: ReviewParams = {
          keyword: "", // ✅ 검색어 반영
          orderBy: "REGDT",
          orderByDir: "DESC",
          hideYn: "N",
          reportCount: 10,
          bestYn: null,
        };
        const blockUserParams: UserListParams = {
          blockYn: "N",
          orderBy: "REGDT",
          orderByDir: "DESC",
          reportCountAfterUnblock: 10,
        };
        const [inquiryResponse, reviewResponse, userResponse] =
          await Promise.all([
            fetchLoadAdminInquiryNotAnswer(inquiryParams),
            fetchLoadReviewList(reviewParams),
            fetchLoadUserList(blockUserParams),
          ]);

        setInquiryDt(inquiryResponse.result);
        setReviewDt(reviewResponse.result);
        setUserDt(userResponse.result);
      } catch (error) {
        console.error(error);
      }
    };

    loadData();
  }, []);

  const handleMenuClicked = (path: string) => {
    navigate(path);
  };

  return (
    <AsideWrap>
      <AsideLogo>
        <h1>LOGO</h1>
      </AsideLogo>
      <Logout>
        <span>로그인 계정 : master</span>
        <button onClick={logOutHandler}>로그아웃</button>
      </Logout>
      <MenuBox>
        <ul>
          {AdminMenuDt.map((menu, index) => (
            <li
              key={index}
              onClick={() => {
                menu.path !== undefined && handleMenuClicked(menu.path);
              }}
              className={`${menu.path ? "link-Menu" : ""} ${
                location.pathname === menu.path ? "isActive" : ""
              }`.trim()}
            >
              <div className="menu-name">
                <p>{menu.title}</p>
                {inquiryDt.length > 0 && index === 0 && (
                  <span className="dot"></span>
                )}
                {reviewDt.length > 0 && index === 1 && (
                  <span className="dot"></span>
                )}
                {userDt.length > 0 && index === 2 && (
                  <span className="dot"></span>
                )}
              </div>
              {/* ✅ `subMenu`가 있는 경우 하위 메뉴 표시 */}
              {menu.subMenu && (
                <ul className="submenu">
                  {menu.subMenu.map((sub, subIndex) => (
                    <li
                      key={subIndex}
                      onClick={() => {
                        sub.path && handleMenuClicked(sub.path);
                      }}
                      className={`${
                        location.pathname === sub.path ? "isActive" : ""
                      }`.trim()}
                    >
                      <div className="submenu-name">
                        <span className="submenu-dot">
                          <ListDotIcon />
                        </span>
                        <p>{sub.title}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </MenuBox>
    </AsideWrap>
  );
};

export default AdminAside;
