export const CustomPrevArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <img
      src="/assets/icon/leftBtn_active.png"
      alt="이전 페이지"
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
};

export const CustomNextArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <img
      src="/assets/icon/rightBtn_active.png"
      alt="다음 페이지"
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
};
