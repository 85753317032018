import { LinkIcon } from "cssReset/icon";
import styled from "styled-components";

const ModalShareBox = () => {
  const shareToKakao = () => {
    Kakao.Share.sendDefault({
      objectType: "feed", // 기본 템플릿
      content: {
        title: "공유할 제목",
        description: "공유할 설명",
        imageUrl: "http://sombat.co.kr:9090/",
        link: {
          mobileWebUrl: "http://221.143.21.225:59090/",
          webUrl: "http://221.143.21.225:59090/",
        },
      },
      buttons: [
        {
          title: "웹으로 보기",
          link: {
            mobileWebUrl: "http://221.143.21.225:59090/",
            webUrl: "http://221.143.21.225:59090/",
          },
        },
      ],
    });
  };

  return (
    <BoxInner>
      <ShareItemBox>
        <li onClick={shareToKakao}>
          <span className="icon-box">
            <img src="/assets/icon/SNS/kakaotalk/Default.png" alt="" />
          </span>
          카카오톡 공유하기
        </li>
        <li>
          <span className="icon-box">
            <img
              src="/assets/icon/SNS/Facebook/Facebook - Original.png"
              alt=""
            />
          </span>
          페이스북 공유하기
        </li>
        <li>
          <span className="icon-box">
            <LinkIcon />
          </span>
          공유링크 복사하기
        </li>
      </ShareItemBox>
    </BoxInner>
  );
};

export default ModalShareBox;

const BoxInner = styled.div``;

const ShareItemBox = styled.ul`
  li {
    display: flex;
    padding: 1.6rem 1rem;
    gap: 1rem;
    align-items: center;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: -0.06rem;
    border-bottom: 1px solid #ececec;
    .icon-box {
      display: flex;
      width: 2.4rem;
      height: 2.4rem;
      img,
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
`;
