import styled from "styled-components";
import { motion } from "framer-motion";

export const Container = styled.div`
  display: grid;
  grid-template-columns: 20rem auto;
  align-items: stretch;
`;

export const ContainerWrapper = styled.div`
  height: 100%;
`;

export const MainWrapper = styled(motion.main)`
  min-height: calc(100% - 9.6rem);
  height: calc(100% - 9.6rem);
`;

export const AdminHeaderContainer = styled.header`
  padding: 3rem 4rem;
  h2 {
    font-size: 3.2rem;
    font-weight: 700;
    line-height: 3.6rem;
    display: flex;
    align-items: center;
    span.sub-title {
      display: flex;
      margin-left: 6.1rem;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        width: 1px;
        height: 3.6rem;
        background-color: #242629;
        left: -3.1rem;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  .back-arrow-btn-box {
    display: flex;
    gap: 1rem;
    font-size: 2.8rem;
    font-weight: 600;
    line-height: 3.6rem;
    .icon-box {
      cursor: pointer;
    }
    h2 {
      font-size: 2.8rem;
      font-weight: 600;
      line-height: 3.6rem;
    }
  }
  &.flex-space {
    display: grid;
    align-items: center;
    grid-template-columns: auto 14.1rem;
    padding: 1.9rem 4rem;
    &.review-detail-wrap {
      grid-template-columns: auto 56.1rem;
    }
  }
  &.notice-btn-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &.flex-space-banner {
    display: grid;
    align-items: center;
    grid-template-columns: auto auto;
    padding: 1.9rem 4rem;
    &.review-detail-wrap {
      grid-template-columns: auto 56.1rem;
    }
  }

  .btn-box {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
    button {
      border: 1px solid #cecece;
      outline: none;
      padding: 1.4rem 1.6rem;
      font-size: 1.8rem;
      line-height: 2.8rem;
      font-weight: 700;
      background-color: white;
      border-radius: 0.5rem;
      color: #242629;
      box-shadow: 1px 1px 4px 0 #00000040;
      cursor: pointer;
      &.review-hide-btn:disabled {
        color: #cecece;
        cursor: default;
      }
      &:disabled {
        cursor: default;
        color: #cecece;
      }
    }
    &.review-btn-box {
      display: grid;
      grid-template-columns: 14.1rem 17rem 10.6rem 12.4rem;
    }
  }

  .blue-btn-box {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    button {
      border: none;
      outline: none;
      background-color: #225298;
      color: white;
      border-radius: 0.5rem;
      padding: 1.4rem 1.6rem;
      font-size: 2rem;
      font-weight: 700;
      line-height: 2.8rem;
      cursor: pointer;
      &:disabled {
        background-color: #cecece;
      }
    }
  }
`;

export const AsideWrap = styled.aside`
  background-color: #fafafa;
  min-height: 100vh;
`;

export const AsideLogo = styled.div`
  padding: 4rem 3.2rem;
  h1 {
    font-family: "Monoton";
    font-weight: 400;
    font-size: 4rem;
    line-height: 2rem;
    transform: translateY(3px);
    color: #242629;
    text-align: center;
  }
`;

export const Logout = styled.div`
  padding: 1.6rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  span {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.6rem;
  }
  button {
    border: none;
    outline: none;
    border-radius: 0.5rem;
    padding: 1rem;
    background-color: white;
    font-size: 1.4rem;
    line-height: 2rem;
    font-weight: 500;
    letter-spacing: -0.06rem;
    color: #242629;
    box-shadow: 1px 1px 4px 0 #00000040;
  }
`;

export const MenuBox = styled.ul`
  display: flex;
  flex-direction: column;
  li {
    &.link-Menu {
      cursor: pointer;
    }
    &.isActive {
      background-color: #cecece;
    }
    .menu-name {
      padding: 2rem 2.4rem;
      display: flex;
      gap: 0.4rem;
      p {
        padding: 0.8rem 0;
        font-size: 2rem;
        font-weight: 700;
        line-height: 2.4rem;
      }
      .dot {
        margin-top: 0.8rem;
        width: 0.6rem;
        height: 0.6rem;
        background-color: #ed2115;
        display: flex;
        border-radius: 0.6rem;
      }
    }
    .submenu {
      li {
        padding: 0.8rem 2.4rem;
        cursor: pointer;
        .submenu-name {
          padding: 1rem 0;
          display: flex;
          gap: 0.8rem;
          align-items: center;
          p {
            font-size: 1.6rem;
            font-weight: 700;
            line-height: 2rem;
            color: #242629;
          }
        }
      }
    }
  }
`;

// modal
export const BoxInner = styled.div`
  display: flex;
  flex-direction: column;
  .btn-box {
    display: flex;
    position: relative;
    padding: 0.8rem 0;
    button {
      cursor: pointer;
      border: 0;
      outline: 0;
      flex: 1;
      background-color: transparent;
      padding: 1.6rem 0;
      font-size: 1.6rem;
      font-weight: 700;
      line-height: 2.4rem;
      letter-spacing: -0.06rem;
      color: #575757;
      &.del {
        color: #ed2115;
        &:disabled {
          color: #cecece;
          cursor: default;
        }
      }
    }
    .continue {
      color: #242629;
    }
    span {
      position: absolute;
      display: block;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 1px;
      height: 2.4rem;
      background-color: #a1a1a1;
    }
  }
  .column-btn-box {
    padding: 1.6rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    button {
      cursor: pointer;
      border: none;
      outline: none;
      display: flex;
      border-radius: 0.5rem;
      align-items: center;
      justify-content: center;
      &.continue {
        padding: 1.6rem;
        background-color: #225298;
        color: white;
        font-size: 1.6rem;
        line-height: 2.4rem;
        font-weight: 700;
      }
      &.del {
        padding: 0.6rem;
        font-size: 1.4rem;
        line-height: 2rem;
        letter-spacing: -0.06rem;
        font-weight: 500;
        background-color: transparent;
      }
    }
  }
`;

export const TextBox = styled.div`
  display: flex;
  gap: 1.6rem;
  flex-direction: column;
  padding: 1.6rem 3.2rem;
  min-height: 28.8rem;
  p {
    font-size: 2rem;
    font-weight: 700;
    line-height: 2.4rem;
    letter-spacing: -0.06rem;
    text-align: center;
  }
  span {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 2.4rem;
    text-align: center;
    display: block;
    color: #575757;
    white-space: pre-wrap;
  }
  .textarea-wrapper {
    padding: 0.8rem;
    border: 1px solid #cecece;
    border-radius: 0.5rem;
    height: 9.6rem;
    overflow: hidden scroll;
    max-width: 34.3rem;
    margin: 0 auto;
    width: 100%;
    textarea {
      resize: none;
      border: none;
      outline: none;
      width: 100%;
      height: 100%;
      padding: 0;
      font-size: 1.4rem;
      line-height: 2rem;
      font-weight: 400;
    }
  }
  .banner-img-box {
    width: 37.5rem;
    height: 15.2rem;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;

export const TextLengthBox = styled.div`
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.6rem;
  color: #a1a1a1;
  text-align: right;
  padding: 0 0.4rem;
  max-width: 34.3rem;
  margin: 0 auto;
  span {
    text-align: right;
  }
`;
