import { appleCodeHandler, AppleRequest } from "api/login";
import { AppleIcon, AppleLabelIcon } from "cssReset/icon";
import { useState } from "react";
import AppleLogin from "react-apple-login";
import { useNavigate } from "react-router-dom";
import { AppleLoginBtnContainer } from "style/login";

const AppleLoginBtn = () => {
  const clientId = process.env.REACT_APP_APPLE_CLIENTID ?? ""; // ✅ undefined 방지
  const redirectURL = "https://sombat.co.kr/backend/apple/oauth";
  const [isFetching, setIsFetching] = useState(false);
  const navigate = useNavigate();

  // ✅ 환경 변수 값이 없는 경우 콘솔 경고
  if (!clientId) {
    console.warn("🚨 REACT_APP_APPLE_CLIENTID 값이 설정되지 않았습니다.");
  }

  const handleSuccess = async (response: any) => {
    console.log("✅ Apple 로그인 성공!");
    console.log("🔹 응답 데이터:", response);
    if (isFetching) return;
    setIsFetching(true);

    try {
      const requestData: AppleRequest = {
        code: response.authorization?.code || "", // ✅ 필수 필드 (없으면 빈 문자열)
        email: response.user?.email, // ✅ 선택 필드
        name: response.user
          ? `${response.user.name.lastName} ${response.user.name.firstName}`
          : undefined, // ✅ 선택 필드
      };

      const loginResponse = await appleCodeHandler(requestData);

      if (loginResponse.result) {
        const { accessToken, refreshToken } = loginResponse.result;

        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("refreshToken", refreshToken);

        // 로컬 스토리지에 사용자 유형 저장
        localStorage.setItem(
          "authState",
          JSON.stringify({ isAuthenticated: true })
        );

        navigate("/home");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <AppleLoginBtnContainer>
      <AppleLogin
        clientId={clientId}
        redirectURI={redirectURL}
        responseType={"code id_token"} // ✅ code와 id_token 동시 요청
        scope="name email"
        responseMode="form_post"
        usePopup={false}
        callback={(response: any) => handleSuccess(response)} // ✅ 응답 확인
        render={(renderProps: any) => (
          <button onClick={renderProps.onClick}>
            <span>
              <AppleIcon />
            </span>
            <p>Sign in with Apple</p>
          </button>
        )}
      />
    </AppleLoginBtnContainer>
  );
};

export default AppleLoginBtn;
