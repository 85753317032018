import {
  InquiryParams,
  UserBlockRequest,
  UserListParams,
  UserReviewCommentParams,
  UserReviewParams,
} from "interface/admin/interface.user";
import axiosInstance from "plugin/axios";

export const fetchLoadUserList = async (params: UserListParams) => {
  try {
    const response = await axiosInstance.get(`/admin/user`, {
      params,
    });
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

export const fetchLoadUserInfoDetail = async (id: string) => {
  try {
    const response = await axiosInstance.get(`/admin/user/${id}`);
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

export const fetchUpdateUserBlock = async (data: UserBlockRequest) => {
  try {
    const response = await axiosInstance.post(`/admin/user-block`, data);
    return response.data;
  } catch (error) {
    console.error("🚨 이용 제한 요청 실패:", error);
    throw new Error("이용 제한 요청 중 오류가 발생했습니다.");
  }
};

export const fetchLoadUserDetailInquiry = async (
  id: string,
  params: InquiryParams
) => {
  try {
    const response = await axiosInstance.get(`/inquiry/admin/${id}`, {
      params,
    });
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

export const fetchLoadUserDetailReview = async (params: UserReviewParams) => {
  try {
    const response = await axiosInstance.get(`/admin/user/review`, { params });
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

export const fetchLoadUserDetailReviewComment = async (
  params: UserReviewCommentParams
) => {
  try {
    const response = await axiosInstance.get(`/admin/user/review-comment`, {
      params,
    });
    return response.data;
  } catch (error) {
    throw new Error();
  }
};
