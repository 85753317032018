import { BoxInner, TextBox } from "style/admin/layout";
import { fetchUpdateReviewHide } from "api/admin/review";

interface ModalProps {
  closedHandler: () => void;
  onClick?: () => void;
  reviewId: number;
  isHideState: "Y" | "N";
}

const modalTxt = `모든 고객이 해당 글을 볼 수 있게 됩니다.

글 내용을 확인 후, [글 노출하기] 버튼을 눌러주세요.`;

const ShowReviewConfirmModal = ({
  closedHandler,
  isHideState,
  reviewId,
}: ModalProps) => {
  console.log(isHideState, reviewId);

  const showClickHandler = async (reviewId: number, hideYn: "Y" | "N") => {
    const yn = hideYn === "Y" ? "N" : "Y";
    try {
      const response = await fetchUpdateReviewHide(reviewId, yn, "");
      if (response.result) closedHandler();
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <BoxInner>
      <TextBox style={{ minHeight: "auto" }}>
        <p>글을 다시 노출하시겠습니까?</p>
        <span>{modalTxt}</span>
      </TextBox>
      <div className="btn-box">
        <button className="continue" onClick={closedHandler} type="button">
          돌아가기
        </button>
        <span></span>
        <button
          className="del"
          onClick={() => showClickHandler(reviewId, isHideState)}
        >
          글 노출하기
        </button>
      </div>
    </BoxInner>
  );
};

export default ShowReviewConfirmModal;
