import { atom } from "recoil";

export const authCodeState = atom<{
  email: string | null;
  authCode: string | null;
}>({
  key: "authCodeState",
  default: {
    email: null,
    authCode: null,
  },
});

export const logoutToastStatus = atom<boolean>({
  key: "logoutToastStatus",
  default: false,
});
