import { useGoogleLogin } from "@react-oauth/google";
import { googleCodeHandler } from "api/login";
import { GoogleSimbolVer2 } from "cssReset/icon";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const GoogleSocialLogin = () => {
  const REDIRECT_URI = process.env.REACT_APP_DEV_URL;
  const navigate = useNavigate();

  const handleGoogleLogin = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage("google_login");
    }
    login(); // 기존 구글 로그인 호출
  };

  const login = useGoogleLogin({
    onSuccess: async (response) => {
      // 로그인 성공 후 인증 코드
      console.log(response);
      const { code } = response;
      if (code) {
        try {
          const result = await googleCodeHandler(code); // 인증 코드로 토큰 교환

          const { accessToken, refreshToken } = result;

          // 토큰 로컬 스토리지에 저장
          localStorage.setItem("accessToken", accessToken);
          localStorage.setItem("refreshToken", refreshToken);

          // 인증 상태 로컬 스토리지에 저장
          localStorage.setItem(
            "authState",
            JSON.stringify({ isAuthenticated: true })
          );

          navigate("/home");
        } catch (error: any) {
          console.error(
            "구글 로그인 에러:",
            error.response?.data || error.message
          );
        }
      }
    },
    onError: () => console.error("Login failed"),
    flow: "auth-code", // 'auth-code' 플로우 사용
    ux_mode: "redirect", // 전체 페이지 리디렉션 방식 사용
    redirect_uri: REDIRECT_URI + "/google/oauth", // 서버 redirect uri
  });

  return (
    <div>
      <GoogleBtn onClick={() => handleGoogleLogin()}>
        {" "}
        <span>
          <GoogleSimbolVer2 />
        </span>
        <p>Google 계정으로 로그인</p>
      </GoogleBtn>
    </div>
  );
};

export default GoogleSocialLogin;

const GoogleBtn = styled.button`
  border: 1px solid #dadce0;
  outline: 0;
  background-color: transparent;
  padding: 0;
  display: grid;
  grid-template-columns: 2rem auto;
  width: 100%;
  border-radius: 0.5rem;
  padding: 0.9rem 1.2rem;
  background-color: white;
  img {
    max-width: 100%;
    width: 100%;
  }
  span {
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 2.4rem;
      height: 2.4rem;
    }
  }
  p {
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 2rem;
    color: rgba(0, 0, 0, 0.85);
  }
`;
