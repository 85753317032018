import {
  fetchLoadAdminColumnDetail,
  fetchUpdateColumn,
} from "api/admin/content.column";
import { fetchDeleteFileId, fetchRegistFile } from "api/files";
import {
  columnAwayModalState,
  columnSubmitTriggerState,
  columnUpdateCompleteModalState,
  isColumnChangedState,
} from "atoms/admin/contents/column.state";
import AdminCommonModalLayout from "components/admin/common.modal.layout/admin.common.modal";
import { ColumnDto, FileDto } from "interface/admin/interface.contents";
import { useEffect, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { NoticeModifyContainer } from "style/admin/contents";
import TextareaAutosize from "react-textarea-autosize";
import AdminNoticeAttachment from "components/admin/contents/notice/admin.notice.attachment";
import ColumnUpdateCompleteModal from "components/admin/contents/column/modal/column.update.complete.modal";
import ColumnUpdateAwayModal from "components/admin/contents/column/modal/column.update.away.modal";

const AdminColumnModify = () => {
  const { register, setValue, handleSubmit, control } = useForm();
  const [data, setData] = useState<ColumnDto>();
  const [formFiles, setFormFiles] = useState<FileDto[]>([]);
  const { id } = useParams();
  const fileGroupId = data?.fileGroupId;
  const columnId = data?.contentsId;
  const [columnSubmitTrigger, setColumnSubmitTrigger] = useRecoilState(
    columnSubmitTriggerState
  );
  const [columnUpdateCompleteModal, setColumnUpdateCompleteModal] =
    useRecoilState(columnUpdateCompleteModalState);
  const [columnAwayModal, setColumnAwayModal] =
    useRecoilState(columnAwayModalState);
  const setIsColumnChanged = useSetRecoilState(isColumnChangedState);

  const loadAdminColumnDetail = async () => {
    try {
      const response = await fetchLoadAdminColumnDetail(Number(id));

      if (response.result) {
        setData(response.result);
        setValue("title", response.result?.title);
        setValue("contents", response.result?.contents);
        setFormFiles(response.result.files || []); // ✅ 기존 파일 저장
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    loadAdminColumnDetail();
  }, [id]);

  const watchedFile = useWatch({ control, name: "columnFiles" });
  const watchedTitle = useWatch({ control, name: "title" });
  const watchedContent = useWatch({ control, name: "contents" });

  useEffect(() => {
    // ✅ 새 파일 감지 (File 객체만 필터링)
    const newFiles =
      watchedFile?.filter((file: any) => file instanceof File) || [];

    // ✅ 기존 파일 중에서 삭제된 파일 찾기
    const removedFiles = formFiles.filter(
      (file) =>
        !watchedFile?.some((newFile: any) => newFile.fileId === file.fileId)
    );

    // ✅ 변경 여부 확인
    const isFileChanged = newFiles.length > 0 || removedFiles.length > 0;
    const isTitleChanged = watchedTitle !== data?.title;
    const isContentsChanged = watchedContent !== data?.contents;

    setIsColumnChanged(isFileChanged || isTitleChanged || isContentsChanged);
  }, [watchedFile, formFiles, watchedTitle, watchedContent, data]);

  const onSubmit = async (data: any) => {
    try {
      let isFileChanged = false;

      // ✅ 새로운 파일이 있는지 확인 (이전 코드 수정)
      const newFiles =
        data.columnFiles?.filter(
          (file: any) => file instanceof File && file.name && file.size
        ) || [];

      // ✅ 기존 파일이 있는 경우 삭제된 파일 찾기
      const removedFiles = formFiles.filter(
        (file) =>
          !data.columnFiles?.some(
            (newFile: any) => newFile.fileId === file.fileId
          )
      );

      // ✅ 파일 변경 여부 확인
      if (newFiles.length > 0 || removedFiles.length > 0) {
        isFileChanged = true;
      }

      // ✅ 기존 파일 삭제 (변경된 경우에만 실행)
      if (isFileChanged && removedFiles.length > 0) {
        for (const file of removedFiles) {
          if ("fileId" in file) {
            console.log(`🗑️ 삭제 요청 파일:`, file);
            const deleteResponse = await fetchDeleteFileId(file.fileId);
            console.log(`✅ 파일 삭제 완료:`, deleteResponse);
          }
        }
      }

      // ✅ 새 파일 등록 (변경된 경우에만 실행)
      if (isFileChanged && newFiles.length > 0) {
        await fetchRegistFile({
          fileGroupId: fileGroupId || "", // 기존 파일 그룹 ID 유지
          fileType: "banner",
          files: newFiles,
        });
      } else {
        console.log("🟢 새 파일 업로드 없음");
      }

      const params = {
        title: data.title,
        contents: data.contents,
      };

      const response = await fetchUpdateColumn(Number(columnId), params);
      if (response.result) {
        setColumnUpdateCompleteModal({ isOpen: true, isVisible: true });
        setIsColumnChanged(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (columnSubmitTrigger) {
      handleSubmit(onSubmit)();
      setColumnSubmitTrigger(false);
    }
  }, [columnSubmitTrigger, handleSubmit]);

  // modal handler
  const completeModalClosed = () => {
    setColumnUpdateCompleteModal({ isOpen: false, isVisible: false });
  };
  const awayModalClosed = () => {
    setColumnAwayModal({ isOpen: false, isVisible: false });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <NoticeModifyContainer>
        <div className="title-box">
          <input type="text" {...register("title")} />
          <span></span>
        </div>
        <div className="contents-box">
          <Controller
            name="contents"
            control={control}
            rules={{ required: "제목은 필수입니다." }}
            render={({ field }) => (
              <TextareaAutosize {...field} minRows={17} maxRows={17} />
            )}
          />
          <span></span>
        </div>
        <div className="img-box">
          <AdminNoticeAttachment
            name="columnFiles"
            register={register}
            setValue={setValue}
            initialFiles={data?.files || []} // ReviewFile[] 그대로 전달
          />
        </div>

        {columnUpdateCompleteModal && (
          <AdminCommonModalLayout
            isVisible={columnUpdateCompleteModal.isVisible}
            closedHandler={completeModalClosed}
          >
            <ColumnUpdateCompleteModal closedHandler={completeModalClosed} />
          </AdminCommonModalLayout>
        )}

        {columnAwayModal && (
          <AdminCommonModalLayout
            isVisible={columnAwayModal.isVisible}
            closedHandler={awayModalClosed}
          >
            <ColumnUpdateAwayModal closedHandler={awayModalClosed} />
          </AdminCommonModalLayout>
        )}
      </NoticeModifyContainer>
    </form>
  );
};

export default AdminColumnModify;
