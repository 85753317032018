import {
  BarChart,
  XAxis,
  YAxis,
  Bar,
  Cell,
  ReferenceLine,
  CartesianGrid,
} from "recharts";
import { ClickedItem, NoiseData } from "interface/interface.measure";
import {
  ChartContainer,
  ChartScrollArea,
  GraphWrapper,
  ResultAudioContainer,
  ResultChartContainer,
  ResultNoiseLogContainer,
  YAxisFixed,
} from "style/measure";
import { colorSelector } from "utils/measure.util";
import MeasureRecordResultAudio from "./measure.record.result.audio";
import MeasureRecordResultNoiseLog from "./measure.record.result.noise.log";
import { useRef, useState, useEffect } from "react";

interface DataProps {
  resultData: NoiseData[];
  audioPath: string;
}

const MeasureRecordResultChart = ({ resultData, audioPath }: DataProps) => {
  const displayedData = resultData.map((item) => ({
    label: item.time,
    value: item.value,
  }));
  const [clickedItem, setClickedItem] = useState<ClickedItem>();

  const singleBarWidth = 6;
  const chartWidth = displayedData.length * singleBarWidth;
  const scrollRef = useRef<HTMLDivElement>(null);

  const clickedItemHandler = (item: ClickedItem) => {
    setClickedItem(item);
  };

  const getClickedSeconds = () => {
    if (!clickedItem) return null;

    const startTime = new Date(resultData[0].time); // 기준 시간
    const clickedTime = new Date(clickedItem.time);
    const seconds = (clickedTime.getTime() - startTime.getTime()) / 1000;

    return seconds;
  };

  const clickedSeconds = getClickedSeconds();

  useEffect(() => {
    if (!clickedItem || !scrollRef.current) return;

    const index = displayedData.findIndex(
      (item) => item.label === clickedItem.time
    );

    const validIndex = index === -1 ? 0 : index;

    const scrollArea = scrollRef.current;
    const containerWidth = scrollArea.offsetWidth;
    const targetScrollX =
      validIndex * singleBarWidth - containerWidth / 2 + singleBarWidth / 2;

    scrollArea.scrollTo({
      left: targetScrollX,
      behavior: "smooth",
    });
  }, [clickedItem, displayedData]);

  return (
    <>
      <ResultChartContainer>
        <GraphWrapper>
          {/* 고정된 Y축 */}
          <YAxisFixed>
            <BarChart
              data={displayedData}
              width={50}
              height={288}
              margin={{ top: 10, bottom: 30 }}
            >
              <XAxis dataKey="label" hide />
              <YAxis
                label={{
                  value: "소리 강도(dB HL)",
                  angle: -90,
                  position: "insideLeftTop",
                  dy: -85,
                  dx: -10,
                }}
                tickLine={false}
                axisLine={false}
                tickCount={12} // ✅ 10개 단위로 표시
                tickFormatter={(tick) => (tick % 10 === 0 ? tick : "")} // ✅ 10 단위 값만 표시
                domain={[0, 110]}
                tick={{ fill: "#575757", fontSize: 12 }}
                width={50}
              />
              {/* <Bar dataKey="value" fill="transparent"></Bar> */}
            </BarChart>
          </YAxisFixed>

          {/* 스크롤 가능한 막대 차트 */}
          <ChartContainer ref={scrollRef}>
            <ChartScrollArea>
              <BarChart
                data={displayedData}
                width={chartWidth}
                height={288}
                margin={{ top: 10, right: 0, left: 0, bottom: 0 }}
                barGap={5}
              >
                <XAxis
                  dataKey="label"
                  label={{
                    value: "경과 시간",
                    position: "insideBottomRight",
                    offset: 0,
                    dy: -1,
                  }}
                  dy={0}
                  tickLine={true}
                  axisLine={false}
                  padding={{ left: 7, right: 7 }}
                  tick={{ fill: "#575757", fontSize: 12 }}
                  tickFormatter={(time) => {
                    const date = new Date(time);
                    const yy = String(date.getFullYear()).slice(2); // ✅ 연도 'YYYY' → 'YY'
                    const mm = String(date.getMonth() + 1).padStart(2, "0"); // ✅ 월 (1~9월을 01~09로 변환)
                    const dd = String(date.getDate()).padStart(2, "0"); // ✅ 일 (01~09로 변환)
                    const hh = String(date.getHours()).padStart(2, "0");
                    const mi = String(date.getMinutes()).padStart(2, "0");
                    const ss = String(date.getSeconds()).padStart(2, "0");

                    return `${yy}.${mm}.${dd} ${hh}:${mi}:${ss}`; // ✅ 최종 형식 'YY.MM.DD HH:mm:ss'
                  }}
                  interval="preserveStart" // ✅ 첫 번째, 마지막 tick 유지 & 중간 tick 자동 조정
                />
                <YAxis
                  width={0}
                  tickCount={12} // ✅ 10개 단위로 표시
                  tickFormatter={(tick) => (tick % 10 === 0 ? tick : "")} // ✅ 10 단위 값만 표시
                  domain={[0, 110]}
                />
                <CartesianGrid
                  strokeDasharray="0"
                  vertical={false}
                  stroke="#ececec"
                />
                <ReferenceLine
                  y={57} // ✅ 기준선 위치 (60dB)
                  stroke="#242629" // ✅ 선 색상
                  strokeWidth={1} // ✅ 선 굵기
                />
                <ReferenceLine
                  y={52} // ✅ 기준선 위치 (60dB)
                  stroke="#000" // ✅ 선 색상
                  strokeWidth={1} // ✅ 선 굵기
                />
                <Bar
                  key={`bar-${displayedData.length}`} // 고유하게 만들어주는 게 핵심!
                  dataKey="value"
                  maxBarSize={6}
                  isAnimationActive={false}
                  onClick={(data, index) => {
                    console.log(`Clicked bar index: ${index}`);
                  }}
                >
                  {/* map 이 돌때 시간 비교해서 주간인지 야간인지 구분 */}
                  {displayedData.map((entry, index) => (
                    <Cell
                      key={`cell-${entry.label}-${entry.value}-${index}`}
                      fill={colorSelector(entry.value, entry.label)}
                    />
                  ))}
                </Bar>
              </BarChart>
            </ChartScrollArea>
          </ChartContainer>
        </GraphWrapper>
      </ResultChartContainer>
      <ResultAudioContainer>
        <MeasureRecordResultAudio
          audioPath={audioPath}
          clickedSeconds={clickedSeconds ?? undefined} // null → undefined로 변환
        />
      </ResultAudioContainer>
      <ResultNoiseLogContainer>
        <MeasureRecordResultNoiseLog
          resultData={resultData}
          clickedItemHandler={clickedItemHandler}
          clickedItem={clickedItem}
        />
      </ResultNoiseLogContainer>
    </>
  );
};

export default MeasureRecordResultChart;
