import { LeftArrowIcon, RightArrowIcon } from "cssReset/icon";
import { InquiryDTO } from "interface/interface.inquiry";
import { useState } from "react";
import Slider from "react-slick";
import { AnswerInner, SliderWrapper } from "style/inquiry";

interface InquiryAnswerListDto {
  data: InquiryDTO;
}

const InquiryAnswerBox = ({ data }: InquiryAnswerListDto) => {
  const [currentSlide, setCurrentSlide] = useState(1); // 현재

  //  슬라이드 번호
  const sliderSetting = {
    infinite: data.files && data.files.length > 1,
    speed: 500,
    slidesToShow: 1,
    rows: 1,
    slidesToScroll: 1,
    prevArrow: <LeftArrowIcon color="#000" />, // 이전 버튼에 아이콘 추가
    nextArrow: <RightArrowIcon color="#000" />, // 다음 버튼에 아이콘 추가
    afterChange: (current: number) => setCurrentSlide(current + 1), // 현재 슬라이드 번호 업데이트
  };
  console.log(data);
  return (
    <AnswerInner>
      <p className="answer-txt">{data.answer}</p>

      {data.files && data.files.length > 0 && (
        <SliderWrapper>
          <div className="inner">
            <Slider {...sliderSetting}>
              {data.files.map((img) => (
                <div key={img.fileId} className="img-box">
                  <img src={img.accessUrl} alt={img.realFileName} />
                </div>
              ))}
            </Slider>
          </div>
          {/* 현재 슬라이드 위치 표시 */}
          <div className="slide-counter">
            {currentSlide} / {data.files.length}
          </div>
        </SliderWrapper>
      )}
    </AnswerInner>
  );
};

export default InquiryAnswerBox;
