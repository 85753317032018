import { HeaderTitleProps } from "interface/interface.header";
import { Helmet } from "react-helmet-async";
import { Outlet, useLocation } from "react-router-dom";
import BackArrowHeader from "./back.arrow.header";
import styled from "styled-components";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect } from "react";

const BackArrowLayout = ({
  headerTitle,
  detailBtnBox,
  alramSettingBtn,
  backPath,
}: HeaderTitleProps) => {
  const location = useLocation(); // 현재 경로 가져오기

  useEffect(() => {
    // 페이지 이동 시 스크롤을 맨 위로 이동
    window.scrollTo(0, 0);
  }, [location.pathname]); // 경로 변경될 때 실행

  const pageTitle = location.pathname.replace("/", "") || "Home";
  const pathsWithAutoHeight = [
    "review/detail",
    "review",
    "alram",
    "inquiry",
    "notice",
  ];
  const pathsPaddingNonw = ["declaration"];

  // `BackArrowHeader`를 숨길 경로
  const pathsToHideHeader = ["inquiry"];

  return (
    <MainContainer>
      <Helmet>
        <title>RS101 | {pageTitle}</title>
        <meta property="og:type" content="website" />
      </Helmet>
      {/* 특정 경로에서는 `BackArrowHeader`를 숨김 */}
      {!pathsToHideHeader.some((path) => location.pathname.includes(path)) && (
        <BackArrowHeader
          headerTitle={headerTitle}
          detailBtnBox={detailBtnBox}
          alramSettingBtn={alramSettingBtn}
          backPath={backPath}
        />
      )}
      <AnimatePresence initial={true}>
        <ContainerWrapper
          className={
            pathsPaddingNonw.some((path) => location.pathname.includes(path))
              ? "none-padding"
              : ""
          }
        >
          <Container
            key={location.pathname} // 경로가 변경될 때마다 애니메이션 트리거
            initial={{ opacity: 0, y: 20 }} // 초기 상태 (살짝 오른쪽에서 시작)
            animate={{ opacity: 1, y: 0 }} // 나타날 때
            exit={{ opacity: 0, y: -20 }} // 사라질 때 (왼쪽으로 이동하며 사라짐)
            transition={{ duration: 0.3 }} // 애니메이션 속도
            className={
              pathsWithAutoHeight.some((path) =>
                location.pathname.includes(path)
              )
                ? "auto-height"
                : ""
            }
          >
            <Outlet />
          </Container>
        </ContainerWrapper>
      </AnimatePresence>
    </MainContainer>
  );
};

export default BackArrowLayout;

const MainContainer = styled.div`
  position: relative;
  @media screen and (min-width: 785px) {
    width: 37.5rem;
    border: 1px solid #ececec;
    border-radius: 0.5rem;
    margin: 0 auto;
    overflow: hidden;
  }
`;

const ContainerWrapper = styled.div`
  position: relative;
  overflow: hidden; // 페이지 이동 시 요소가 영역을 벗어나지 않도록
  &.none-padding {
    > main {
      padding-bottom: 0;
    }
  }
`;

const Container = styled(motion.main)`
  height: calc(100vh - 5.6rem);
  padding-bottom: 7.5rem;
  &.auto-height {
    height: auto;
  }

  @media screen and (min-width: 785px) {
    min-height: calc(100vh - 5.6rem);
    padding-bottom: 0;
  }
`;
