import { AnimatePresence } from "framer-motion";
import { LegendWrapper } from "style/measure";

const MeasureLegend = () => {
  return (
    <AnimatePresence mode="wait">
      <LegendWrapper
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{
          duration: 0.3,
          type: "fade",
        }}
      >
        <ul>
          <li>
            <span className="skyblue"></span>
            <p>양호 : 최고소음도 기준 미만</p>
          </li>
          <li>
            <span className="pink"></span>
            <p>소음 : 최고소음도 기준 이상~70데시벨 미만</p>
          </li>
          <li>
            <span className="red"></span>
            <p>아주 큰 소음 : 70데시벨 이상</p>
          </li>
          <li>
            <span className="line"></span>
            <p>최대소음도 기준선 (주간 57 데시벨)</p>
          </li>
          <li>
            <span className="night-line"></span>
            <p>최대소음도 기준선 (야간 52 데시벨)</p>
          </li>
        </ul>
      </LegendWrapper>
    </AnimatePresence>
  );
};

export default MeasureLegend;
