import {
  NoticeParams,
  NoticeRegistParams,
  NoticeUpdateParams,
} from "interface/admin/interface.contents";
import axiosInstance from "plugin/axios";

export const fetchLoadAdminNoticeList = async (params: NoticeParams) => {
  try {
    const response = await axiosInstance.get(`/admin/announcement`, { params });
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

export const fetchLoadAdminNoticeDetail = async (announcementId: number) => {
  try {
    const response = await axiosInstance.get(
      `/admin/announcement/${announcementId}`
    );
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

export const fetchNoticeRestore = async (id: number) => {
  try {
    const response = await axiosInstance.put(
      `/admin/announcement/restore/${id}`
    );
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

export const fetchDeleteNotice = async (id: number) => {
  try {
    const response = await axiosInstance.delete(`/admin/announcement/${id}`);
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

export const fetchUpdateNotice = async (
  id: number,
  params: NoticeUpdateParams
) => {
  try {
    const response = await axiosInstance.put(
      `/admin/announcement/${id}`,
      params
    );
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

export const fetchNoticeRegist = async (data: NoticeRegistParams) => {
  try {
    const formData = new FormData();

    // ✅ 공지사항 데이터 추가 (객체는 JSON 문자열로 변환)
    formData.append(
      "data",
      JSON.stringify({
        title: data.title,
        contents: data.contents,
      })
    );

    // ✅ 파일이 있는 경우 추가
    if (data.files && data.files.length > 0) {
      data.files.forEach((file) => {
        formData.append("files", file);
      });
    }

    const response = await axiosInstance.post("/admin/announcement", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    throw new Error();
  }
};
