import { TableContainer, TableContents } from "style/admin/table";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useEffect, useMemo, useRef, useState } from "react";
import { formatDateTime } from "utils/util";
import {
  AdminBannerDto,
  OrderUpdateList,
} from "interface/admin/interface.contents";
import { useNavigate } from "react-router-dom";
import { DownArrowIcon, UpArrowIcon } from "cssReset/icon";
import AdminCommonModalLayout from "components/admin/common.modal.layout/admin.common.modal";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  bannerAwayModalState,
  bannerDeleteModalState,
  bannerSubmitTriggerState,
  isBannerChangedState,
  orderIdSaveState,
} from "atoms/admin/contents/banner.state";
import BannerDeleteModal from "./modal/banner.delete.modal";
import { fetchBannerSortUpdate } from "api/admin/contents.banner";
import BannerSortUpdateModal from "./modal/banner.sort.update.modal";

interface DataProps {
  data: AdminBannerDto[];
}

const AdminBannerTable = ({ data }: DataProps) => {
  const [bannerData, setBannerData] = useState<AdminBannerDto[]>([]);
  const [deleteBannerInfo, setDeleteBannerInfo] = useState<{
    bannerId: number;
    imgUrl: string;
    orderNo: number;
  } | null>(null);

  // ✅ 기존 순서를 저장할 Ref 추가
  const initialBannerData = useRef<AdminBannerDto[]>([]);
  const navigate = useNavigate();

  const [bannerDeleteModal, setBannerDeleteModal] = useRecoilState(
    bannerDeleteModalState
  );
  const setOrderId = useSetRecoilState(orderIdSaveState);
  const [bannerSubmitTrigger, setBannerSubmitTrigger] = useRecoilState(
    bannerSubmitTriggerState
  );
  const [sortUpdateModal, setSortUpdateModal] =
    useRecoilState(bannerAwayModalState);
  const [isBannerSortChanged, setIsBannerSortChanged] =
    useRecoilState(isBannerChangedState);

  useEffect(() => {
    if (bannerSubmitTrigger) {
      sortUpdateModalOpen();
      setBannerSubmitTrigger(false); // 상태 초기화 (중복 실행 방지)
    }
  }, [bannerSubmitTrigger]);

  const sortUpdateclicked = async () => {
    try {
      const sortedBannerList: OrderUpdateList = filledData
        .filter((item) => !item.bannerId.includes("dummy")) // ✅ 더미 데이터 제거
        .map((item) => ({
          banner_id: Number(item.bannerId), // ✅ bannerId를 숫자로 변환
          order: item.order,
        }));

      const response = await fetchBannerSortUpdate(sortedBannerList);
      if (response.result) {
        sortUpdateModalClosed();
        setIsBannerSortChanged(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (data && data.length > 0) {
      const sortedData = [...data].sort((a, b) => a.order - b.order);
      setBannerData(sortedData);
      initialBannerData.current = sortedData; // ✅ 초기 데이터 저장
    }
  }, [data]); // ✅ data가 변경될 때 실행

  // ✅ `useEffect`를 사용해 변경 사항 감지 및 상태 업데이트 (렌더링 중 `setState()` 방지)
  useEffect(() => {
    const isChanged =
      JSON.stringify(initialBannerData.current) !== JSON.stringify(bannerData);
    setIsBannerSortChanged(isChanged); // ✅ 변경 감지 후 실행
  }, [bannerData]);

  // ✅ 배너 순서 변경 핸들러
  const moveBanner = (index: number, direction: "up" | "down") => {
    setBannerData((prevData) => {
      const newData = [...prevData];

      if (direction === "up" && index > 0) {
        [newData[index], newData[index - 1]] = [
          newData[index - 1],
          newData[index],
        ];
      } else if (direction === "down" && index < newData.length - 1) {
        [newData[index], newData[index + 1]] = [
          newData[index + 1],
          newData[index],
        ];
      }

      // ✅ order 값 재정렬
      return newData.map((item, idx) => ({ ...item, order: idx }));
    });
  };

  const filledData: AdminBannerDto[] = useMemo(() => {
    const extraRows = 5 - bannerData.length;
    const dummyRows: AdminBannerDto[] = Array.from(
      { length: extraRows },
      (_, i) => ({
        bannerId: `dummy-${i}`,
        files: [],
        contents: "",
        fileGroupId: "",
        order: bannerData.length + i,
        regDt: "",
        regUserId: "",
        title: "",
        updtDt: null,
        useYn: "N",
      })
    );
    return [...bannerData, ...dummyRows]; // ✅ 데이터 + 더미 데이터
  }, [bannerData]);

  const columns = useMemo<ColumnDef<AdminBannerDto>[]>(
    () => [
      {
        header: "Index",
        id: "number", // 넘버링을 위한 id
        cell: ({ row }) => row.index + 1, // 각 행의 인덱스에 1을 더해 넘버링
        size: 96,
      },
      {
        header: "배너이미지",
        accessorKey: "files",
        cell: (info) => {
          const files = info.getValue() as AdminBannerDto["files"];

          if (files && files.length > 0) {
            return (
              <div className="img-box">
                <img src={files[0].accessUrl} alt={files[0].realFileName} />
              </div>
            );
          } else {
            return null; // ✅ 파일이 없는 경우 처리
          }
        },
        size: 415, // ✅ 적절한 크기 지정
      },
      {
        header: "클릭시 연결 링크",
        accessorKey: "contents",
        cell: (info) => info.getValue(),
        size: 160,
      },
      {
        header: "등록일시",
        accessorKey: "regDt",
        cell: (info) => {
          const value = info.getValue(); // ✅ 값 가져오기
          return value ? formatDateTime(String(value)) : ""; // ✅ 값이 없으면 "-" 반환
        },
        size: 160,
      },
      {
        header: "담당자",
        accessorKey: "regUserId",
        cell: (info) => info.getValue(),
        size: 96,
      },
      {
        header: "순서변경",
        id: "orderChange",
        cell: ({ row }) => (
          <div className="order-controls">
            <button
              onClick={() => moveBanner(row.index, "up")}
              disabled={row.index === 0} // 첫 번째 항목이면 비활성화
            >
              <UpArrowIcon />
            </button>
            <button
              onClick={() => moveBanner(row.index, "down")}
              disabled={row.index === filledData.length - 1} // 마지막 항목이면 비활성화
            >
              <DownArrowIcon />
            </button>
          </div>
        ),
        size: 64,
      },
      {
        header: "수정",
        id: "actions",
        cell: ({ row }) => {
          const rowData = row.original;
          const bannerId = rowData.bannerId;
          const imgUrl =
            rowData.files && rowData.files.length > 0
              ? rowData.files[0].accessUrl
              : "";
          const orderNo = rowData.order;
          return rowData.bannerId.includes("dummy") ? (
            <div className="action-buttons">
              <button
                className="register-btn"
                onClick={() => {
                  setOrderId(orderNo);
                  navigate("/admin/banner/regist");
                }}
              >
                등록
              </button>
            </div>
          ) : (
            <div className="action-buttons">
              <button
                className="edit-btn"
                onClick={() => {
                  navigate(`/admin/banner/detail/${bannerId}`);
                }}
              >
                수정
              </button>
              <button
                className="delete-btn"
                onClick={() =>
                  bannerDeleteClicked(Number(bannerId), imgUrl, orderNo)
                }
              >
                삭제
              </button>
            </div>
          );
        },
        size: 92,
      },
    ],
    []
  );

  const table = useReactTable({
    data: filledData,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const bannerDeleteClicked = (
    bannerId: number,
    imgUrl: string,
    orderNo: number
  ) => {
    setDeleteBannerInfo({ bannerId, imgUrl, orderNo });
    setBannerDeleteModal({ isOpen: true, isVisible: true });
  };

  // modal handler
  const bannerDelModalClosed = () => {
    setBannerDeleteModal({ isOpen: false, isVisible: false });
  };

  const sortUpdateModalClosed = () => {
    setSortUpdateModal({ isOpen: false, isVisible: false });
  };
  const sortUpdateModalOpen = () => {
    setSortUpdateModal({ isOpen: true, isVisible: true });
  };

  return (
    <TableContainer>
      <TableContents className="another-table-padding">
        <table
          className={
            table.getRowModel().rows.length === 0 ? "no-data-table" : ""
          }
        >
          <thead>
            {table.getHeaderGroups().map((item) => (
              <tr key={item.id}>
                {item.headers.map((header) => (
                  <th
                    key={header.id}
                    style={{ minWidth: `${header.getSize()}px` }}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.length > 0 ? (
              table.getRowModel().rows.map((row) => (
                <tr
                  key={row.id}
                  onDoubleClick={() => {}} // ✅ 더블 클릭 시 상세 페이지 이동
                >
                  {row.getVisibleCells().map((cell) => {
                    const isBanner = cell.column.id === "files";

                    return (
                      <td
                        key={cell.id}
                        className={`${isBanner ? "banner-cell" : ""}`.trim()} // ✅ 여러 개의 클래스 추가
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                </tr>
              ))
            ) : (
              // ✅ 데이터가 없을 경우 "검색 결과가 없습니다" 표시
              <tr className="no-data">
                <td colSpan={columns.length} className="no-data-td">
                  검색 결과가 없습니다.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </TableContents>

      {bannerDeleteModal && (
        <AdminCommonModalLayout
          isVisible={bannerDeleteModal.isVisible}
          closedHandler={bannerDelModalClosed}
        >
          <BannerDeleteModal
            closedHandler={bannerDelModalClosed}
            bannerId={deleteBannerInfo?.bannerId}
            imgUrl={deleteBannerInfo?.imgUrl}
            orderNo={deleteBannerInfo?.orderNo}
          />
        </AdminCommonModalLayout>
      )}

      {sortUpdateModal && (
        <AdminCommonModalLayout
          isVisible={sortUpdateModal.isVisible}
          closedHandler={sortUpdateModalClosed}
        >
          <BannerSortUpdateModal
            closedHandler={sortUpdateModalClosed}
            onClick={sortUpdateclicked}
          />
        </AdminCommonModalLayout>
      )}
    </TableContainer>
  );
};

export default AdminBannerTable;
