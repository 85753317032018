import { BoxInner, TextBox } from "style/modal";

interface ModalProps {
  closedHandler: () => void;
}
const ConfirmModal = ({ closedHandler }: ModalProps) => {
  return (
    <BoxInner>
      <TextBox>
        <p>문의글이 정상 등록되었습니다.</p>
        <span>
          {` 빠르게 확인하여 답변드리겠습니다. \n답변이 등록되면 앱 푸시로 알려드립니다.`}
        </span>
      </TextBox>
      <div className="btn-box">
        <button onClick={closedHandler}>닫기</button>
      </div>
    </BoxInner>
  );
};

export default ConfirmModal;
