import { BoxInner, TextBox } from "style/modal";

interface ModalProps {
  closedHandler: () => void;
  title?: string;
  contents?: string;
}

const ModalLogin = ({
  closedHandler,
  title = "알림",
  contents = "내용을 입력해주세요.",
}: ModalProps) => {
  return (
    <BoxInner>
      <TextBox>
        <p>{title}</p>
        <span>{contents}</span>
      </TextBox>
      <div className="btn-box">
        <button className="continue" onClick={closedHandler}>
          닫기
        </button>
      </div>
    </BoxInner>
  );
};

export default ModalLogin;
