import {
  reviewCommentBeforeUpdateValue,
  reviewCommentUpdateState,
} from "atoms/modal.state";
import { useSetRecoilState } from "recoil";
import styled from "styled-components";

interface ModalReviewCommentEditProps {
  commentId?: number | undefined;
  commentContnets?: string | null;
  handleReviewDeleteConfirmModalOpen: () => void;
  handleModalClose: () => void;
}

const ModalReviewCommentEditBox = ({
  commentId,
  handleReviewDeleteConfirmModalOpen,
  handleModalClose,
  commentContnets,
}: ModalReviewCommentEditProps) => {
  const setCommentUpdateState = useSetRecoilState(reviewCommentUpdateState);
  const setCommentUpdateValue = useSetRecoilState(
    reviewCommentBeforeUpdateValue
  );

  const modifyHandler = () => {
    setCommentUpdateState(true);
    setCommentUpdateValue({
      commentId: commentId ?? null,
      commentContnets: commentContnets ?? null,
    });
    handleModalClose();
  };

  return (
    <BoxInner>
      <ModalItemBox>
        <li onClick={modifyHandler}>수정하기</li>
        <li className="del" onClick={handleReviewDeleteConfirmModalOpen}>
          삭제하기
        </li>
      </ModalItemBox>
    </BoxInner>
  );
};

export default ModalReviewCommentEditBox;

const BoxInner = styled.div``;

const ModalItemBox = styled.ul`
  li {
    display: flex;
    padding: 1.6rem 1rem;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: -0.06rem;
    border-bottom: 1px solid #ececec;
    &.del {
      color: #ed2115;
    }
  }
`;
