import { useEffect, useState } from "react";

// 가상 키보드 감지 커스텀 훅
export const useVirtualKeyboard = () => {
  const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);
  const [initialHeight, setInitialHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      const currentHeight = window.innerHeight;
      const heightDifference = initialHeight - currentHeight;

      // 화면 높이가 100px 이상 줄어들면 가상 키보드가 열린 것으로 간주
      if (heightDifference > 100) {
        setIsKeyboardVisible(true);
      } else {
        setIsKeyboardVisible(false);
      }
    };

    window.addEventListener("resize", handleResize);

    // 초기 높이 설정
    setInitialHeight(window.innerHeight);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [initialHeight]);

  return isKeyboardVisible;
};
