import { BoxInner, TextBox } from "style/admin/layout";
import { fetchDeleteColumn } from "api/admin/content.column";

interface ModalProps {
  closedHandler: () => void;
  contentsId: number;
}

const modalTxt = `모든 고객이 해당 글을 볼 수 없게 됩니다.
[글 숨기기] 버튼을 눌러주세요.`;

const HideColumnModal = ({ closedHandler, contentsId }: ModalProps) => {
  const hideClickedHandle = async (id: number) => {
    try {
      const response = await fetchDeleteColumn(id);
      console.log(response);
      if (response.result) closedHandler();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <BoxInner>
      <TextBox style={{ minHeight: "auto" }}>
        <p>공지사항을 숨김처리 하시겠습니까?</p>
        <span>{modalTxt}</span>
      </TextBox>
      <div className="btn-box">
        <button className="continue" onClick={closedHandler} type="button">
          돌아가기
        </button>
        <span></span>
        <button className="del" onClick={() => hideClickedHandle(contentsId)}>
          글 숨기기
        </button>
      </div>
    </BoxInner>
  );
};

export default HideColumnModal;
