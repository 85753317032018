import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const NotFound = () => {
  return (
    <ErrorWrap>
      <div className="inner">
        <div className="text">
          <h1>PAGE NOT FOUND</h1>
          <h2>ERROR CODE : 404</h2>

          <p>
            존재하지 않는 주소를 입력하셨거나, <br />
            요청하신 페이지의 주소가 <br /> 변경, 삭제되어 찾을 수 없습니다.
          </p>
          <Link to="/home">메인으로 가기 &gt;</Link>
        </div>
      </div>
      <div className="bottom"></div>
    </ErrorWrap>
  );
};

export default NotFound;

// style
const ErrorWrap = styled.div`
  width: 100%;
  height: 100vh;
  .inner {
    border: 2px solid #333;
    display: flex;
    justify-content: center;
    padding: 2rem;
    align-items: center;
    height: calc(100% - 5rem);
    .text {
      text-align: center;
      h1 {
        font-size: 2.8rem;
      }
      h2 {
        font-size: 1.6rem;
        margin-top: 0.2rem;
        margin-bottom: 2rem;
      }
      p {
        font-size: 1.4rem;
        margin-bottom: 2rem;
      }
      a {
        font-size: 1.6rem;
        line-height: 1.4;
        padding: 0.2rem 1rem;
        position: relative;
        transition: all 0.2s;
        font-weight: 700;
        ::after {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 1px;
          background-color: #008fc5;
        }
        :hover {
          color: #008fc5;
        }
      }
    }
  }
  .bottom {
    height: 5rem;
    background-color: #333;
  }
`;
