import { Controller, useForm } from "react-hook-form";
import { BoxInner, TextBox, TextLengthBox } from "style/admin/layout";
import TextareaAutosize from "react-textarea-autosize";
import { UserBlockRequest } from "interface/admin/interface.user";
import { fetchUpdateUserBlock } from "api/admin/user";

interface ModalProps {
  closedHandler: () => void;
  userId: string;
  blockTemporary?: boolean;
}

const DateLimitModal = ({
  closedHandler,
  userId,
  blockTemporary,
}: ModalProps) => {
  const { control, watch, setError, setValue, clearErrors, handleSubmit } =
    useForm();

  const contentsValue = watch("contents") || "";

  // ✅ 현재 시간 기준 14일 후의 날짜 계산
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + 14); // 14일 추가

  // ✅ YYYY-MM-DDTHH:MM:SS 형태로 변환
  const formattedExpDt = currentDate.toISOString(); // UTC 기준 ISO 형식 변환

  const onSubmit = async (data: any) => {
    try {
      // ✅ 요청 데이터 생성
      const requestData: UserBlockRequest = {
        targetUserId: userId, // ✅ 이용 제한 대상 유저 ID
        type: "BLOCK", // ✅ "BLOCK" 또는 "UNBLOCK"
        reason: data.contents, // ✅ 이용 제한 사유
        expDt: blockTemporary ? formattedExpDt : "9999-12-31T23:59:59.000Z", // ✅ "BLOCK"일 경우 expDt 추가
      };

      // ✅ API 요청
      const response = await fetchUpdateUserBlock(requestData);

      if (response.result) {
        closedHandler(); // ✅ 성공 시 모달 닫기
      }
    } catch (error) {
      console.error("🚨 이용 제한 처리 실패:", error);
    }
  };

  // 내용 길이 검증 (1000자 이상 입력 불가)
  const handleContentsChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const inputValue = e.target.value;

    if (inputValue.length > 50) {
      setError("contents", {
        type: "maxLength",
        message: "문의 내용은 최대 1,000자까지 입력 가능합니다.",
      });

      // 🔥 입력값을 1000자까지만 유지하도록 강제 설정
      setValue("contents", inputValue.slice(0, 50));
    } else {
      clearErrors("contents"); // 🔥 에러 초기화
      setValue("contents", inputValue);
    }
  };

  return (
    <BoxInner>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextBox>
          {blockTemporary ? (
            <p>이 회원을 일시 이용제한 하시겠습니까?</p>
          ) : (
            <p>이 회원을 영구 이용제한 하시겠습니까?</p>
          )}
          {blockTemporary ? (
            <span>{`2주간 이용을 제한합니다.
제한해제일시 : ${formattedExpDt}

회원에게 제한사실 및 사유가 푸시 알림으로 발송됩니다.
이용제한 사유를 입력 후, [제한하기] 버튼을 눌러주세요.`}</span>
          ) : (
            <span>{`영구적으로 서비스 이용을 제한합니다.

회원에게 제한사실 및 사유가 푸시 알림으로 발송됩니다.
이용제한 사유를 입력 후, [제한하기] 버튼을 눌러주세요.`}</span>
          )}
          <div>
            <div className="textarea-wrapper">
              <Controller
                name="contents"
                control={control}
                rules={{
                  required: "제한 사유를 입력해주세요.",
                  maxLength: {
                    value: 1000,
                    message: "제한 사유는 최대 50자까지 입력 가능합니다.",
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextareaAutosize
                    {...field}
                    placeholder="제한 사유를 입력해주세요."
                    minRows={3}
                    maxRows={5}
                    onChange={(e) => {
                      field.onChange(e); // react-hook-form의 값 업데이트
                      handleContentsChange(e); // 입력 제한 적용
                    }}
                  />
                )}
              />
            </div>
            <TextLengthBox>
              <span>{`${contentsValue.length} / 50`}</span>
            </TextLengthBox>
          </div>
        </TextBox>
        <div className="btn-box">
          <button className="continue" onClick={closedHandler} type="button">
            돌아가기
          </button>
          <span></span>
          <button
            className="del"
            disabled={Number(contentsValue.length) === 0}
            type="submit"
          >
            제한하기
          </button>
        </div>
      </form>
    </BoxInner>
  );
};

export default DateLimitModal;
