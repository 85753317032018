import { fetchNoticeList } from "api/notice";
import NoticeList from "components/setting/notice/notice.list";
import { NoticeDto } from "interface/interface.notice";
import { useEffect, useState } from "react";
import styled from "styled-components";

const Notice = () => {
  const [noticeDt, setNoticeDt] = useState<NoticeDto[]>([]);

  useEffect(() => {
    const loadNoticeList = async () => {
      try {
        const response = await fetchNoticeList();
        if (response.result) {
          setNoticeDt(response.result);
        }
      } catch (error) {
        console.log(error);
      }
    };

    loadNoticeList();
  }, []);

  return (
    <NoticeContainer>
      {Array.isArray(noticeDt) && noticeDt.length > 0 ? (
        <NoticeListWrap>
          {noticeDt.map((item, index) => (
            <NoticeList key={index} noticeDt={item} />
          ))}
        </NoticeListWrap>
      ) : (
        <NoticeListWrap>
          <div className="none-block-user">
            <p>공지사항이 없습니다.</p>
          </div>
        </NoticeListWrap>
      )}
    </NoticeContainer>
  );
};

export default Notice;

const NoticeContainer = styled.div`
  padding: 0 1.6rem;
`;

const NoticeListWrap = styled.div`
  .none-block-user {
    padding: 4rem 0;
    text-align: center;
    p {
      font-size: 1.4rem;
      font-weight: 700;
      line-height: 2.4rem;
    }
  }
`;
