import { NoiseDefaultTableWrapper } from "style/measure";

const MeasureNoiseDefaultTable = () => {
  return (
    <NoiseDefaultTableWrapper>
      <table>
        <thead>
          <tr>
            <th colSpan={2} rowSpan={2}>
              층간소음의 구분
            </th>
            <th colSpan={2}>층간소음의 기준 [단위: dB(A)]</th>
          </tr>
          <tr>
            <td>
              주간
              <br />
              (06시~22시)
            </td>
            <td>
              야간
              <br />
              (22시~06시)
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th rowSpan={2}>
              직접충격 소음
              <br />
              (발소리 등)
            </th>
            <td>1분간 등가소음도</td>
            <td>39</td>
            <td>34</td>
          </tr>
          <tr>
            <td>최고소음도</td>
            <td>57</td>
            <td>52</td>
          </tr>
          <tr>
            <th>
              공기전달 소음
              <br />
              (TV, 음악 등)
            </th>
            <td>5분간 등가소음도</td>
            <td>45</td>
            <td>40</td>
          </tr>
        </tbody>
      </table>
      <div className="info">
        <div>
          <span>2005년 6월말 이전</span> 사업승인을 받은 공동주택의{" "}
          <span>직접충격 소음</span>에 대해서는 +5 (2025년부터는 +2) dB 적용
        </div>
        <div>출처 : 환경부</div>
      </div>
    </NoiseDefaultTableWrapper>
  );
};

export default MeasureNoiseDefaultTable;
