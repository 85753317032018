import { fetchLoadAdminBanner } from "api/admin/contents.banner";
import { bannerDeleteModalState } from "atoms/admin/contents/banner.state";
import AdminBannerTable from "components/admin/contents/banner/admin.banner.table";
import {
  AdminBannerDto,
  BannerParams,
} from "interface/admin/interface.contents";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { Container } from "style/admin/contents";

const AdminBannerHome = () => {
  const [data, setData] = useState<AdminBannerDto[]>([]);
  const bannerDeleteModal = useRecoilValue(bannerDeleteModalState);
  useEffect(() => {
    const loadBannerList = async () => {
      try {
        const params: BannerParams = {
          orderBy: "ORDER",
          orderByDir: "ASC",
        };
        const response = await fetchLoadAdminBanner(params);
        if (response.result) {
          // ✅ useYn이 "Y"인 항목만 필터링하여 저장
          const filteredData = response.result.filter(
            (item: AdminBannerDto) => item.useYn === "Y"
          );
          setData(filteredData);
        }
      } catch (error) {
        console.error(error);
      }
    };

    loadBannerList();
  }, [bannerDeleteModal]);

  return (
    <Container>
      <AdminBannerTable data={data} />
    </Container>
  );
};

export default AdminBannerHome;
