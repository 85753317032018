import { fetchColumnRegist } from "api/admin/content.column";
import {
  columnRegistAwayModalState,
  columnRegistCompleteModalState,
  columnRegistSubmitTriggerState,
  isColumnRegistChangedState,
} from "atoms/admin/contents/column.state";
import AdminCommonModalLayout from "components/admin/common.modal.layout/admin.common.modal";
import AdminNoticeAttachment from "components/admin/contents/notice/admin.notice.attachment";
import { useEffect } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useRecoilState, useSetRecoilState } from "recoil";
import TextareaAutosize from "react-textarea-autosize";
import { NoticeRegistContainer } from "style/admin/contents";
import ColumnRegistCompleteModal from "components/admin/contents/column/modal/column.regist.complete.modal";
import ColumnRegistAwayModal from "components/admin/contents/column/modal/column.regist.away.modal";

const AdminColumnRegist = () => {
  const { register, handleSubmit, control, setValue } = useForm();
  const [columnSubmitTrigger, setColumnSubmitTrigger] = useRecoilState(
    columnRegistSubmitTriggerState
  );
  const [completeModal, setCompleteModal] = useRecoilState(
    columnRegistCompleteModalState
  );
  const [awayModal, setAwayModal] = useRecoilState(columnRegistAwayModalState);
  const setIsChanged = useSetRecoilState(isColumnRegistChangedState);

  const watchedTitle = useWatch({ control, name: "title" });
  const watchedContents = useWatch({ control, name: "contents" });
  const watchedFiles = useWatch({ control, name: "columnFiles" });

  useEffect(() => {
    // ✅ 새 파일 감지 (File 객체만 필터링)
    const newFiles =
      watchedFiles?.filter((file: any) => file instanceof File) || [];

    // ✅ 제목과 내용은 둘 다 입력되어야 함
    const isTitleValid = !!watchedTitle && watchedTitle.trim() !== "";
    const isContentsValid = !!watchedContents && watchedContents.trim() !== "";

    // ✅ 제목과 내용이 모두 입력되었을 때만 변경 여부 판단
    const isTextChanged = isTitleValid && isContentsValid;

    // ✅ 파일은 없어도 괜찮음, 추가된 경우만 변경됨
    const isFileChanged = newFiles.length > 0;

    // ✅ 제목과 내용이 입력된 상태에서 파일이 추가되거나 텍스트가 변경되었을 때만 변경 감지
    setIsChanged(isTextChanged && (isFileChanged || isTextChanged));
  }, [watchedFiles, watchedTitle, watchedContents]);

  const onSubmit = async (data: any) => {
    try {
      const requestData = {
        title: data.title,
        contents: data.contents,
        files: data.columnFiles?.filter((file: any) => file instanceof File),
      };

      const response = await fetchColumnRegist(requestData);
      if (response.result) {
        setCompleteModal({ isOpen: true, isVisible: true });
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (columnSubmitTrigger) {
      handleSubmit(onSubmit)();
      setColumnSubmitTrigger(false);
    }
  }, [columnSubmitTrigger, handleSubmit]);

  // modal handler
  const completeModalClosed = () => {
    setCompleteModal({ isOpen: false, isVisible: false });
  };
  const awayModalClosed = () => {
    setAwayModal({ isOpen: false, isVisible: false });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <NoticeRegistContainer>
        <div className="title-box">
          <input
            type="text"
            {...register("title")}
            placeholder="제목을 입력하세요."
          />
          <span></span>
        </div>
        <div className="contents-box">
          <Controller
            name="contents"
            control={control}
            rules={{ required: "제목은 필수입니다." }}
            render={({ field }) => (
              <TextareaAutosize
                {...field}
                minRows={17}
                maxRows={17}
                placeholder="내용을 입력하세요."
              />
            )}
          />
          <span></span>
        </div>
        <div className="file-box">
          <AdminNoticeAttachment
            name="columnFiles"
            register={register}
            setValue={setValue}
            initialFiles={[]} // ReviewFile[] 그대로 전달
          />
        </div>

        {completeModal && (
          <AdminCommonModalLayout
            isVisible={completeModal.isVisible}
            closedHandler={completeModalClosed}
          >
            <ColumnRegistCompleteModal closedHandler={completeModalClosed} />
          </AdminCommonModalLayout>
        )}

        {awayModal && (
          <AdminCommonModalLayout
            isVisible={awayModal.isVisible}
            closedHandler={awayModalClosed}
          >
            <ColumnRegistAwayModal closedHandler={awayModalClosed} />
          </AdminCommonModalLayout>
        )}
      </NoticeRegistContainer>
    </form>
  );
};

export default AdminColumnRegist;
