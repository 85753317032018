import { atom } from "recoil";

export const columnHideConfirmModalState = atom<{
  isOpen: boolean;
  isVisible: boolean;
  onClick?: () => void;
}>({
  key: "columnHideConfirmModalState",
  default: {
    isOpen: false,
    isVisible: false,
  },
});

export const columnShowConfirmModalState = atom<{
  isOpen: boolean;
  isVisible: boolean;
  onClick?: () => void;
}>({
  key: "columnShowConfirmModalState",
  default: {
    isOpen: false,
    isVisible: false,
  },
});

export const columnHideState = atom<"Y" | "N">({
  key: "columnHideState",
  default: "N",
});

export const columnHideInfoState = atom<{
  contentsId: number;
  hideYn: "Y" | "N";
}>({
  key: "columnHideInfoState",
  default: {
    contentsId: 0,
    hideYn: "N",
  },
});

export const columnSubmitTriggerState = atom<boolean>({
  key: "columnSubmitTriggerState",
  default: false, // 기본값 false
});

export const columnUpdateCompleteModalState = atom<{
  isOpen: boolean;
  isVisible: boolean;
}>({
  key: "columnUpdateCompleteModalState",
  default: {
    isOpen: false,
    isVisible: false,
  },
});

export const columnAwayModalState = atom<{
  isOpen: boolean;
  isVisible: boolean;
}>({
  key: "columnAwayModalState",
  default: {
    isOpen: false,
    isVisible: false,
  },
});

export const isColumnChangedState = atom<boolean>({
  key: "isColumnChangedState",
  default: false, // 기본값: 변경 없음
});

export const columnRegistSubmitTriggerState = atom<boolean>({
  key: "columnRegistSubmitTriggerState",
  default: false, // 기본값 false
});

export const columnRegistCompleteModalState = atom<{
  isOpen: boolean;
  isVisible: boolean;
}>({
  key: "columnRegistCompleteModalState",
  default: {
    isOpen: false,
    isVisible: false,
  },
});

export const columnRegistAwayModalState = atom<{
  isOpen: boolean;
  isVisible: boolean;
}>({
  key: "columnRegistAwayModalState",
  default: {
    isOpen: false,
    isVisible: false,
  },
});

export const isColumnRegistChangedState = atom<boolean>({
  key: "isColumnRegistChangedState",
  default: false, // 기본값: 변경 없음
});
