import styled from "styled-components";
import { motion } from "framer-motion";

export const InquiryContainer = styled.div`
  padding: 3rem;
  height: 100%;
`;

export const TapMenuWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.6rem;
  border-bottom: 1px solid #ececec;
  width: 100%;
`;

export const TapMenuBox = styled.div`
  display: flex;
  align-items: center;
  gap: 1.6rem;
  flex: 1;
`;

export const TapMenuItem = styled(motion.div)`
  width: 16.35rem;
  padding: 1.8rem 1.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 2.2rem;
  letter-spacing: -0.06rem;
  color: #a1a1a1;
  position: relative;
  cursor: pointer;
  &.isActive {
    color: black;
  }
  .manu-name {
    display: flex;
    gap: 0.4rem;
    span.dot {
      padding-top: 0.4rem;
      width: 0.6rem;
      height: 0.6rem;
      background-color: #ed2115;
      display: flex;
      border-radius: 0.6rem;
    }
  }
`;

export const UnderLine = styled(motion.div)`
  position: absolute;
  bottom: -1px;
  left: 0;
  height: 2px;
  width: 100%;
  background-color: black;
  border-radius: 2px;
`;

export const SearchBox = styled.div`
  width: 41.5rem;
  display: flex;
  align-items: center;
  gap: 1.6rem;
  padding: 0.8rem 1.6rem;
  border: 1px solid #cecece;
  border-radius: 0.5rem;
  .icon-box {
    display: flex;
    width: 2.4rem;
    height: 2.4rem;
    svg {
      width: 100%;
      height: 100%;
    }
  }
  input {
    width: 100%;
    border: 0;
    font-size: 1.6rem;
    font-weight: 700;
    letter-spacing: -0.06rem;
    line-height: 2rem;
  }
`;

export const TabWrapper = styled.div`
  height: calc(100% - 5.7rem);
`;

export const TapItem = styled(motion.div)`
  height: 100%;
`;

export const InquiryPenddingListBox = styled.div`
  height: 100%;
`;

export const InquiryDetailContainer = styled.div`
  height: 100%;
  padding: 3rem;
  display: grid;
  gap: 2.4rem;
  grid-template-columns: 48% 1px 48%;
`;

export const InquiryDetailLeftBox = styled.div`
  .top-box {
    padding: 1.6rem 1.6rem 2.1rem;
    background-color: #ececec;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    border-bottom: 1px solid #a1a1a1;
    .question-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .lbox {
        display: flex;
        align-items: center;
        gap: 0.8rem;
        span {
          padding: 0.4rem;
          color: white;
          font-size: 1.4rem;
          font-weight: 700;
          line-height: 1.8rem;
          letter-spacing: -0.06rem;
          border-radius: 0.2rem;
          &.pending {
            background-color: #ff7a00;
          }
          &.complete {
            background-color: #a1a1a1;
          }
        }
        p {
          font-size: 1.6rem;
          font-weight: 700;
          line-height: 2rem;
          letter-spacing: -0.06rem;
        }
      }
      .rbox {
        font-size: 1.6rem;
        font-weight: 700;
        line-height: 2rem;
        letter-spacing: -0.06rem;
        margin-right: 0.8rem;
        color: #575757;
      }
    }
    .profile {
      display: flex;
      gap: 0.4rem;
      align-items: center;
      .img-box {
        width: 2rem;
        height: 2rem;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          object-fit: cover;
        }
      }
      span {
        font-size: 1.6rem;
        font-weight: 700;
        line-height: 2.4rem;
      }
    }
  }
  .contents-box {
    padding: 1.6rem 2.4rem;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    max-height: 68.2rem;
    p {
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 2.4rem;
      white-space: pre-wrap;
      overflow: hidden scroll;
    }
  }
`;

export const InquiryDetailRightBox = styled.div`
  .top-box {
    padding: 2.4rem 1.6rem 2.3rem;
    background-color: #ececec;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    border-bottom: 1px solid #a1a1a1;
    div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      p {
        font-size: 1.6rem;
        font-weight: 700;
        line-height: 2rem;
        letter-spacing: -0.06rem;
      }
    }
  }
  form {
    .answer-contents-box {
      display: flex;
      flex-direction: column;
      .btn-box {
        padding: 2rem 0;
        input[type="submit"] {
          width: 100%;
          border: none;
          outline: none;
          padding: 1.4rem;
          border-radius: 0.5rem;
          color: white;
          background-color: #225298;
          &:disabled {
            background-color: #cecece;
          }
        }
      }
      .textarea-wrapper {
        padding: 1.6rem;
        border: 1px solid #a1a1a1;
        border-radius: 0.5rem;
        height: 59.8rem;
        textarea {
          resize: none;
          border: none;
          outline: none;
          width: 100%;
          height: 100%;
          padding: 0;
          font-size: 1.4rem;
          line-height: 2rem;
          font-weight: 400;
        }
      }
    }
  }
  .answer-box {
    padding: 1.6rem;
    max-height: 68.2rem;
    overflow: hidden scroll;
    p {
      white-space: pre-wrap;
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 2rem;
    }
  }
`;

export const InquiryDetailHr = styled.div`
  height: 100%;
  width: 1px;
  background-color: #a1a1a1;
`;

export const SliderWrapper = styled.div`
  position: relative;
  .inner {
    padding: 0 4.8rem;
    width: 100%;
    height: 31.7rem;
    max-width: 78.2rem;
    .img-box {
      height: 31.7rem;
      img {
        height: 100%;
        object-fit: contain;
        border-radius: 0.5rem;
        overflow: hidden;
        max-width: 100%;
        margin: 0 auto;
      }
    }
    .slick-prev,
    .slick-next {
      width: 4rem;
      height: 4rem;
    }
    .slick-prev {
      left: -4.8rem;
    }
    .slick-next {
      right: -4.8rem;
    }
  }
  .slide-counter {
    position: absolute;
    bottom: 0.6rem;
    right: 5.7rem;
    padding: 0.2rem 0.4rem;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border-radius: 10rem;
    font-weight: 400;
    line-height: 1.2rem;
    font-size: 1rem;
  }
`;

export const TextLengthBox = styled.div`
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.6rem;
  color: #a1a1a1;
  text-align: right;
  padding: 0 0.4rem;
`;
