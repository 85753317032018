import { PageClosedIcon } from "cssReset/icon";
import { useNavigate } from "react-router-dom";
import { MeasureHeader } from "style/measure";

const MeasureRecordingResultHeader = () => {
  const navigate = useNavigate();

  return (
    <MeasureHeader>
      <div className="closed-btn-box">
        <button onClick={() => navigate("/home")}>
          <div className="icon-box">
            <PageClosedIcon />
          </div>
        </button>
      </div>
      <div className="header-title">
        <p>층간소음 측정 결과</p>
      </div>
    </MeasureHeader>
  );
};

export default MeasureRecordingResultHeader;
