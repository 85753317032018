import React, { useState, useEffect } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
} from "recharts";

const MAX_DATA_POINTS = 60; // X축 60초 유지
const INTERVAL_MS = 1000; // 1초마다 데이터 추가

interface DataDto {
  time: string;
  value: number;
}

interface Props {
  data: DataDto[];
  isRecording: boolean;
}

interface DataPoint {
  name: number;
  db: number | null; // ✅ 타입을 number | null로 변경
}

const MeasureChartRechart = ({ data, isRecording }: Props) => {
  // 초기 데이터 (X축을 0~59로 설정)
  const initialData = Array.from({ length: MAX_DATA_POINTS }, (_, i) => ({
    name: i - MAX_DATA_POINTS, // 0~59초 고정된 X축
    db: null, // 초기 데이터
  }));

  const [recordData, setRecordData] = useState<DataPoint[]>(initialData); // 그래프 데이터
  const [currentTime, setCurrentTime] = useState(0); // 현재 시간 (초)

  useEffect(() => {
    if (!isRecording) {
      return;
    }

    const interval = setInterval(() => {
      setCurrentTime((prev) => prev + 1);

      setRecordData((prevData) => {
        if (data.length === 0) {
          return prevData;
        }

        const latestData = data[data.length - 1]; // 가장 최신 데이터 가져오기
        const newDataPoint = {
          name:
            prevData.length > 0 ? prevData[prevData.length - 1].name + 1 : -60,
          db: latestData.value,
        };

        return [...prevData.slice(1), newDataPoint]; // 기존 데이터 유지하면서 새 값 추가
      });
    }, INTERVAL_MS);

    return () => {
      clearInterval(interval);
    };
  }, [isRecording]); // ✅ isRecording이 변경될 때만 실행 (data 제거)

  // 2️⃣ data 변경 시 바로 recordData 업데이트
  useEffect(() => {
    if (data.length === 0) {
      setRecordData(initialData); // 🔥 logs 초기화 시 recordData도 초기화
      return;
    }

    setRecordData((prevData) => {
      const latestData = data[data.length - 1]; // 가장 최신 데이터 가져오기
      const newDataPoint = {
        name: prevData.length > 0 ? prevData[prevData.length - 1].name + 1 : 0,
        db: latestData.value,
      };

      return [...prevData.slice(1), newDataPoint];
    });
  }, [data]); // ✅ data 변경 시 실행

  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart
        data={recordData}
        margin={{ top: 25, right: 18, left: 0, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="0" vertical={false} stroke="#ececec" />
        <XAxis
          dataKey="name"
          tickFormatter={(tick) =>
            tick % 10 === 0 && tick >= 0 ? tick.toString() : ""
          } // ✅ 10단위 & 음수 숨김
          domain={[-60, 0]} // ✅ -60초부터 0초까지 유지
          interval={0} // 모든 tick 검사 후 필터링
          label={{
            value: "경과 시간",
            position: "insideBottomRight",
            offset: 0,
            dy: 2,
          }}
          axisLine={false} // ✅ X축 축 선 제거
          tickLine={false}
        />

        <YAxis
          label={{
            value: "소리 강도(dB HL)",
            angle: -90,
            position: "insideLeftTop",
            dy: -85,
            dx: -8,
          }}
          domain={[0, 110]} // ✅ Y축을 10~100으로 설정
          tickCount={12} // ✅ 10개 단위로 표시
          tickFormatter={(tick) => (tick % 10 === 0 ? tick : "")} // ✅ 10 단위 값만 표시
          axisLine={false} // ✅ Y축 축 선 제거
          tickLine={false} // ✅ Y축 눈금선 제거
        />

        <Tooltip />
        <ReferenceLine
          y={57} // ✅ 기준선 위치 (60dB)
          stroke="#242629" // ✅ 선 색상
          strokeWidth={1} // ✅ 선 굵기
        />
        <ReferenceLine
          y={52} // ✅ 기준선 위치 (60dB)
          stroke="yellow" // ✅ 선 색상
          strokeWidth={1} // ✅ 선 굵기
        />
        {/* <Legend /> */}
        <Line
          type="monotone"
          dataKey="db"
          stroke="#9CD0DE"
          dot={false}
          activeDot={{ r: 6 }}
          isAnimationActive={false}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default MeasureChartRechart;
