import { ResultInfoWrap } from "style/measure";
import {
  formatDateTimeAddSecYear,
  formatTodayDate,
  recordResultCalculateElapsedTime,
} from "utils/util";

interface ComponentsProps {
  startDt: string;
  endDt: string;
  location: string;
  simpleMeasureResultId: number;
}

const MeasureRecordResultTopInfo = ({
  endDt,
  location,
  startDt,
  simpleMeasureResultId,
}: ComponentsProps) => {
  const today = formatTodayDate();

  return (
    <ResultInfoWrap>
      <div className="item another">
        <div className="measure-depth">
          <span>간이 측정</span>
        </div>
        <div className="value">{`${today}_${simpleMeasureResultId}`}</div>
      </div>
      <div className="item">
        <div className="title">측정 장소</div>
        <div className="value">{location}</div>
      </div>
      <div className="item align-start">
        <div className="title">측정 기간</div>
        <div className="value">
          {`${formatDateTimeAddSecYear(
            String(startDt)
          )} ~ ${formatDateTimeAddSecYear(String(endDt))}`}
          <span className="elapsed-time">
            {`(${recordResultCalculateElapsedTime(startDt, endDt)})`}
          </span>
        </div>
      </div>
    </ResultInfoWrap>
  );
};

export default MeasureRecordResultTopInfo;
