import axiosInstance from "../plugin/axios";

interface UploadFileParams {
  fileGroupId: string;
  fileType: string;
  files: File[]; // 여러 개의 파일 업로드 가능
}

export const fetchRegistFile = async ({
  fileGroupId,
  fileType,
  files,
}: UploadFileParams) => {
  try {
    console.log("fetch로 넘어온 파일들", files, fileGroupId, fileType);
    const formData = new FormData();

    // 🔹 파일 메타데이터 추가 (JSON 데이터)
    formData.append(
      "data",
      new Blob([JSON.stringify({ fileGroupId, fileType })], {
        type: "application/json",
      })
    );

    // 🔹 선택한 파일 추가
    files.forEach((file) => {
      formData.append("files", file);
    });

    const response = await axiosInstance.post(`/file`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

export const fetchDeleteFileId = async (fileId: number) => {
  try {
    const response = await axiosInstance.delete(`/file/${fileId}`);
    return response.data;
  } catch (error) {
    throw new Error();
  }
};
