import { useNavigate } from "react-router-dom";
import { BoxInner, TextBox } from "style/admin/layout";

interface ModalProps {
  closedHandler: () => void;
}

const modalTxt = `최초 등록 시 글은 숨겨진 상태입니다.

작성 내용을 확인하신 후, 
문제가 없다면 [글 노출하기] 버튼을 눌러주세요.`;

const NoticeRegistCompleteModal = ({ closedHandler }: ModalProps) => {
  const navigate = useNavigate();
  const clickHandler = () => {
    closedHandler();
    navigate(`/admin/notice`);
  };

  return (
    <BoxInner>
      <TextBox style={{ minHeight: "auto" }}>
        <p>공지가 등록되었습니다.</p>
        <span>{modalTxt}</span>
      </TextBox>
      <div className="btn-box">
        <button className="continue" onClick={clickHandler}>
          확인
        </button>
      </div>
    </BoxInner>
  );
};

export default NoticeRegistCompleteModal;
