import { TableNavigation } from "style/admin/table";

interface PaginationProps {
  table: any;
}

const PaginationControls = ({ table }: PaginationProps) => {
  return (
    <TableNavigation>
      {/* ✅ 이전 페이지 버튼 */}
      <button
        onClick={() => table.previousPage()}
        disabled={!table.getCanPreviousPage()} // ❌ 이전 페이지가 없으면 비활성화
        className={`prev-button ${
          table.getCanPreviousPage() ? "active" : "disabled"
        }`}
      >
        {table.getCanPreviousPage() ? (
          <img src="/assets/icon/leftBtn_active.png" alt="이전 페이지" />
        ) : (
          <img src="/assets/icon/leftBtn.png" alt="이전 페이지" />
        )}
      </button>

      {/* ✅ 다음 페이지 버튼 */}
      <button
        onClick={() => table.nextPage()}
        disabled={!table.getCanNextPage()} // ❌ 다음 페이지가 없으면 비활성화
        className={`next-button ${
          table.getCanNextPage() ? "active" : "disabled"
        }`}
      >
        {table.getCanNextPage() ? (
          <img src="/assets/icon/rightBtn_active.png" alt="다음 페이지" />
        ) : (
          <img src="/assets/icon/rightBtn.png" alt="다음 페이지" />
        )}
      </button>
    </TableNavigation>
  );
};

export default PaginationControls;
