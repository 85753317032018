import {
  fetchLoadReviewComment,
  fetchLoadReviewDetail,
} from "api/admin/review";
import {
  bestReviewState,
  regUserIdState,
  reviewHideInfoState,
  reviewHideState,
  reviewShowUpdateTrigger,
} from "atoms/admin/review/admin.review.state";
import AdminDetailTopContents from "components/admin/reivew/admin.detail.top.contents";
import {
  CustomNextArrow,
  CustomPrevArrow,
} from "components/admin/slick.arrow/slick.arrow";
import {
  AdminReviewDto,
  ReviewCommentDto,
} from "interface/admin/interface.review";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { DetailContainer, SliderWrapper } from "style/admin/review";
import AdminReviewDetailComment from "components/admin/reivew/admin.review.detail.comment";
import {
  bestReviewCancelUpdateState,
  bestReviewUpdateState,
  reviewCommentHideConfirmModalState,
  reviewCommentShowConfirmModalState,
  reviewHideConfirmModalState,
  reviewShowConfirmModalState,
} from "atoms/admin/modal/modal.state";
import AdminCommonModalLayout from "components/admin/common.modal.layout/admin.common.modal";
import HideConfirmModal from "components/admin/reivew/modal/hide.confirm.modal";
import HideReviewConfirmModal from "components/admin/reivew/modal/hide.review.comfirm.modal";
import ShowConfirmModal from "components/admin/reivew/modal/show.confirm.modal";
import ShowReviewConfirmModal from "components/admin/reivew/modal/show.review.confirm.modal";
import BestReviewUpdateModal from "components/admin/reivew/modal/best.review.update.modal";
import BestReviewCancelModal from "components/admin/reivew/modal/best.review.cancel.modal";

const AdminReviewDetail = () => {
  const { id } = useParams();
  const [data, setData] = useState<AdminReviewDto>();
  const [commentData, setCommentData] = useState<ReviewCommentDto[]>();
  const [reviewCommentId, setReviewCommentId] = useState<number | null>(null);
  const [isHideState, setIsHideState] = useState<"Y" | "N">();
  const [updateTrigger, setUpdateTrigger] = useState(false); // 업데이트 트리거 추가
  const showUpdateTrigger = useRecoilValue(reviewShowUpdateTrigger);
  const setRegUserId = useSetRecoilState(regUserIdState);

  const [currentSlide, setCurrentSlide] = useState(1); // 현재 슬라이드 번호
  const [hideConfirmModal, setHideConfirmModal] = useRecoilState(
    reviewCommentHideConfirmModalState
  );
  const [showConfirmModal, setShowConfirmModal] = useRecoilState(
    reviewCommentShowConfirmModalState
  );
  const [reviewHideModal, setReviewHideModal] = useRecoilState(
    reviewHideConfirmModalState
  );
  const [reviewShowModal, setReviewShowModal] = useRecoilState(
    reviewShowConfirmModalState
  );
  const [bestReview, setBestReview] = useRecoilState(bestReviewUpdateState);
  const [bestReviewCancel, setBestReviewCancel] = useRecoilState(
    bestReviewCancelUpdateState
  );
  const setReviewHide = useSetRecoilState(reviewHideState);
  const setReviewHideInfo = useSetRecoilState(reviewHideInfoState);
  const setBestReviewStatus = useSetRecoilState(bestReviewState);

  const sliderSetting = {
    infinite: !!(data?.files && data.files.length > 1), // ✅ null 제거 및 boolean 변환
    speed: 500,
    slidesToShow: 1, // 3개 이하일 경우 슬라이드를 하나만 보여줌
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />, // ✅ 커스텀 컴포넌트 사용
    nextArrow: <CustomNextArrow />, // ✅ 커스텀 컴포넌트 사용
    afterChange: (current: number) => setCurrentSlide(current + 1), // 현재 슬라이드 번호 업데이트
  };

  useEffect(() => {
    const loadReviewData = async () => {
      try {
        // 리뷰 상세 정보 불러오기
        const reviewResponse = await fetchLoadReviewDetail(Number(id));
        setData(reviewResponse.result);
        setReviewHide(reviewResponse.result.hideYn);
        setReviewHideInfo({
          reviewId: Number(id),
          hideYn: reviewResponse.result.hideYn,
        });
        setBestReviewStatus(reviewResponse.result.bestYn);
        setRegUserId(reviewResponse.result.regUserId);

        // 리뷰 댓글 불러오기
        const commentResponse = await fetchLoadReviewComment(Number(id));
        setCommentData(commentResponse.result);
      } catch (error) {
        console.error("리뷰 데이터를 불러오는 중 오류 발생:", error);
      }
    };

    loadReviewData();
  }, [
    id,
    hideConfirmModal,
    updateTrigger,
    reviewHideModal,
    showUpdateTrigger,
    reviewShowModal,
    bestReview,
    bestReviewCancel,
  ]); // ✅ id가 변경될 때만 실행

  // 후기 댓글 모달 핸들러
  const hideConfirmModalClosed = () => {
    setHideConfirmModal({ isOpen: false, isVisible: false });
  };
  const commentIdPropsHandler = (id: number, hideState: "Y" | "N") => {
    setReviewCommentId(id);
    setIsHideState(hideState);
  };
  const showConfirmModalClosed = () => {
    setShowConfirmModal({ isOpen: false, isVisible: false });
  };

  // 후기 모달 핸들러
  const reviewHideModalClosed = () => {
    setReviewHideModal({ isOpen: false, isVisible: false });
  };
  const reviewShowModalClosed = () => {
    setReviewShowModal({ isOpen: false, isVisible: false });
  };

  // 베스트 후기 핸들러
  const bestReviewModalClosed = () => {
    setBestReview({ isOpen: false, isVisible: false });
  };
  const bestReviewCancelModalClosed = () => {
    setBestReviewCancel({ isOpen: false, isVisible: false });
  };

  const triggerUpdate = () => {
    setUpdateTrigger((prev) => !prev); // 상태 변경으로 `useEffect` 재실행
  };

  return (
    <DetailContainer>
      {data && (
        <AdminDetailTopContents
          hideYn={data?.hideYn}
          regDt={data?.regDt}
          reviewCommentCount={data?.reviewCommentCount}
          reviewReportCount={data?.reviewReportCount}
          title={data?.title}
          userName={data?.userName}
          userProfileUrl={data?.userProfileUrl}
          views={data?.views}
          reviewId={data?.reviewId}
        />
      )}
      <div className="detail-wrapper">
        <div className="contents-box">
          <p className="content-txt">{data?.contents}</p>
          {data?.files && data.files.length > 0 ? (
            <SliderWrapper>
              <div className="inner">
                <Slider {...sliderSetting}>
                  {data.files.map((img, index) => (
                    <div className="img-box" key={img.fileId}>
                      {/* src url 배포시 수정 */}
                      <img src={img.accessUrl} alt={img.realFileName} />
                    </div>
                  ))}
                </Slider>
                {/* 현재 슬라이드 위치 표시 */}
                <div className="slide-counter">
                  {currentSlide} / {data.files.length}
                </div>
              </div>
            </SliderWrapper>
          ) : null}
        </div>
        {commentData && (
          <AdminReviewDetailComment
            commentData={commentData}
            regUserId={data?.regUserId}
            commentIdPropsHandler={commentIdPropsHandler}
            triggerUpdate={triggerUpdate}
          />
        )}
      </div>

      {/* 댓글 숨겨짐 모달 */}
      {hideConfirmModal && (
        <AdminCommonModalLayout
          isVisible={hideConfirmModal.isVisible}
          closedHandler={hideConfirmModalClosed}
        >
          <HideConfirmModal
            closedHandler={hideConfirmModalClosed}
            reviewCommentId={reviewCommentId || 0}
            isHideState={isHideState || "N"}
          />
        </AdminCommonModalLayout>
      )}

      {/* 댓글 노출하기 모달 */}
      {showConfirmModal && (
        <AdminCommonModalLayout
          isVisible={showConfirmModal.isVisible}
          closedHandler={showConfirmModalClosed}
        >
          <ShowConfirmModal
            closedHandler={showConfirmModalClosed}
            reviewCommentId={reviewCommentId || 0}
            isHideState={isHideState || "N"}
          />
        </AdminCommonModalLayout>
      )}

      {/* 후기 숨기기 모달 */}
      {reviewHideModal && (
        <AdminCommonModalLayout
          isVisible={reviewHideModal.isVisible}
          closedHandler={reviewHideModalClosed}
        >
          <HideReviewConfirmModal
            closedHandler={reviewHideModalClosed}
            reviewId={Number(id) || 0}
            isHideState={isHideState || "N"}
          />
        </AdminCommonModalLayout>
      )}

      {/* 후기 노출하기 모달 */}
      {reviewShowModal && (
        <AdminCommonModalLayout
          isVisible={reviewShowModal.isVisible}
          closedHandler={reviewShowModalClosed}
        >
          <ShowReviewConfirmModal
            closedHandler={reviewShowModalClosed}
            reviewId={Number(id) || 0}
            isHideState={data?.hideYn || "N"}
          />
        </AdminCommonModalLayout>
      )}

      {/* 베스트 후기 지정 모달 */}
      {bestReview && (
        <AdminCommonModalLayout
          isVisible={bestReview.isVisible}
          closedHandler={bestReviewModalClosed}
        >
          <BestReviewUpdateModal
            closedHandler={bestReviewModalClosed}
            reviewId={data?.reviewId ?? 0}
          />
        </AdminCommonModalLayout>
      )}

      {/* 베스트 후기 해제 모달 */}
      {bestReviewCancel && (
        <AdminCommonModalLayout
          isVisible={bestReviewCancel.isVisible}
          closedHandler={bestReviewCancelModalClosed}
        >
          <BestReviewCancelModal
            closedHandler={bestReviewCancelModalClosed}
            reviewId={data?.reviewId ?? 0}
          />
        </AdminCommonModalLayout>
      )}
    </DetailContainer>
  );
};

export default AdminReviewDetail;
