import { BoxInner, TextBox } from "style/admin/layout";

interface ModalProps {
  closedHandler: () => void;
  onClick: () => void;
}

const modalTxt = `등록하면 고객 어플리케이션에서 즉시 적용되며,
고객에게 푸시 알림이 발송됩니다.

등록 후 답변 수정이나 삭제가 불가능합니다.
정확한 내용을 작성하셨다면 [등록하기] 를 눌러주세요.`;

const AnswerRegistConfirmModal = ({ closedHandler, onClick }: ModalProps) => {
  return (
    <BoxInner>
      <TextBox>
        <p>답변을 등록하시겠습니까?</p>
        <span>{modalTxt}</span>
      </TextBox>
      <div className="btn-box">
        <button className="continue" onClick={closedHandler}>
          돌아가기
        </button>
        <span></span>
        <button className="del" onClick={onClick}>
          등록하기
        </button>
      </div>
    </BoxInner>
  );
};

export default AnswerRegistConfirmModal;
