import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { SettingMenuData } from "utils/datas";

// 마이 페이지
const ProfileHome = () => {
  return (
    <SettingContainer>
      <SettingMenu>
        {SettingMenuData.map((menu, index, arr) => {
          const shouldAddSpace =
            index > 0 && arr[index - 1].isTitle === false && menu.isTitle; // ✅ 앞 요소가 일반 메뉴이고, 현재 요소가 제목이면 공백 추가

          return (
            <React.Fragment key={index}>
              {shouldAddSpace && (
                <li>
                  <Spacer />
                </li>
              )}{" "}
              {/* ✅ 빈 li 추가 */}
              <li className="menu-item">
                {menu.isTitle ? (
                  <Title>{menu.title}</Title>
                ) : (
                  <MenuItem>
                    <Link to={menu.path || "#"}>{menu.title}</Link>
                  </MenuItem>
                )}
              </li>
            </React.Fragment>
          );
        })}
      </SettingMenu>
    </SettingContainer>
  );
};

export default ProfileHome;

const SettingContainer = styled.div`
  padding: 1.6rem;
`;

const SettingMenu = styled.ul`
  display: grid;
  gap: 1.6rem;
  .menu-item {
    height: 2.8rem;
    display: flex;
    align-items: center;
  }
`;

const Title = styled.p`
  font-size: 1.4rem;
  color: black;
  font-weight: 700;
  line-height: 1.8rem;
  letter-spacing: -0.06rem;
`;

const MenuItem = styled.div`
  font-size: 1rem;
  color: black;

  a {
    text-decoration: none;
    color: inherit;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.6rem;
  }
`;

const Spacer = styled.div`
  border: 1px solid #d9d9d9;
`;
