import { fetchUpdateBestReviewCancel } from "api/admin/review";
import { BoxInner, TextBox } from "style/admin/layout";

interface ModalProps {
  closedHandler: () => void;
  reviewId: number;
}

const modalTxt = `베스트 후기를 해제합니다.

[베스트 후기 해제] 버튼을 눌러주세요.`;

const BestReviewCancelModal = ({ closedHandler, reviewId }: ModalProps) => {
  const bestReviewCancelUpdate = async () => {
    try {
      const response = await fetchUpdateBestReviewCancel(reviewId);
      if (response.result) closedHandler();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <BoxInner>
      <TextBox style={{ minHeight: "auto" }}>
        <p>베스트 후기를 해제하시겠습니까?</p>
        <span>{modalTxt}</span>
      </TextBox>
      <div className="btn-box">
        <button className="continue" onClick={closedHandler} type="button">
          돌아가기
        </button>
        <span></span>
        <button className="del" onClick={bestReviewCancelUpdate}>
          베스트 후기 해제하기
        </button>
      </div>
    </BoxInner>
  );
};

export default BestReviewCancelModal;
