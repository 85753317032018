import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { AdminSearchIcon } from "cssReset/icon";
import { Container, TapMenuBox, TapMenuItem } from "style/admin/review";
import {
  SearchBox,
  TabWrapper,
  TapItem,
  TapMenuWrapper,
  UnderLine,
} from "style/admin/inquiry";
import AdminReviewList from "components/admin/reivew/admin.review.list";

const AdminReviewHome = () => {
  const [tap, setTap] = useState(0);
  const [searchInput, setSearchInput] = useState(""); // ✅ 입력값 상태
  const [searchTerm, setSearchTerm] = useState(""); // ✅ API 요청을 위한 상태
  const [dataLengths, setDataLengths] = useState<{ [key: number]: boolean }>({
    0: false,
    1: false,
    2: false,
    3: false,
  }); // ✅ 각 탭별로 dataLength 저장

  // ✅ 탭 변경 핸들러
  const menuHandler = (idx: number) => {
    setTap(idx);
    setSearchInput("");
    setSearchTerm("");
  };

  // ✅ 검색 실행 (버튼 클릭 또는 Enter 입력 시 실행)
  const handleSearch = () => {
    setSearchTerm(searchInput); // ✅ API를 호출하는 상태 변경
  };

  // ✅ Enter 키 입력 시 검색 실행
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  // ✅ 데이터 길이를 업데이트하는 핸들러
  const handleDataLengthUpdate = (tabIndex: number, length: boolean) => {
    setDataLengths((prev) => ({ ...prev, [tabIndex]: length }));
  };

  return (
    <Container>
      <TapMenuWrapper>
        <TapMenuBox>
          {["신고 누적", "정상노출", "숨겨짐", "전체글", "베스트 후기"].map(
            (menu, index) => (
              <TapMenuItem
                key={index}
                onClick={() => menuHandler(index)}
                className={tap === index ? "isActive" : ""}
                layout
                transition={{ type: "spring", stiffness: 300, damping: 20 }}
              >
                <motion.span
                  transition={{ duration: 0.3 }}
                  className="manu-name"
                >
                  {menu}
                  {/* ✅ 탭이 0번일 때만 dot을 표시하고, 데이터가 존재할 경우 유지 */}
                  {index === 0 && dataLengths[0] && (
                    <span className="dot"></span>
                  )}
                </motion.span>

                {tap === index && (
                  <UnderLine
                    layoutId="underline"
                    className="underline"
                    transition={{ type: "spring", stiffness: 300, damping: 20 }}
                  />
                )}
              </TapMenuItem>
            )
          )}
        </TapMenuBox>

        {/* ✅ 검색창 */}
        <SearchBox>
          <div className="icon-box" onClick={handleSearch}>
            <AdminSearchIcon />
          </div>

          <input
            type="text"
            placeholder="제목, 내용, 작성자 검색"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            onKeyDown={handleKeyDown}
          />
        </SearchBox>
      </TapMenuWrapper>

      <TabWrapper>
        <AnimatePresence mode="wait" initial={false}>
          {[0, 1, 2, 3, 4].map(
            (index) =>
              tap === index && (
                <TapItem
                  key={index}
                  initial={{ opacity: 0, x: 20 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: -20 }}
                  transition={{ duration: 0.3 }}
                >
                  <AdminReviewList
                    searchTerm={searchTerm}
                    setDataLength={(length: any) =>
                      handleDataLengthUpdate(index, length)
                    }
                    {...(index === 0 ? { reportCount: 10, hideYn: "N" } : {})}
                    {...(index === 1 ? { hideYn: "N" } : {})}
                    {...(index === 2 ? { hideYn: "Y" } : {})}
                    {...(index === 4 ? { bestYn: "Y" } : {})}
                  />
                </TapItem>
              )
          )}
        </AnimatePresence>
      </TabWrapper>
    </Container>
  );
};

export default AdminReviewHome;
