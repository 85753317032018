import { styled } from "styled-components";
import { motion } from "framer-motion";

export const Container = styled.div`
  padding: 3rem;
  height: 100%;
`;

export const UnderLine = styled(motion.div)`
  position: absolute;
  bottom: -1px;
  left: 0;
  height: 2px;
  width: 100%;
  background-color: black;
  border-radius: 2px;
`;

export const SearchBox = styled.div`
  width: 41.5rem;
  display: flex;
  align-items: center;
  gap: 1.6rem;
  padding: 0.8rem 1.6rem;
  border: 1px solid #cecece;
  border-radius: 0.5rem;
  .icon-box {
    display: flex;
    width: 2.4rem;
    height: 2.4rem;
    svg {
      width: 100%;
      height: 100%;
    }
  }
  input {
    width: 100%;
    border: 0;
    font-size: 1.6rem;
    font-weight: 700;
    letter-spacing: -0.06rem;
    line-height: 2rem;
  }
`;

export const TabWrapper = styled.div`
  height: calc(100% - 5.7rem);
`;

export const TapMenuWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.6rem;
  border-bottom: 1px solid #ececec;
  width: 100%;
`;

export const TapItem = styled(motion.div)`
  height: 100%;
`;

export const ListBox = styled.div`
  height: 100%;
`;

export const TapMenuBox = styled.div`
  display: flex;
  align-items: center;
  gap: 1.6rem;
  flex: 1;
`;

export const TapMenuItem = styled(motion.div)`
  width: 16.5rem;
  padding: 1.8rem 1.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 2.2rem;
  letter-spacing: -0.06rem;
  color: #a1a1a1;
  position: relative;
  cursor: pointer;
  &.isActive {
    color: black;
  }
  .manu-name {
    display: flex;
    gap: 0.4rem;
    span.dot {
      padding-top: 0.4rem;
      width: 0.6rem;
      height: 0.6rem;
      background-color: #ed2115;
      display: flex;
      border-radius: 0.6rem;
    }
  }
`;

export const UserDefaultInfoWrap = styled.ul`
  padding: 1.6rem 0;
  li {
    padding: 1.2rem 2.4rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    &.flex-start {
      align-items: flex-start;
    }
    .title {
      width: 10rem;
      font-size: 1.6rem;
      font-weight: 700;
      line-height: 2rem;
      letter-spacing: -0.06rem;
    }
    .value {
      flex: 1;
      display: flex;
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 2.4rem;
      .item {
        padding: 0.8rem 1.35rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid;
        border-radius: 100px;

        font-size: 1.6rem;
        font-weight: 700;
        line-height: 2rem;
        letter-spacing: -0.06rem;
        &.bluebox {
          border-color: #225298;
          color: #225298;
        }
        &.graybox {
          border-color: #575757;
          color: #575757;
        }
        &.redbox {
          border-color: #ed2115;
          color: #ed2115;
        }
        &.withdraw {
          border-color: #a1a1a1;
          color: #a1a1a1;
        }
      }
      .limit-wrap {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        max-height: 13rem;
        overflow: hidden scroll;
        flex: 1;
        span {
          display: flex;
          span {
            margin: 0 1.6rem;
          }
        }
      }
      .redbox {
        color: #ed2115;
      }
      .flex {
        display: flex;
        gap: 1.6rem;
        .account-type {
          width: 10rem;
        }
      }
    }
  }
`;
