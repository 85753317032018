import { fetchRestoreColumn } from "api/admin/content.column";
import { BoxInner, TextBox } from "style/admin/layout";

interface ModalProps {
  closedHandler: () => void;
  contentsId: number;
}

const modalTxt = `모든 고객이 해당 글을 볼 수 있게 됩니다.

글 내용을 확인 후, [글 노출하기] 버튼을 눌러주세요.`;

const ShowColumnModal = ({ contentsId, closedHandler }: ModalProps) => {
  const showClickHandler = async (id: number) => {
    try {
      const response = await fetchRestoreColumn(id);
      if (response.result) closedHandler();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <BoxInner>
      <TextBox style={{ minHeight: "auto" }}>
        <p>글을 다시 노출하시겠습니까?</p>
        <span>{modalTxt}</span>
      </TextBox>
      <div className="btn-box">
        <button className="continue" onClick={closedHandler} type="button">
          돌아가기
        </button>
        <span></span>
        <button className="del" onClick={() => showClickHandler(contentsId)}>
          글 노출하기
        </button>
      </div>
    </BoxInner>
  );
};

export default ShowColumnModal;
