import { BoxInner, TextBox } from "style/admin/layout";

interface ModalProps {
  closedHandler: () => void;
  onClick: () => void;
}

const modalTxt = `현재 설정된 내용으로 메인 배너를 업데이트합니다.
고객 어플리케이션에 즉시 적용됩니다.`;

const BannerSortUpdateModal = ({ closedHandler, onClick }: ModalProps) => {
  return (
    <BoxInner>
      <TextBox style={{ minHeight: "auto" }}>
        <p>변경사항을 업데이트하시겠습니까?</p>
        <span>{modalTxt}</span>
      </TextBox>
      <div className="btn-box">
        <button className="continue" onClick={closedHandler} type="button">
          페이지로 돌아가기
        </button>
        <span></span>
        <button className="del" onClick={onClick}>
          업데이트 적용하기
        </button>
      </div>
    </BoxInner>
  );
};

export default BannerSortUpdateModal;
