import JoinHeader from "components/auth/join/join.header";
import UnauthenticatedMeasureStepTwo from "components/measure/components/unauthenticated/step/unauthenticated.measure.step.two";
import MeasureStepOne from "components/measure/step/measure.step.one";
import MeasureStepThree from "components/measure/step/measure.step.three";
import { AnimatePresence } from "framer-motion";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SimpleContainer, Step } from "style/measure";

const UnauthenticatedSimpleMeasureStep = () => {
  const [step, setStep] = useState(0); // 현재 스텝을 관리
  const navigate = useNavigate();

  const handleNext = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const backArrowHandler = () => {
    if (step === 0) {
      navigate("/measure");
    } else {
      setStep((prevStep) => prevStep - 1);
    }
  };

  return (
    <SimpleContainer>
      <JoinHeader onClick={backArrowHandler} headerTitle="층간소음 간이 측정" />
      <AnimatePresence mode="wait" initial={false}>
        {step === 0 && (
          <Step
            key={0}
            initial={{ opacity: 0, y: 20 }} // 초기 상태 (살짝 오른쪽에서 시작)
            animate={{ opacity: 1, y: 0 }} // 나타날 때
            exit={{ opacity: 0, y: -20 }} // 사라질 때 (왼쪽으로 이동하며 사라짐)
            transition={{ duration: 0.3 }} // 애니메이션 속도
          >
            <MeasureStepOne handleNext={handleNext} />
          </Step>
        )}
        {step === 1 && (
          <Step
            key={1}
            initial={{ opacity: 0, y: 20 }} // 초기 상태 (살짝 오른쪽에서 시작)
            animate={{ opacity: 1, y: 0 }} // 나타날 때
            exit={{ opacity: 0, y: -20 }} // 사라질 때 (왼쪽으로 이동하며 사라짐)
            transition={{ duration: 0.3 }} // 애니메이션 속도
          >
            <UnauthenticatedMeasureStepTwo />
          </Step>
        )}
        {step === 2 && (
          <Step
            key={2}
            initial={{ opacity: 0, y: 20 }} // 초기 상태 (살짝 오른쪽에서 시작)
            animate={{ opacity: 1, y: 0 }} // 나타날 때
            exit={{ opacity: 0, y: -20 }} // 사라질 때 (왼쪽으로 이동하며 사라짐)
            transition={{ duration: 0.3 }} // 애니메이션 속도
          >
            <MeasureStepThree />
          </Step>
        )}
      </AnimatePresence>
    </SimpleContainer>
  );
};

export default UnauthenticatedSimpleMeasureStep;
